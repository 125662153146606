import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  useTheme, Flex,
} from '@chakra-ui/react';
import MdiIcon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';
import InView from '~ui/InView';
import * as C from './AlertOverviewSection.styled';
import OverviewTable from './components/OverviewTable';

const propTypes = {
  typeName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(Object),
  searchQuery: PropTypes.string,
};

const defaultProps = {
  filter: null,
  searchQuery: '',
};

const AlertOverviewSection = ({
  typeName,
  name,
  filter,
  searchQuery,
}) => {
  const { colors, breakpoints } = useTheme();

  const [isExpanded, setIsExpanded] = useState(true);
  const [hasBeenInView, setHasBeenInView] = useState(false);

  const handleExpand = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const handleInView = useCallback(() => {
    setHasBeenInView(true);
  }, []);

  return (
    <C.Wrapper breakpoints={breakpoints}>
      <C.Header colors={colors} onClick={handleExpand}>
        <Flex alignItems="center">

          <C.BottomButtonWrapper isExpanded={isExpanded}>
            <MdiIcon path={mdiChevronRight} size={0.75} />
          </C.BottomButtonWrapper>
          <C.Title colors={colors} breakpoints={breakpoints}>{name}</C.Title>
        </Flex>
      </C.Header>
      <InView onEnter={handleInView} triggerOnce />
      { hasBeenInView && (
      <OverviewTable
        filter={filter}
        isExpanded={isExpanded}
        searchQuery={searchQuery}
        typeName={typeName}
      />
      )}

    </C.Wrapper>

  );
};

AlertOverviewSection.propTypes = propTypes;
AlertOverviewSection.defaultProps = defaultProps;

export default AlertOverviewSection;
