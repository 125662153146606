import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import MdiIcon from '@mdi/react';
import { mdiChevronDown, mdiChevronLeft, mdiCommentQuestion } from '@mdi/js';
import {
  useTheme,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  useMediaQuery,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
} from '@chakra-ui/react';
import CreateTicketModal from '~scenes/tickets/scenes/Create/components/Scene';
import { Button } from '../../Button';
import IconAsurget from '../../Icons/IconAsurget';
import CurrentUser from '../CurrentUser';
import DropdownMenu from '../DropdownMenu';
import DropdownMenuMobile from '../DropdownMenu/DropdownMenuMobile';
import DropdownCreate from '../DropdownCreate';
import * as C from './Layout.styled';
import Navigation from '../Navigation';
import translation from './Layout.translation';

const propTypes = {
  provider: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

const defaultProps = {};

const createListPropTypes = {
  createList: PropTypes.instanceOf(Array),
};

const createListDefaultProps = {
  createList: [],
};

const CreateList = ({ createList }) => {
  const { onOpen, onClose, isOpen } = useDisclosure({ id: 'create-list' });
  const { t } = translation;

  if (createList && createList.length === 0) {
    return null;
  }

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <span>
          <Button rightIcon={isOpen ? mdiChevronLeft : mdiChevronDown}>
            { t('create', 'ui') }
          </Button>
        </span>
      </PopoverTrigger>
      <PopoverContent border="none">
        <DropdownCreate createActionList={createList} onClose={onClose} />
      </PopoverContent>
    </Popover>
  );
};

CreateList.propTypes = createListPropTypes;
CreateList.defaultProps = createListDefaultProps;

const Layout = ({ provider, children }) => {
  const {
    onOpen: onMenuOpen, onClose: onMenuClose, isOpen: isMenuOpen,
  } = useDisclosure({ id: 'menu' });

  const {
    onOpen: onFeedbackModalOpen,
    onToggle: onFeedbackModalToggle,
    onClose: onFeedbackModalClose, isOpen: isFeedbackModalOpen,
  } = useDisclosure({ id: 'feedback' });

  const { colors, breakpoints } = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${breakpoints?.lg})`);

  const navigationList = provider.getNavigationItems();

  const languages = provider.getAvaliableLanguages();
  const selectedLanguage = provider.getCurrentLanguage();
  const {
    name,
    email,
    imageLink,
  } = provider.getUser();

  const customerName = provider.getCustomerName();

  const sideNavList = useMemo(() => {
    if (navigationList) {
      return navigationList.filter((nav) => !nav.isDropdownItem);
    }
    return [];
  }, [navigationList]);

  const handleOpenFeedbackModalOnMobile = useCallback(() => {
    onFeedbackModalOpen();
    onMenuClose();
  }, [onFeedbackModalOpen, onMenuClose]);

  return (
    <C.Main colors={colors} breakpoints={breakpoints}>
      <C.Left colors={colors}>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
        >

          <C.Logo colors={colors} breakpoints={breakpoints}>
            <IconAsurget />
          </C.Logo>

          <Navigation navigationList={sideNavList} />

        </Flex>

        <Button
          tooltip="Feedback"
          colors={colors}
          breakpoints={breakpoints}
          onClick={onFeedbackModalToggle}
        >
          <MdiIcon path={mdiCommentQuestion} size={0.875} />
        </Button>

      </C.Left>

      <C.Content>
        {children}

        <Modal onClose={onFeedbackModalClose} isOpen={isFeedbackModalOpen} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Flex flexDirection="row-reverse">

                <CreateTicketModal
                  isFeedbackModal
                  onCloseModal={onFeedbackModalClose}
                  onTicketCreatedCallback={onFeedbackModalClose}
                />
              </Flex>

            </ModalBody>

          </ModalContent>
        </Modal>
      </C.Content>

      <C.Top colors={colors} breakpoints={breakpoints}>
        {isMobile && (
        <C.Logo colors={colors} breakpoints={breakpoints}>
          <IconAsurget />
        </C.Logo>
        )}

        <CreateList createList={provider.getCreateList()} />

        <Popover
          isOpen={isMenuOpen}
          onOpen={onMenuOpen}
          onClose={onMenuClose}
        >
          <PopoverTrigger>
            <span>
              <CurrentUser
                name={name}
                email={email}
                imageLink={imageLink}
                customerName={customerName}
                isOpen={isMenuOpen}
              />
            </span>
          </PopoverTrigger>

          {isMobile ? (
            <Modal onClose={onMenuClose} size="full" isOpen={isMenuOpen}>
              <ModalOverlay />
              <ModalContent>
                <ModalBody>
                  <DropdownMenuMobile
                    onClose={onMenuClose}
                    name={name}
                    email={email}
                    customerName={customerName}
                    imageLink={imageLink}
                    languages={languages}
                    navigationList={navigationList}
                    onNavigate={onMenuClose}
                    selectedLanguage={selectedLanguage}
                    onChangeLanguage={provider.onChangeLanguage}
                    onLogout={provider.onLogout}
                    onFeedbackModalOpen={handleOpenFeedbackModalOnMobile}
                  />
                </ModalBody>

              </ModalContent>
            </Modal>
          ) : (
            <PopoverContent border="none">
              <DropdownMenu
                name={name}
                email={email}
                customerName={customerName}
                imageLink={imageLink}
                languages={languages}
                navigationList={navigationList}
                onNavigate={onMenuClose}
                selectedLanguage={selectedLanguage}
                onChangeLanguage={provider.onChangeLanguage}
                onLogout={provider.onLogout}
              />
            </PopoverContent>
          )}

        </Popover>
      </C.Top>
    </C.Main>
  );
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
