import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '~ui/Button';
import { useHistory } from 'react-router-dom';
import translation from './NotAuthorized.translation';
import * as C from './NotAuthorized.styled';

const propTypes = {
  authed: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
};

const NotAuthorized = ({

  authed, onLogout,
}) => {
  const { t } = translation;
  const history = useHistory();

  const handleClick = useCallback(() => {
    if (!authed) {
      onLogout();
    }
    history.push('/dashboard');
  }, [authed, history, onLogout]);

  return (

    <C.Wrapper>
      <C.StyledErrorContent>
        <h1>{t('title')}</h1>
        <h2>{ t('content')}</h2>

        <Button
          variant="outline"
          goBack
          onClick={handleClick}
        >
          {t('buttonTitle')}
        </Button>

      </C.StyledErrorContent>

    </C.Wrapper>

  );
};

NotAuthorized.propTypes = propTypes;

export default NotAuthorized;
