import React, { useRef } from 'react';
import {
  useTheme, Heading, Stack, useDisclosure, Text, Flex, IconButton, Box,
} from '@chakra-ui/react';
import MdiIcon from '@mdi/react';
import { mdiChevronUp } from '@mdi/js';
import * as Permission from '~ui/Permission';
import { useSecurityHours } from '~scenes/securityHours/context/SecurityHoursContext';
import { useScrollThreshold } from '~ui/hooks';
import Month from '../../Components/Month';
import translation from './StepOne.translation';
import * as C from './StepOne.styled';
import CustomerPicker from '../../Components/CustomerPicker';

const StepOne = () => {
  const { t } = translation;
  const { breakpoints } = useTheme();

  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();

  const { customers } = useSecurityHours();

  const customersLength = customers?.length || 0;

  // Setting a max height for adapting to the customers popover content
  const fixedPickersHeight = isGlobalAdmin ? `${Math.min((customersLength * 45) + 200, 600)}px` : '150px';

  const thresholdRef = useRef(null);
  const threshold = thresholdRef?.current?.offsetTop || 50;

  const isFixed = useScrollThreshold(threshold);
  const {
    isOpen, onToggle,
  } = useDisclosure();

  return (
    <C.Wrapper>
      <Heading ml="2rem" as="h1">{t('title')}</Heading>

      <C.PickersWrapper isFixed={isFixed} breakpoints={breakpoints}>
        {isFixed && (
          <Flex justifyContent="flex-end" alignItems="center" position="relative" right={-5}>
            <Text fontSize={12} margin="0">
              {t(isOpen ? 'hide' : 'show')}
              {' '}
              {isGlobalAdmin ? t('pickers') : t('monthPicker')}
            </Text>
            <IconButton
              style={{
                transition: 'transform 0.3s ease',
                display: 'flex',
                justifyContent: 'center',
                transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
              isOpen={isOpen}
              variant="unstyled"
              icon={<MdiIcon path={mdiChevronUp} size={1} />}
              onClick={onToggle}
            />
          </Flex>
        )}
        <C.ExpandedContent isExpanded={!isFixed || isOpen}>
          <C.ExpandedContentInner useOverflow={isFixed}>

            <Stack
              direction={['column', null, null, 'row']}
              spacing="2rem"
              width="100%"
              alignItems="flex-start"
              minHeight={isFixed ? fixedPickersHeight : 'auto'}
            >

              {isGlobalAdmin && (
              <CustomerPicker />
              )}
              <Month />
            </Stack>

          </C.ExpandedContentInner>
        </C.ExpandedContent>
      </C.PickersWrapper>
      <Box
        ref={thresholdRef}
        visible="hidden"
        position="relative"
        top="-4rem"
        background="red"
        zIndex={-1000}
      />

    </C.Wrapper>
  );
};

export default StepOne;
