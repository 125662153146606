import React from 'react';
import { Heading, Text, useTheme } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import MdiIcon from '@mdi/react';
import * as C from '../AvaliableActionRow/AvaliableActionRow.styled';
import { iconMapper } from '../AvaliableActionRow/helpers';
import actionTranslation from '../../AutoActions.translation';

const propTypes = {
  service: PropTypes.instanceOf(Object).isRequired,
};

const RowComponentSelected = ({ service }) => {
  const { colors, breakpoints } = useTheme();

  const { id, description, name } = service;
  const { fallback } = actionTranslation;

  return (
    <C.Card breakpoints={breakpoints}>
      <C.Icon colors={colors}>
        <MdiIcon path={iconMapper(id)} size={1.5} />
      </C.Icon>
      <C.Content>
        <Heading as="h3" style={{ marginBottom: 0 }}>{fallback(id, name)}</Heading>
        <Text style={{ margin: 0 }}>
          {fallback(`${id}-desc`, description)}
        </Text>
      </C.Content>
    </C.Card>
  );
};

RowComponentSelected.propTypes = propTypes;

export default RowComponentSelected;
