import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'StepOne',
  sv: {
    title: 'Security Timmar',
    show: 'Visa',
    hide: 'Dölj',
    pickers: 'kund- och månadsväljare',
    monthPicker: 'månadsväljare',
  },
  en: {
    title: 'Security Hours',
    show: 'Show',
    hide: 'Hide',
    pickers: 'customer and month pickers',
    monthPicker: 'month picker',
  },
});
