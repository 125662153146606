import React from 'react';
import PropTypes from 'prop-types';
import { MultiGrid, AutoSizer } from 'react-virtualized';
import {
  useTheme, Tooltip, Spinner, Flex, Text, Box,
} from '@chakra-ui/react';
import AlertStatus from '../AlertStatus';
import * as C from './OverviewTable.styled';
import translation from './OverviewTable.translation';

const propTypes = {
  entities: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  alertNames: PropTypes.arrayOf(PropTypes.string),
  isExpanded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isGlobalAdmin: PropTypes.bool.isRequired,
};

const defaultProps = {
  entities: [],
  alertNames: [],
};

const OverviewTable = ({
  entities, alertNames, isExpanded, isLoading, isGlobalAdmin,
}) => {
  const { colors, breakpoints } = useTheme();

  const STYLE_BOTTOM_LEFT_GRID = {
  };
  const STYLE_TOP_LEFT_GRID = {
    borderBottom: `1px solid ${colors?.gray?.['100']}`,
  };
  const STYLE_TOP_RIGHT_GRID = {
    borderBottom: `1px solid ${colors?.gray?.['100']}`,
    fontWeight: 'bold',
  };

  const { t } = translation;

  function cellRenderer({
    columnIndex, rowIndex, key: _key, style, isVisible, isScrolling,
  }) {
    if (rowIndex === 0) {
      if (isGlobalAdmin) {
        if ([0, 1].includes(columnIndex)) {
          return (
            <Box
              key={_key}
              style={{
                ...style,
                textAlign: 'left',
                borderRight: `1px solid ${colors?.gray?.['100']}`,
              }}
            >
              <Text fontWeight="bold" fontSize="12px" margin={0} padding="1rem">
                {t(columnIndex === 0 ? 'customer' : 'resource')}
              </Text>
            </Box>
          );
        }
      } else if (columnIndex === 0) {
        return (
          <Box
            key={_key}
            style={{
              ...style,
              textAlign: 'left',
              borderRight: `1px solid ${colors?.gray?.['100']}`,
            }}
          >
            <Text fontWeight="bold" margin={0} padding="1rem">
              {t('resource')}
            </Text>
          </Box>
        );
      }

      const alertName = alertNames[columnIndex - (isGlobalAdmin ? 2 : 1)];

      const key = `head-${alertName}`;

      const alertNameEl = (
        <Text
          noOfLines={3}
          fontSize="11px"
          lineHeight={1.3}
          margin={0}
          whiteSpace="initial"
          maxHeight="55px"
          wordBreak="break-all"
        >
          {alertName}
        </Text>
      );

      return (
        <C.AlertCell
          isHead
          colors={colors}
          key={key}
          style={{
            ...style,
            padding: '0.5rem',
          }}
        >

          { isVisible && !isScrolling ? (
            <Tooltip hasArrow label={alertName}>
              {alertNameEl}
            </Tooltip>
          ) : (
            alertNameEl
          )}

        </C.AlertCell>
      );
    }

    // Render Entity cell (first cell in a row after Header row)
    const entity = entities[rowIndex - 1];

    if (isGlobalAdmin) {
      if ([0, 1].includes(columnIndex)) {
        const key = columnIndex === 0 ? `${entity.customer_name}-${entity.name}` : `${entity.name}-${entity.customer_name}`;

        return (
          <C.TextCell
            key={key}
            colors={colors}
            breakpoints={breakpoints}
            style={
            style
          }
          >

            <Text
              noOfLines={3}
              fontSize="11px"
              lineHeight={1.3}
              margin={0}
              padding="0 1rem"
              whiteSpace="initial"
            >
              {columnIndex === 0 ? entity?.customer_name : entity?.name}
            </Text>

          </C.TextCell>
        );
      }
    } else if (columnIndex === 0) {
      const key = `${entity.name}`;
      return (
        <C.TextCell
          key={key}
          colors={colors}
          breakpoints={breakpoints}
          style={
            style
          }
        >
          <C.TextCellContent>
            {entity?.name}
          </C.TextCellContent>
        </C.TextCell>
      );
    }

    // Render alert status cell
    const alertName = alertNames[columnIndex - (isGlobalAdmin ? 2 : 1)];
    const key = `${entity.customer_name}-${entity.name}-${alertName}}`;

    return (

      <C.AlertCell
        key={key}
        colors={colors}
        style={
            style
          }
      >
        <AlertStatus
          isScrolling={isScrolling}
          isVisible={isVisible}
          entity={entity}
          alertName={alertName}
        />
      </C.AlertCell>

    );
  }

  const getColumnWidth = ({ index }) => {
    const customerWidth = 150;
    const entityWidth = 170;
    const alertCellWidth = 82;
    switch (index) {
      case 0:
        return isGlobalAdmin ? customerWidth : entityWidth;
      case 1:
        return isGlobalAdmin ? entityWidth : alertCellWidth;
      default:
        return alertCellWidth;
    }
  };

  // extra column for entity name, and another for customer name if global admin
  const columnCount = alertNames.length + (isGlobalAdmin ? 2 : 1);
  const rowCount = entities.length + 1;

  const rowHeight = 60;

  const height = (() => {
    if (isLoading) return 150;
    const maxHeight = window.innerHeight - 300;
    const fullHeight = ((entities.length + 1) * rowHeight) + 20;

    if (fullHeight > maxHeight) return maxHeight;

    return fullHeight;
  })();

  const settings = {

    fixedColumnCount: isGlobalAdmin ? 2 : 1,
    fixedRowCount: 1,
    overscanColumnCount: 10,
    overscanRowCount: 10,
  };

  return (

    <C.ExpandedContent isExpanded={isExpanded}>
      <C.ExpandedContentInner>

        {isLoading && <Flex alignItems="center" justifyContent="center" padding="3rem" width="100%" height="100%"><Spinner /></Flex>}

        {!isLoading && (
        <AutoSizer disableHeight>
          {({ width }) => (
            <MultiGrid
              {...settings}
            // eslint-disable-next-line react/jsx-no-bind
              cellRenderer={cellRenderer}
              columnWidth={getColumnWidth}
              columnCount={columnCount}
              enableFixedColumnScroll
              enableFixedRowScroll
              height={height}
              rowHeight={rowHeight}
              rowCount={rowCount}
              styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
              styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
              styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
              width={width}
              hideTopRightGridScrollbar
              hideBottomLeftGridScrollbar
            />
          )}
        </AutoSizer>
        )}
      </C.ExpandedContentInner>
    </C.ExpandedContent>

  );
};

OverviewTable.propTypes = propTypes;
OverviewTable.defaultProps = defaultProps;

export default React.memo(OverviewTable);
