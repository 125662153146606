import { connect } from 'react-redux';
import Main from './components/CloudOpsServicePage';
import withNavigation from '../../../../hoc/Navigation';
import * as A from './actions';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  onMount: (payload) => dispatch(A.onMount(payload)),
  onUnmount: () => dispatch(A.onUnmount()),
  onDisableService: (payload) => dispatch(A.onDisableService(payload)),
});

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Main));
