import React from 'react';
import {
  useTheme, Heading, Spinner, Flex,
} from '@chakra-ui/react';
import { useSecurityHours } from '~scenes/securityHours/context/SecurityHoursContext';
import monthTranslation from '~ui/MonthPicker/MonthPicker.translation';
import * as Block from '~ui/Block';
import * as C from './Details.styled';
import SpentHoursSummary from '../SpentHoursSummary';
import ApprovedHours from '../ApprovedHours';
import ServiceLevel from '../ServiceLevel';
import translation from './Details.translation';

const Details = () => {
  const { colors, breakpoints } = useTheme();
  const { t } = translation;
  const { t: monthT } = monthTranslation;

  const {
    selectedCustomerName, selectedDate, selectedMonth,
    isServiceLevelAdvanced, selectedCustomerServiceLevel, isSelectedMonthLoading,
  } = useSecurityHours();

  if (!selectedMonth) return null;

  if (isSelectedMonthLoading) {
    return (
      <C.Wrapper colors={colors} breakpoints={breakpoints}>

        <Flex justifyContent="center" pt="2rem">
          <Spinner
            color="#133A5D"
            emptyColor="#DADADA"
            speed="0.65s"
            thickness="4px"
            width={50}
            height={50}
          />
        </Flex>

      </C.Wrapper>
    );
  }

  if (selectedMonth?.error) {
    return (
      <C.Wrapper colors={colors} breakpoints={breakpoints}>

        <Block.Error>{t('error')}</Block.Error>

      </C.Wrapper>
    );
  }

  return (
    <C.Wrapper colors={colors} breakpoints={breakpoints}>
      <Heading fontWeight={300} mb="2rem">
        {selectedCustomerName}
        {' '}
        /
        {' '}
        {selectedDate?.getMonth ? monthT(`monthIndex${selectedDate.getMonth()}`) : ''}
        {' '}
        {selectedDate?.getFullYear() ?? ''}

      </Heading>
      <C.ContentWrapper>

        <ServiceLevel />
        {selectedCustomerServiceLevel && !isServiceLevelAdvanced && <ApprovedHours />}
        <SpentHoursSummary />

      </C.ContentWrapper>

    </C.Wrapper>
  );
};

export default Details;
