import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'ExpandedContent',
  sv: {
    entityGroupsTabButton: 'pågående incidenter',
    entitiesTabButton: 'Resurser',
    showAll: 'Visa alla',
    showLess: 'Visa färre',
    backPathButtonText: ' Översikt',
  },
  en: {
    entityGroupsTabButton: 'ongoing Incidents',
    entitiesTabButton: 'Resources',
    showAll: 'Show all',
    showLess: 'Show less',
    backPathButtonText: 'Dashboard',
  },
});
