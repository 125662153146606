import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'MyEnvServiceWindowsMain',
  sv: {
    emptystate: 'Det finns inga servicefönster för denna resurs',
    serviceWindows: 'Servicefönster',
    searchLabel: 'Sök servicefönster',
    tableReason: 'Orsak',
    requestedBy: 'Skapad av',
    start: 'Start',
    end: 'Slut',
    modified: 'Ändrad',
    duration: 'Varaktighet',
    customer: 'Kund',

    filterDrawerTitle: 'Filtrera på Servicefönster',
    changeRequestedBy: 'Ändra skapare',
  },
  en: {
    emptystate: 'There are no service windows for this resource',
    serviceWindows: 'Service windows',
    searchLabel: 'Search service windows',
    tableReason: 'Reason',
    requestedBy: 'Requested by',
    start: 'Start',
    end: 'End',
    modified: 'Modified',
    duration: 'Duration',
    customer: 'Customer',

    filterDrawerTitle: 'Filter on Service windows',
    changeRequestedBy: 'Change requester',
  },
});
