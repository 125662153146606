import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  Wrap,
  Box,
} from '@chakra-ui/react';
import * as Layout from '~ui/Layout';
import * as Permission from '~ui/Permission';

import {
  TableSort,
  TableSearch,
  TableSearchProvider,
  TableHeader,
  TableFilterTags,
  TableFilterSelect,
} from '~ui/Table';

import * as Keys from '~lib/utils/historyStateKeys';
import { searchEntityTickets } from '~lib/services/entityTicketSearch';
import AlertOverviewOuterList from '../AlertOverviewOuterList';
import translation from './List.translation';
import { listFilterType } from '../../../../../../utils/propTypes';

const tagColors = {
  customer_display_name: 'blue',
  container_name: 'gold',
  resource_group: 'ruby',
  type: 'green',
  tags: 'gray',
};

const propTypes = {
  onUpdateListFilter: PropTypes.func.isRequired,
  listFilter: listFilterType,
  customerId: PropTypes.string.isRequired,
};

const defaultProps = {
  listFilter: null,
};

const AlertOverview = ({
  onUpdateListFilter, listFilter: globalListFilter, customerId,
}) => {
  const { t } = translation;
  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();

  const filterCollection = {
    customer_display_name: (_, value) => `${t('customer')}: ${value}`,
  };

  const sortList = [
    { value: 'type_name', label: t('entityType') },
  ];

  const callback = useCallback((state, azureSearchParser) => {
    const { is_stale: stale, ...restFiler } = state.filter;
    const newState = { ...state, filter: restFiler };
    const includeStale = stale !== undefined;
    const parsers = {
      filter: {
        tags: (value) => `tags/any(tag: tag eq '${value}')`,
      },
    };

    if (state.isFilterTrigger) {
      return {
        payload: azureSearchParser.facets(newState, state.filterKey, parsers),
        isGlobalAdmin,
        customerId,
        includeStale,
      };
    }

    onUpdateListFilter({
      [Keys.ALERT_OVERVIEW]: { ...newState },
    });

    return {
      payload: {
        ...azureSearchParser.items(state, parsers),
        facets: ['type_name, count:0'],
      },
      isGlobalAdmin,
      customerId,
      includeStale,
    };
  }, [customerId, isGlobalAdmin, onUpdateListFilter]);

  return (
    <>
      <TableSearchProvider
        initialValues={globalListFilter}
        pageSize={15}
        payload={callback}
        fetcher={searchEntityTickets}
        urlStateKey={Keys.ALERT_OVERVIEW}
        sort={sortList}
        globalStateCallback={onUpdateListFilter}
      >
        <Layout.Omnibar.Main>
          <Box flex="1">
            <TableHeader>
              <TableSearch />
              <TableSort />
            </TableHeader>
            <Wrap>
              {isGlobalAdmin && (
              <TableFilterSelect
                label={t('customer')}
                filterKey="customer_display_name"
              />
              )}

              <TableFilterSelect
                label={t('entityType')}
                filterKey="type_name"
              />
            </Wrap>
            <TableFilterTags
              configurations={filterCollection}
              colors={tagColors}
            />
          </Box>
        </Layout.Omnibar.Main>
        <AlertOverviewOuterList />

      </TableSearchProvider>
    </>
  );
};

AlertOverview.propTypes = propTypes;
AlertOverview.defaultProps = defaultProps;

export default AlertOverview;
