/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Button } from '~ui/Button';
import * as Permission from '~ui/Permission';
import * as List from '~ui/List';
import {
  Tooltip, useToast, Box, Collapse, useTheme,
} from '@chakra-ui/react';
import {
  mdiChevronDown, mdiChevronUp, mdiArrowRight,
} from '@mdi/js';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as C from './Card.styled';
import translation from './Card.translation';
import TimeComponents from './TimeComponents';
import { serviceWindowPermission } from '../../../../../../lib/utils/featureKeys';
import { useSWByTempID } from '../../../../../../lib/services/serviceWindow';
import TicketLink from './TicketLink';

const { t } = translation;

const propTypes = {
  row: PropTypes.instanceOf(Object),
};
const defaultProps = {
  row: null,
};

const Card = ({ row }) => {
  const { colors, breakpoints } = useTheme();

  const toast = useToast();
  const history = useHistory();
  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();
  const [isOpen, setIsOpen] = useState(false);

  const { params } = useRouteMatch();
  const { customerId } = params;

  const {
    cron_expression,
    cron_expression_description,
    customer_id,
    customer_name,
    duration_in_seconds,
    reason,
    requested_by,
    temp_ticket_id,
    id,
  } = row;

  const { refetch: getRelatedTicket } = useSWByTempID(
    customerId,
    temp_ticket_id,
    isGlobalAdmin,
    {
      enabled: false,
      onSuccess: (val) => {
        const link = `/tickets/${val[0]?.customer_id}/${val[0]?.id}/details`;
        history.push(link);
      },
      onError: () => toast({
        description: t('fail'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      }),
    },
  );

  return (
    <C.Card isOpen={isOpen}>
      <C.Top colors={colors} breakpoints={breakpoints} isOpen={isOpen}>
        <C.TopText isOpen={isOpen}>
          <C.TextLarge>{reason}</C.TextLarge>
          {!isOpen && (
          <>
            {isGlobalAdmin && (
            <C.TextValue>{`${customer_name} (${customer_id})`}</C.TextValue>
            )}
            <TicketLink getRelatedTicket={getRelatedTicket} tempTicketId={temp_ticket_id} />
          </>
          )}
        </C.TopText>

        {!isOpen && (<TimeComponents isOpen={isOpen} serviceWindow={row} />)}

        <C.Expander breakpoints={breakpoints} colors={colors} onClick={() => setIsOpen(!isOpen)}>
          <Tooltip label={t('viewDetails')} hasArrow>
            <Box>
              <Button
                variant="ghost"
                rightIcon={isOpen ? mdiChevronUp : mdiChevronDown}
              />
            </Box>
          </Tooltip>
        </C.Expander>

      </C.Top>

      <Collapse in={isOpen} animateOpacity>
        <C.Bottom colors={colors}>

          <List.Primary rows={[
            isGlobalAdmin && { label: t('customer'), value: `${customer_name} (${customer_id})` },
            { label: t('createdBy'), value: requested_by },
            {
              label: t('cronExpression'),
              value: cron_expression
                ? `${cron_expression} (UTC)`
                : 'N/A',
            },
            {
              label: t('cronDescription'),
              value: cron_expression_description
                ? `${cron_expression_description} (UTC)`
                : 'N/A',
            },
            {
              label: t('duration'),
              value: (
                <Tooltip hasArrow label={`${duration_in_seconds} ${t('seconds')}`}>
                  <C.TextValue>{moment.duration(duration_in_seconds, 'seconds').humanize()}</C.TextValue>
                </Tooltip>
              ),
            },
            { label: t('relations'), value: <TicketLink getRelatedTicket={getRelatedTicket} tempTicketId={temp_ticket_id} /> },
          ]}
          />

          <C.IconContainer>
            <TimeComponents isOpen={isOpen} serviceWindow={row} />

            <Permission.Render withFeature={[serviceWindowPermission.write]}>
              <C.Edit breakpoints={breakpoints}>
                <Button
                  internalLink={`/service-window/${customer_id}/${id}/edit`}
                  rightIcon={mdiArrowRight}
                >
                  {t('edit')}
                </Button>
              </C.Edit>
            </Permission.Render>
          </C.IconContainer>

        </C.Bottom>
      </Collapse>

    </C.Card>
  );
};

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
