import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'EntityListingMain',
  sv: {
    filterDrawerTitle: 'Filtrera bland Entiteter',
    changeCustomer: 'Filtrera på kund',
    changeContainer: 'Filtrera på Container',
    changeResourceGroup: 'Filtrera på Resursgrupp',
    changeType: 'Filtrera på Typ',
    changeIsMapped: 'Filtrera bland entiteter med eller utan cloudops aktiverat',
    changeIsHidden: 'Välj om du även vill se dolda entiter',
    changeIStale: 'Välj om du även vill se entiter som är stale',
    changeTags: 'Filtrera på taggar',
    changeRegion: 'Filtrera på region',
    entityName: 'Entitetsnamn',
    entityType: 'Entitetstyp',
    resourceGroup: 'Resursgrupp',
    tags: 'Taggar',
    region: 'Region',
    customer: 'Kund',
    container: 'Container',
    isMapped: 'CloudOps',
    isHidden: 'Dolda entiteter',
    isStale: 'Stale',
    subType: 'Subtyp',
    name: 'Namn',
    cloudopsActive: 'Cloudops Aktivt',
    cloudopsInactive: 'Cloudops Inaktivt',
  },
  en: {
    filterDrawerTitle: 'Filter on Entities',
    changeCustomer: 'Filter by Customer',
    changeContainer: 'Filter by Container types',
    changeResourceGroup: 'Filter by resouce group',
    changeType: 'Filter by types',
    changeRegion: 'Filter by region',
    changeIsMapped: 'Filter with entities that has Cloudops activated or not',
    changeIsHidden: 'Choose if you want to see entities that are hidden',
    changeIStale: 'Choose if you want to see entities that are stale',
    changeTags: 'Filter with tags',
    entityName: 'Entity name',
    entityType: 'Entity type',
    resourceGroup: 'Resource group',
    tags: 'Tags',
    region: 'Region',
    customer: 'Customer',
    type: 'Type',
    container: 'Container',
    isMapped: 'CloudOps active',
    isHidden: 'Hidden entities',
    isStale: 'Stale',
    subType: 'Sub type',
    name: 'Name',
    cloudopsActive: 'Cloudops Active',
    cloudopsInactive: 'Cloudops Inactive',
  },
});
