import Api from '../../../../../../../lib/api';
import { flattenAutoActions } from '../helpers';

const getAutoActionsForEntity = async ({
  includeDisabled = true,
  customerId,
  entityId,
}) => {
  const path = `customer/${customerId}/entity/${entityId}/autoactionprocessmodel`;
  const params = { maxItems: -1, includeDisabled };

  return Api.continuationToken.get(path, null, params)
    .then((res) => res.map(flattenAutoActions))
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default getAutoActionsForEntity;
