import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Servicewindow.Edit.Metrics',
  sv: {
    header: 'Metrics',
    text: 'Här kan du anpassa ett service fönster till att enbart gälla för vissa metrics.',
    showMetricsFormLabel: 'Använd metrics',
    metricsFormPlaceholder: 'Lägg till en eller flera metrics',
    metricOptionsLabel: 'Metrics',
    loadMetricOptions: 'Hämtar metrics från valda resurser',
    loadMetricButton: 'Hämta metrics',
    noEntitiesText: 'Du behöver lägga till resurser innan vi kan visa dig några metrics',
    noMetricsFoundTitle: 'Inga metrics hittades',
    noMetricsFound: 'Inga metrics kunde hittas för valda resurser. Var god välj en annan resurs.',
  },
  en: {
    header: 'Metrics',
    text: 'Here you can configure your service window to only be applied for specific metrics',
    showMetricsFormLabel: 'Use metrics',
    metricsFormPlaceholder: 'Add one or more metric',
    metricOptionsLabel: 'Metrics',
    loadMetricOptions: 'Loading metrics from selected resources',
    loadMetricButton: 'Load metrics',
    noEntitiesText: 'You need to add resources before we can show you any metrics',
    noMetricsFound: 'We could not find any metrics for the choosen resources. Please try another resource.',
    noMetricsFoundTitle: 'No metrics found',
  },
});
