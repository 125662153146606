import axios from 'axios';
import * as Msal from '@azure/msal-browser';
import SessionExpiredError from './errors/SessionExpiredError';
import SessionInvalidError from './errors/SessionInvalidError';
import { debugHandleResponse } from './debug';

const API_TOKEN_EXPIRED = '@api.general.API_TOKEN_EXPIRED';

const redirectErrors = [
  SessionInvalidError,
  SessionExpiredError,
  Msal.ClientAuthError,
  Msal.ClientConfigurationError,
  Msal.AuthError,
  Msal.InteractionRequiredAuthError,
  Msal.ServerError,
];

const settings = {
  redux: {},
  acquireAuthToken: new Promise((r) => r({ token: null })),
};

const baseUrl = `${process.env.REACT_APP_API_URI}/${process.env.REACT_APP_API_VERSION}/`;
const apiActions = ['post', 'delete', 'get', 'put'];

const responseHandler = (response) => {
  const { data, request } = response;
  const { responseURL } = request;

  const debugResponse = false;
  if (debugResponse) {
    return debugHandleResponse({ data, responseURL });
  }

  return data;
};

const succesInterceptor = (response) => responseHandler(response);

const failedInterceptor = (error) => {
  const { response } = error;

  if (response && (response.status === 401 || response.status === 403)) {
    settings.redux.dispatch({
      type: API_TOKEN_EXPIRED,
      meta: { fromOrigin: window.location.pathname },
    });

    return Promise.reject(new SessionExpiredError());
  }

  return Promise.reject(error);
};

const configureAxios = (instance) => {
  const inter = instance.interceptors;

  inter.request.use(
    (config) => config,
    (error) => Promise.reject(error),
  );

  inter.response.use(
    (response) => succesInterceptor(response),
    (error) => failedInterceptor(error),
  );
};

const generateOptions = (options, override) => {
  if (override && typeof override === 'object') {
    const { headers, continuationToken, ...optionOverride } = override;
    const result = { ...options, ...optionOverride };

    if (headers) {
      Object.assign(result.headers, headers);
    }

    if (continuationToken) {
      Object.assign(result.headers, {
        'x-asurgent-continuation-token': continuationToken,
      });
    }

    return result;
  }

  return options;
};

const buildUrl = (path) => {
  const parsedPath = path
    .replace(/^\/+/, '') // remove slashes in begining of path
    .replace(/\/+$/, ''); // remove trailing slashes
  return `${baseUrl}${parsedPath}`;
};

const Api = apiActions
  .reduce((acc, method) => ({
    [method]: (url, data = {}, params = {}, optionOverride = {}) => settings
      .acquireAuthToken()
      .then((response) => {
        if (!response.token) {
          throw new Error('Could not acquire auth token', response);
        }

        const headers = {
          'Content-type': 'application/json',
          'x-asurgent-aad-token': response.token,
        };

        const baseOptions = {
          url: buildUrl(url),
          method: method.toUpperCase(),
          headers,
          data,
          params,
        };

        const requestOptions = generateOptions(baseOptions, optionOverride);

        const instance = axios.create();
        configureAxios(instance);

        return instance.request(requestOptions);
      })
      .catch((error) => {
        // Redirect user to login-page if one of followin exceptions has been thrown
        if (redirectErrors.some((exp) => error instanceof exp)) {
          const origin = `${window.location.pathname}`;
          settings.redux.dispatch({
            type: API_TOKEN_EXPIRED,
            meta: { fromOrigin: origin },
          });
        }

        return new Promise((_, reject) => reject(error));
      }),
    ...acc,
  }), {});

const executeContinuationTokenRequest = (
  service,
  cacheResult,
  continuationToken,
) => new Promise(
  (resolve, reject) => {
    service(continuationToken)
      .then((response) => {
        if ('continuation_token' in response && 'result' in response) {
          const { result, continuation_token: ct } = response;
          const returnData = [...cacheResult, ...result];

          if (ct) {
            return executeContinuationTokenRequest(service, returnData, ct);
          }

          return returnData;
        }

        return response;
      })
      .then((result) => resolve(result))
      .catch((e) => reject(e));
  },
);

const continuationTokenHandler = (method) => (
  url,
  data = {},
  params = {},
  override = {},
) => new Promise(
  (resolve, reject) => {
    const service = (continuationToken) => {
      const config = { continuationToken, ...override };
      return Api[method](url, data, params, config);
    };

    executeContinuationTokenRequest(service, [], null)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  },
);

Object.assign(Api, {
  continuationToken: apiActions
    .reduce((acc, method) => ({
      [method]: continuationTokenHandler(method),
      ...acc,
    }), {}),
});

const configureApi = ({ redux, acquireAuthToken }) => {
  Object.assign(settings, {
    redux,
    acquireAuthToken,
  });
};

export {
  SessionInvalidError,
  SessionExpiredError,
  API_TOKEN_EXPIRED,
  configureApi,
};

export default Api;
