import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';

const propTypes = {
  to: PropTypes.string.isRequired,
};

const NavLink = React.forwardRef(({ to, ...rest }, ref) => {
  const location = useLocation();
  const currentPathnameRoot = location.pathname.split('/')[1];
  const targetPathnameRoot = to.split('/')[1];
  const isActive = currentPathnameRoot === targetPathnameRoot;

  return (
    <RouterNavLink
      to={to}
      {...rest}
      isActive={() => isActive}
      ref={ref}
    />
  );
});

NavLink.propTypes = propTypes;

export default NavLink;
