import translation from './Edit.translation';

const { t } = translation;

const validRegex = (str) => {
  try {
    const reg = new RegExp(str);
    return reg && true;
  } catch (e) {
    return false;
  }
};

export const formObjects = (type) => {
  if (type === 'monitor') {
    return {
      services_to_monitor: {
        type: 'textmultiple',
        label: t('servicesToMonitor'),
        parseOutput: (r) => r.filter((entry) => entry !== ''),
        placeholder: t('addNew'),
      },
      services_not_to_monitor: {
        type: 'textmultiple',
        label: t('servicesNotToMonitor'),
        parseOutput: (r) => r.filter((entry) => entry !== ''),
        placeholder: t('addNew'),
      },
      iis_web_sites_not_to_monitor: {
        type: 'textmultiple',
        label: t('iisWebSitesNotToMonitor'),
        parseOutput: (r) => r.filter((entry) => entry !== ''),
        placeholder: t('addNew'),
      },
      iis_app_pools_not_to_monitor: {
        type: 'textmultiple',
        label: t('iisAppPoolsNotToMonitor'),
        parseOutput: (r) => r.filter((entry) => entry !== ''),
        placeholder: t('addNew'),
      },
      urls_to_monitor: {
        type: 'objectmultiple',
        label: t('urlsToMonitor'),
        options: {
          name: { label: t('name'), type: 'string' },
          url: { label: t('url'), type: 'string' },
          http_method: {
            label: t('httpMethod'),
            type: 'select',
            placeholder: t('select'),
            options: [
              { value: 'GET', label: 'GET' },
              { value: 'PUT', label: 'PUT' },
              { value: 'POST', label: 'POST' },
              { value: 'DELETE', label: 'DELETE' },
              { value: 'HEAD', label: 'HEAD' },
              { value: 'OPTIONS', label: 'OPTIONS' },
            ],
          },
          response_body_should_contain_regex: { label: t('responseBodyShouldContainRegex'), type: 'string' },
        },
        validator: {
          conditions: () => {
            const validation = {
              name: { valid: (val) => val !== '', errorMessage: t('errorEmptyString') },
              url: { valid: (val) => val !== '', errorMessage: t('errorEmptyString') },
              http_method: { valid: (val) => val !== '', errorMessage: t('errorEmptyString') },
              response_body_should_contain_regex: { valid: (val) => val !== '', errorMessage: t('errorEmptyString') },
            };
            return validation;
          },
        },
      },
      folders_last_modified_to_monitor: {
        type: 'objectmultiple',
        label: t('foldersLastModifiedToMonitor'),
        options: {
          folder_path: { label: t('folderPath'), type: 'string' },
          file_mask: { label: t('fileMask'), type: 'string' },
        },
        validator: {
          conditions: () => {
            const validation = {
              folder_path: { valid: (val) => val !== '', errorMessage: t('errorEmptyString') },
              file_mask: { valid: (val) => val !== '', errorMessage: t('errorEmptyString') },
            };
            return validation;
          },
        },
      },
    };
  }
  if (type === 'queries') {
    return {
      event_log_queries: {
        type: 'objectmultiple',
        label: ' ',
        validator: {
          conditions: () => {
            const validation = {
              query_name: { valid: (val) => val !== '', errorMessage: t('errorEmptyString') },
              log_name: { valid: (val) => val !== '', errorMessage: t('errorEmptyString') },
              level: { valid: (val) => val !== '', errorMessage: t('errorEmptyString') },
              regex_string: { valid: (val) => val !== '' && validRegex(val), errorMessage: t('errorInvalidRegex') },
              source_regex_string: { valid: (val) => val !== '' && validRegex(val), errorMessage: t('errorInvalidRegex') },
            };
            return validation;
          },
        },
        options: {
          query_name: { label: t('queryName'), type: 'string' },
          log_name: { label: t('logName'), type: 'string' },
          level: {
            label: t('level'),
            type: 'select',
            placeholder: t('select'),
            options: [
              { value: 'Verbose', label: t('verbose') },
              { value: 'Information', label: t('information') },
              { value: 'Warning', label: t('warning') },
              { value: 'Error', label: t('error') },
              { value: 'Critical', label: t('critical') },
            ],
          },
          regex_string: { label: t('regexString'), type: 'string' },
          event_id: { label: t('eventID'), type: 'string' },
          source_regex_string: { label: t('sourceRegexString'), type: 'string' },
        },
      },
    };
  }
  return {
    web_tests: {
      type: 'objectmultiple',
      label: ' ',
      validator: {
        conditions: () => {
          const validation = {
            name: { valid: (val) => val !== '', errorMessage: t('errorEmptyString') },
            interval_in_minutes: { valid: (val) => typeof val === 'number', errorMessage: t('errorEmptyString') },
          };
          return validation;
        },
      },
      options: {
        name: {
          label: t('name'),
          type: 'string',
        },
        interval_in_minutes: {
          label: t('intervalInMinutes'),
          type: 'number',
        },
      },
    },
  };
};
