import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTheme, Text, Tooltip } from '@chakra-ui/react';
import MdiIcon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';
import translation from './EntityGroupListItem.translation';
import * as C from './EntityGroupListItem.styled';

const propTypes = {
  priority: PropTypes.number.isRequired,
  subject: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  backPathUrlParam: PropTypes.string.isRequired,
};

const defaultProps = {
};

function formatDate(dateString, _t) {
  const date = new Date(dateString);
  const now = new Date();
  const difference = now.getTime() - date.getTime();

  const minutes = Math.floor(difference / 1000 / 60);
  const hours = Math.floor(difference / 1000 / 60 / 60);
  const days = Math.floor(difference / 1000 / 60 / 60 / 24);

  if (minutes < 60) {
    return `${minutes} ${_t('minutesAgo')}`;
  } if (hours < 24) {
    return `${hours} ${_t('hoursAgo')}`;
  } if (days === 1) {
    return _t('yesterday');
  }
  return `${days} ${_t('daysAgo')}`;
}

const TicketListItem = ({
  customerId, priority, subject, created, status, id, backPathUrlParam,
}) => {
  const { colors } = useTheme();
  const { t } = translation;

  const ticketPath = `/tickets/${customerId}/${id}/details${backPathUrlParam}`;

  return (
    <Link to={ticketPath}>
      <C.TicketListItem colors={colors}>
        <Tooltip label={t(`priority${priority || 0}`)}>
          <C.PriorityDot priority={priority} colors={colors} />
        </Tooltip>
        <Text>{id}</Text>
        <Tooltip label={subject}>
          <Text isTruncated>{subject}</Text>
        </Tooltip>
        <Text>{formatDate(created, t)}</Text>
        <Text>
          {status}
        </Text>

        <div>

          <MdiIcon path={mdiChevronRight} size={0.75} />

        </div>

      </C.TicketListItem>
    </Link>
  );
};

TicketListItem.propTypes = propTypes;
TicketListItem.defaultProps = defaultProps;

export default TicketListItem;
