import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from '~ui/Button';
import * as Block from '~ui/Block';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Text, useTheme } from '@chakra-ui/react';
import * as Keys from '../../../../../../../../lib/utils/historyStateKeys';
import Icon from './Icon';
import * as C from './Entry.styled';
import { SUCCESS, FAIL, NODATA } from './Constants';

import { getText } from './helpers';

const getType = (group) => {
  if (group.fails > 0) {
    return FAIL;
  }
  if (group.total > 0) {
    return SUCCESS;
  }
  return NODATA;
};

const ClickRow = ({
  startDate, endDate, type, children,
}) => {
  const { params } = useRouteMatch();
  const { entityId, customerId, testName } = params;

  const history = useHistory();

  const link = useMemo(() => {
    const baseLink = `/app-monitor/${customerId}/${entityId}/${testName}/test-runs`;

    const tblState = {
      query: '',
      filter: {
        afterDate: [moment(startDate).startOf('day').toISOString()],
        beforeDate: [moment(endDate).endOf('day').toISOString()],
      },
      order: 'timestamp',
      order_desc: true,
      page: 1,
    };

    if (type === SUCCESS) {
      Object.assign(tblState, {
        filter: { ...tblState.filter, succeeded: [true] },
      });
    }
    if (type === FAIL) {
      Object.assign(tblState, {
        filter: { ...tblState.filter, succeeded: [false] },
      });
    }

    const basedFilter = window.btoa(JSON.stringify(tblState));

    return `${baseLink}?${Keys.APP_MONITOR_TEST_RUNS}=${basedFilter}`;
  }, [customerId, endDate, startDate, entityId, testName, type]);

  return (
    <Button style={{ width: '100%' }} variant="ghost" onClick={() => history.push(link)}>
      {children}
    </Button>
  );
};
ClickRow.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

ClickRow.defaultProps = {
  children: null,
};

const Entry = ({ group }) => {
  const { colors } = useTheme();
  // Is grouped array (multiple dates with smooth sailing/no data)
  if (Array.isArray(group)) {
    const groupStart = group[group.length - 1];
    const groupEnd = group[0];
    const type = getType(groupEnd);
    return (
      <ClickRow
        startDate={moment(groupStart.date).startOf('day').toISOString()}
        endDate={moment(groupEnd.date).endOf('day').toISOString()}
        type={type}
      >
        <C.ListItem colors={colors}>
          <Icon type={type} />
          <Block.SpaceBetween>
            <Text>{getText(group[0])}</Text>
            <Text>{`${moment(groupStart.date).format('ddd D')} - ${moment(groupEnd.date).format('ddd D')}`}</Text>
          </Block.SpaceBetween>
        </C.ListItem>
      </ClickRow>
    );
  }

  // is single entry (specific date)
  const type = getType(group);
  return (
    <ClickRow
      startDate={moment(group.date).startOf('day').toISOString()}
      endDate={moment(group.date).endOf('day').toISOString()}
      type={type}
    >
      <C.ListItem colors={colors}>
        <Icon type={type} />
        <Block.SpaceBetween>
          <Text>{getText(group)}</Text>
          <Text>{moment(group.date).format('ddd D')}</Text>
        </Block.SpaceBetween>
      </C.ListItem>
    </ClickRow>
  );
};
Entry.propTypes = {
  group: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    PropTypes.instanceOf(Object),
  ]).isRequired,
};

export default Entry;
