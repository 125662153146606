import moment from 'moment';
import { SessionInvalidError } from '../lib/api';

import { acquireTokenSilent } from './services/auth.service';
import { setJwtToken } from './actions';

/*
* Handler that keeps track of when the current JWT session obtained from Microsoft
* will expire. This handler will run every ajax-request that is initiated by lib/api.
* Whenever the handler detects that the current session has expired, we will run the auth.service
* function .refreshJWT. This function runs the MSAL function .acquireTokenSilent.
* We only pass ONE scope in .acquireTokenSilent, since more than one scope won't update
* the JTW-token, it will just update the access-token.
* We will wait until the promise is resolved from acquireTokenSilent. If the request is successful
* we will pass the newly obtained JWT to the pending request, as well as dispatching an
* event to redux and storing the newly obtained JWT, that will be used for future requests,
* until it as well has expired, and we perform the same procedure again.
* (that we only use on login to obtain the profile-picture from microsoft)
*/

export const acquireAuthTokenHandler = (store) => () => new Promise(
  // eslint-disable-next-line no-async-promise-executor
  async (resolve, reject) => {
    const { application } = store.getState();

    const now = moment();
    const then = moment(application.expires);
    const diff = moment.duration(moment(then).diff(moment(now)));
    const msUntillExpire = diff.asMilliseconds();

    if (msUntillExpire <= 0) {
    // Refresh token and set new state
      const { success, error } = await acquireTokenSilent();
      if (success && !error) {
        const { expiresOn, idToken } = success;

        store.dispatch(setJwtToken(idToken, expiresOn));
        resolve({ token: idToken });
      } else {
        reject(error || new SessionInvalidError());
      }
    } else if (application.jwt) {
      resolve({ token: application.jwt });
    } else {
      reject(new SessionInvalidError());
    }
  },
);
