import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'CloudOpsPageServiceWeekList',
  sv: {
    monday: 'm',
    tuesday: 't',
    wednesday: 'o',
    thursday: 't',
    friday: 'f',
    saturday: 'l',
    sunday: 's',
  },
  en: {
    monday: 'm',
    tuesday: 't',
    wednesday: 'w',
    thursday: 't',
    friday: 'f',
    saturday: 's',
    sunday: 's',
  },
});
