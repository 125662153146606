import * as t from './actionTypes';

export const onMount = (payload) => ({
  type: t.MOUNT,
  meta: payload,
});

export const onUnmount = (payload) => ({
  type: t.UNMOUNT,
  meta: payload,
});

export const onUpdateService = (payload) => ({
  type: t.UPDATE_SERVICE,
  meta: payload,
});
