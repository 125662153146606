import styled from 'styled-components';
import backgroundImage from './assets/bg.jpeg';

export const Wrapper = styled.div`
  width: 100vw;
  overflow: auto;
  top: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: 0;
  background: url(${backgroundImage});
  background-size: cover;
  padding: 1.875rem;
      
  @media screen and (min-width: ${({ breakpoints }) => breakpoints.md}) {
    align-items: flex-start;
  }
`;

export const Header = styled.div`
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
  
  button {
    border: none;
  }
  svg {
    margin-right: 0.625rem;
  }
`;

export const Actions = styled.div`
  width: 100%;

  @media screen and (min-width: ${({ breakpoints }) => breakpoints.md}) {
    width: 80%;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.875rem;

  @media screen and (min-width: ${({ breakpoints }) => breakpoints.md}) {
    margin-top: 3.125rem;
    position: relative;
    padding: 0;
  }

  a:first-child {
    margin-right: 1.5rem;
    text-transform: uppercase;
  }
  .white-text {
    color: #fff;
    text-decoration: none;
  }

  svg {
    height: 2rem!important;
    width: 2rem!important;
  }
`;

export const Content = styled.div`
  max-width: 37.5rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  

  @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
    padding: 1.875rem 6.25rem ;
  }

  h1 {
    white-space: nowrap;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 4.375rem;
    font-size: 3.75rem!important;
    font-family: 'Poppins', sans-serif;
    color: #fff;
  }

  p {
    color: #fff;
  }

  @media screen and (min-width: ${({ breakpoints }) => breakpoints.sm}) {
    h1 {
      font-size: 5.625rem!important;
      line-height: 6.875rem;
    }
  }

  @media screen and (min-width: ${({ breakpoints }) => breakpoints.md}) {
    h1 {
      font-size: 7.5rem!important;
      line-height: 6.875rem;
    }
  }

`;

export const Border = styled.div`
  width: 4.375rem;
  height: 2px;
  background: #CAAA77;
  margin: 1.875rem 0;
`;
