import React, {
  createContext, useState,
} from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  value: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

const defaultProps = {
  value: {},
  children: null,
};

export const ServiceWindowMutationContext = createContext(null);

export const ServiceWindowMutationProvider = ({ children, value }) => {
  const [entities, setEntities] = useState(value.entities || []);
  const [serviceWindow, setServiceWindow] = useState(() => ({
    ...value.serviceWindow,
    useMetrics: value?.serviceWindow?.entity_type_metric_name_mappings?.length > 0,
  }));

  return (
    <ServiceWindowMutationContext.Provider value={{
      ...value,
      entities,
      setEntities,
      setServiceWindow,
      serviceWindow,
    }}
    >
      {children}
    </ServiceWindowMutationContext.Provider>
  );
};

ServiceWindowMutationProvider.propTypes = propTypes;
ServiceWindowMutationProvider.defaultProps = defaultProps;
