import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AutoActionsCreateConfigureService',
  sv: {
    add: 'Lägg till',
    update: 'Uppdatera',
  },
  en: {
    add: 'Add',
    update: 'Update',
  },
});
