import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'ApplicationRouter',
  sv: {
    linkOverviewLabel: 'Översikt',
    linkAlertOverviewLabel: 'Larmöversikt',
    linkAlertOverviewTooltip: 'Larmöversikt',
    linkExploreLabel: 'Min miljö',
    linkTicketsLabel: 'Ärenden',
    linkOverviewTooltip: 'Översikt',
    linkExploreTooltip: 'Min miljö',
    linkTicketsTooltip: 'Ärenden',
    linkServiceWindowLabel: 'Servicefönster',
    linkServiceWindowTooltip: 'Servicefönster',
    linkReportsLabel: 'Rapporter',
    linkReportsTooltip: 'Rapporter',
    linkAppMonitorLabel: 'Appövervakning',
    linkAppMonitorTooltip: 'Appövervakning',
    createTicket: 'Ärende',
    createTicketDesc: 'skapa nytt ärende',
    createServiceWindow: 'Service window',
    createServiceWindowDesc: 'skapa nytt service window',
    incidentsLabel: 'Incidenter',
    incidentsTooltip: 'Incidenter',
    menuCreate: 'Skapa',
    swedish: 'Svenska',
    english: 'Engelska',
  },
  en: {
    linkOverviewLabel: 'Overview',
    linkAlertOverviewLabel: 'Alert overview',
    linkAlertOverviewTooltip: 'Alert overview',
    linkExploreLabel: 'My environment',
    linkTicketsLabel: 'Tickets',
    linkOverviewTooltip: 'Overview',
    linkExploreTooltip: 'My environment',
    linkTicketsTooltip: 'Tickets',
    linkServiceWindowLabel: 'Service window',
    linkServiceWindowTooltip: 'Service window',
    linkReportsLabel: 'Reports',
    linkReportsTooltip: 'Reports',
    linkAppMonitorLabel: 'App monitor',
    linkAppMonitorTooltip: 'App monitor',
    createTicket: 'Ticket',
    createTicketDesc: 'create a new ticket',
    createServiceWindow: 'Service window',
    createServiceWindowDesc: 'create a new service window',
    incidentsLabel: 'Incidents',
    incidentsTooltip: 'Incidents',
    menuCreate: 'Create',
    swedish: 'Swedish',
    english: 'English',
  },
});
