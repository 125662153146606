import * as historyStateKeys from '~lib/utils/historyStateKeys';

export const SERVICE_LEVEL_BASIC = 'basic';
export const SERVICE_LEVEL_STANDARD = 'standard';
export const SERVICE_LEVEL_ADVANCED = 'advanced';

export const TIER_1 = 'Tier 1';
export const TIER_2_ENGINEERING = 'Tier 2 Engineering';
export const TIER_2_ANALYTICS = 'Tier 2 Analytics';
export const TIER_2_ADVISORY = 'Tier 2 Advisory';

export const URL_STATE_KEY = historyStateKeys.SECURITY_HOURS;
