import React from 'react';
import PropTypes from 'prop-types';
import ImagePreview from '~ui/ImagePreview';
import { Spinner } from '@chakra-ui/react';
import { useScreenshot } from '../../../../../../lib/services/appMonitor';

const propTypes = {
  imgLink: PropTypes.string,
};

const defaultProps = {
  imgLink: '',
};

const Screenshot = ({ imgLink }) => {
  const { data, isLoading } = useScreenshot(imgLink);

  if (isLoading) {
    return (
      <Spinner
        color="#133A5D"
        emptyColor="#DADADA"
        speed="0.65s"
        thickness="4px"
        size="lg"
      />
    );
  }

  return (<ImagePreview imgLink={data} />);
};

Screenshot.propTypes = propTypes;
Screenshot.defaultProps = defaultProps;

export default Screenshot;
