import Api from '../api';

export const overviewSearch = async ({ payload, customerId, isGlobalAdmin }) => {
  let path = `/customer/${customerId}/resourcetaggroupticket/search`;

  if (isGlobalAdmin) {
    path = `resourcetaggroupticket/search`;
  }

  return Api.post(path, payload)
    .then((result) => (result))
    .catch((error) => ({ error }));
};

export default overviewSearch;
