import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AlertRuleCreate',
  sv: {
    titleText: 'Skapa ny Larmregel',
    buttonText: 'Skapa',
  },
  en: {
    titleText: 'Create new Alert Rule',
    buttonText: 'Create',
  },
});
