import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Security.AnalyticRule.Metadata',
  sv: {
    severity: 'Allvarlighetsgrad',
    kind: 'Typ',
    queryFrequency: 'Queryfrekvens',
    queryPeriod: 'Queryperiod',
    tactics: 'Taktiker',
    tags: 'Taggar',
    relevantTechniques: 'Tekniker',
    requiredDataConnectors: 'Nödvändiga Data Connectors',

    every: 'Varje',
  },
  en: {
    severity: 'Severity',
    kind: 'Kind',
    queryFrequency: 'Query frequency',
    queryPeriod: 'Query period',
    tactics: 'Tactics',
    tags: 'Tags',
    relevantTechniques: 'Techniques',
    requiredDataConnectors: 'Required Data connectors',

    every: 'Every',
  },
});
