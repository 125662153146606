import myEnvironment from './myEnvironment/saga';
import ticketsSaga from './tickets/saga';
import reportsSaga from './reports/saga';
import adminSaga from './admin/saga';
import incidentsSaga from './incidents/saga';
import teamSaga from './team/saga';

export default [
  ...myEnvironment,
  ...ticketsSaga,
  ...reportsSaga,
  ...adminSaga,
  ...incidentsSaga,
  ...teamSaga,
];
