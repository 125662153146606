/* eslint-disable camelcase */
import React, {
  memo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Text, useTheme } from '@chakra-ui/react';
import { TableFilterSelectSingle } from '~ui/Table';

import * as C from '../Steps.styled';
import translation from './CustomerPicker.translation';

const { t } = translation;

const CustomerPicker = ({ setEntities, customerPickerId }) => {
  const { colors, breakpoints } = useTheme();

  // clear chosen entities on customer change
  useEffect(() => {
    setEntities([]);
  }, [customerPickerId, setEntities]);

  return (
    <C.StepContainer isReady breakpoints={breakpoints}>
      <C.Step colors={colors}>1</C.Step>
      <C.StepTitle>{t('header')}</C.StepTitle>
      <C.Content>
        <Text>{t('text')}</Text>
        <TableFilterSelectSingle
          label={t('customer')}
          filterKey="customer_display_name"
          renderTags={false}
        />
      </C.Content>
    </C.StepContainer>
  );
};

CustomerPicker.propTypes = {
  customerPickerId: PropTypes.string,
  setEntities: PropTypes.func.isRequired,
};
CustomerPicker.defaultProps = {
  customerPickerId: '',
};

export default memo(CustomerPicker);
