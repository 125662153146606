import Api from '../../../../../lib/api';

const searchTickets = async (customerId, payload) => {
  const path = `customer/${customerId}/ticket/search`;

  return Api.post(path, payload)
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default searchTickets;
