import React from 'react';
import PropTypes from 'prop-types';
import {
  Text, useTheme,
} from '@chakra-ui/react';
import translation from './EntitiesList.translation';
import * as C from './EntitiesList.styled';
import EntitiesListItem from './components/EntitiesListItem';

const propTypes = {
  entities: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  customerId: PropTypes.string.isRequired,
  backPathUrlParam: PropTypes.string.isRequired,
};

const defaultProps = {};

const EntitiesList = ({ entities, customerId, backPathUrlParam }) => {
  const { t } = translation;
  const { colors } = useTheme();

  const columns = [
    // { label: t('incidentStatus') },
    { label: t('entityName') },
    { label: t('entityType') },
    { label: t('resourceGroup') },
    { label: t('containerName') },
    { label: t('tags') },
    { label: t('region') },
    { label: '' },
  ];

  return (

    <C.Wrapper>

      <C.Table colors={colors}>

        <C.Row isHeadRow>
          {columns.map((column, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <td key={index}>
              <C.Cell isLast={index === columns.length - 1}>
                <Text fontWeight="bold" margin="0">{column.label}</Text>
              </C.Cell>
            </td>
          ))}

        </C.Row>
        {entities?.map((data) => (
          <EntitiesListItem
            key={data.id}
            id={data.id}
            name={data?.name}
            type={data?.type}
            highestPriority={data?.highestPriority}
            resourceGroup={data?.resource_group}
            containerName={data?.container_name}
            tags={data?.tags}
            region={data?.region}
            customerId={customerId}
            backPathUrlParam={backPathUrlParam}
          />
        ))}

      </C.Table>

    </C.Wrapper>
  );
};

EntitiesList.propTypes = propTypes;
EntitiesList.defaultProps = defaultProps;

export default EntitiesList;
