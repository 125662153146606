import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  fill: PropTypes.string.isRequired,
};

const defaultProps = {};

const HighIcon = ({ fill }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill || 'currentColor'} d="M15 2.41L13.59 1L8 6.59L2.41 1L1 2.41L6.59 8L1 13.59L2.41 15L8 9.41L13.59 15L15 13.59L9.41 8L15 2.41Z" />
  </svg>

);

HighIcon.propTypes = propTypes;
HighIcon.defaultProps = defaultProps;

export default HighIcon;
