import * as t from './actionTypes';

export const onMount = (payload) => ({
  type: t.MOUNT,
  meta: payload,
});

export const onUnmount = (payload) => ({
  type: t.UNMOUNT,
  meta: payload,
});

export const onCreate = (payload) => ({
  type: t.CREATE,
  meta: payload,
});

export const initCreateNewTicket = () => ({
  type: t.INIT_CREATE_NEW_TICKET,
  meta: {},
});

export const ticketError = (payload) => ({
  type: t.TICKET_ERROR,
  meta: {
    errors: payload,
  },
});

export const ticketCreated = () => ({
  type: t.TICKET_CREATED,
  meta: {},
});

export const createTicketTaskDone = () => ({
  type: t.CREATE_TICKET_TASK_DONE,
  meta: {},
});

export const restoreState = () => ({
  type: t.RESTORE_STATE,
  meta: {},
});
