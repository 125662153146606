import styled from 'styled-components';

export const Priority = styled.div`
    margin-right: 0.5rem;
    display: block;
    width: 8px;
    height: 8px;
    margin-top: 1px;
    border-radius: 100%;
    background: ${({ colors, priority }) => {
    switch (priority) {
      case 1:
        return colors?.priority?.low;
      case 2:
        return colors?.priority?.medium;
      case 3:
        return colors?.priority?.high;
      case 4:
        return colors?.priority?.urgent;
      default:
        return colors?.gray?.['300'];
    }
  }};
`;
