import Api from '../../../lib/api';

const getAlertRuleById = async ({ customerId, entityId, metricName }) => {
  const path = `customer/${customerId}/metric/entity/${entityId}/metric`;

  return Api.continuationToken.get(path, null, {
    name: metricName,
    interval: 'm',
    maxItems: -1,
  })
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default getAlertRuleById;
