import { connect } from 'react-redux';
import * as Keys from '~lib/utils/historyStateKeys';
import Main from './components/TagGroupOverviewList';
import { getFilterListConnectMethods } from '../../../redux';

const {
  mapStateToProps: listFilterStateToProps,
  mapDispatchToProps,
} = getFilterListConnectMethods(Keys.DASHBOARD);

const mapStateToProps = (state) => {
  const { customer_id: customerId } = state.model.userContext;

  const listFilterProps = listFilterStateToProps(state);

  return { customerId, ...listFilterProps };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
