import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as List from '~ui/List';
import * as Moment from '~ui/Moment';
import { Button } from '~ui/Button';
import * as Block from '~ui/Block';
import { Heading } from '@chakra-ui/react';
import * as C from '../Main.styled';
import translation from '../Main.translation';

const { t } = translation;

const Details = ({
  vmConfig, heartbeat, redeployAgent, entityStatus, canWrite,
}) => {
  const agentVersion = heartbeat?.dimensions
    ?.find((dim) => dim.includes('version'))
    ?.replace('version:', '');

  return (
    <C.Type>
      <Heading as="h2">{t('details')}</Heading>
      <List.Primary
        rows={[
          {
            label: t('agentVersion'),
            value: (
              canWrite && (
              <Block.SpaceBetween style={{ flexWrap: 'wrap' }}>
                <div>{heartbeat ? agentVersion : '-'}</div>
                <Button
                  tooltip={t('redeployAgentTooltip')}
                  variant="ghost"
                  style={{
                    color: '#133A5D', padding: '0', whiteSpace: 'normal', wordBreak: 'normal',
                  }}
                  onClick={redeployAgent}
                >
                  {t('redeployAgent')}
                </Button>
              </Block.SpaceBetween>
              )
            ),
          },
          {
            label: t('heartbeatReceived'),
            value: heartbeat?.timestamp
              ? <Moment.Ago timestamp={heartbeat.timestamp} />
              : t('noHeartbeat'),
          },
          { label: t('id'), value: vmConfig.entity_id || '-' },
          { label: t('customerId'), value: vmConfig.customer_id || '-' },
          { label: t('clientId'), value: vmConfig.client_id || '-' },
          { label: t('entityId'), value: vmConfig.entity_id || '-' },
          { label: t('entityType'), value: vmConfig.entity_type || '-' },
          { label: t('entityStatus'), value: <C.Capitalized>{entityStatus}</C.Capitalized> },
          { label: t('source'), value: <C.Capitalized>{vmConfig.source}</C.Capitalized> || '-' },
          { label: t('configModified'), value: vmConfig.config_timestamp ? <Moment.DateTime timestamp={moment.utc(vmConfig.config_timestamp * 1000).toISOString()} /> : '-' },
        ]}
      />
    </C.Type>
  );
};
Details.propTypes = {
  canWrite: PropTypes.bool.isRequired,
  vmConfig: PropTypes.instanceOf(Object),
  heartbeat: PropTypes.instanceOf(Object),
  redeployAgent: PropTypes.func.isRequired,
  entityStatus: PropTypes.string,
};
Details.defaultProps = { vmConfig: {}, heartbeat: {}, entityStatus: '-' };

export default Details;
