import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AlarmOverviewList',
  sv: {
    noResults: 'Inga resultat',

  },
  en: {
    noResults: 'No result',
  },
});
