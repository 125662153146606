import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '~ui/Button';
import * as Cards from '~ui/Cards';
import Attachment from '~ui/Attachment';
import * as Permission from '~ui/Permission';
import {
  Spinner, Tooltip, Tag, Wrap, useTheme, VStack,
} from '@chakra-ui/react';
import * as C from './TicketDetails.styled';
import Metadata from '../Metadata';
import AlertRuleMetricsWidget from '../../../../../../modules/AlertRuleMetricsWidget';
import translation from './TicketDetails.translation';
import EditMeta from '../../modules/EditMeta';

const { t } = translation;

const Meta = ({
  alertRule, ticket, entity, ticketCustomer, attachments,
}) => {
  const { colors } = useTheme();

  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();

  return (
    <C.Meta>
      {isGlobalAdmin && ticketCustomer && (
        <Metadata
          title={t('customer')}
          value={ticketCustomer}
        />
      )}

      <Metadata title={t('type')} value={ticket.type} />

      { isGlobalAdmin
        ? (<EditMeta />)
        : (<Metadata title={t('status')} value={ticket.status} />)}

      <Metadata title={t('priority')}>
        <C.Priority colors={colors} priority={ticket.priority} />
        <div>{t(`priority${ticket.priority}`)}</div>
      </Metadata>

      { isGlobalAdmin && <Metadata title={t('assignee')} value={ticket.responder_id || t('none')} /> }

      {ticket.tags && ticket.tags.length > 0 && (
        <Metadata title={t('tags')}>
          <Wrap spacing={2}>
            {ticket.tags.map((tag) => <Tag key={tag} bg="#f5edd8">{tag}</Tag>)}
          </Wrap>
        </Metadata>
      )}

      {ticket.external_id && (
        <Metadata
          title={t('externalId')}
          value={`#${ticket.external_id}`}
        />
      )}

      {ticket.entity_id && (
        <Metadata title={t('entity')}>
          { entity === null && (
          <Spinner
            color="#133A5D"
            emptyColor="#DADADA"
            speed="0.65s"
            thickness="4px"
            size="xl"
          />
          )}
          { entity !== false && entity !== null && (

            <Button variant="link" internalLink={entity.link}>
              <Tooltip
                hasArrow
                placement="top"
                bg="white"
                color="black"
                label={(
                  <Cards.Entity
                    id={entity.id}
                    hasCloudops={entity?.properties?.cloudops_active}
                    name={entity.name}
                    type={entity.type}
                    resourceGroup={entity.resource_group}
                    region={entity.region}
                    displayName={entity.customer_display_name}
                    tags={Object.entries(entity.tags).map(([a, b]) => `${a}:${b}`)}
                  />
             )}
              >
                {entity.name}
              </Tooltip>
            </Button>

          )}
        </Metadata>
      )}

      { ticket.alert_rule_id && (
        <>
          <Metadata title={t('alertRule')}>
            { alertRule === null && (
            <Spinner
              color="#133A5D"
              emptyColor="#DADADA"
              speed="0.65s"
              thickness="4px"
              size="xl"
            />
            )}
            { alertRule !== false && alertRule !== null && (
            <Button variant="link" internalLink={alertRule.link}>
              {alertRule.name}
            </Button>
            )}
          </Metadata>
          { alertRule !== null && (
          <AlertRuleMetricsWidget
            customerId={alertRule.customer_id}
            entityId={alertRule.entity_id}
            threshold={alertRule.threshold}
            criticalThreshold={alertRule.critical_threshold}
            metricName={alertRule.metric_name}
          />
          )}
        </>
      )}

      {attachments.length > 0 && (
      <Metadata title="Attachments">
        <VStack width="100%">
          {attachments?.map((attachment) => (
            <Attachment
              key={attachment?.id || attachment.key}
              file={attachment}
              modalPreview
            />
          ))}
        </VStack>
      </Metadata>
      )}
    </C.Meta>
  );
};

Meta.propTypes = {
  alertRule: PropTypes.instanceOf(Object),
  ticket: PropTypes.instanceOf(Object),
  entity: PropTypes.instanceOf(Object),
  ticketCustomer: PropTypes.string,
  attachments: PropTypes.arrayOf(PropTypes.shape({
    attachment_url: PropTypes.string.isRequired,
    content_type: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    updated: PropTypes.string.isRequired,
  })),
};

Meta.defaultProps = {
  alertRule: {},
  ticket: {},
  entity: {},
  ticketCustomer: null,
  attachments: [],
};

export default Meta;
