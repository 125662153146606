import React from 'react';
import { Text } from '@chakra-ui/react';
import * as C from '../Main.styled';
import translation from '../Main.translation';

const { t } = translation;

export const mapRow = ({ array = [], label = '' }) => array.map((value, index) => ({
  label: index === 0 ? label : '',
  value: (<C.BorderedItem>{value}</C.BorderedItem>),
}));

export const mapUrls = ({ array = [], label = '' }) => array.map((url, index) => ({
  label: index === 0 ? label : '',
  value: (
    <C.BorderedItem key={url.url}>
      <C.Center>
        <Text textTransform="uppercase" style={{ margin: '0', marginRight: '0.46875rem' }}>{`${url.http_method}`}</Text>
        <C.NullLink onClick={(e) => e.preventDefault()}>{url.url}</C.NullLink>
      </C.Center>
      <Text>{`${t('bodyRegex')}: '${url.response_body_should_contain_regex}'`}</Text>
    </C.BorderedItem>
  ),
}));

export const mapFolders = ({ array = [], label = '' }) => array.map((folder, index) => ({
  label: index === 0 ? label : '',
  value: (
    <C.BorderedItem key={folder.folder_path + folder.file_mask}>
      <Text>{`${t('folderPath')}: ${folder.folder_path}`}</Text>
      <Text>{`${t('fileMask')}: ${folder.file_mask}`}</Text>
    </C.BorderedItem>
  ),
}));
