const graphUrl = 'https://graph.microsoft.com/v1.0';

const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => {
    binary += String.fromCharCode(b);
  });
  return window.btoa(binary);
};

export const getUserPhoto = (token) => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const options = { headers };

  return fetch(`${graphUrl}/me/photo/$value`, options)
    .then((res) => {
      if (res.status !== 200) {
        return null;
      }
      return res.arrayBuffer().then((buffer) => {
        const base64Flag = 'data:image/jpeg;base64,';
        const imageStr = arrayBufferToBase64(buffer);
        return base64Flag + imageStr;
      });
    })

    .catch((e) => {
      throw new Error('error fetching photo from graph api', e);
    });
};

const getGroups = (token, url) => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });
  const options = {
    headers,
  };
  return fetch(url, options)
    .then((r) => r.json());
};

export const getUserGroups = (
  token,
  cacheResult,
  continuationToken,
) => new Promise(
  (resolve, reject) => {
    getGroups(token, (continuationToken || `${graphUrl}/me/memberOf`))
      .then((response) => {
        if (Object.prototype.hasOwnProperty.call(response, 'value')) {
          const { value, '@odata.nextLink': ct } = response;
          const groupIds = value
            .reduce((acc, { id }) => [...acc, id], []);
          const returnData = [...(cacheResult || []), ...groupIds];

          if (ct) {
            return getUserGroups(token, returnData, ct);
          }

          return returnData;
        }

        return null;
      })
      .then((result) => {
        resolve(result);
      })
      .catch((e) => {
        reject(new Error('error getting user groups', e));
      });
  },
);
