import {
  take,
  call,
  cancel,
  fork,
} from 'redux-saga/effects';
import * as t from './actionTypes';
import getPBIEmbedToken from './services/getPBIEmbedToken';
import { generatePowerBIConfig, generatePowerBIService } from './helpers';

function* loadPowerBIReport(meta) {
  const {
    setPbiService, setPbiConfig, groupId, reportId, customerId, setIsLoading, setError,
  } = meta;
  const { success } = yield call(getPBIEmbedToken, { groupId, reportId, customerId });

  if (success) {
    const embedToken = success.result;
    const pbiService = generatePowerBIService();
    const pbiConfig = generatePowerBIConfig({
      embedToken, reportId, groupId,
    });

    setPbiService(pbiService);
    setPbiConfig(pbiConfig);
  } else {
    // couldn't generate token
    setError(true);
  }
  setIsLoading(false);
}

export default function* rootWatcher() {
  while (true) {
    const { meta } = yield take(t.MOUNT);

    const tasks = [
      yield fork(loadPowerBIReport, meta),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
