import * as t from './actionTypes';

export const onMount = (payload) => ({
  type: t.MOUNT,
  meta: payload,
});

export const onUnmount = (payload, id) => ({
  type: `${t.UNMOUNT}-${id}`,
  meta: payload,
});

export const onUpdate = (payload, id) => ({
  type: `${t.UPDATE}-${id}`,
  meta: payload,
});
