import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'CloudOpsVMAgentEdit',
  sv: {
    monitor: 'Övervakning',
    servicesToMonitor: 'Windows-tjänster att övervaka (Regex)',
    servicesNotToMonitor: 'Windows-tjänster som ska ignoreras (Regex)',
    urlsToMonitor: 'URL som ska övervakas (exakt matchning)',
    iisWebSitesNotToMonitor: 'IIS Web Sites som ska ignoreras (exakt matchning)',
    iisAppPoolsNotToMonitor: 'IIS App pools som ska ignoreras (exakt matchning)',
    foldersLastModifiedToMonitor: 'Senast uppdatering av katalog (exakt matchning)',

    queries: 'Event Log Queries',
    eventLogQueries: 'Event Log Queries',
    folderPath: 'Folder Path',
    fileMask: 'File Mask',
    queryName: 'Query namn (metric-namnet baseras på detta)',
    logName: 'Log-namn i Event Log (t.ex. Application, System)',
    level: 'Level i Event Log',
    regexString: 'Message i Event Log (Regex)',
    eventID: 'Event ID i Event Log (number, exact match)',
    sourceRegexString: 'Source i Event Log (Regex)',

    edit: 'Ändra',
    back: 'VM Agent',
    update: 'Uppdatera',
    select: 'Välj',

    verbose: 'Verbose',
    information: 'Information',
    warning: 'Warning',
    error: 'Error',
    critical: 'Critical',

    name: 'Namn (metric-namnet baseras på detta)',
    url: 'URL som ska övervakas',
    httpMethod: 'HTTP Metod',
    responseBodyShouldContainRegex: 'Response Body ska innehålla följande Regex (annars genereras larm)',

    multiStepWebTests: 'Multi step web tests',
    intervalInMinutes: 'Interval (minutes)',

    addNew: 'Lägg till ny',

    success: 'Konfiguration uppdaterad (kan ta upp till 20 minuter för ny agentversion).',
    fail: 'Kunde inte uppdatera konfigurationen, testa igen senare.',
  },
  en: {
    monitor: 'Monitor',
    servicesToMonitor: 'Windows Services to monitor (Regex)',
    servicesNotToMonitor: 'Windows Services to ignore (Regex)',
    urlsToMonitor: 'URLs to monitor (exact match)',
    iisWebSitesNotToMonitor: 'IIS Websites to ignore (exact match)',
    iisAppPoolsNotToMonitor: 'IIS App pools to ignore (exact match)',
    foldersLastModifiedToMonitor: 'Folders last modified to monitor (exact match)',

    queries: 'Event Log Queries',
    eventLogQueries: 'Event Log Queries',
    folderPath: 'Folder Path',
    fileMask: 'File Mask',
    queryName: 'Query name (metric name will be based on this)',
    logName: 'Log name in Event Log (e.g. Application, System)',
    level: 'Level in Event Log',
    regexString: 'Message in Event Log (Regex)',
    eventID: 'Event ID in Event Log (number, exact match)',
    sourceRegexString: 'Source in Event Log (Regex)',

    edit: 'Edit',
    back: 'VM Agent',
    update: 'Update',
    select: 'Select',

    verbose: 'Verbose',
    information: 'Information',
    warning: 'Warning',
    error: 'Error',
    critical: 'Critical',

    name: 'Name (metric name will be based on this)',
    url: 'URL to monitor',
    httpMethod: 'HTTP Method',
    responseBodyShouldContainRegex: 'Response Body should contain the following Regex (alert if not found)',

    multiStepWebTests: 'Multi step web tests',
    intervalInMinutes: 'Interval (minutes)',

    addNew: 'Add new',

    success: 'Configuration updated (might take up to 20 minutes for new agent version).',
    fail: 'Could not update the configuration. Try again later.',
  },
});
