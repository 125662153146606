import {
  call,
  cancel,
  delay,
  takeEvery,
  fork,
} from 'redux-saga/effects';
import moment from 'moment';
import * as t from './actionTypes';
import ticketsSearch from './services/ticketsSearch.service';

const metricNameList = {};

function* cancelCustomerSaga({ meta }) {
  const { metricName } = meta;
  if (metricNameList?.[metricName]) {
    yield cancel(metricNameList[metricName]);
  }
}

function* getIncidentData({
  onSuccess, onFail, customerId, metricName,
}) {
  const graphPayload = {
    facets: ['created, interval:day'],
    filter: `(type eq 'incident') and 
      (customer_id eq '${customerId}') and 
      (alert_rule_name eq '${metricName}') and 
      (created gt ${moment().subtract(30, 'days').toISOString()}) and
      (alert_rule_name ne '') and 
      (alert_rule_name ne null)
      `,
    order_by: ['modified desc'],
    page: 1,
    page_size: 0,
    search_fields: [],
    search_string: '',
  };

  while (true) {
    const { success } = yield call(ticketsSearch, customerId, graphPayload);

    if (success) {
      yield call(onSuccess, success.facets.created);
    } else {
      yield call(onFail, []);
    }
    const delayTime = (60 + Math.floor(Math.random() * 10)) * 1000;
    yield delay(delayTime);
  }
}

function* startCustomerSaga({ meta }) {
  const customerInstance = yield fork(getIncidentData, meta);
  Object.assign(metricNameList, {
    [meta.metricName]: customerInstance,
  });
}

export default function* rootWatcher() {
  yield takeEvery(t.MOUNT, startCustomerSaga);
  yield takeEvery(t.UNMOUNT, cancelCustomerSaga);
}
