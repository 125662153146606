/* eslint-disable camelcase */
import React, {
  useState,
  useContext,
  useCallback,
} from 'react';
import * as Form from '~ui/Form';
import PropTypes from 'prop-types';
import { Text, useTheme } from '@chakra-ui/react';
import MetricOptions from './MetricsOptions';
import { ServiceWindowMutationContext } from '../../../../serviceWindowMutationContext';

import * as C from '../Steps.styled';
import translation from './Metrics.translation';

const formSpec = ({ t: _t }, initialValue) => ({
  showMetricsOptionsForm: {
    type: 'switch',
    label: _t('showMetricsFormLabel'),
    value: initialValue,
    wrapperStyle: { border: 'none' },
  },
}
);

const defaultProps = {
  initialData: null,
};

const propTypes = {
  isReady: PropTypes.bool.isRequired,
  step: PropTypes.string.isRequired,
  initialData: PropTypes.instanceOf(Object),
  customerId: PropTypes.string.isRequired,
  entities: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

const Metrics = (props) => {
  const {
    isReady, step, initialData, entities,
    customerId,
  } = props;
  const { t } = translation;
  const { colors, breakpoints } = useTheme();

  const [showMetricsOptions, setShowMetricsOptions] = useState(
    initialData?.useMetrics,
  );

  const metricsForm = Form.useFormBuilder(formSpec(translation, showMetricsOptions));

  const ctx = useContext(ServiceWindowMutationContext);

  const handleFormChange = useCallback(({ values }) => {
    const { showMetricsOptionsForm } = values;
    setShowMetricsOptions(showMetricsOptionsForm);

    ctx.setServiceWindow({
      ...ctx.serviceWindow,
      useMetrics: showMetricsOptionsForm,
    });
  }, [ctx]);

  return (
    <C.StepContainer isReady={isReady} breakpoints={breakpoints}>
      <C.Step colors={colors}>{step}</C.Step>
      <C.StepTitle>{t('header')}</C.StepTitle>
      <C.Content>
        <Text>
          {entities.length === 0 ? t('noEntitiesText') : t('text')}
        </Text>
        <Form.Primary
          form={metricsForm}
          onChange={handleFormChange}
        >
          {({ render }) => render}
        </Form.Primary>

        {showMetricsOptions
        && (
        <MetricOptions
          customerId={customerId}
          entities={entities}
          initialData={initialData?.metrics}
        />
        )}
      </C.Content>
    </C.StepContainer>
  );
};

Metrics.propTypes = propTypes;
Metrics.defaultProps = defaultProps;

export default Metrics;
