import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'MyEnvironmentNavigation',
  en: {
    information: 'Information',
    alertRules: 'Alert rules',
    tickets: 'Tickets',
    services: 'Services',
    automation: 'Automation',
    myEnv: 'My environment',
    serviceWindows: 'Service windows',
    cloudopsVMAgent: 'VM agent',
  },
  sv: {
    information: 'Information',
    alertRules: 'Larmregler',
    tickets: 'Ärenden',
    services: 'Tjänster',
    automation: 'Automation',
    myEnv: 'Min miljö',
    serviceWindows: 'Servicefönster',
    cloudopsVMAgent: 'VM agent',
  },
});
