import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Securtiy.Component.TemplateCard',
  sv: {
    connectorWarning: 'Detta workspace har inte alla Azure Connectors aktiverade för att använda denna mall optimalt',

    configure: 'Konfigurera',
    required: 'Nödvändiga data connectors',
    tactics: 'Taktiker',
  },
  en: {
    connectorWarning: 'This workspace doesn\'t have all Azure connectors activated to use this template optimally',

    configure: 'Configure',
    required: 'required data connectors',
    tactics: 'Tactics',
  },
});
