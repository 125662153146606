export function getYearAndMonthIndex(date) {
  if (!(date instanceof Date)) {
    return [];
  }

  const year = date.getFullYear();
  const monthIndex = date.getMonth();

  return [year, monthIndex];
}
