import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@chakra-ui/react';
import translation from './Weeklist.translation';
import * as C from './WeekList.styled';

const propTypes = {
  onWeekDays: PropTypes.bool.isRequired,
  onWeekEnds: PropTypes.bool.isRequired,
  style: PropTypes.instanceOf(Object),
};

const defaultProps = {
  style: {},
};

const WeekList = ({ onWeekDays, onWeekEnds, style }) => {
  const { colors } = useTheme();

  const { t } = translation;
  const days = useMemo(() => [
    { value: t('monday'), weekday: true, key: 1 },
    { value: t('tuesday'), weekday: true, key: 2 },
    { value: t('wednesday'), weekday: true, key: 3 },
    { value: t('thursday'), weekday: true, key: 4 },
    { value: t('friday'), weekday: true, key: 5 },
    { value: t('saturday'), weekend: true, key: 6 },
    { value: t('sunday'), weekend: true, key: 7 },
  ], [t]);

  return (
    <C.Days style={style}>
      {
        days.map((day) => {
          const isActive = (day.weekend && onWeekEnds) || (day.weekday && onWeekDays);
          return (
            <C.Day colors={colors} key={day.key} isActive={isActive}>
              {day.value}
            </C.Day>
          );
        })
      }
    </C.Days>
  );
};

WeekList.defaultProps = defaultProps;
WeekList.propTypes = propTypes;

export default WeekList;
