import styled from 'styled-components';

export const StyledProperties = styled.div`
  transition: 0.5s;
  flex-basis: 100%;
  overflow: hidden;
  transition-timing-function: ease-in-out;
  
  pre {
    background: #f8f8f8;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid #e6e6e6;
    color: #555;

    white-space: pre-wrap;
    font-family: Consolas, 'Lucida Console', Monaco, monospace;
  }
`;
