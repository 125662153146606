import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AlertRuleEdit',
  sv: {
    titleText: 'Uppdatera Larmregel',
    buttonText: 'Uppdatera',
  },
  en: {
    titleText: 'Edit Alert Rule',
    buttonText: 'Update',
  },
});
