import styled from 'styled-components';

export const Icon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ colors }) => colors?.blue?.['900']};
`;

export const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width:100%;
`;

export const LinkCard = styled.div`
    width: 100%;
    display: grid;
    padding: 1rem 0;
    min-height: 7rem;
    grid-template-rows: 1fr;
    grid-template-columns: 3.125rem 1fr 3.125rem;
    grid-template-areas: 
        "icon"
        "content"
        "arrow";
    color: #000;

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.sm}) {
        grid-template-columns: 6rem 1fr 6rem;
        grid-template-areas: 
        "icon"
        "content"
        "arrow";
    }
`;

export const Card = styled.div`
    padding: 2rem 2rem 2rem 0;
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 3.125rem 1fr;
    grid-template-areas: "icon content";
    color: #000;

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.md}) {
        grid-template-columns: 6rem 1fr;
        grid-template-areas: 
            "icon"
            "content";        
    }
`;
