import styled from 'styled-components';

export const DateSpan = styled.div`
    grid-area: dateSpan;
    display: flex;
    align-items: flex-start;
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    flex-direction: column;
    justify-content: center;
`;
export const Repeat = styled.div`
    grid-area: repeat;
    display: none;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 700px) {
        display: flex;
    }
`;
export const StartEnd = styled.div`
    grid-area: startEnd;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    position:relative;
    &:before {
        display:none;
        content: '';
        position: absolute;
        left: 0px;
        height: 5rem;
        width: 1px;
        border-left: 1px solid;
        border-left-color: ${({ colors, isOpen }) => (isOpen ? colors?.gray?.['300'] : colors?.gray?.['200'])};
    }
`;

export const Expander = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-width: 9.375rem;
    width: 100%;
    padding: 0.5rem;
    grid-area: openBtn;
  
    @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
        border-top: none;
        border-left: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
    }
`;

export const Edit = styled(Expander)`
    grid-area: edit;
    border: none;
    width: 175px;
    display: flex;
    align-items: inherit;
    padding-left: 2rem;
    justify-content: center;
    gap: 1rem;
`;

export const Card = styled.div`
    width: 100%; 
    display: grid;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: ${({ isOpen }) => (isOpen ? '0 3px 10px 0px rgba(32, 33, 36, 0.28)' : '0 3px 6px -2px rgba(32, 33, 36, 0.28)')};
    margin-bottom: 1rem;
`;

export const Icons = styled.div`
    flex-shrink: 0;
    flex-grow: 1;
    display: grid;
    justify-content: center;
    padding: 1rem;
    grid-template-areas: "scheduleText ." "dateSpan repeat" "occurrenceText ." "startEnd startEnd";

    & > * {
     padding: 0;
    }
    @media screen and (min-width: 600px) {
        margin-bottom: 0;
        & > * {
            padding: 0 2.5rem;
        }
    }
    ${DateSpan} {
        padding-right: 0;
    } 
    ${Repeat} {
        align-items: center;
        margin-bottom: 0.625rem;
    } 

    @media screen and (min-width: 700px) {
        ${StartEnd} {
            &:before {
                display: block;
            }
        }
    }

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
        grid-template-areas: "scheduleText scheduleText occurrenceText" "dateSpan repeat startEnd";
        justify-content: flex-end;
    }
`;

export const Top = styled.div`
    flex-grow: 1;
    overflow: hidden;
    display: grid;  
    grid-template-rows: auto;
    border: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};

    grid-template-columns: 1fr;
    grid-template-areas:
        'topText'
        'topIcons'
        'openBtn';
    
    @media screen and (min-width: 600px) {
        grid-template-areas: 
            'topText topIcons topIcons'
            'openBtn openBtn openBtn'
        ;
    }

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
        grid-template-areas: 'topText topIcons openBtn';
        grid-template-columns: 1fr auto calc(175px + 1rem);
    }

    ${Icons} {
        grid-area: topIcons;
        grid-template-areas:
            "repeat"
            "startEnd"
           ;
        @media screen and (min-width: 600px) {
            grid-template-areas: "repeat startEnd";
        }
    }
    /* remove margin of the button */
    .label p {
        margin: 0;
    }

    border-radius: ${({ isOpen }) => (isOpen ? '5px 5px 0 0' : '5px')};
`;

export const Bottom = styled.div`
    background: ${({ colors }) => colors?.gray?.['50']};
    padding: 0 1rem 1rem 1rem;
`;

export const TopText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    overflow: hidden;
    padding: 1.5625rem 2rem;
    grid-area: topText;
    p {
        white-space: ${({ isOpen }) => (isOpen ? 'normal' : 'nowrap')} ;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    & > * {
        margin: 0;
        margin-bottom: 0.5rem;
    }
`;

export const BottomText = styled.div`
   padding: 0 2rem;
`;

export const TicketLink = styled.div`
    display: flex;
`;

export const Black = styled.span`
    color: #000;
`;

export const TextValue = styled.p`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
`;

export const OccurrenceText = styled(TextValue)`
    grid-area: occurrenceText;
`;

export const ScheduleText = styled(TextValue)`
    grid-area: scheduleText;
`;

export const TextLarge = styled.p`
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
`;
export const LinkContainer = styled.div`
    display: flex;
`;

export const IconContainer = styled.div`
     display: flex; 
     width: 100%; 
     flex-wrap: wrap;
     padding: 0.625rem 0 1.25rem 0;
`;
