import styled from 'styled-components';

export const BoldText = styled.p`
    font-weight: bold;
`;

export const DescriptionText = styled.div`
    flex: 1; 
    flex-basis: 500px;
`;

export const AsurgentIcon = styled.div`
    margin-bottom: 1.25rem;
`;

export const Paragraph = styled.div`
    border-top: 1px solid ${({ colors }) => colors?.gray?.['300']};
    padding: 1rem 0 2rem 0;
`;
