import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  fill: PropTypes.string.isRequired,
};

const defaultProps = {};

const LowIcon = ({ fill }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill || 'currentColor'} d="M14.0625 16.62L12 14.37L12.87 13.5L14.0625 14.6925L16.755 12L17.625 13.0575L14.0625 16.62ZM9.75 9.75V5.25H8.25V9.75H9.75ZM9.75 12.75V11.25H8.25V12.75H9.75ZM9 1.5C13.125 1.5 16.5 4.875 16.5 9L16.4325 9.9825C15.7556 9.75608 15.0347 9.69377 14.329 9.8007C13.6233 9.90763 12.9532 10.1807 12.3738 10.5975C11.7944 11.0143 11.3223 11.5628 10.9965 12.1979C10.6708 12.8329 10.5006 13.5363 10.5 14.25C10.5 14.9625 10.665 15.6375 10.965 16.2375C10.335 16.41 9.6825 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5Z" />
  </svg>
);

LowIcon.propTypes = propTypes;
LowIcon.defaultProps = defaultProps;

export default LowIcon;
