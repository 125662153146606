import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AppMonitor.Overview.Entry',
  sv: {
    noFailedTests: 'Gått som tåget - Alla testkörningar genomfördes utan fel',
    noData: 'Ingen data tillgänglig för perioden',
    of: 'av',
    runsFailed: 'körningar misslyckades',
  },
  en: {
    noFailedTests: 'Smooth sailing - All runs went through without error',
    noData: 'No data available for this period',
    of: 'out of',
    runs: 'runs',
    runsFailed: 'runs failed',
  },
});
