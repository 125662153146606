import moment from 'moment';
import Api from '../../../../../../../../../lib/api';

const addService = ({ customerId, entityId, id }) => {
  const path = `/customer/${customerId}/entity/${entityId}/cloudopsservice/${id}`;
  const params = {
    validFrom: moment().toISOString(),
    validTo: moment('9999-12-31').toISOString(),
  };

  return Api.post(path, null, params)
    .then((success) => ({ success }))
    .catch((error) => {
      if (error?.response?.data) {
        return { error: error.response.data };
      }

      return { error };
    });
};

export default addService;
