import Api from '../../../../../lib/api';

const getTeam = async ({ customerId }) => {
  const path = `customer/${customerId}/team`;

  return Api.get(path)
    .then((success) => ({ success }))
    .catch((error) => {
      if (error?.response?.data) {
        return { error: error.response.data };
      }
      return { error };
    });
};

export default getTeam;
