import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AutoActionListRow',
  sv: {
    remove: 'Ta bort',
    edit: 'Ändra',
    description: 'Beskrivning',
    activeOnAlertRule: 'Aktiv på Larmregler',
    sequence: 'Sekvens',
    activeOnEntity: 'Denna åtgärd körs under alla larm på denna resurs',
    id: 'ID',
  },
  en: {
    remove: 'Remove',
    edit: 'Edit',
    description: 'Description',
    activeOnAlertRule: 'Active on Alert Rules',
    sequence: 'Sequence',
    activeOnEntity: 'This action will run for all alerts on this entity',
    id: 'ID',
  },
});
