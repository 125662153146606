import styled from 'styled-components';

export const InnerSection = styled.div`
  `;

export const Wrapper = styled.div`
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 2rem;
    overflow-x: hidden;
    ${InnerSection} {
      border-bottom: 1px solid ${({ colors }) => colors?.gray?.['200']};
    }
`;
