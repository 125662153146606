/* eslint-disable no-param-reassign */
/* eslint-disable prefer-object-spread */

const initalState = {
  userContext: null,
  customer: null,
  listFilter: {},
};

// used by entityloadermodule
export const ENTITY_SET = '[ENTITY][SET]';

// used by router-saga
export const CUSTOMER_SET = '[CUSTOMER][SET]';
export const USER_CONTEXT_SET = '[USER_CONTEXT][SET]';
export const LIST_FILTER_SET = '[LIST_FILTER][SET]';

export const modelReducer = (state = initalState, { type, meta }) => {
  switch (type) {
    case ENTITY_SET:
      return Object.assign({}, state, {
        entity: meta.entity,
      });
    case USER_CONTEXT_SET:
      return Object.assign({}, state, {
        userContext: meta.userContext,
      });
    case CUSTOMER_SET:
      return Object.assign({}, state, {
        customer: meta.customer,
      });

    case LIST_FILTER_SET: {
      const listKeys = meta.listFilter && Object.keys(meta?.listFilter);

      const nextFilter = {
        ...state.listFilter,
      };

      listKeys.forEach((listKey) => {
        nextFilter[listKey] = {
          ...meta.listFilter[listKey],
        };
      });

      return Object.assign({}, state, {
        listFilter: nextFilter,
      });
    }

    default:
      return state;
  }
};
