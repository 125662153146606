import styled from 'styled-components';

export const Layout = styled.div`
    display: grid;
    width: 100%;
    min-width: 0;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 2.5rem;
    

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.md}) {
      grid-template-columns: 1fr 25rem;
      grid-column-gap: 2.5rem;
    }
`;
