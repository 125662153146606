import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Button } from '~ui/Button';
import * as Form from '~ui/Form';
import * as Block from '~ui/Block';
import { useToast, useTheme } from '@chakra-ui/react';
import translation from './EditMeta.translation';
import * as C from './EditMeta.styled';

const { t } = translation;

const EditMeta = ({
  onMount, onUnmount, onUpdateTicket, name, email,
}) => {
  const { colors } = useTheme();

  const { params } = useRouteMatch();
  const { customerId, ticketId } = params;
  const [ticket, setTicket] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    onMount({
      customerId,
      ticketId,
      setTicket,
      onSuccess: () => toast({
        description: t('success'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      }),
      onFail: () => toast({
        description: t('fail'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      }),
    });

    return () => onUnmount();
  }, [onMount, onUnmount, customerId, ticketId, toast]);

  const formData = Form.useFormBuilder({
    status: {
      type: 'select',
      label: 'Status',
      options: [
        { value: 'open', label: 'Open' },
        { value: 'executing', label: 'Executing' },
        { value: 'pending', label: 'Pending' },
        { value: 'resolved', label: 'Resolved' },
        { value: 'closed', label: 'Closed' },
        { value: 'on hold', label: 'On Hold' },
        { value: '3rd party', label: '3rd Party' },
        { value: 'rejected', label: 'Rejected' },
      ],
    },
    comment: {
      type: 'textarea',
      label: t('comment'),
      render: () => touched,
      validator: {
        condition: (v) => v !== '',
      },
      placeholder: t('commentPlaceholder'),
    },
  });

  useEffect(() => {
    formData.updateField('status', { value: ticket.status });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  useEffect(() => {
    formData.updateField('comment', { render: () => touched });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  return (
    <C.MetaForm
      form={formData}
      msTimer={15}
      onChange={() => {
        setTouched(true);
      }}
      onSubmit={({ values, valid }) => {
        if (valid) {
          const payload = { ...values, name, email };
          onUpdateTicket({ payload, setIsLoading, setTouched });
        } else {
          formData.errors([{ property: 'comment', message: t('commentError') }]);
        }
      }}
    >
      {({ render, isDirty }) => (
        <>
          {render}
          {touched && (
          <Block.SpaceBetween>
            <C.Cancel
              colors={colors}
              style={{ marginBottom: '1rem' }}
              onClick={() => {
                formData.resetValues();
                setTouched(false);
              }}
            >
              {t('btnCancel')}
            </C.Cancel>
            <Button
              style={{ marginBottom: '1rem' }}
              type="submit"
              disabled={!isDirty}
              isLoading={isLoading}
            >
              {t('btnUpdate')}
            </Button>
          </Block.SpaceBetween>
          )}
        </>
      )}
    </C.MetaForm>
  );
};

EditMeta.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  onUpdateTicket: PropTypes.func.isRequired,
};

export default EditMeta;
