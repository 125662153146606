import {
  take,
  call,
  cancel,
  select,
  takeEvery,
  fork,
  all,
  put,
} from 'redux-saga/effects';
import * as t from './actionTypes';

import {
  initTaskAddComments, commentError, fileUploaded,
} from './actions';

import getTicketDetailsService from './services/getTicket.service';
import getTicketCommentsService from './services/getTicketComments.service';
import getEntityService from './services/getEntity.service';
import getAlertRule from './services/getAlertRule.service';
import getCustomer from './services/getCustomer.service';
import postTicketResponse from './services/postTicketResponse.service';
import createTicketAttachment from './services/createTicketAttachment.service';

const getCustomerId = ({ model }) => model.customer.id;

const getUser = ({ model }) => {
  const { name, email } = model.userContext;

  return { name, email };
};

const getErrors = ({ ticket }) => ticket.details.errors;
const getUploadedFiles = ({ ticket }) => ticket.details.uploadedFiles;

function* loadDetailData({
  customerId, entityId, alertRuleId, setEntity, setAlertRule,
}) {
  const baseLink = `/my-environment/${customerId}/${entityId}`;

  if (entityId) {
    const { success: entity } = yield call(getEntityService, { customerId, entityId });
    const link = `${baseLink}/information`;
    if (entity) {
      setEntity({ ...entity, link });
    }
  }
  if (alertRuleId) {
    const link = `${baseLink}/alert-rules`;
    const { success: entity } = yield call(getAlertRule, { customerId, entityId, alertRuleId });
    if (entity) {
      setAlertRule({ ...entity, link });
    }
  }
}

function* uploadSingleFile({ file, customerId, ticketId }) {
  const { error } = yield call(createTicketAttachment, {
    customerId, ticketId, file,
  });

  if (error) {
    yield put(commentError(
      [{
        propterty: 'file',
        message: 'error',
        message_translation_key: `property-validation-ticket-attachment`,
      }],
    ));
  } else {
    yield put(fileUploaded(file));
  }
}

function* uploadFiles({ files, customerId, ticketId }) {
  yield all(files.map(
    (file) => call(uploadSingleFile, { file, customerId, ticketId }),
  ));
}

function* commentHandler(ticket, { meta }) {
  yield put(initTaskAddComments());
  const { customer_id: customerId, id: ticketId } = ticket;

  const user = yield select(getUser);
  const {
    setIsSaving, message, file: files, setComments, clearForm, onFail, onSuccess,
  } = meta;

  const newComments = [];

  const newComment = {
    user_name: user.name,
    user_id: user.email,
    body: message,
    id: new Date().toISOString(),
    created: new Date().toISOString(),
    is_private: false,
  };

  setIsSaving(true);

  if (message) {
    const { success, error } = yield call(postTicketResponse, {
      customerId, ticketId, message, ...user,
    });

    if (success) {
      newComments.push(newComment);
    } else {
      yield put(commentError(error?.error));
    }
  }

  if (files.length) {
    yield call(uploadFiles, { files, customerId, ticketId });
  }

  const uploadedFiles = yield select(getUploadedFiles);

  uploadedFiles.forEach((file) => {
    newComments.push({
      ...newComment,
      body: `${user.email}: Attached file.`,
      attachments: [
        file,
      ],
    });
  });

  if (newComments.length) {
    setComments((prevComments) => [...prevComments, ...newComments]);
  }

  const commentErrors = yield select(getErrors);

  if (commentErrors.length) {
    onFail();
  } else {
    onSuccess();
  }

  clearForm();
  setIsSaving(false);
}

function* loadTicket({
  customerId: dynamicCustomerId, ticketId, setCommnets, setTicket, setEntity, setAlertRule,
}) {
  const userCustomerId = yield select(getCustomerId);
  const customerId = dynamicCustomerId || userCustomerId;

  const { success: ticket } = yield call(getTicketDetailsService, { customerId, ticketId });
  const { success: comments } = yield call(getTicketCommentsService, { customerId, ticketId });

  if (ticket) {
    const { entity_id: entityId, alert_rule_id: alertRuleId } = ticket;
    yield call(setTicket, ticket);
    yield fork(loadDetailData, {
      customerId, entityId, alertRuleId, setEntity, setAlertRule,
    });
  } else {
    // Could not load ticket
  }

  if (comments) {
    yield call(setCommnets, comments.reverse());
  } else {
    // Could not load comments
  }

  yield takeEvery(t.SUBMIT_COMMENT, commentHandler, ticket);
}

function* getTicketCustomer({ customerId, setTicketCustomer }) {
  const { success } = yield call(getCustomer, { customerId });

  if (success) {
    const { name, id } = success;
    setTicketCustomer(`${name} (${id})`);
  } else {
    setTicketCustomer('Could not find customer');
  }
}

export default function* rootWatcher() {
  while (true) {
    const { meta } = yield take(t.MOUNT);

    const tasks = [
      yield fork(loadTicket, meta),
      yield fork(getTicketCustomer, meta),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
