import { useEffect, useState } from 'react';

const useScrollThreshold = (scrollThreshold) => {
  const [isBeyondThreshold, setIsBeyondThresold] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollProgress = window.scrollY;
      setIsBeyondThresold(scrollProgress > scrollThreshold);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollThreshold]);

  return isBeyondThreshold;
};

export default useScrollThreshold;
