import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Block from '~ui/Block';
import { Button } from '~ui/Button';
import * as Permission from '~ui/Permission';
import { Text, Spinner, Heading } from '@chakra-ui/react';
import { mdiPlus } from '@mdi/js';
import translation from './AutoActionPage.translation';
import Row from '../Row';
import { myEnvironmentPermission } from '../../../../../../../../lib/utils/featureKeys';

const { t } = translation;

const propTypes = {
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  onRemoveModel: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
};

const AutoActionPage = ({
  onMount,
  onUnmount,
  onRemoveModel,
  entityId,
  customerId,
}) => {
  const [isLoading, setLoading] = useState(null);
  const [processModels, setProcessModels] = useState([]);
  const [alertRules, setAlertRules] = useState([]);

  useEffect(() => {
    onMount({
      setLoading,
      setProcessModels,
      setAlertRules,
      customerId,
      entityId,
    });

    return () => onUnmount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Block.Center>
        <Spinner
          color="#133A5D"
          emptyColor="#DADADA"
          speed="0.65s"
          thickness="4px"
          size="xl"
        />
      </Block.Center>
    );
  }

  return (
    <>
      <Heading as="h2">{ t('title')}</Heading>

      <Block.Stretch style={{ marginTop: '2rem' }}>
        { processModels.length > 0 && processModels.map((model) => (
          <Row
            onRemoveAction={() => onRemoveModel(model)}
            key={model.id}
            alertRules={alertRules}
            model={model}
            buttonAction={() => {}}
          />
        )) }
      </Block.Stretch>
      <Permission.Render
        withFeature={[myEnvironmentPermission.write]}
        fallback={<Text>{t('noPermissions')}</Text>}
      >
        <Button
          variant="block"
          internalLink={`/my-environment/${customerId}/${entityId}/auto-actions/new`}
          rightIcon={mdiPlus}
        >
          {t('create')}
        </Button>
      </Permission.Render>
    </>
  );
};

AutoActionPage.propTypes = propTypes;

export default AutoActionPage;
