import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, Text, HStack } from '@chakra-ui/react';
import translatons from './AlertLevel.translation';
import * as icons from './icons';

const propTypes = {
  priorityLevel: PropTypes.oneOf([0, 1, 2, 3, 4]).isRequired,
};

const defaultProps = {};

const AlertLevel = ({ priorityLevel }) => {
  const { colors } = useTheme();

  const { t } = translatons;

  const getComponentParts = (level) => {
    const switchObject = {
      0: {
        Icon: icons.AllGoodIcon,
        text: t('allGood'),
        fillColor: colors?.white,
        circleColor: colors?.green?.['700'],
        textColor: colors?.green?.['700'],

      },
      1: {
        Icon: icons.IncidentIcon,
        text: t('low'),
        fillColor: colors?.white,
        circleColor: colors?.priority?.low,
        textColor: colors?.blue?.['200'],

      },
      2: {
        Icon: icons.IncidentIcon,
        text: t('medium'),
        fillColor: colors?.white,
        circleColor: colors?.priority?.medium,
        textColor: colors?.gold?.['900'],

      },
      3: {
        Icon: icons.IncidentIcon,
        text: t('high'),
        fillColor: colors?.white,
        circleColor: colors?.priority?.high,
        textColor: colors?.priority?.high,
      },
      4: {
        Icon: icons.IncidentIcon,
        text: t('urgent'),
        fillColor: colors?.ruby?.['100'],
        circleColor: colors?.priority?.urgent,
        textColor: colors?.priority?.urgent,

      },
      default: {
        error: true,
      },
    };

    return switchObject[level] ?? switchObject.default;
  };

  const componentParts = getComponentParts(priorityLevel);
  const {
    Icon, text, fillColor, circleColor, error, textColor,
  } = componentParts ?? {};

  return (
    <>
      {!error && (
      <HStack width="100%">
        <div style={{
          height: '2rem', width: '2rem', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: circleColor,
        }}
        >

          <Icon fill={fillColor} />
        </div>
        <Text fontWeight="bold" color={textColor}>{text}</Text>

      </HStack>
      )}
    </>
  );
};

AlertLevel.propTypes = propTypes;
AlertLevel.defaultProps = defaultProps;

export default AlertLevel;
