import styled from 'styled-components';
import { Button } from '~ui/Button';
import { Heading } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';

const H2Heading = ({ children, ...rest }) => <Heading as="h2" {...rest}>{children}</Heading>;
H2Heading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};
H2Heading.defaultProps = {
  children: '',
};

export const RemoveEntity = styled.div``;

export const StepContainer = styled.div`
    width: 100%;
    position: relative;
    padding: 2rem;
    max-width: 54rem;
    display: grid;
    grid-template-rows: 2rem 1fr;
    grid-template-columns: 2rem 1fr;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    grid-template-areas:
        "step title"
        "content content";

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.sm}) {
        grid-column-gap: 2.5rem;
        grid-template-areas:
        "step title"
        ". content";
    }

    ${({ isReady }) => `opacity: ${isReady ? '1' : '0.4'};`}
`;

export const Step = styled.div`
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background-color: ${({ colors }) => colors?.blue?.['900']};
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1rem;
    grid-area: step;
`;

export const StepTitle = styled(H2Heading)`
    margin-bottom: 0;
    line-height: initial;
`;

export const Content = styled.div`
    grid-area: content;
`;
export const AllActionsTextContainer = styled.div`
    padding: 1rem;
`;

export const AllActionsButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem 0;

    span {
        margin-right: 0.5rem;
    }
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
`;

export const Divider = styled.div`
    height: 1px;
    width: 100%;
    background: ${({ colors }) => colors?.gray?.['300']};
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
`;

export const RemoveBtn = styled(Button)`
    background: transparent;
    color: ${({ colors }) => colors?.ruby?.['800']};

    &:hover {
        background: ${({ colors }) => colors?.ruby?.['50']};
        color: ${({ colors }) => colors?.ruby?.['800']};
    }
    margin-left: 1.5rem;
    padding: 0.25rem 0.75rem;
`;

export const EntitySummation = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.75rem;
    p {
        margin: 0;
    }

`;

export const Card = styled.div`
    background: ${({ selected, colors }) => (selected ? colors?.green?.['50'] : 'transparent')};
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 3.5rem;

    svg {
        margin: 0 1.25rem;
    }
`;

export const AddBtn = styled.button`
    background: ${({ colors }) => colors?.green?.['400']};
    color: #fff;
    padding: .2rem 1rem;
    border-radius: 3px;
    &:hover {
        opacity: 0.9;
    }
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.875rem;
    margin: 0.5rem;
`;

export const RemBtn = styled(AddBtn)`
    color: ${({ colors }) => colors?.ruby?.['800']};
    background: transparent;
`;
