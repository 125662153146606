import {
  call,
  cancel,
  delay,
  takeEvery,
  fork,
} from 'redux-saga/effects';
import moment from 'moment';
import * as t from './actionTypes';
import ticketsSearch from './services/ticketsSearch.service';

const customerSagasList = {};

function* getIncidentData({ onSuccess, onFail, customerId }) {
  const incidentsPayload = {
    facets: ['priority'],
    filter: `((type eq 'incident')) and 
      ((status ne 'closed')) and 
      (customer_id eq '${customerId}') and 
      (alert_rule_name ne '') and 
      (alert_rule_name ne null)
    `,
    order_by: ['modified desc'],
    page: 1,
    page_size: 0,
    search_fields: [],
    search_string: '',
  };

  const graphPayload = {
    facets: ['created, interval:day'],
    filter: `((type eq 'incident')) and 
      (customer_id eq '${customerId}') and 
      (created gt ${moment().subtract(30, 'days').toISOString()})`,
    order_by: ['modified desc'],
    page: 1,
    page_size: 0,
    search_fields: [],
    search_string: '',
  };

  while (true) {
    const { success: incidentsCount } = yield call(ticketsSearch, incidentsPayload);
    const { success: graph } = yield call(ticketsSearch, graphPayload);

    if (incidentsCount) {
      yield call(onSuccess, incidentsCount.facets.priority, graph.facets.created);
    } else {
      yield call(onFail, 'Could not load tickets');
    }

    const delayTime = (60 + Math.floor(Math.random() * 10)) * 1000;
    yield delay(delayTime);
  }
}

function* startCustomerSaga({ meta }) {
  const customerInstance = yield fork(getIncidentData, meta);
  Object.assign(customerSagasList, {
    [meta.customerId]: customerInstance,
  });
}

function* cancelCustomerSaga({ meta }) {
  const { customerId } = meta;
  if (customerSagasList?.[customerId]) {
    yield cancel(customerSagasList[customerId]);
  }
}

export default function* rootWatcher() {
  yield takeEvery(t.MOUNT, startCustomerSaga);
  yield takeEvery(t.UNMOUNT, cancelCustomerSaga);
}
