import styled from 'styled-components';

export const Pre = styled.pre`
  white-space: pre-wrap;
  border: 1px solid ${({ colors }) => colors?.gray?.['300']};
  background: ${({ colors }) => colors?.gray?.['100']};
  color: ${({ colors }) => colors?.gray?.['800']};
  padding: 1rem;
  border-radius: 5px;
  width: 100%;
  position: relative;
  max-width: calc(100vw - 4rem);
  overflow-x: auto;
  background: #eee;
  
  code {
    width: 100%;
    max-width: 100%;
  }
`;

export const Layout = styled.div`
    display: grid;
    width: 100%;
    min-width: 0;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 2.5rem;
    

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.md}) {
      grid-template-columns: 1fr 25rem;
      grid-column-gap: 2.5rem;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;
