import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'IncidentsCustomerOverviewList',
  sv: {
    search: 'sök',
    title: 'Pågående incidenter',
  },
  en: {
    search: 'search',
    title: 'Ongoing incidents',
  },
});
