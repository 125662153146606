/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, Link } from 'react-router-dom';
import * as Layout from '~ui/Layout';
import moment from 'moment';
import {
  Flex,
  Wrap,
  Text,
  Box,
  Tooltip,
  Tag,
  TagLabel,
  useTheme,
} from '@chakra-ui/react';
import {
  TableSort,
  TableSearch,
  TableSearchProvider,
  TablePagination,
  TableHeader,
  TableFilterTriState,
  TableDrawer,
  TableRows,
  TableBody,
  TableBodyHeader,
  TableResultCount,
  TableFilterTags,
} from '~ui/Table';
import * as Keys from '../../../../../lib/utils/historyStateKeys';
import translation from './TestRuns.translation';
import * as C from './TestRuns.styled';
import { searchWebTestRuns } from '../../../../../lib/services/appMonitor';
import { LIST_NAME } from '../constants';
import { listFilterType } from '../../../../../utils/propTypes';

const TestRuns = (
  { onUpdateListFilter, listFilter: globalListFilter },
) => {
  const { colors } = useTheme();

  const { t } = translation;
  const { params } = useRouteMatch();
  const { customerId, entityId, testName } = params;

  const filterPills = {
    beforeDate: (_, value) => `${t('beforeDate')}: ${moment(value).format('YYYY-MM-DD HH:mm:ss')}`,
    afterDate: (_, value) => `${t('afterDate')}: ${moment(value).format('YYYY-MM-DD HH:mm:ss')}`,
  };

  const columns = [
    { key: '', size: 'minmax(4.0625rem, 4.0625rem)' },
    { label: t('timestamp'), size: 'minmax(12.5rem, 1fr)' },
    { label: t('elapsedTime'), size: 'minmax(12.5rem, 18.75rem)' },
    { label: t('ranOn'), size: 'minmax(10.625rem, 13.75rem)' },
    { label: t('testSteps'), size: 'minmax(11.25rem, 12.375rem)' },
  ];

  const sortList = [
    {
      value: 'timestamp', label: t('timestamp'), desc: true, default: true,
    },
  ];

  const callback = useCallback((state, azureSearchParser) => {
    const parsers = {
      filter: {
        // custom filters for when passed from Overview-page
        afterDate: (val) => `timestamp ge ${val}`,
        beforeDate: (val) => `timestamp le ${val}`,
      },
    };
    const payload = {
      payload: {},
      entityId,
      customerId,
      testName,
    };

    if (state.isFilterTrigger) {
      Object.assign(payload, {
        payload: azureSearchParser.facets(state, state.filterKey, parsers),
      });
    } else {
      onUpdateListFilter({
        [LIST_NAME]: { ...state },
      });

      Object.assign(payload, {
        payload: azureSearchParser.items(state, parsers),
      });
    }
    return payload;
  }, [customerId, entityId, onUpdateListFilter, testName]);

  return (
    <TableSearchProvider
      pageSize={15}
      payload={callback}
      fetcher={searchWebTestRuns}
      urlStateKey={Keys.APP_MONITOR_TEST_RUNS}
      sort={sortList}
      initialValues={globalListFilter}
    >

      <Box flex="1">
        <TableHeader>
          <TableSearch />
          <TableSort />

          <TableDrawer title={t('filterDrawerTitle')}>
            <TableFilterTriState
              title={t('changeSucceeded')}
              label={t('succeeded')}
              filterKey="succeeded"
            />

          </TableDrawer>
        </TableHeader>

        <Wrap>
          <TableFilterTriState
            label={t('succeeded')}
            filterKey="succeeded"
          />
        </Wrap>
        <TableFilterTags
          configurations={filterPills}
          colors={[]}
        />

      </Box>

      <Layout.Scene>
        <TableResultCount fileName="appmonitor-testruns.csv" />
        <TableBody columns={columns}>
          <TableBodyHeader />
          <TableRows>
            {(data, idx, RowComponent) => (

              <Link key={data.id} to={`/app-monitor/${customerId}/${entityId}/${testName}/details/${data.web_test_run_id}`} style={{ textDecoration: 'none' }}>

                <RowComponent>
                  <Flex p={2} alignItems="center" justifyContent="center">
                    <Tooltip
                      hasArrow
                      placement="auto"
                      label={data.succeeded ? t('testSucceeded') : t('testFailed')}
                    >
                      <C.TestStatus colors={colors} succeeded={data.succeeded} />
                    </Tooltip>
                  </Flex>

                  <Flex overflow="hidden" p={2} alignItems="center">
                    <Tooltip hasArrow label={data.timestamp} placement="auto">
                      <Text isTruncated>{moment(data.timestamp).format('YYYY-MM-DD HH:mm') }</Text>
                    </Tooltip>
                  </Flex>

                  <Flex overflow="hidden" p={2} alignItems="center">
                    <Tooltip hasArrow label={`${data.total_elapsed_ms} ms`} placement="auto">
                      <Text isTruncated>
                        {data.total_elapsed_ms > 1000
                          ? moment.duration(data.total_elapsed_ms / 1000, 'seconds').humanize()
                          : `${data.total_elapsed_ms} ms`}
                      </Text>
                    </Tooltip>
                  </Flex>

                  <Flex overflow="hidden" p={2} alignItems="center">
                    <Tooltip hasArrow label={data.entity_name} placement="auto">
                      <Text isTruncated>{ data.entity_name || '-' }</Text>
                    </Tooltip>
                  </Flex>

                  <Box overflow="hidden" p={2}>
                    <Wrap spacing={2}>
                      { data.test_steps.map((label, index) => (
                        <Tag key={label + index} bg="#f5edd8">
                          <Tooltip hasArrow label={label} placement="top">
                            <TagLabel isTruncated>
                              {label}
                            </TagLabel>
                          </Tooltip>
                        </Tag>
                      ))}
                    </Wrap>
                  </Box>

                </RowComponent>
              </Link>
            )}
          </TableRows>
        </TableBody>
        <TablePagination />
      </Layout.Scene>
    </TableSearchProvider>
  );
};

const propTypes = {
  onUpdateListFilter: PropTypes.func.isRequired,
  listFilter: listFilterType,
};

TestRuns.propTypes = propTypes;

TestRuns.defaultProps = {
  listFilter: null,
};

export default TestRuns;
