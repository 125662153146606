import Api from '../../../../../../../lib/api';

const updateTicket = async ({ customerId, ticketId, payload }) => {
  const path = `customer/${customerId}/ticket/${ticketId}/status`;

  return Api.put(path, payload)
    .then((success) => ({ success }))
    .catch((error) => {
      if (error?.response?.data) {
        return { error: error.response.data };
      }

      return { error };
    });
};

export default updateTicket;
