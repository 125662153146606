import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from '~ui/Button';
import * as Block from '~ui/Block';
import * as Form from '~ui/Form';
import { Heading, Text } from '@chakra-ui/react';
import { genreateSnoozeUntillPayload } from '../helpers';
import * as C from '../OnCall.styled';

const propTypes = {
  currentOnCall: PropTypes.instanceOf(Object).isRequired,
  onUpdateOnCall: PropTypes.func.isRequired,
};

const OnCall = ({ onUpdateOnCall, currentOnCall }) => {
  const snoozeForm = Form.useFormBuilder({
    snooze: {
      type: 'select',
      label: 'Snooze ',
      options: [
        { value: 60, label: '1 Hour' }, // Value is in minutes
        { value: 180, label: '3 Hours' }, // Value is in minutes
        { value: 1440, label: '24 Hours' }, // Value is in minutes
        { value: 'custom', label: 'Custom' },
        { value: null, label: 'Disable' },
      ],
    },
    snoozeCustom: {
      type: 'datepicker',
      label: 'Snooze until',
      minDate: moment().subtract(2, 'days').startOf('day').toISOString(),
      render: ({ snooze }) => snooze === 'custom',
    },
  });

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleOnSubmit = ({ values, dirty }) => {
    if (dirty) {
      setErrorMessage('');
      setIsSaving(true);
      onUpdateOnCall({
        payload: genreateSnoozeUntillPayload(values, currentOnCall),
        onSuccess: () => {
          setIsSaving(false);
        },
        onFailSave: (error) => {
          setIsSaving(false);
          setErrorMessage(error.message);
        },
      });
    } else {
      onUpdateOnCall();
    }
  };

  return (
    <Block.Bordered noShadow withPadding>
      <Heading as="h2">Did you come in early?</Heading>
      <Text>
        Let your colleagues sleep and snooze the On Call phone.
        When you hit the snooze button the On Call phone will
        no longer be active and calls will be redirected to you instead.
      </Text>
      <C.Status status={currentOnCall.trigger_calls_status_enabled_now}>
        Current status
      </C.Status>
      <p>
        {currentOnCall.trigger_calls_status_message}
      </p>
      <Form.Primary
        form={snoozeForm}
        onSubmit={handleOnSubmit}
      >
        {({ render, onSubmitAction }) => (
          <>
            {render}
            <Block.SpaceBetween>
              <Button isLoading={isSaving} onClick={onSubmitAction}>
                Update
              </Button>
            </Block.SpaceBetween>
            { errorMessage && (
              <Block.Error withMargins>
                {errorMessage}
              </Block.Error>
            )}
          </>
        )}
      </Form.Primary>
    </Block.Bordered>
  );
};

OnCall.propTypes = propTypes;

export default OnCall;
