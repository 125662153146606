import { useSaveCustomerMutation, getCustomer } from '../../../../lib/services/customer';

export const useUpdateCustomerPermissions = (customerId) => {
  const { mutateAsync: mutateCustomer } = useSaveCustomerMutation();

  const updateCustomer = async (update) => {
    const userConfig = await getCustomer(customerId);

    const features = userConfig.role_feature_mappings.map((roleMap) => {
      if (roleMap.role === update.role) {
        if (update.checked) {
          return { ...roleMap, features: [...roleMap.features, update.feature] };
        }

        const removedFeature = roleMap.features.filter((feature) => update.feature !== feature);
        return { ...roleMap, features: removedFeature };
      }

      return roleMap;
    });

    const newCustomer = {
      ...userConfig,
      role_feature_mappings: features,
    };

    await mutateCustomer(newCustomer);
  };

  return updateCustomer;
};
