/* eslint-disable camelcase */
import React, {
  memo,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import * as Cards from '~ui/Cards';
import * as Permission from '~ui/Permission';
import {
  TableSort,
  TableSearch,
  TableRows,
  TableHeader,
  TableFilterSelect,
  TableBody,
  TableBodyHeader,
  TableResultCount,
  TablePagination,
  TableFilterTriState,
  TableFilterTags,
} from '~ui/Table';
import {
  Wrap, Box, Tooltip, Text, useTheme,
} from '@chakra-ui/react';
import { mdiPlus, mdiMinus } from '@mdi/js';
import MdiIcon from '@mdi/react';

import translation from './EntitiesTable.translation';
import * as C from '../Steps.styled';

const { t } = translation;

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  isCreateMode: PropTypes.bool,
  customerPickerId: PropTypes.string,
  isReady: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  entities: PropTypes.array.isRequired,
  setEntities: PropTypes.func.isRequired,
  itemCount: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  downloadSource: PropTypes.object.isRequired,
  step: PropTypes.string.isRequired,
};

const defaultProps = {
  customerPickerId: '',
  children: null,
  isCreateMode: false,
  itemCount: 0,
};

const tagColors = {
  container_name: 'blue',
  resource_group: 'gold',
  type: 'ruby',
};

const EntitiesTable = ({
  children,
  isCreateMode,
  customerPickerId,
  isReady,
  entities,
  setEntities,
  itemCount,
  downloadSource,
  step,
}) => {
  const { colors, breakpoints } = useTheme();

  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();

  const filterPills = {
    container_name: (_, value) => `${t('container')}: ${value}`,
    resource_group: (_, value) => `${t('resourceGroup')}: ${value}`,
    type: (_, value) => `${t('entityType')}: ${value}`,
    customer_display_name: { render: false },
    is_mapped: { render: false },
  };

  // add every entity that fulfills the current table filter/query
  const addTableEntities = useCallback(async () => {
    const result = await downloadSource.fetch(itemCount);

    const noDuplicates = result.reduce((acc, cur) => {
      const alreadyExists = acc.some((ent) => ent.id === cur.id);

      if (alreadyExists) {
        return [...acc];
      }

      return [...acc, cur];
    }, [...entities]);

    setEntities(noDuplicates);
  }, [downloadSource, entities, itemCount, setEntities]);

  // filter out entities that fulfill the current table filter/query
  const removeTableEntities = useCallback(async () => {
    const result = await downloadSource.fetch(itemCount);
    const ids = result.map((ent) => ent.id);
    const filteredEntities = entities.filter((ent) => !ids.includes(ent.id));
    setEntities(filteredEntities);
  }, [downloadSource, entities, itemCount, setEntities]);

  const toggleEntity = useCallback((entity) => {
    const alreadyInArray = entities.some((ent) => ent.id === entity.id);

    if (alreadyInArray) {
      const filteredEntities = entities.filter((ent) => ent.id !== entity.id);
      setEntities(filteredEntities);
    } else {
      setEntities([...entities, entity]);
    }
  }, [entities, setEntities]);

  const isSelected = useCallback(({ id }) => entities?.some((ent) => ent.id === id), [entities]);

  const showPickCustomerText = useMemo(() => isGlobalAdmin && isCreateMode && !customerPickerId,
    [isGlobalAdmin, isCreateMode, customerPickerId]);

  return (
    <>
      <C.StepContainer isReady={isReady} breakpoints={breakpoints}>
        {children}
        <C.Step colors={colors}>{step}</C.Step>
        <C.StepTitle>{t('header')}</C.StepTitle>
        <C.Content>

          {showPickCustomerText
            ? (<Text>{t('selectCustomerFirst')}</Text>)
            : (
              <>
                <Text>{t('text')}</Text>

                <Box flex="1">
                  <TableHeader>
                    <TableSearch />
                    <TableSort />
                  </TableHeader>

                  <Wrap>
                    <TableFilterSelect
                      label={t('container')}
                      filterKey="container_name"
                      renderTags={false}
                    />
                    <TableFilterSelect
                      label={t('resourceGroup')}
                      filterKey="resource_group"
                      renderTags={false}
                    />
                    <TableFilterSelect
                      label={t('entityType')}
                      filterKey="type"
                      renderTags={false}
                    />
                    <TableFilterTriState
                      label={t('cloudopsActive')}
                      filterKey="is_mapped"
                      renderTags={false}
                    />
                  </Wrap>

                  <TableFilterTags
                    configurations={filterPills}
                    colors={tagColors}
                  />

                </Box>

                <TableResultCount fileName="sw_entities.csv" />
                <C.AddBtn colors={colors} onClick={addTableEntities}>{`${t('add')} (${itemCount})`}</C.AddBtn>
                <C.RemBtn colors={colors} onClick={removeTableEntities}>{`${t('remove')} (${itemCount})`}</C.RemBtn>
                <TableBody>
                  <TableBodyHeader />

                  <TableRows>
                    {(data) => (
                      <C.Card
                        colors={colors}
                        key={data.id}
                        selected={isSelected(data)}
                        onClick={() => toggleEntity(data)}
                      >
                        <MdiIcon path={isSelected(data) ? mdiMinus : mdiPlus} size={0.875} />
                        <Tooltip
                          hasArrow
                          placement="top"
                          bg="white"
                          color="black"
                          label={(
                            <Cards.Entity
                              id={data.id}
                              hasCloudops={data.is_mapped}
                              name={data.name}
                              type={data.type}
                              resourceGroup={data.resource_group}
                              region={data.region}
                              displayName={data.customer_display_name}
                              tags={data.tags}
                            />
                        )}
                        >
                          <Text>{ data.name}</Text>
                        </Tooltip>
                      </C.Card>
                    )}
                  </TableRows>
                </TableBody>
                <TablePagination delta={2} />
              </>
            )}

        </C.Content>
      </C.StepContainer>
    </>
  );
};

EntitiesTable.propTypes = propTypes;
EntitiesTable.defaultProps = defaultProps;

export default memo(EntitiesTable);
