import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Security.Template',
  sv: {
    error: 'Ett fel inträffade',
    missingConnectorsTitle: 'Saknar Azure data connectors',
    missingConnectorsDesc: 'Du saknar några av de nödvändiga datakällorna som behövs för den här regeln. Du kan fortfarande deploya det här mallen, men resultaten kan inte garanteras.',

    deploy: 'Skapa',
    deployAnyway: 'Skapa ändå',

    back: 'Tillbaka',
    deploySuccessfull: 'Azure Sentinel säkerhetsregel skapad. Det kan ta några sekunder innan den syns i listan',
    deployFailed: 'Någonting gick snett, försök igen senare',
  },
  en: {
    error: 'An Error occurred',
    missingConnectorsTitle: 'Missing Azure data connectors',
    missingConnectorsDesc: 'You are missing some of the required data sources that is needed for this rule. You can still try to deploy this template, but the results cannot be guaranteed.',

    deploy: 'Deploy',
    deployAnyway: 'Deploy anyway',

    back: 'Back',
    deploySuccessfull: 'Azure Sentinel analytic rule created. It might take a couple of seconds before it shows in the list.',
    deployFailed: 'Something went wrong, try again later',
  },
});
