import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AppMonitor.Tickets',
  sv: {
    id: 'ID',
    ticketId: 'Ärende ID',
    subject: 'Ämne',
    tags: 'Taggar',
    modified: 'Ändrad',
    type: 'Typ',
    status: 'Status',
    created: 'Skapad',
    closed: 'Stängd',
    due: 'Förfaller',
    customer: 'Kund',

    searchLabel: 'Sök ärenden',
    emptystate: 'Kunde inte hitta några relaterade ärenden',

    priority: 'Prioritet',
    priorityAvaliable: 'Tillgängliga',
    prioritySelected: 'Valda',
    priority1: 'Låg',
    priority2: 'Medium',
    priority3: 'Hög',
    priority4: 'Brådskande',

    filterDrawerTitle: 'Filtrera bland ärenden',
    backButtonText: 'Ärenden för appövervakning',
  },
  en: {
    id: 'ID',
    ticketId: 'Ticket ID',
    subject: 'Subject',
    tags: 'Tags',
    modified: 'Modified',
    type: 'Type',
    status: 'Status',
    created: 'Created',
    closed: 'Closed',
    due: 'Due',
    customer: 'Customer',

    searchLabel: 'Search for tickets',
    emptystate: 'Could not find any related tickets',

    priority: 'Priority',
    priorityAvaliable: 'Available',
    prioritySelected: 'Selected',
    priority1: 'Low',
    priority2: 'Medium',
    priority3: 'High',
    priority4: 'Urgent',

    filterDrawerTitle: 'Filter tickets',
    backButtonText: 'Tickets for App Monitor',
  },
});
