import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'TicketDetailsComment',
  sv: {
    privateComment: 'Den här kommentaren är privat',
    errorParsing: 'Fel. Kunde inte parsa kommentar',
    attachments: 'Bilagor',
  },
  en: {
    privateComment: 'This comment is private',
    errorParsing: 'Error. Could not parse comment',
    attachments: 'Attachments',
  },
});
