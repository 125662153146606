import React, { useState, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import * as Graph from '~ui/Graph';
import * as Selector from '~ui/Selector';
import * as Block from '~ui/Block';
import { Spinner, Heading, useTheme } from '@chakra-ui/react';
import translation from './OverviewPage.translation';
import OutcomeList from './OutcomeList';
import { useSearchWebTestRuns } from '../../../../../lib/services/appMonitor';
import withNavigation from '../../../hoc/Navigation';
import * as C from './OverviewPage.styled';

const { t } = translation;

const getHeaderText = (fails = 0, successes = 0) => {
  if (fails === 0) {
    return `${t('smoothSailing')}`;
  }
  const totalRuns = successes + fails;
  return `${fails} ${t('failed')} ${t('outOf')} ${totalRuns} ${t('runs')}`;
};

const getTotalCount = (arr = []) => arr.reduce((acc, cur) => acc + cur.value, 0);

const OverviewPage = () => {
  const { colors, breakpoints } = useTheme();

  const [startDate, setStartDate] = useState(
    moment()
      .startOf('year')
      .toISOString(),
  );
  const [endDate, setEndDate] = useState(
    moment()
      .endOf('year')
      .toISOString(),
  );

  const { params } = useRouteMatch();
  const { customerId, entityId, testName } = params;

  const {
    data: successData, isLoading: successLoading,
  } = useSearchWebTestRuns({
    succeeded: true,
    customerId,
    entityId,
    testName,
    startDate,
    endDate,
  });

  const {
    data: failData, isLoading: failLoading,
  } = useSearchWebTestRuns({
    succeeded: false,
    customerId,
    entityId,
    testName,
    startDate,
    endDate,
  });

  const yearSelectOptions = useMemo(() => [...Array(2)].map((_, ind) => {
    const year = moment().year() - ind;
    return ({
      label: year,
      default: moment(startDate).year() === year,
      value: {
        from: moment().set('year', year).startOf('year').toISOString(),
        to: moment().set('year', year).endOf('year').toISOString(),
      },
    });
  }), [startDate]);

  const totalFails = useMemo(() => getTotalCount(failData), [failData]);
  const totalSuccesses = useMemo(() => getTotalCount(successData), [successData]);

  const headerText = useMemo(() => {
    if (totalFails && totalSuccesses) {
      return getHeaderText(totalFails, totalSuccesses);
    }
    return null;
  }, [totalFails, totalSuccesses]);

  if (successLoading || failLoading) {
    return (
      <Block.Center>
        <Spinner
          color="#133A5D"
          emptyColor="#DADADA"
          speed="0.65s"
          thickness="4px"
          size="lg"
        />
      </Block.Center>
    );
  }

  return (
    <>
      <Heading as="h2">{headerText}</Heading>

      <C.GraphContainer colors={colors}>
        <Graph.Heatmap
          primaryData={failData}
          secondaryData={successData}
          cellGap={4}
          steps={5}
          primaryLabel={t('fails')}
          secondaryLabel={t('successes')}
        />
      </C.GraphContainer>

      <C.ListContainer breakpoints={breakpoints}>
        <C.Outcome>{t('testOutcome')}</C.Outcome>

        <C.OutcomeList>
          <OutcomeList failData={failData} successData={successData} />
        </C.OutcomeList>

        <C.YearSelector>
          <Selector.Main
            entries={yearSelectOptions}
            onSelect={({ from, to }) => {
              setStartDate(from);
              setEndDate(to);
            }}
          />
        </C.YearSelector>
      </C.ListContainer>
    </>
  );
};

export default withNavigation(OverviewPage);
