import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'ReportsPageMain',
  sv: {
    tableReportName: 'Rapportnamn',
    searchLabel: 'Sök rapporter',
    emptystate: 'Hittade inga rapporter för',
    tableCustomer: 'Kund',
    tableName: 'Rapportnamn',
    changeCustomer: 'Ändra kund',
    changeName: 'Ändra namn',
    filterDrawerTitle: 'Filtrera rapporter',
  },
  en: {
    tableReportName: 'Report name',
    searchLabel: 'Search reports',
    emptystate: 'Could not find any reports for',
    tableCustomer: 'Customer',
    tableName: 'Report name',
    changeCustomer: 'Change customer',
    changeName: 'Change name',
    filterDrawerTitle: 'Filter reports',
  },
});
