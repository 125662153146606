import {
  take,
  takeEvery,
  call,
  cancel,
} from 'redux-saga/effects';
import * as t from './actionTypes';

import getVMConfig from './services/getVMConfig.service';
import putVMConfig from './services/putVMConfig.service';

function* loadVMConfigAction({
  setVMConfig, entityId, customerId, setLoading,
}) {
  setLoading(true);
  const { success } = yield call(getVMConfig, { customerId, entityId });

  if (success) {
    setVMConfig(success);
  } else {
    setVMConfig(null);
  }
  setLoading(false);
}

function* updateAgentConfigTask({ customerId, entityId }, { meta }) {
  const { vmConfig, onSuccess, onFail } = meta;
  const { success, error } = yield call(putVMConfig, { customerId, entityId, payload: vmConfig });

  if (success) {
    yield call(onSuccess);
  } else {
    yield call(onFail, error);
  }
}

export default function* rootWatcher() {
  while (true) {
    const { meta } = yield take(t.MOUNT);

    const {
      setLoading,
      setVMConfig,
      entityId,
      customerId,
    } = meta;

    yield call(loadVMConfigAction, {
      setVMConfig, entityId, customerId, setLoading,
    });

    const tasks = [
      yield takeEvery([t.UPDATE_AGENT_CONFIG], updateAgentConfigTask, {
        setVMConfig, entityId, customerId, setLoading,
      }),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
