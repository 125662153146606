import React from 'react';
import PropTypes from 'prop-types';
import MdiIcon from '@mdi/react';
import {
  mdiAlertCircleOutline, // (!)
  mdiCheck, // v
  mdiCancel, // (/)
} from '@mdi/js';
import { useTheme } from '@chakra-ui/react';
import { SUCCESS, FAIL } from './Constants';

const Icon = ({ type }) => {
  const { colors } = useTheme();

  if (type === FAIL) {
    return <MdiIcon path={mdiAlertCircleOutline} size={0.75} style={{ color: colors?.ruby?.['800'] }} />;
  }
  if (type === SUCCESS) {
    return <MdiIcon path={mdiCheck} size={0.75} style={{ color: colors?.green?.['700'] }} />;
  }
  return <MdiIcon path={mdiCancel} size={0.75} style={{ color: colors?.gray?.['300'] }} />;
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Icon;
