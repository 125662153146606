import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 2rem;
    overflow-x: hidden;
`;

export const PieChartWrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  max-width: 920px;
  margin: 0 auto;
  @media (min-width: 1050px) {
    grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  }
`;
