import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'CloudOpsVMAgentMain',
  sv: {
    title: 'CloudOps VM Agent',
    description: 'CloudOps använder en VM-agent för övervakning i VM:ens gäst-OS. Agenten installeras automatiskt när CloudOps aktiveras, och uppdateras automatiskt vid ändringar av konfigurationen.',
    details: 'Detaljer',
    id: 'Id',
    customerId: 'Customer Id',
    clientId: 'Client id',
    entityId: 'Entity Id',
    entityType: 'Entity type',
    entityStatus: 'Entity status',
    source: 'Source',
    configModified: 'Config modified',

    deploy: 'Deploy',
    status: 'Status',
    statusMessage: 'Status message',
    timestamp: 'Timestamp',
    operationStatusURI: 'Operation Status URI',

    monitor: 'Övervakning',
    services: 'Services',
    servicesNotTo: 'Services not to',
    urls: 'URLs',
    iisWebsitesNotTo: 'IIS Websites not to',
    iisAppPoolsNotTo: 'IIS App pools not to',
    foldersLastModified: 'Folders last modified',

    queries: 'Queries',
    eventLogQueries: 'Event log queries',
    folderPath: 'Folder Path',
    fileMask: 'File mask',
    queryName: 'Query name',
    logName: 'Log name',
    level: 'Level',
    regexString: 'Regex String',
    eventId: 'Event id',
    sourceRegexString: 'Source regex string',

    edit: 'Ändra',
    editMonitoring: 'Ändra övervakning',
    editQueries: 'Ändra queries',

    multiStepWebTests: 'Flerstegswebtester',
    webTest: 'Web test',
    editWebTests: 'Ändra webtestser',
    name: 'Name',
    intervalInMinutes: 'Interval (minutes)',

    code: 'Code',

    showMore: 'Visa fler',
    collapse: 'Kollapsa',

    noAgentTitle: 'Agent saknas',
    errorNoCloudOps: 'Var god aktivera CloudOps på resursen för att deploya agenten.',
    errorHasCloudOps: 'Utför första deployen av agenten.',

    heartbeatReceived: 'Heartbeat received',
    noHeartbeat: 'Heartbeat Missing',
    agentVersion: 'Agent version',

    redeployAgent: 'Redeploy agent',
    redeployAgentTooltip: 'Redeploy the agent with current configuration',

    url: 'URL',
    bodyRegex: 'Response body ska innehålla',

    success: 'Konfiguration uppdaterad (kan ta upp till 20 minuter för ny agentversion).',
    fail: 'Kunde inte uppdatera konfigurationen, testa igen senare.',
  },
  en: {
    title: 'CloudOps VM Agent',
    description: 'CloudOps uses a VM agent for monitoring inside the Guest OS of the VM. The agent is deployed automatically when CloudOps is activated, and is updated automatically on changes of the configuration.',
    details: 'Details',
    id: 'Id',
    customerId: 'Customer Id',
    clientId: 'Client id',
    entityId: 'Entity Id',
    entityType: 'Entity type',
    entityStatus: 'Entity status',
    source: 'Source',
    configModified: 'Config modified',

    deploy: 'Deploy',
    status: 'Status',
    statusMessage: 'Status message',
    timestamp: 'Timestamp',
    operationStatusURI: 'Operation Status URI',

    monitor: 'Monitor',
    services: 'Services',
    servicesNotTo: 'Services not to',
    urls: 'URLs',
    iisWebsitesNotTo: 'IIS Websites not to',
    iisAppPoolsNotTo: 'IIS App pools not to',
    foldersLastModified: 'Folders last modified',

    queries: 'Queries',
    eventLogQueries: 'Event log queries',
    folderPath: 'Folder Path',
    fileMask: 'File mask',
    queryName: 'Query name',
    logName: 'Log name',
    level: 'Level',
    regexString: 'Regex String',
    eventId: 'Event id',
    sourceRegexString: 'Source regex string',

    edit: 'Edit',
    editMonitoring: 'Edit monitoring',
    editQueries: 'Edit queries',

    multiStepWebTests: 'Multi step web tests',
    webTest: 'Web test',
    editWebTests: 'Edit web tests',
    name: 'Name',
    intervalInMinutes: 'Interval (minutes)',

    code: 'Code',

    showMore: 'Show more',
    collapse: 'Collapse',

    noAgentTitle: 'Agent missing',
    errorNoCloudOps: 'Please activate CloudOps to deploy the agent.',
    errorHasCloudOps: 'Performing initial deploy of the agent.',

    heartbeatReceived: 'Heartbeat received',
    noHeartbeat: 'Heartbeat Missing',
    agentVersion: 'Agent version',

    redeployAgent: 'Redeploy agent',
    redeployAgentTooltip: 'Redeploy the agent with current configuration',

    url: 'URL',

    bodyRegex: 'Response body should contain',

    success: 'Configuration updated (might take up to 20 minutes for new agent version).',
    fail: 'Could not update the configuration. Try again later.',
  },
});
