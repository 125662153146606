import React, { useMemo } from 'react';
import * as Block from '~ui/Block';
import { Button } from '~ui/Button';
import * as Layout from '~ui/Layout';
import * as Permission from '~ui/Permission';
import {
  Spinner, useToast, Heading, useTheme,
} from '@chakra-ui/react';
import { mdiArrowLeft } from '@mdi/js';
import { useRouteMatch } from 'react-router';

import translation from './Template.translation';
import * as C from './Template.styled';
import Metadata from '../../components/MetaData';
import { securityPermission } from '../../../../lib/utils/featureKeys';

import {
  useAnalyticRuleTemplate,
  useDeployTemplateMutation,
  useTemplateIsAvaliableInWorkspace,
} from '../../../../lib/services/analyticRules';

import formatPlainText from '../../../../utils/formatPlainText';

const Template = () => {
  const { colors, breakpoints } = useTheme();

  const toast = useToast();
  const { t } = translation;
  const { params } = useRouteMatch();
  const {
    customerId,
    workspaceId,
    templateId,
    templateCustomerId,
  } = params;

  const {
    isError,
    isLoading,
    isSuccess,
    data: template,
  } = useAnalyticRuleTemplate(templateId, templateCustomerId);

  const isAvaliable = useTemplateIsAvaliableInWorkspace(
    customerId,
    workspaceId,
    null,
    templateId,
    templateCustomerId,
  );

  const deploy = useDeployTemplateMutation(customerId, workspaceId, {
    onSuccess: () => {
      toast({
        description: t('deploySuccessfull'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: (err) => {
      toast({
        description: err.message || t('deployFailed'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const descriptionText = useMemo(() => formatPlainText(template?.properties?.description),
    [template]);

  if (isError) {
    return (
      <Layout.Scene>
        <Block.Center style={{ padding: '2rem' }}>
          <Block.Error>
            {t('error')}
          </Block.Error>
        </Block.Center>
      </Layout.Scene>
    );
  }

  return (
    <>
      <Layout.Omnibar.Main>
        <Layout.Omnibar.LeftActions>
          <Button
            variant="ghost"
            internalLink={`/security/${customerId}/${workspaceId}/templates`}
            leftIcon={mdiArrowLeft}
          >
            {t('back')}
          </Button>
        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>
      <Layout.Scene>
        <Block.Left withPadding>
          { isLoading && (
            <Block.Center withPadding>
              <Spinner
                color="#133A5D"
                emptyColor="#DADADA"
                speed="0.65s"
                thickness="4px"
                size="xl"
              />
            </Block.Center>
          )}
          {isSuccess && (
            <C.Layout breakpoints={breakpoints}>
              <Block.Left>
                <Heading as="h2">{template.properties.displayName}</Heading>
                {descriptionText}
                <Heading as="h3">Query</Heading>
                <C.Pre colors={colors}>
                  <code>
                    {template.properties.query}
                  </code>
                </C.Pre>

                {!isAvaliable && (
                  <Block.Warning title={t('missingConnectorsTitle')} style={{ marginTop: '2.5rem' }}>
                    {t('missingConnectorsDesc')}
                  </Block.Warning>
                )}

                <Permission.Render withFeature={[securityPermission.write]}>
                  <Block.Right style={{ marginTop: '1rem' }}>
                    <Button
                      mt="1rem"
                      isLoading={deploy.isLoading}
                      onClick={() => deploy.mutate({ template })}
                    >
                      {isAvaliable ? t('deploy') : t('deployAnyway')}
                    </Button>
                  </Block.Right>
                </Permission.Render>

              </Block.Left>
              <Block.Left>
                <Metadata data={template} />
              </Block.Left>
            </C.Layout>
          )}
        </Block.Left>
      </Layout.Scene>
    </>
  );
};

export default Template;
