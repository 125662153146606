import Api from '../../../../../../../lib/api';

const getCloudopsMappings = async ({ customerId, entityId }) => {
  const path = `customer/${customerId}/entity/${entityId}/cloudopsservice/mapping`;
  const params = {
    onlyActive: true,
  };

  return Api.get(path, null, params)
    .then((success) => ({ success }))
    .catch((error) => {
      if (error?.response?.data) {
        return { error: error.response.data };
      }
      return { error };
    });
};

export default getCloudopsMappings;
