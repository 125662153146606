import * as pbi from 'powerbi-client';

const getEmbedUrl = ({ reportID, groupId }) => `https://app.powerbi.com/reportEmbed?reportId=${reportID}&groupId=${groupId}`;

export const generatePowerBIConfig = ({
  embedToken, reportId, groupId,
}) => {
  const { models } = pbi;
  const config = {
    type: 'report',
    tokenType: models.TokenType.Embed,
    accessToken: embedToken,
    embedUrl: getEmbedUrl({ reportId, groupId }),
    id: reportId,
    permissions: models.Permissions.All,
    settings: {
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption.FitToWidth,
      },
    },
  };

  return config;
};

export const generatePowerBIService = () => new pbi.service.Service(
  pbi.factories.hpmFactory,
  pbi.factories.wpmpFactory,
  pbi.factories.routerFactory,
);
