import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'IncidentsMetricCard',
  sv: {
    viewTickets: 'Visa ärenden',
    tooltip: 'Incidenttrend för de senaste 30 dagarna',
  },
  en: {
    viewTickets: 'View tickets',
    tooltip: 'Trend for incidents over the past 30 days',
  },
});
