import {
  take,
  call,
  cancel,
  select,
  fork,
} from 'redux-saga/effects';
import * as t from './actionTypes';
import getTeamService from './services/getTeam.service';

const getCustomerId = ({ model }) => model.customer.id;

function* loadTeam({ setMyTeam, setLoading }) {
  setLoading(true);
  const customerId = yield select(getCustomerId);
  const { success } = yield call(getTeamService, { customerId });

  if (success) {
    const sortedTeam = success.sort((a, b) => a.name.localeCompare(b.name));
    setMyTeam(sortedTeam);
  }
  setLoading(false);
}

export default function* rootWatcher() {
  while (true) {
    const { meta } = yield take(t.MOUNT);
    const { setMyTeam, setLoading } = meta;

    const tasks = [
      yield fork(loadTeam, { setMyTeam, setLoading }),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
