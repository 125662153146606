/* eslint-disable camelcase */
import React, {
  memo, useContext, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import * as Cron from '~ui/Cron';
import * as Form from '~ui/Form';
import moment from 'moment';
import { Text, useTheme } from '@chakra-ui/react';
import * as C from '../Steps.styled';
import translation from './CronEditor.translation';
import { ServiceWindowMutationContext } from '../../../../serviceWindowMutationContext';

const { t } = translation;

const propTypes = {
  initialData: PropTypes.instanceOf(Object),
  setValidSchedule: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  isReady: PropTypes.bool.isRequired,
  step: PropTypes.string.isRequired,
};

const defaultProps = {
  initialData: null,
  setValidSchedule: () => null,
  children: null,
};

const CronEditor = ({
  setValidSchedule, children, initialData, isReady, step,
}) => {
  const { colors, breakpoints } = useTheme();

  const ctx = useContext(ServiceWindowMutationContext);

  const scheduleForm = Form.useFormBuilder({
    reason: {
      type: 'string',
      label: t('reason'),
      placeholder: t('reasonPlaceholder'),
      value: initialData?.reason,
    },
  });

  const handleFormChange = useCallback(({ values }) => {
    const { reason, cron_expression_description } = values;
    ctx.setServiceWindow({
      ...ctx.serviceWindow,
      reason,
      schedule: {
        ...ctx.serviceWindow?.schedule,
        cron_expression_description,
      },
    });
  }, [ctx]);

  const handleCronChange = useCallback(({ payload, valid }) => {
    ctx.setServiceWindow({
      ...ctx.serviceWindow,
      schedule: {
        ...ctx.serviceWindow?.schedule,
        ...payload,
      },
    });
    setValidSchedule(valid);
  }, [ctx, setValidSchedule]);

  return (
    <C.StepContainer
      isReady={isReady}
      breakpoints={breakpoints}
    >

      {children}
      <C.Step colors={colors}>{step}</C.Step>
      <C.StepTitle>
        {t('header')}
      </C.StepTitle>
      <C.Content>
        <Text>{t('text')}</Text>

        <Form.Primary
          form={scheduleForm}
          onChange={handleFormChange}
        >
          {({ render }) => render}
        </Form.Primary>

        <Cron.Editor
          start={initialData?.schedule?.start || moment()}
          end={initialData?.schedule?.end || moment().add(1, 'days')}
          duration={initialData?.schedule?.duration_in_seconds || 1800}
          expression={initialData?.schedule?.cron_expression || ''}
          onChange={handleCronChange}
        />
      </C.Content>
    </C.StepContainer>
  );
};

CronEditor.propTypes = propTypes;
CronEditor.defaultProps = defaultProps;

export default memo(CronEditor);
