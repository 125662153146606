import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { mdiArrowLeft } from '@mdi/js';
import { useRouteMatch, useHistory } from 'react-router-dom';
import * as Form from '~ui/Form';
import { Button } from '~ui/Button';
import * as Layout from '~ui/Layout';
import * as Block from '~ui/Block';
import {
  Spinner, useToast, Heading,
} from '@chakra-ui/react';
import translation from './AlertRuleForm.translation';

const defaultProps = {};
const propTypes = {
  titleText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  formHook: PropTypes.instanceOf(Object).isRequired,
  onSubmitForm: PropTypes.func.isRequired,
};

const AlertRuleForm = ({
  titleText,
  buttonText,
  resourceName,
  loading,
  formHook,
  onSubmitForm,
}) => {
  const { t, exists: translationExists } = translation;
  const [saving, setSaving] = useState(false);
  const { params: { customerId, entityId } } = useRouteMatch();
  const backLink = `/my-environment/${customerId}/${entityId}/alert-rules`;
  const history = useHistory();
  const toast = useToast();

  const handleSubmit = () => {
    setSaving(true);
    const { values } = formHook.getValues();

    onSubmitForm({
      values,
      onSuccess: () => {
        toast({
          description: t('success'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        history.push(backLink);
      },
      onError: (formErrors) => {
        toast({
          title: t('fail'),
          description: translationExists(formErrors[0]?.message_translation_key)
            ? t(formErrors[0]?.message_translation_key) : formErrors[0]?.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setSaving(false);
      },
    });
  };

  return (
    <>
      <Layout.Omnibar.Main>

        <Layout.Omnibar.LeftActions>

          <Button
            variant="ghost"
            leftIcon={mdiArrowLeft}
            internalLink={backLink}
          >
            {t('back')}
          </Button>

        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>
      <Layout.Scene>
        { loading && (
          <Block.Center withPadding>
            <Spinner
              color="#133A5D"
              emptyColor="#DADADA"
              speed="0.65s"
              thickness="4px"
              size="xl"
            />
          </Block.Center>
        )}
        {!loading && (
          <Block.Left style={{ padding: '2rem' }}>

            {resourceName && (
            <Heading mb="2rem">
              {resourceName}
            </Heading>
            )}

            <h1>{titleText}</h1>
            <Form.Primary form={formHook}>
              {({ render }) => (
                <>
                  {render}
                  <Block.Right style={{ maxWidth: '700px' }}>
                    <Button onClick={handleSubmit} isLoading={saving}>
                      {buttonText}
                    </Button>
                  </Block.Right>
                </>
              )}
            </Form.Primary>
          </Block.Left>
        )}
      </Layout.Scene>
    </>
  );
};

AlertRuleForm.defaultProps = defaultProps;
AlertRuleForm.propTypes = propTypes;

export default AlertRuleForm;
