import {
  useState,
} from 'react';

export function useLocalSort(defaultSortKey) {
  const [sortKey, setSortKey] = useState(
    defaultSortKey,
  );

  const [orderDesc, setOrderDesc] = useState(true);

  const onSort = (key) => {
    if (sortKey === key) {
      setOrderDesc((prevDirection) => !prevDirection);
      return;
    }

    setSortKey(key);
    setOrderDesc(true);
  };

  return {
    sortKey,
    orderDesc,
    onSort,
  };
}

export default useLocalSort;
