import {
  mdiPlay,
  mdiPhoneInTalk,
  mdiEmail,
  mdiAndroidMessages,
  mdiBellRing,
  mdiTimerSandEmpty,
  mdiMessageProcessing,
} from '@mdi/js';

const TagTicketAutoActionId = '1b5a7671-3d90-437d-a7b0-5d3790669268';
const CheckIfMetricIsStillAlertingId = '7a116520-ae05-4919-afc7-13cf8a2669d5';
const DelayId = 'bc20c8bb-2d0f-454a-928c-842d9347e18f';
const EmailId = '4ACE20BC-2DC4-437E-99F3-D1B3C2F91955';
const StartWindowsServiceId = '64d9af93-8d03-48e3-8ff6-1136f90f6a42';
const UpdateTicketAssigneeGroupId = '2EED542B-D062-4ADA-819F-AC54501A57F6';
const UpdateTicketPriorityAutoActionId = 'a42953f1-e203-4188-a426-0842b549548c';
const UpdateTicketStatusId = '976c9ae5-0575-4e72-8471-498df585f7b9';
const VoiceCallId = '5981950E-596D-44A7-9CFB-F95A50C096F5';
const SmsId = '0322C832-27B3-4CF0-9ABE-180DCCDE897B';

export const iconMapper = (id) => {
  switch (id) {
    case TagTicketAutoActionId:
      return mdiAndroidMessages;
    case CheckIfMetricIsStillAlertingId:
      return mdiBellRing;
    case DelayId:
      return mdiTimerSandEmpty;
    case EmailId:
      return mdiEmail;
    case StartWindowsServiceId:
      return mdiPlay;
    case UpdateTicketAssigneeGroupId:
      return mdiAndroidMessages;
    case UpdateTicketPriorityAutoActionId:
      return mdiAndroidMessages;
    case UpdateTicketStatusId:
      return mdiAndroidMessages;
    case VoiceCallId:
      return mdiPhoneInTalk;
    case SmsId:
      return mdiMessageProcessing;
    default:
      return mdiPlay;
  }
};
