import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AlertRuleForm',
  sv: {
    back: 'Alla Larmregler',
    severity: 'Allvarlighetsgrad',
    aggregation: 'Aggregering',
    thresholdComparison: 'Tröskeljämförelse',
    threshold: 'Tröskelvärde',
    useCriticalThreshold: 'Använd kritiskt tröskelvärde',
    criticalThreshold: 'Kritiskt tröskelvärde',
    yes: 'Ja',
    no: 'Nej',
    name: 'Namn',
    description: 'Beskrivning',
    alertsBeforeAction: 'Antal överträdelser',
    metricName: 'Metricnamn',
    dependsOnMetrics: 'Beror på metric (valfritt)',
    validFrom: 'Giltig från',
    validFromDT: 'Startdatum',
    validTo: 'Giltig till',
    validToDT: 'Slutdatum',
    low: 'Låg',
    medium: 'Mellan',
    high: 'Hög',
    urgent: 'Brådskande',
    average: 'Medelvärde',
    sum: 'Summa',
    lessThan: 'Lägre än',
    moreThan: 'Högre än',
    moreEqual: 'Högre eller lika med',
    lessEqual: 'Lägre eller lika med',
    equal: 'Lika med',
    to: 'Till',
    now: 'Nu',
    specificStart: 'Specifikt datum',
    specificTurnoff: 'Specifikt datum',
    untilTurnoff: 'Tills jag stänger av',
    enabled: 'Aktiverad',
    updateAndActivate: 'Uppdatera och aktivera',
    autoCloseDelayInSecondsBool: 'Stäng ärende automatiskt',
    autoCloseDelayInSeconds: 'Efter tid inom tröskelvärde (i minuter)',
    'property-validation-alert-rule-severity-missing': 'Ange allvarlighetsgrad',
    'property-validation-alert-rule-aggregation-missing': 'Ange aggregering',
    'property-validation-alert-rule-threshold_comparison-missing': 'Ange tröskelvärdesjämförelse',
    'property-validation-alert-rule-threshold-missing': 'Ange tröskelvärde',
    'property-validation-alert-rule-name-missing': 'Ange name',
    'property-validation-alert-rule-description-missing': 'Ange beskrivning',
    'property-validation-alert-rule-alerts_before_action-missing': 'Ange larm innan åtgård',
    'property-validation-alert-rule-metric_name-missing': 'Ange metric-namn',
    'property-validation-alert-rule-valid_from_dt-missing': 'Ange start-datum',
    'property-validation-alert-rule-valid_to_dt-missing': 'Ange slut-datum',
    'property-validation-alert-rule-name-not-found': 'Metric kunde inte hittas med valt metricnamn. Var god välj en annan metric från listan.',
    tooltipMetricName: 'Välj den metric som ska övervakas med denna larmregel.',
    tooltipDependsOnMetrics: 'Larma bara om dessa metrics också överskrider tröskelvärdet.',
    tooltipSeverity: `Allvarlighetsgrad avgör hur larmet ska hanteras.

    Information: Data om larmet sparas men inget ärende skapas.

    Normal: Ärende skapas och hanteras under kontorstid.

    Hög: Ärende skapas och hanteras alla dagar dygnet runt.`,
    tooltipAggregation: 'Metrics aggregeras över 5 minuters perioder innan de jämförs med larmregelns tröskelvärde. Ska summan eller medelvärdet av metric-värdet över 5 minutersperioden användas vid jämförelse med tröskelvärdet?',
    tooltipThresholdComparison: 'Ska larmet triggas när metric-värdet är större än, större eller lika med, lika med, mindre än eller mindre eller lika med tröskelvärdet?',
    tooltipThreshold: 'Detta värde i kombination med vald jämförelse avgör när ett larm ska triggas.',
    tooltipAlertsBeforeAction: 'Ibland vill man att metric-värdet ska överträda tröskelvärdet flera gånger i rad innan ett ärende skapas. Detta kan användas för att exempelvis hantera tillfälliga spikar i belastning eller liknande. Detta värde anger hur många 5-minutersperioder tröskelvärdet ska överstigas innan ett ärende ska skapas.',
    tooltipName: 'Ange namn på larmregeln. Detta namn kommer att finnas med på alla ärenden som genereras från denna regel.',
    tooltipDescription: 'Ange en beskrivning för denna regel. Beskrivningen kommer att finnas med på alla ärenden som genereras från denna regel och kan användas för att informera om vad regeln innebär eller liknande.',
    placeholderMetricName: 'Sök efter metrics',
    placeholderDependsOnMetrics: 'Sök efter metrics',

    success: 'Larmregel skapad/uppdaterad',
    fail: 'Misslyckades att skapa/uppdatera Larmregel',
  },
  en: {
    back: 'All Alert rules',
    severity: 'Severity',
    aggregation: 'Aggregation',
    thresholdComparison: 'Threshold comparison',
    threshold: 'Threshold',
    useCriticalThreshold: 'Use critical threshold',
    criticalThreshold: 'Critical threshold',
    yes: 'Yes',
    no: 'No',
    name: 'Name',
    description: 'Description',
    alertsBeforeAction: 'Number of violations',
    metricName: 'Metric name',
    dependsOnMetrics: 'Depends on metric (optional)',
    validFrom: 'Valid from ',
    validFromDT: 'Start date',
    validTo: 'Valid to',
    validToDT: 'End date',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    urgent: 'Urgent',
    average: 'Average',
    sum: 'Sum',
    lessThan: 'Less than',
    moreThan: 'More than',
    moreEqual: 'More or equal to',
    lessEqual: 'Less or equal to',
    equal: 'Equal to',
    to: 'To',
    now: 'now',
    specificStart: 'Specific date',
    specificTurnoff: 'Specific date',
    untilTurnoff: 'Until I turn it off',
    updateAndActivate: 'Uppdate and activate',
    autoCloseDelayInSecondsBool: 'Close ticket automatically',
    autoCloseDelayInSeconds: 'Time within threshold (in minutes)',
    'property-validation-alert-rule-severity-missing': 'Severity missing',
    'property-validation-alert-rule-aggregation-missing': 'Aggregation missing',
    'property-validation-alert-rule-threshold_comparison-missing': 'Threshold comparison missing',
    'property-validation-alert-rule-threshold-missing': 'Threshold missing',
    'property-validation-alert-rule-name-missing': 'Name missing',
    'property-validation-alert-rule-description-missing': 'Description missing',
    'property-validation-alert-rule-alerts_before_action-missing': 'Alerts before action missing',
    'property-validation-alert-rule-metric_name-missing': 'Metric name missing',
    'property-validation-alert-rule-depends_on_metrics-missing': 'Depends on metrics missing',
    'property-validation-alert-rule-valid_from_dt-missing': 'From date missing',
    'property-validation-alert-rule-valid_to_dt-missing': 'To date missing',
    'property-validation-alert-rule-name-not-found': 'Metric could not be found with the given metric name. Please choose another metric from the list.',
    tooltipMetricName: 'Choose the metric to be monitored with this alert rule.',
    tooltipDependsOnMetrics: 'Only alert if these metrics are also violating the threshold.',
    tooltipSeverity: `Severity decides how an alert is handled.

    Informational: Alert data is collected but no ticket is created.

    Normal: Ticket is created and processed during business hours.

    High: Ticket is created and processed 24/7.`,
    tooltipAggregation: 'Metrics are aggregated over 5 minute periods before being evaluated against the alert rule threshold. Should the metrics be aggregated by the sum or by the mean value over each 5 minute period?',
    tooltipThresholdComparison: 'Should the alert rule be triggered when the metric value is larger than, larger than or equals to, equals to, less than or equals to, less than the threshold value?',
    tooltipThreshold: 'This value combined with the selected threshold comparison determines when the alert rule is triggered.',
    tooltipAlertsBeforeAction: `It's sometimes desirable to require the metric to exceed the threshold multiple times in a row before an incident ticket is created. For example, the alert rule shouldn't alert on temporary spikes but should alert when the increase is sustained. This value determines how many 5 minute periods the metric value needs to exceed the threshold value before an incident ticket is created.`,
    tooltipName: 'Set a name for this alert rule. This name will be attached to all tickets that are created from this rule.',
    tooltipDescription: 'Provide a description for this rule. The description will be attached to all tickets and can be used to provide any additional information for alerts generated for this rule.',
    placeholderMetricName: 'Search for metrics',
    placeholderDependsOnMetrics: 'Search for metrics',

    success: 'Success! Alert Rule created/updated',
    fail: 'Failed to create/update Alert Rule',
  },
});
