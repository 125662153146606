import React, { useCallback } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Flex,
  Wrap,
  Text,
  Box,
  Tooltip,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import * as Layout from '~ui/Layout';
import * as Permission from '~ui/Permission';
import MdiIcon from '@mdi/react';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import {
  TableSort,
  TableSearch,
  TableRows,
  TableSearchProvider,
  TablePagination,
  TableHeader,
  TableFilterTags,
  TableFilterSelect,
  TableDrawer,
  TableBody,
  TableBodyHeader,
  TableResultCount,
  TableFilterBool,
  TableFilterTriState,
} from '~ui/Table';
import * as Keys from '../../../../lib/utils/historyStateKeys';
import translation from './List.translation';
import { searchEntities } from '../../../../lib/services/entities';
import { listFilterType } from '../../../../utils/propTypes';
import { LIST_NAME } from './constants';
import { LIST_NAME as MYENV_SW_LIST } from '../ServiceWindows/components/List/constants';
import { LIST_NAME as MYENV_TICKETS_LIST } from '../Tickets/components/constants';

const tagColors = {
  customer_display_name: 'blue',
  container_name: 'gold',
  resource_group: 'ruby',
  type: 'green',
  is_mapped: 'teal',
  is_hidden: 'teal',
  is_stale: 'teal',
  tags: 'gray',
  region: 'purple',
};

const ListTemplates = ({ onUpdateListFilter, listFilter: globalListFilter }) => {
  const { t } = translation;
  const { params: { customerId } } = useRouteMatch();
  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();

  const columns = [
    {
      key: 'cloudops',
      size: 'minmax(4.0625rem, 4.0625rem)',
      props: { style: { textAlign: 'center' } },
    },
    { label: t('entityName'), size: 'minmax(12.5rem, 1fr)' },
    {
      label: t('entityType'),
      size: 'minmax(12.5rem, 18.75rem)',
    },
    { label: t('resourceGroup'), size: 'minmax(10.625rem, 13.75rem)' },
    { label: t('tags'), size: 'minmax(11.25rem, 12.375rem)' },
    { label: t('region'), size: 'minmax(9.375rem, 9.375rem)' },
    {
      label: t('customer'), size: 'minmax(9.375rem, 11.25rem)', render: isGlobalAdmin,
    },
  ];

  const filterCollection = {
    customer_display_name: (_, value) => `${t('customer')}: ${value}`,
    container_name: (_, value) => `${t('container')}: ${value}`,
    resource_group: (_, value) => `${t('resourceGroup')}: ${value}`,
    type: (_, value) => `${t('entityType')}: ${value}`,
    is_mapped: (_, value) => `${t('isMapped')}: ${value}`,
    is_hidden: (_, value) => `${t('isHidden')}: ${value}`,
    is_stale: (_, value) => `${t('isStale')}: ${value}`,
    region: (_, value) => `${t('region')}: ${value}`,
    tags: (_, value) => `${t('tags')}: ${value}`,
  };

  const sortList = [
    {
      value: 'is_mapped', label: t('cloudopsActive'), desc: true, default: true,
    },
    { value: 'name', label: t('name') },
    { value: 'resource_group', label: t('resourceGroup') },
    { value: 'region', label: t('region') },
    { value: 'type', label: t('entityType') },
    { value: 'sub_type', label: t('subType') },
  ];

  const callback = useCallback((state, azureSearchParser) => {
    const { is_stale: stale, ...restFiler } = state.filter;
    const newState = { ...state, filter: restFiler };
    const includeStale = stale !== undefined;
    const parsers = {
      filter: {
        tags: (value) => `tags/any(tag: tag eq '${value}')`,
      },
    };

    if (state.isFilterTrigger) {
      return {
        payload: azureSearchParser.facets(newState, state.filterKey, parsers),
        isGlobalAdmin,
        customerId,
        includeStale,
      };
    }

    onUpdateListFilter({
      [LIST_NAME]: { ...newState },
      [MYENV_SW_LIST]: null,
      [MYENV_TICKETS_LIST]: null,
    });

    return {
      payload: azureSearchParser.items(newState, parsers),
      isGlobalAdmin,
      customerId,
      includeStale,
    };
  }, [customerId, isGlobalAdmin, onUpdateListFilter]);

  return (
    <>
      <TableSearchProvider
        initialValues={globalListFilter}
        pageSize={15}
        payload={callback}
        fetcher={searchEntities}
        urlStateKey={Keys.ENV_LIST}
        sort={sortList}
        globalStateCallback={onUpdateListFilter}
      >
        <Layout.Omnibar.Main>
          <Box flex="1">
            <TableHeader>
              <TableSearch />
              <TableSort />
              <TableDrawer title={t('filterDrawerTitle')}>
                <TableFilterTriState
                  title={t('changeIsMapped')}
                  label={t('isMapped')}
                  filterKey="is_mapped"
                />
                <TableFilterTriState
                  title={t('changeIsHidden')}
                  label={t('isHidden')}
                  filterKey="is_hidden"
                />
                <TableFilterBool
                  title={t('changeIStale')}
                  label={t('isStale')}
                  filterKey="is_stale"
                />
              </TableDrawer>
            </TableHeader>
            <Wrap>
              {isGlobalAdmin && (
                <TableFilterSelect
                  label={t('customer')}
                  filterKey="customer_display_name"
                />
              )}
              <TableFilterSelect
                label={t('container')}
                filterKey="container_name"
              />
              <TableFilterSelect
                label={t('resourceGroup')}
                filterKey="resource_group"
              />
              <TableFilterSelect
                label={t('entityType')}
                filterKey="type"
              />
              <TableFilterSelect
                label={t('tags')}
                filterKey="tags"
              />
              <TableFilterSelect
                label={t('region')}
                filterKey="region"
              />
            </Wrap>
            <TableFilterTags
              configurations={filterCollection}
              colors={tagColors}
            />
          </Box>
        </Layout.Omnibar.Main>
        <Layout.Scene>
          <TableResultCount fileName="my-enviornment.csv" />
          <TableBody columns={columns}>
            <TableBodyHeader />
            <TableRows>
              {(data, idx, RowComponent) => (
                <Link key={idx} to={`/my-environment/${data.customer_id}/${data.id}/information`} style={{ textDecoration: 'none' }}>

                  <RowComponent>

                    <Flex p={2} spacing=".4rem" alignItems="center" justifyContent="center">
                      <Tooltip
                        hasArrow
                        placement="auto"
                        label={data.is_mapped ? t('cloudopsActive') : t('cloudopsInactive')}
                      >
                        <MdiIcon
                          opacity={data.is_mapped ? 1 : 0.5}
                          path={data.is_mapped ? mdiEye : mdiEyeOff}
                          size={0.75}
                        />
                      </Tooltip>
                    </Flex>

                    <Flex overflow="hidden" p={2} alignItems="center">
                      <Tooltip hasArrow label={data.name} placement="auto">
                        <Text isTruncated margin="0">{data.name}</Text>
                      </Tooltip>
                    </Flex>
                    <Flex overflow="hidden" p={2} alignItems="center">
                      <Tooltip hasArrow label={data.type} placement="auto">
                        <Text isTruncated margin="0">{data.type}</Text>
                      </Tooltip>
                    </Flex>
                    <Flex overflow="hidden" p={2} alignItems="center">
                      <Tooltip hasArrow label={data.resource_group} placement="auto">
                        <Text isTruncated margin="0">{ data.resource_group || '-' }</Text>
                      </Tooltip>
                    </Flex>
                    <Box overflow="hidden" p={2}>
                      <Wrap p={2} spacing=".4rem">
                        {data.tags.length ? (
                          data.tags.map((label, index) => (
                            index === 0 ? (
                              <Tag key={label} bg="#f5edd8" position="relative" style={{ marginRight: '20px' }}>
                                <Tooltip hasArrow placement="top" label={data.tags.map((el) => `${el}`).join(', ')}>
                                  <TagLabel isTruncated>
                                    {data.tags[0]}
                                  </TagLabel>
                                </Tooltip>
                                { data.tags.length > 1 && (
                                <span style={{ position: 'absolute', right: '-20px', fontSize: '12px' }}>
                                  {(' +')}
                                  {data.tags.length - 1}
                                </span>
                                )}
                              </Tag>
                            )
                              : null
                          ))
                        ) : (
                          <Text padding="2px">-</Text>
                        )}
                      </Wrap>
                    </Box>
                    <Flex p={2} alignItems="center">
                      <Text margin="0">{ data.region || '-' }</Text>
                    </Flex>
                    {isGlobalAdmin && (
                    <Flex overflow="hidden" p={2} alignItems="center">
                      <Tooltip hasArrow label={`${data.customer_name} (${data.customer_id})`} placement="auto">
                        <Text margin="0">
                          {data.customer_name}
                        </Text>
                      </Tooltip>
                    </Flex>
                    )}
                  </RowComponent>
                </Link>
              )}
            </TableRows>
          </TableBody>
          <TablePagination />
        </Layout.Scene>
      </TableSearchProvider>
    </>
  );
};

const propTypes = {
  onUpdateListFilter: PropTypes.func.isRequired,
  listFilter: listFilterType,
};

ListTemplates.propTypes = propTypes;

ListTemplates.defaultProps = {
  listFilter: null,
};

export default ListTemplates;
