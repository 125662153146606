import { connect } from 'react-redux';
import LoginView from './LoginView';
import { triggerLogin } from '../../actions';

const mapStateToProps = ({ application }) => ({ application });
const mapDispatchToProps = (dispatch) => ({
  onTriggerLogin: (payload) => dispatch(triggerLogin(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
