import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '~ui/Button';
import * as Form from '~ui/Form';
import { useRouteMatch } from 'react-router-dom';
import { useToast, Flex } from '@chakra-ui/react';
import translation from './ServiceForm.translation';
import * as C from './ServiceForm.styled';

const propTypes = {
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  service: PropTypes.instanceOf(Object).isRequired,
  mode: PropTypes.string.isRequired,
  onUpdateService: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

const ServiceForm = ({
  onMount,
  onUnmount,
  onUpdateService,
  mode,
  service,
  children,
}) => {
  const { t } = translation;
  const { services } = service;
  const { params } = useRouteMatch();
  const { customerId, entityId } = params;

  const [isSaving, setSaving] = useState(false);

  const toast = useToast();

  useEffect(() => {
    onMount({
      customerId,
      entityId,
      serviceName: services[0]?.name || '',
      onSuccess: () => toast({
        description: t('success'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      }),
      onFail: () => toast({
        description: t('fail'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      }),
    });
    return () => onUnmount();
  }, [onMount, onUnmount, customerId, entityId, services, t, toast]);

  const handleSubmit = ({ values }) => {
    onUpdateService({
      values,
      setSaving,
    });
  };

  const formData = Form.useFormBuilder({
    operationHours: {
      label: t('operationHours'),
      type: 'radiogroup',
      options: [],
      value: '',
      props: {
        vertical: true,
      },
    },
  });

  useEffect(() => {
    formData.updateFields([{
      name: 'operationHours',
      options: services
        .sort((a, b) => a.description.localeCompare(b.description))
        .map((s) => (
          {
            label: s.custom_billing ? `${s.description} ${t('custom')}` : `${s.description} ${s.monthlyPrice}/${t('month')}`,
            value: s.id.toString(),
            key: s.id,
          })),
      value: service.activeServiceId?.toString(),
    }]);
    /* eslint-disable-next-line */
  }, [services]);

  return (
    <C.StyledForm form={formData} onSubmit={handleSubmit}>
      {({ render, isDirty }) => (
        <>
          {render}
          <Flex justifyContent="space-between" flexDirection="row-reverse">
            <Button type="submit" isLoading={isSaving} disabled={!isDirty}>
              {t(`btn${mode}`)}
            </Button>
            {children}
          </Flex>
        </>
      )}
    </C.StyledForm>
  );
};

ServiceForm.propTypes = propTypes;
ServiceForm.defaultProps = {
  children: null,
};

export default ServiceForm;
