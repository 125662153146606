import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'ServiceWindowsSummary',
  sv: {
    header: 'Sammanfattning',
    reason: 'Anledning',
    whenAndHowOften: 'När och hur ofta',
    noReason: 'Ingen anledning angiven',
    repeatsAccordingToCron: 'Repeteras enligt CRON-uttryck',
    removeAll: 'Ta bort alla',
    startsOn: 'Startar den',
    at: 'vid',
    andLastsFor: 'och pågår i',
    minutes: 'minuter',
    entitiesAndResources: 'Entiteter och resurer',
    text: 'När du klickar på knappen nedan kommer servicefönstret att skapas/uppdateras enligt de val du gjort.',
    cronDescription: 'CRON-beskrivning',
    create: 'Skapa Servicefönster',
    update: 'Uppdatera',

    createSuccessful: 'Servicefönster skapat',
    updateSuccessful: 'Servicefönster uppdaterat',
    disable: 'Avaktivera',

    deactivationSuccessful: 'Avaktivering lyckades, kan ta några sekunder innan det syns',
    somethingWrong: 'Något gick snett, försök igen om ett tag',
  },
  en: {
    header: 'Summary',
    reason: 'Reason',
    whenAndHowOften: 'When and how often',
    noReason: 'No reason given',
    repeatsAccordingToCron: 'Repeats according to CRON expression',
    removeAll: 'Remove all',
    startsOn: 'Starts on',
    at: 'at',
    andLastsFor: 'and lasts for',
    minutes: 'minutes',
    entitiesAndResources: 'Entities and resource groups',
    text: 'When you click on the button below the service window will be created/updated according to the choices you have made.',
    cronDescription: 'CRON Description',
    create: 'Create Service Window',
    update: 'Update',

    createSuccessful: 'Service window created',
    updateSuccessful: 'Service window updated',
    disable: 'Disable',

    deactivationSuccessful: 'Deactivation succeeded, might take a couple of seconds before it shows',
    somethingWrong: 'Something went wrong, try again in a bit',
  },
});
