import Api from '../../lib/api';

const headers = {
  headers: {
    'Cache-Control': 'No-Cache',
  },
};

const getCustomer = (customerId) => Api
  .get(`customer/${customerId}`, null, null, headers)
  .then((success) => ({ success }))
  .catch((error) => ({ error }));

export default getCustomer;
