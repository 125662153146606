import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { TableContext } from '~ui/Table';
import { useRouteMatch } from 'react-router-dom';
import { ServiceWindowMutationContext } from '../../../serviceWindowMutationContext';

import EntitiesTable from './EntitiesTable';
import CronEditor from './CronEditor';
import Summary from './Summary';
import CustomerPicker from './CustomerPicker';
import Metrics from './Metrics';

export const parseCustomerId = (filter) => filter?.customer_display_name?.[0]?.match(/\((\d+)\)/)?.[1];

const Steps = ({ isGlobalAdmin, isEditMode }) => {
  const { itemCount, downloadSource, state: { getKey: getTableKey } } = useContext(TableContext);

  const { params } = useRouteMatch();

  const { customerId } = params;

  // get customer_id from the table select
  const customerPickerId = useMemo(() => {
    if (!isGlobalAdmin || isEditMode) return customerId;
    const filter = getTableKey('filter');

    return parseCustomerId(filter);
  }, [getTableKey, customerId, isGlobalAdmin, isEditMode]);

  const {
    entities,
    setEntities,
    serviceWindow,
  } = useContext(ServiceWindowMutationContext);

  const isEntitiesReady = Boolean(serviceWindow?.reason
   && serviceWindow?.schedule?.duration_in_seconds
    && serviceWindow?.schedule?.start && serviceWindow?.schedule?.end);

  const isAllRequiredReady = isEditMode
    ? serviceWindow?.reason && entities?.length > 0
    : customerPickerId && serviceWindow?.reason && entities?.length > 0;

  return (
    <>
      {isGlobalAdmin && !isEditMode
      && <CustomerPicker customerPickerId={customerPickerId} setEntities={setEntities} />}

      <CronEditor
        initialData={serviceWindow}
        isReady={Boolean(isEditMode || !isGlobalAdmin || customerPickerId)}
        step={isGlobalAdmin && !isEditMode ? '2' : '1'}
      />

      <EntitiesTable
        customerPickerId={customerPickerId}
        downloadSource={downloadSource}
        entities={entities}
        isCreateMode={!isEditMode}
        isReady={isEntitiesReady}
        itemCount={itemCount}
        setEntities={setEntities}
        step={isGlobalAdmin ? '3' : '2'}

      />

      <Metrics
        step={isGlobalAdmin ? '4' : '3'}
        isReady={isAllRequiredReady}
        customerId={customerPickerId}
        entities={entities}
        initialData={{
          metrics: serviceWindow?.entity_type_metric_name_mappings || [],
          useMetrics: serviceWindow.useMetrics,
        }}
      />

      <Summary
        entities={entities}
        isEditMode={isEditMode}
        isReady={isAllRequiredReady}
        serviceWindow={serviceWindow}
        setEntities={setEntities}
        customerPickerId={customerPickerId}
      />

    </>
  );
};

Steps.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  isGlobalAdmin: PropTypes.bool.isRequired,
};

export default Steps;
