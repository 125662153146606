import * as t from './actionTypes';

export const onMount = (payload) => ({
  type: t.MOUNT,
  meta: payload,
});

export const onUnmount = (payload) => ({
  type: t.UNMOUNT,
  meta: payload,
});

export const onSubmitComment = (payload) => ({
  type: t.SUBMIT_COMMENT,
  meta: payload,
});

export const initTaskAddComments = () => ({
  type: t.INIT_TASK_ADD_COMMENTS,
  meta: {},
});

export const commentError = (payload) => ({
  type: t.COMMENT_ERROR,
  meta: {
    errors: payload,
  },
});

export const fileUploaded = (payload) => ({
  type: t.FILE_UPLOADED,
  meta: {
    uploadedFile: payload,
  },
});
