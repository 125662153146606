import styled from 'styled-components';

export const AvailableServices = styled.div`
     margin-top: 6rem; 
     overflow: hidden; 
     border-radius: 5px; 
     border: 1px solid #d2d2d2;

     & > div {
       border-bottom: none;
     }
  `;
