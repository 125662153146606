import React, { useState, useCallback } from 'react';

import {
  Heading, Text, useToast,
} from '@chakra-ui/react';
import * as Form from '~ui/Form';
import { Button } from '~ui/Button';
import { useSecurityHours } from '~scenes/securityHours/context/SecurityHoursContext';

import translation from './ApproveHours.translation';
import * as C from './ApproveHours.styled';

import { formSpec } from './helpers';

const isAnyMonthEarlier = (date1, date2) => {
  const yearDiff = date2.getFullYear() - date1.getFullYear();
  const monthDiff = date2.getMonth() - date1.getMonth();

  return yearDiff > 0 || (yearDiff === 0 && monthDiff > 0);
};

const ApproveHours = () => {
  const toast = useToast();

  const { t } = translation;

  const { mutateSelectedMonth, selectedDate } = useSecurityHours();

  const isFormDisabled = !selectedDate || isAnyMonthEarlier(selectedDate, new Date());
  const formData = Form.useFormBuilder(formSpec(translation, isFormDisabled));

  const [errorFields, setErrorFields] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (errorFields.length) {
      errorFields.forEach((field) => {
        toast({
          description: t(`${field}FieldError`),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });

      return;
    }

    const { values } = formData.getValues();
    mutateSelectedMonth.mutate({ ...values }, {
      onSuccess: (data) => {
        const { error } = data;

        if (error) {
          toast({
            description: t('error'),
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        toast({
          description: t('success'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          description: t('error'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },

    });
  };

  const handleFormChange = useCallback(({
    validates,
  }) => {
    const invalidFields = Object.keys(validates).filter((key) => !validates[key]);
    setErrorFields(invalidFields);
  }, []);

  return (
    <C.Wrapper isFormDisabled={isFormDisabled}>

      <Heading as="h2">{t('title')}</Heading>
      <Text>{t(isFormDisabled ? 'oldDate' : 'description')}</Text>

      <Form.Primary
        form={formData}
        onChange={handleFormChange}
        disabled={isFormDisabled}
      >
        {({
          render,
        }) => (
          <>
            {render}
            <Button
              disabled={isFormDisabled}
              onClick={handleSubmit}
              isLoading={mutateSelectedMonth?.isLoading}
            >
              {t('submit')}
            </Button>
          </>
        )}

      </Form.Primary>

    </C.Wrapper>
  );
};

export default ApproveHours;
