import Api from '../../../../../../../lib/api';

const flattenObjectToArray = (baseList, action) => {
  const { succeeded_child: nextInList, ...rest } = action;

  baseList.push({ listIndex: baseList.length + 1, ...rest });
  if (nextInList) {
    return flattenObjectToArray(baseList, nextInList);
  }

  return baseList;
};

const flattenAutoActions = (model) => {
  const { root_action: rootAction, ...rest } = model;
  const convertedToArr = flattenObjectToArray([], rootAction);

  return { ...rest, autoActions: convertedToArr };
};

const getAutoActionsForEntity = async ({
  includeDisabled = false,
  customerId,
  entityId,
}) => {
  const path = `customer/${customerId}/entity/${entityId}/autoactionprocessmodel`;
  const params = { maxItems: -1, includeDisabled };

  return Api.continuationToken.get(path, null, params)
    .then((res) => res.map(flattenAutoActions))
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default getAutoActionsForEntity;
