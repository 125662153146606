import React, { useContext, useMemo } from 'react';
import * as Cards from '~ui/Cards';
import { Tooltip, useTheme } from '@chakra-ui/react';
import MdiIcon from '@mdi/react';
import { mdiFileTableBox } from '@mdi/js';
import { reduceOnResourceGroup } from './helpers';
import * as C from './SelectedEntities.styled';
import translation from './SelectedEntities.translation';
import ResourceGroup from './ResourceGroup';
import { ServiceWindowMutationContext } from '../../../../../serviceWindowMutationContext';

const { t } = translation;

const SelectedEntities = () => {
  const { colors } = useTheme();

  const { entities, setEntities } = useContext(ServiceWindowMutationContext);
  const groupedOnResourceGroup = useMemo(() => {
    if (entities) {
      return reduceOnResourceGroup(entities);
    }
    return [];
  }, [entities]);

  const removeEntity = (entity) => {
    const filteredEntities = entities.filter((ent) => ent.id !== entity.id);
    setEntities(filteredEntities);
  };

  return (
    <C.EntitiesContainer colors={colors}>
      {Object.keys(groupedOnResourceGroup).map((group) => {
        const groupEntities = groupedOnResourceGroup[group];

        // if only one entity in resource group, show entityname and id
        if (groupEntities.length === 1) {
          const entity = groupEntities[0];
          return (
            <C.Entry key={`entity${entity.id}`}>
              <C.Entity colors={colors}>
                <MdiIcon path={mdiFileTableBox} size={1.5} />

                <C.Info>
                  <Tooltip
                    hasArrow
                    placement="top"
                    bg="white"
                    color="black"
                    label={(
                      <Cards.Entity
                        id={entity.id}
                        key={entity.id}
                        hasCloudops={entity?.properties?.cloudops_active}
                        name={entity.name}
                        type={entity.type}
                        resourceGroup={entity.resource_group}
                        region={entity.region}
                        displayName={entity.customer_display_name}
                        tags={Object.entries(entity.tags).map(([a, b]) => `${a}:${b}`)}
                      />
)}
                  >
                    <C.TopText>{entity.name}</C.TopText>
                  </Tooltip>

                  <C.BottomText>{t('entity')}</C.BottomText>
                </C.Info>

                <C.Action>
                  <C.RemoveBtn variant="ghost" colors={colors} onClick={() => removeEntity(entity)}>{t('remove')}</C.RemoveBtn>
                </C.Action>
              </C.Entity>
              <C.Divider colors={colors} />
            </C.Entry>
          );
        }

        // else render entities as a list of resource group
        return (
          <C.Entry key={`rg${group}`}>
            <ResourceGroup groupName={group} groupEntities={groupEntities} />
            <C.Divider colors={colors} />
          </C.Entry>
        );
      })}
    </C.EntitiesContainer>

  );
};

export default SelectedEntities;
