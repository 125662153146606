import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AdminActionsList',
  sv: {
    appMonitor: 'Appövervakning',
    overview: 'Överblick',
    testRuns: 'Testkörningar',
    tickets: 'Ärenden',
  },
  en: {
    appMonitor: 'App monitor',
    overview: 'Overview',
    testRuns: 'Test runs',
    tickets: 'Tickets',
  },
});
