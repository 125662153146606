import Api from '../../../../../../../lib/api';

const getMetricDefinitions = async ({ customerId, entityId }) => {
  const path = `customer/${customerId}/metricdefinition/entity/${entityId}`;

  const params = {
    maxItems: -1,
  };

  return Api.get(path, null, params)
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default getMetricDefinitions;
