import {
  put,
  take,
  call,
  select,
} from 'redux-saga/effects';
import getEntityById from './services/getEntity.service';
import * as t from './actionTypes';
import * as model from '../../../../model';

const currentEntityId = ({ model: { entity } }) => (entity ? entity.id : null);

export default function* main() {
  while (true) {
    const { meta: { entityId, customerId } } = yield take(t.MOUNT);

    // Check if we are looking at the same entity of if we need to load a new one
    if (yield select(currentEntityId) !== entityId) {
      const { success } = yield call(getEntityById, { entityId, customerId });
      if (success) {
        yield put({ type: model.ENTITY_SET, meta: { entity: success } });
      }
    }

    yield take(t.UNMOUNT);
  }
}
