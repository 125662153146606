import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'SpentHoursSummary',
  sv: {
    title: 'Spenderade timmar',
    historyTitle: 'Historik på spenderade timmar',
    selectLabel: 'Filtrera på tjänst',
    export: 'Exportera',
    hours: 'Timmar',
    id: 'Ticket ID',
    selectPlaceholder: 'Välj',
    service: 'Tjänst',
    subject: 'Ämne',
    summaryTitle: 'Summering av spenderade timmar',
    notGovernedServices: '',
    sortOn: 'Sortera',
  },
  en: {
    title: 'Spent hours',
    historyTitle: 'Spent hours history',
    selectLabel: 'Filter on service',
    export: 'Export',
    hours: 'Hours',
    id: 'Ticket ID',
    selectPlaceholder: 'Choose',
    service: 'Service',
    subject: 'Subject',
    summaryTitle: 'Spent hours summary',
    notGovernedServices: '',
    sortOn: 'Sort',
  },
});
