import moment from 'moment';

export const getCost = (service) => {
  // const { price, price_unit: priceUnit } = service;
  const { price } = service;

  return `${price} sek`;
};

export const getMonthlyCost = (service) => {
  const { price_unit: priceUnit, price } = service;

  // if unit = 'st', i.e. databases with fixed prices,
  // just return the price
  if (priceUnit === 'st') {
    return `${price} sek`;
  }
  // else calculate hourly price

  const activeOnWeekends = service.week_ends;

  const monthlyRate = activeOnWeekends
    ? (service.end_hour_utc - service.start_hour_utc) * (365 / 12)
    : (service.end_hour_utc - service.start_hour_utc) * (365 / 12) * (5 / 7);
  const estimatedCost = (service.price * monthlyRate).toFixed();
  return `~${estimatedCost} sek`;
};

const extendSelectedService = (service, activeServices) => {
  const match = activeServices.find(({ cloudops_service_id: id }) => service.id === id);

  Object.assign(service, {
    hourPrice: getCost(service),
    monthlyPrice: getMonthlyCost(service),
    isActive: false,
  });

  if (!match) {
    return service;
  }

  return {
    ...service,
    isActive: moment(match.valid_to) > moment(),
    mappingId: match.cloudops_service_mapping_id,
  };
};

export const groupsServices = (availableServices, activeServices) => Object.entries(
  availableServices
    // Decorate service-object with mapping-data and determine if its active or not
    .reduce((acc, service) => ([
      ...acc,
      extendSelectedService(service, activeServices),
    ]), [])
    // Group services by name and display active service ID
    .reduce((acc, service) => {
      // Check if we have a touched the group allready.
      const cache = acc[service.name] || { services: [], activeServiceId: null };
      const group = Object.assign(cache, {
        name: service.name,
        services: [...cache.services, service],
        activeServiceId: cache.activeServiceId,
        customBilling: service.custom_billing,
        isFree: service.price === 0 && !service.custom_billing,
      });

      if (service.isActive) {
        Object.assign(group, {
          weekEnds: service.week_ends,
          weekDays: service.week_days,
          hourPrice: getCost(service),
          activeServiceId: service.id,
          description: service.description,
          monthlyPrice: getMonthlyCost(service),
          activeServiceMappingId: service.mappingId,
        });
      }

      return Object.assign(acc, {
        [service.name]: group,
      });
    }, {}),
)
// Place active service groups in active-array and avalaible services in another.
  .reduce((acc, x) => {
    const [, group] = x;
    if (group.activeServiceId) {
      return Object.assign(acc, {
        activeServices: [...acc.activeServices, group],
      });
    }
    const { services } = group;
    const highestService = services
      .reduce((cache, service) => (service.price > cache.price ? service : cache),
        services[0]);

    return Object.assign(acc, {
      availableServices: [
        ...acc.availableServices,
        {
          ...group,
          hourPrice: getCost(highestService),
          monthlyPrice: getMonthlyCost(highestService),
        }],
    });
  }, { activeServices: [], availableServices: [] });
