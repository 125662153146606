import Api from '../api';

const getMetricDefinitionsForEntities = async ({ customerId, entityIds = [], signal = null }) => {
  const path = `customer/${customerId}/metricdefinition/entity`;

  const params = {
    maxItems: -1,
  };

  const payload = {
    entity_ids: entityIds,
  };

  const override = {};

  if (signal) {
    override.signal = signal;
  }

  return Api.post(path, payload, params, override)

    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default getMetricDefinitionsForEntities;
