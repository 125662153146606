export const MOUNT = '@module.Router.mount';
export const UNMOUNT = '@module.Router.unmount';

export const LOGIN = '@action.module.Router.login';
export const LOGOUT = '@action.module.Router.logout';
export const SET_LANGUAGE = '@action.module.Router.set_language';
export const CLEAR_LOGIN_TRIGGERED = '@action.clear_login_triggered';

export const AUTHENTICATION_CALLBACK = '@event.module.Router.authentication_callback';
export const SET_REDIRECT_ORIGIN = '@event.module.Router.clear_redirect_path';

export const APPLICATION_READY = '@saga.module.Router.application_ready';
export const INITIATED = '@saga.module.Router.login_process_initiated';
export const AUTHED = '@saga.module.Router.user_authed_complete';
export const SET_ACCOUNT_STATE = '@saga.module.Router.set_account_metadata';
export const SET_JWT = '@saga.module.Router.set_jwt';
