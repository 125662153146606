import React from 'react';
import PropTypes from 'prop-types';
import * as Layout from '~ui/Layout';
import { useTheme } from '@chakra-ui/react';
import * as C from './OverviewLayout.styled';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const defaultProps = {};

const OverviewLayout = ({ children }) => {
  const { breakpoints } = useTheme();

  return (
    <Layout.Scene>
      <C.BlockGrid breakpoints={breakpoints}>
        {children}
      </C.BlockGrid>
    </Layout.Scene>
  );
};

OverviewLayout.propTypes = propTypes;
OverviewLayout.defaultProps = defaultProps;

export default OverviewLayout;
