import * as t from './actionTypes';

export const onMount = (payload) => ({
  type: t.MOUNT,
  meta: payload,
});

export const onUnmount = (payload) => ({
  type: t.UNMOUNT,
  meta: payload,
});

export const onCreateAlertRule = (payload) => ({
  type: t.CREATE_ALERTRULE,
  meta: payload,
});
