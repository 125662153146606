import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'ServiceWindowsCreate',
  sv: {
    title: 'Skapa servicefönster',
    create: 'Skapa servicefönster',
    back: 'Bakåt',
    searchLabel: 'Sök',
    cloudopsActive: 'Cloudops aktivt',
    name: 'Namn',
    customer: 'Kund',
    resourceGroup: 'Resursgrupp',
    region: 'Region',
    type: 'Typ',
    emptystate: 'Inga resultat på resurser eller grupper',
  },
  en: {
    title: 'Create service window',
    back: 'Back',
    create: 'Create service window',
    searchLabel: 'Search',
    cloudopsActive: 'Cloudops active',
    name: 'Name',
    customer: 'Customer',
    resourceGroup: 'Resource group',
    region: 'Region',
    type: 'Type',
    emptystate: 'No results for resources or resource groups',
  },
});
