import * as Msal from '@azure/msal-browser';
import { msalCacheKiller } from '../helpers';

const requestObj = {
  scopes: [`${process.env.REACT_APP_CLIENT_ID}/.default`],
};

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    authority: 'https://login.microsoftonline.com/common',
  },
  storeAuthStateInCookie: true,
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const msalClient = new Msal.PublicClientApplication(msalConfig);

export const acquireTokenSilent = async () => {
  const account = msalClient.getAllAccounts()[0];

  const requestObject = {
    scopes: ['profile'],
    account,
  };

  return msalClient
    .acquireTokenSilent(requestObject)
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export const acquireTokenRedirect = async (redirectPath) => {
  const account = msalClient.getAllAccounts()[0];

  const requestObject = {
    ...requestObj,
    account,
  };

  return msalClient
    .acquireTokenRedirect({
      ...requestObject, // Pass a path to where the user was redirect from their expired session
      state: redirectPath || '/dashboard',
    });
};

export const triggerLoginRedirect = (redirectPath) => {
  msalCacheKiller();
  const account = msalClient.getAllAccounts()[0];
  msalClient.loginRedirect({
    ...requestObj, // Pass a path to where the user was redirect from their expired session
    account,
    state: redirectPath || '/dashboard',
  });
};

export const triggerLogout = () => {
  msalClient.logoutRedirect(requestObj);
};

export const loginSession = async () => {
  const silent = await acquireTokenSilent();
  return silent;
};

export const tokenExpirationMS = async () => {
  // Try to refrehs JWT-token, in case session just has expired
  try {
    const { success } = await acquireTokenSilent();
    if (!success) {
      return 0;
    }
    const { expiresOn } = success;
    const tokenExpiryDate = new Date(expiresOn);
    return tokenExpiryDate - new Date();
  } catch (e) {
    return 0;
  }
};

export const userIsLoggedIn = async () => await tokenExpirationMS() > 0;
