import addTranslation from '../../translations/addTranslation';

export default addTranslation({
  id: 'MainLayout',
  sv: {
    create: 'Skapa',
  },
  en: {
    create: 'Create',
  },
});
