import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import {
  TableSort,
  TableSearch,
  TableRowCards,
  TableSearchProvider,
  TablePagination,
  TableHeader,
  TableFilterSelect,
  TableBody,
  TableFilterBool,
} from '~ui/Table';
import * as Keys from '../../../../lib/utils/historyStateKeys';
import { searchCustomers } from '../../../../lib/services/customer';
import { useFeatureRolesQuery } from '../../../../lib/services/permissions';
import PermissionRow from './PermissionRow';
import { LIST_NAME } from './constants';
import { listFilterType } from '../../../../utils/propTypes';

import translation from './Permissions.translation';

const ListTemplates = (
  { onUpdateListFilter, listFilter: globalListFilter },
) => {
  const { t } = translation;
  const { data: featureRoles, isLoading } = useFeatureRolesQuery();

  const sortList = [
    {
      value: 'name',
      label: t('customer_name'),
      default: true,
    },
  ];

  const callback = useCallback((state, azureSearchParser) => {
    const parsers = {
      filter: {
        isMapped: () => `role_feature_mappings/any()`,
      },
    };

    if (state.isFilterTrigger) {
      return {
        payload: azureSearchParser.facets(state, state.filterKey, parsers),
      };
    }

    onUpdateListFilter({
      [LIST_NAME]: { ...state },
    });

    return {
      payload: azureSearchParser.items(state, parsers),
    };
  }, [onUpdateListFilter]);

  if (isLoading) {
    return 'loading';
  }

  const columns = [
    { label: t('customer'), size: '10rem' },
    { label: t('role'), size: '7rem' },
    ...featureRoles.availableFeatures.map((feature) => ({
      key: feature,
      label: feature.replace('@feature.', ''),
      size: `minmax(11rem, 1fr)`,
    })),
  ];

  return (
    <>
      <TableSearchProvider
        pageSize={15}
        payload={callback}
        fetcher={searchCustomers}
        urlStateKey={Keys.ENV_LIST}
        sort={sortList}
        initialValues={globalListFilter}
      >
        <Box flex="1">
          <TableHeader>
            <TableSearch />
            <TableSort />
          </TableHeader>
          <Flex alignItems="center" justifyContent="space-between">
            <TableFilterSelect
              label="Status"
              filterKey="status"
            />
            <Flex flexDirection="row" alignItems="center">
              <Text as="i" fontSize="xs" mr={2}>Only with mapped roles</Text>
              <TableFilterBool
                switchSize="sm"
                label="role_feature_mappings"
                filterKey="isMapped"
              />
            </Flex>
          </Flex>
        </Box>
        <TableBody columns={columns}>
          <TableRowCards>
            {(data, idx) => (
              <PermissionRow
                key={`${data.customer_id}${idx}`}
                roleFeatureMapping={data.role_feature_mappings}
                availableFeatures={featureRoles.availableFeatures}
                customerName={data.customer_name}
                customerId={data.customer_id}
              />
            )}
          </TableRowCards>
        </TableBody>
        <TablePagination />
      </TableSearchProvider>
    </>
  );
};

const propTypes = {
  onUpdateListFilter: PropTypes.func.isRequired,
  listFilter: listFilterType,
};

ListTemplates.propTypes = propTypes;
ListTemplates.defaultProps = {
  listFilter: null,
};

export default ListTemplates;
