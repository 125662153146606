import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import useInitialValues from './hooks/useInitialValues';
import * as C from './Main.styled';
import SecurityHoursProvider from '../../context/SecurityHoursContext';
import StepOne from '../../modules/StepOne';
import Details from '../../modules/Details';

const propTypes = {
  customerId: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
};

const defaultProps = {};

const Main = ({ customerId, customerName }) => {
  const history = useHistory();

  const { location } = history;

  const { selectedCustomerId, selectedDate } = useInitialValues(location);

  return (
    <SecurityHoursProvider
      loggedInCustomerId={customerId}
      customerName={customerName}
      initialSelectedCustomerId={selectedCustomerId}
      initialDate={selectedDate}
    >

      <C.Wrapper>
        <StepOne />
        <Details />
      </C.Wrapper>

    </SecurityHoursProvider>
  );
};

Main.propTypes = propTypes;
Main.defaultProps = defaultProps;

export default Main;
