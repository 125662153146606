import styled from 'styled-components';

export const Wrapper = styled.div`
    overflow-x: scroll;
`;

export const Cell = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem;
    padding-right: ${({ isLast }) => `${isLast ? '0' : '2rem'}`};
    justify-content: ${({ isLast }) => `${isLast ? 'flex-end' : 'flex-start'}`};
    width: max-content;
`;

export const Row = styled.tr``;

export const Table = styled.table`
  display: table;
  width: 100%;

  ${Row}:not(:last-child) {
    border-bottom: ${({ colors }) => `1px solid ${colors?.gray?.['200']}`};
  }

  ${Row}:not(:first-child) {
    &:hover {
      background-color: ${({ colors }) => (colors?.gray?.['100'])};
      cursor: pointer;
    }
  }
`;
