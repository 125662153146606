import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AlertRuleMetricsWidget',
  sv: {
    threshold: 'Tröskelvärde',
    criticalThreshold: 'Kritiskt tröskelvärde',
  },
  en: {
    threshold: 'Threshold',
    criticalThreshold: 'Critical threshold',
  },
});
