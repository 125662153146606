import addTranslation from '../../../../translations/addTranslation';

export default addTranslation({
  id: 'PreviewList',
  sv: {
    remove: 'Ta bort fil',
  },
  en: {
    remove: 'Remove file',

  },
});
