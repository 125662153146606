import { connect } from 'react-redux';
import OnCall from './components/OnCall';
import {
  onMount, onUnmount, onUpdateOnCall,
} from './actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onMount: (payload) => dispatch(onMount(payload)),
  onUnmount: (payload) => dispatch(onUnmount(payload)),
  onUpdateOnCall: (payload) => dispatch(onUpdateOnCall(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnCall);
