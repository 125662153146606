import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'EntitiesList',
  sv: {
    entityName: 'Namn',
    entityType: 'Typ',
    resourceGroup: 'Resursgrupp',
    tags: 'Taggar',
    region: 'Region',
    containerName: 'Container',
  },
  en: {
    entityName: 'Name',
    entityType: 'Type',
    resourceGroup: 'Resource group',
    tags: 'Tags',
    region: 'Region',
    containerName: 'Container',
  },
});
