import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AppMonitorOverview',
  sv: {
    testOutcome: 'Testresultat',
    failsDuring: 'misslyckades under',
    failsThisYear: 'misslyckades i år',
    fails: 'fel',
    successes: 'passerade',

    smoothSailing: 'Gått som tåget - inga testkörningar misslyckades',
    failed: 'misslyckades',
    outOf: 'av',
    runs: 'körningar',
  },
  en: {
    testOutcome: 'Test outcome',
    failsDuring: 'fails during',
    failsThisYear: 'fails this year',
    fails: 'fails',
    successes: 'successes',

    smoothSailing: 'Smooth sailing - no testruns failed',
    failed: 'failed',
    outOf: 'out of',
    runs: 'runs',
  },
});
