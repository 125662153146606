import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Form from '~ui/Form';
import * as Block from '~ui/Block';
import { Button } from '~ui/Button';
import { Text, useTheme } from '@chakra-ui/react';
import translation from './ConfigureService.translation';
import actionTranslations from '../../AutoActions.translation';
import * as C from '../SequenceForm/SequenceForm.styled';

const propTypes = {
  selectedAction: PropTypes.instanceOf(Object).isRequired,
  onAddAction: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
};
const defaultProps = {};

const ConfigureService = ({
  selectedAction,
  onAddAction,
  isEdit,
}) => {
  const { breakpoints } = useTheme();
  const { t } = translation;
  const { parameters, required_parameter_specifications: specs } = selectedAction;
  const { fallback } = actionTranslations;

  const translatedFields = useMemo(() => specs
    .reduce((acc, field) => {
      const {
        type,
        name,
        description,
        name_translation_key: nameKey,
        description_translation_key: descKey,
      } = field;

      const fallbackName = `${name}`.replace(/([a-z])([A-Z])/g, '$1 $2');

      const newField = {
        type,
        name,
        label: fallback(nameKey, fallbackName),
        description: fallback(descKey, description),
      };

      return [...acc, newField];
    },
    []), [specs, fallback]);

  const formData = Form.useFormBuilder(translatedFields, parameters);

  const translatedName = fallback(selectedAction.id, selectedAction.name);
  const translatedDesc = fallback(`${selectedAction.id}-desc`, selectedAction.description);

  return (
    <Block.Center>
      <C.ActionContainer breakpoints={breakpoints}>
        <C.StepTitle>{translatedName}</C.StepTitle>
        <C.Content>
          <Text>
            {translatedDesc}
          </Text>
          <Form.Primary
            form={formData}
            onSubmit={({ values, dirty }) => {
              if (dirty) {
                onAddAction({ ...selectedAction, parameters: values });
              }
            }}
          >
            {({ render, onSubmitAction }) => (
              <Block.Center>
                {render}
                <Block.SpaceBetween>
                  <Button onClick={onSubmitAction}>
                    {isEdit ? t('update') : t('add')}
                  </Button>
                </Block.SpaceBetween>
              </Block.Center>
            )}
          </Form.Primary>
        </C.Content>
      </C.ActionContainer>
    </Block.Center>
  );
};

ConfigureService.propTypes = propTypes;
ConfigureService.defaultProps = defaultProps;

export default ConfigureService;
