import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'MyEnvInformationPageDescription',
  sv: {
    cloudOpsText: 'Enbart resurser med en aktiv CloudOps-tjänst övervakas och manageras i CloudOps. Ska denna resurs övervakas och manageras? Aktivera i CloudOps-fliken för denna resurs eller kontakta Service Desk för hjälp. Tänk på att se över dokumentation och eskaleringsrutiner så att Service Desk har den information som behövs för att hantera resursen.',
    availableOptions: 'Tillgängliga val',
    whatIsCloudOps: 'Vad är en CloudOps-tjänst?',
  },
  en: {
    cloudOpsText: 'Resources with an active CloudOps service are monitored and managed by CloudOps. Should this resource be monitored and managed? Activate CloudOps in the CloudOps tab for this resource or contact Service Desk for assistance. Also verify that the documentation and escalation routines for this resource are up to date. Service Desk needs this information to be able to manage this resource.',
    availableOptions: 'Available Options',
    whatIsCloudOps: 'What is a CloudOps service?',
  },
});
