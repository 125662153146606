import {
  mdiAndroidMessages,
  mdiMotionPlay,
  mdiApps,
  mdiCompass,
  mdiContrastCircle,
  mdiPollBox,
  mdiShieldCheck,
  mdiAccountGroup,
  mdiShieldAccount,
  mdiAlertCircleCheck,
} from '@mdi/js';
import { IconSecurityHours } from '~ui/Icons';
import * as ServiceWindow from './scenes/serviceWindow';
import * as MyEnvironment from './scenes/myEnvironment';
import * as Tickets from './scenes/tickets';
import * as Admin from './scenes/admin';
import * as Reports from './scenes/reports';
import * as AppMonitor from './scenes/appMonitor';
import * as Incidents from './scenes/incidents';
import * as Security from './scenes/security';
import * as Team from './scenes/team';
import * as NotAuthorized from './router/components/NotAuthorized';
import * as AlertOverview from './scenes/alertOverview';
import * as SecurityHours from './scenes/securityHours';

import {
  overviewPermission,
  ticketPermission,
  myEnvironmentPermission,
  serviceWindowPermission,
  securityPermission,
  reportPermission,
  appMonitorPermission,
} from './lib/utils/featureKeys';

export const firstPagePath = '/dashboard';

export const mainNavigation = (t, { customer_id: customerId }) => [
  {
    label: t('linkOverviewLabel'),
    tooltip: t('linkOverviewTooltip'),
    active: true,
    icon: mdiApps,
    link: '/dashboard',
    featureKeys: [overviewPermission.read],
  },
  {
    label: t('linkAlertOverviewLabel'),
    tooltip: t('linkAlertOverviewTooltip'),
    icon: mdiAlertCircleCheck,
    link: '/alert-overview',
  },
  {
    label: t('linkTicketsLabel'),
    tooltip: t('linkTicketsTooltip'),
    icon: mdiAndroidMessages,
    link: `/tickets/${customerId || ''}`,
    featureKeys: [ticketPermission.read, ticketPermission.write],
  },
  {
    label: t('linkExploreLabel'),
    tooltip: t('linkExploreTooltip'),
    icon: mdiCompass,
    link: `/my-environment/${customerId || ''}`,
    featureKeys: [myEnvironmentPermission.read, myEnvironmentPermission.write],
    isActive: (_, { pathname }) => {
      if (pathname && /^\/my-environment/.test(pathname)) {
        return true;
      }

      return false;
    },
  },
  {
    label: t('linkServiceWindowLabel'),
    tooltip: t('linkServiceWindowTooltip'),
    icon: mdiMotionPlay,
    link: `/service-window/${customerId || ''}`,
    featureKeys: [serviceWindowPermission.read, serviceWindowPermission.write],
  },
  {
    label: t('linkReportsLabel'),
    tooltip: t('linkReportsTooltip'),
    icon: mdiPollBox,
    link: `/reports/${customerId || ''}`,
    featureKeys: [reportPermission.read],
  },
  {
    label: t('linkAppMonitorLabel'),
    tooltip: t('linkAppMonitorTooltip'),
    icon: mdiContrastCircle,
    link: `/app-monitor/${customerId || ''}`,
    featureKeys: [appMonitorPermission.read],
  },
  {
    label: 'Security',
    tooltip: 'Security',
    icon: mdiShieldCheck,
    link: `/security/${customerId || ''}`,
    featureKeys: [securityPermission.read, securityPermission.write],
  },
  {
    label: 'Security Hours',
    tooltip: 'Security Hours',
    icon: IconSecurityHours,
    link: `/security-hours`,
    featureKeys: [securityPermission.read, securityPermission.write],
  },
  {
    label: 'Admin',
    tooltip: 'Admin',
    icon: mdiShieldAccount,
    link: '/admin',
    roleKeys: ['api-permission-global-admin'],
  },
  {
    label: 'Team',
    tooltip: 'Team',
    icon: mdiAccountGroup,
    link: '/team',
    isDropdownItem: true,
  },
];

export const topCreateList = (t, { customer_id: customerId }) => [
  {
    title: t('createTicket'),
    description: t('createTicketDesc'),
    icon: mdiAndroidMessages,
    link: `/tickets/create`,
    featureKeys: [ticketPermission.write],
  },
  {
    title: t('createServiceWindow'),
    description: t('createServiceWindowDesc'),
    icon: mdiMotionPlay,
    link: `/service-window/${customerId}/create`,
    featureKeys: [serviceWindowPermission.write],
  },
];

export const redirectRoutes = [
  {
    args: { exact: true },
    from: '/',
    to: () => '/dashboard',
  },
  {
    from: '/login',
    to: () => '/dashboard',
  },
  {
    args: { exact: true },
    from: '/my-environment/',
    to: () => `/dashboard`,
  },
  {
    args: { exact: true },
    from: '/my-environment/:customerId/:entityId/',
    to: ({ customerId, entityId }) => `/my-environment/${customerId}/${entityId}/information`,
  },
];

const myEnvRoutes = [
  {
    args: { exact: true },
    path: '/my-environment/:customerId/:entityId/cloudops-vmagent',
    component: MyEnvironment.CloudOpsVMAgent.Main,
    featureKeys: [myEnvironmentPermission.write, myEnvironmentPermission.read],
  },
  {
    args: { exact: true },
    path: '/my-environment/:customerId/:entityId/cloudops-vmagent/edit/:vmConfigId/:type',
    component: MyEnvironment.CloudOpsVMAgent.Edit,
    featureKeys: [myEnvironmentPermission.write],
  },
  {
    args: { exact: true },
    path: '/my-environment/:customerId/:entityId/tickets/:ticketId',
    component: Tickets.Details,
    featureKeys: [myEnvironmentPermission.write, myEnvironmentPermission.read],
  },
  {
    args: { exact: true },
    path: '/my-environment/:entityId/tickets/:ticketId',
    component: Tickets.Details,
    featureKeys: [myEnvironmentPermission.write, myEnvironmentPermission.read],
  },
  {
    args: { exact: true },
    path: '/my-environment/:customerId/:entityId/tickets',
    component: MyEnvironment.Tickets,
    featureKeys: [myEnvironmentPermission.write, myEnvironmentPermission.read],
  },
  {
    args: { exact: true },
    path: '/my-environment/:customerId/:entityId/information',
    component: MyEnvironment.Information.Main,
    featureKeys: [myEnvironmentPermission.write, myEnvironmentPermission.read],
  },
  {
    args: { exact: true },
    path: '/my-environment/:customerId/:entityId/information/details',
    component: MyEnvironment.Information.Details,
    featureKeys: [myEnvironmentPermission.write, myEnvironmentPermission.read],
  },
  {
    args: { exact: true },
    path: '/my-environment/:customerId/:entityId/alert-rules',
    component: MyEnvironment.AlertRules.List,
    featureKeys: [myEnvironmentPermission.write, myEnvironmentPermission.read],
  },
  {
    args: { exact: true },
    path: '/my-environment/:customerId/:entityId/alert-rules/create',
    component: MyEnvironment.AlertRules.Create,
    featureKeys: [myEnvironmentPermission.write],
  },
  {
    args: { exact: true },
    path: '/my-environment/:customerId/:entityId/alert-rules/edit/:alertRuleId',
    component: MyEnvironment.AlertRules.Edit,
    featureKeys: [myEnvironmentPermission.write],
  },
  {
    args: { exact: true },
    path: '/my-environment/:customerId/:entityId/service-windows',
    component: MyEnvironment.ServiceWindows,
    featureKeys: [myEnvironmentPermission.write, myEnvironmentPermission.read],
  },
  {
    args: { exact: true },
    path: '/my-environment/:customerId/:entityId/cloudops',
    component: MyEnvironment.CloudOpsService.Main,
    featureKeys: [myEnvironmentPermission.write, myEnvironmentPermission.read],
  },
  {
    args: { exact: true },
    path: '/my-environment/:customerId/:entityId/auto-actions/new',
    component: MyEnvironment.AutoActions.CreateEdit,
    featureKeys: [myEnvironmentPermission.write],
  },
  {
    args: { exact: true },
    path: '/my-environment/:customerId/:entityId/auto-actions/edit/:processModelId',
    component: MyEnvironment.AutoActions.CreateEdit,
    featureKeys: [myEnvironmentPermission.write],
  },
  {
    args: { exact: true },
    path: '/my-environment/:customerId/:entityId/auto-actions',
    component: MyEnvironment.AutoActions.Main,
    featureKeys: [myEnvironmentPermission.write, myEnvironmentPermission.read],
  },
  {
    args: { exact: true },
    path: '/my-environment/:customerId/',
    component: MyEnvironment.Main,
    featureKeys: [myEnvironmentPermission.write, myEnvironmentPermission.read],
  },
];

const ticketRoutes = [
  {
    args: { exact: true },
    path: '/tickets/create',
    component: Tickets.Create,
    featureKeys: [ticketPermission.write],
  },
  {
    args: { exact: true },
    path: '/tickets/:customerId',
    component: Tickets.Main,
    featureKeys: [ticketPermission.read, ticketPermission.write],
  },
  {
    args: { exact: true },
    path: '/tickets/:entityId/:customerId/:ticketId/details',
    component: Tickets.Details,
    featureKeys: [ticketPermission.read, ticketPermission.write],
  },
  {
    args: { exact: true },
    path: '/tickets/:customerId/:ticketId/details',
    component: Tickets.Details,
    featureKeys: [ticketPermission.read, ticketPermission.write],
  },
  {
    args: { exact: true },
    path: '/tickets/:ticketId/details',
    component: Tickets.Details,
    featureKeys: [ticketPermission.read, ticketPermission.write],
  },
];

const appMonitorRoutes = [
  {
    args: { exact: true },
    path: '/app-monitor/:customerId',
    component: AppMonitor.Main,
    featureKeys: [appMonitorPermission.read],
  },
  {
    args: { exact: true },
    path: '/app-monitor/:customerId/:entityId/:testName/overview',
    component: AppMonitor.Overview,
    featureKeys: [appMonitorPermission.read],
  },
  {
    args: { exact: true },
    path: '/app-monitor/:customerId/:entityId/:testName/tickets/:ticketId',
    component: Tickets.Details,
    featureKeys: [appMonitorPermission.read],
  },
  {
    args: { exact: true },
    path: '/app-monitor/:customerId/:entityId/:testName/tickets',
    component: AppMonitor.Tickets,
    featureKeys: [appMonitorPermission.read],
  },
  {
    args: { exact: true },
    path: '/app-monitor/:customerId/:entityId/:testName/test-runs',
    component: AppMonitor.TestRuns,
    featureKeys: [appMonitorPermission.read],
  },
  {
    args: { exact: true },
    path: '/app-monitor/:customerId/:entityId/:testName/details/:testId',
    component: AppMonitor.TestRunDetails,
    featureKeys: [appMonitorPermission.read],
  },
  {
    args: { exact: true },
    path: '/app-monitor/:customerId/:entityId/:testName/details/:testId/logs',
    component: AppMonitor.TestRunLogs,
    featureKeys: [appMonitorPermission.read],
  },
];

const serviceWindowRoutes = [
  {
    args: { exact: true },
    path: '/service-window/:customerId',
    component: ServiceWindow.List,
    featureKeys: [serviceWindowPermission.read, serviceWindowPermission.write],
  },
  {
    args: { exact: true },
    path: '/service-window/:customerId/create',
    component: ServiceWindow.Create,
    featureKeys: [serviceWindowPermission.write],
  },
  {
    args: { exact: true },
    path: '/service-window/:customerId/:swId/edit',
    component: ServiceWindow.Edit,
    featureKeys: [serviceWindowPermission.write],
  },
];

const teamRoutes = [
  {
    args: { exact: true },
    path: '/team',
    component: Team.Main,
  },
];

const securityRoutes = [
  {
    args: { exact: true },
    path: '/security/:customerId',
    component: Security.WorkGroups,
    featureKeys: [securityPermission.read, securityPermission.write],
  },
  {
    args: { exact: true },
    path: '/security/:customerId/:workspaceId/analytic-rules',
    component: Security.ListAnalyticRules,
    featureKeys: [securityPermission.read, securityPermission.write],
  },
  {
    args: { exact: true },
    path: '/security/:customerId/:workspaceId/templates',
    component: Security.ListTemplates,
    featureKeys: [securityPermission.read, securityPermission.write],
  },
  {
    path: '/security/:customerId/:workspaceId/template/:templateCustomerId/:templateId',
    component: Security.Template,
    featureKeys: [securityPermission.read, securityPermission.write],
  },
  {
    path: '/security/:customerId/:workspaceId/analytic-rule/:analyticRuleId',
    component: Security.AnalyticRule,
    featureKeys: [securityPermission.read, securityPermission.write],
  },
  {
    args: { exact: true },
    path: '/security-hours',
    component: SecurityHours.Main,
    featureKeys: [securityPermission.read, securityPermission.write],
  },
];

const reportsRoutes = [
  {
    args: { exact: true },
    path: '/reports/:customerId',
    component: Reports.Main,
    featureKeys: [reportPermission.read],
  },
  {
    args: { exact: true },
    path: '/reports/:customerId/:groupId/report/:reportId',
    component: Reports.Report,
    featureKeys: [reportPermission.read],
  },
];

const adminRoutes = [
  {
    path: '/admin',
    component: Admin.Main,
    roleKeys: ['api-permission-global-admin'],
  },
];

const dashboardRoutes = [
  {
    args: { exact: true },
    path: '/dashboard',
    component: Incidents.TagGroupOverview,
    // roleKeys: ['api-permission-global-admin'],
  },
  // {
  //   args: { exact: true },
  //   path: '/dashboard/:customerId',
  //   component: Incidents.TagGroupOverview,
  //   roleKeys: ['api-permission-global-admin'],
  // },
];

const alertOverviewRoutes = [
  {
    path: '/alert-overview',
    component: AlertOverview.Overview,
  },
];

const notAuthorizedRoutes = [
  {
    args: { exact: true },
    path: NotAuthorized.routePath,
    component: NotAuthorized.View,
    roleKeys: [],
  },
];

export const applicationRoutes = [
  ...adminRoutes,
  ...dashboardRoutes,
  ...reportsRoutes,
  ...securityRoutes,
  ...myEnvRoutes,
  ...ticketRoutes,
  ...appMonitorRoutes,
  ...serviceWindowRoutes,
  ...teamRoutes,
  ...notAuthorizedRoutes,
  ...alertOverviewRoutes,
];

export default applicationRoutes;
