import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { mdiArrowLeft } from '@mdi/js';
import * as Block from '~ui/Block';
import * as Layout from '~ui/Layout';
import { Button } from '~ui/Button';
import * as Permission from '~ui/Permission';
import * as K from '../../../../../lib/utils/historyStateKeys';
import translation from './Navigation.translation';
import { useUserContext } from '../../../../../lib/services/userContext';

const PATH_ROOT = '/app-monitor';
const { t } = translation;

const defaultProps = {
  children: null,
};

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

const Navigation = ({ children }) => {
  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();

  const { params } = useRouteMatch();
  const { customerId, entityId, testName } = params;
  const { data: userContext } = useUserContext();

  const backLink = `${PATH_ROOT}/${userContext?.customer_id}`;

  return (
    <>
      <Layout.Omnibar.Main>
        <Layout.Omnibar.LeftActions>
          <Button
            variant="ghost"
            internalLink={backLink}
            leftIcon={mdiArrowLeft}
            clearStateKeys={[
              K.APP_MONITOR_TICKETS,
              K.APP_MONITOR_TEST_RUNS,
            ]}
          >
            {t('appMonitor')}
          </Button>
        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>
      <Layout.Scene>
        <Block.SubnavigationBlock
          title={testName}
          navigationList={[
            {
              label: t('overview'),
              path: `${PATH_ROOT}/${customerId}/${entityId}/${testName}/overview`,
              isActive: window.location.pathname === `${PATH_ROOT}/${customerId}/${entityId}/${testName}/overview`,
            },
            {
              label: t('testRuns'),
              path: `${PATH_ROOT}/${customerId}/${entityId}/${testName}/test-runs`,
              isActive: window.location.pathname === `${PATH_ROOT}/${customerId}/${entityId}/${testName}/test-runs`,
            },
            {
              label: t('tickets'),
              path: `${PATH_ROOT}/${customerId}/${entityId}/${testName}/tickets`,
              isActive: window.location.pathname === `${PATH_ROOT}/${customerId}/${entityId}/${testName}/tickets`,
            },
          ]}
        >
          { children({ isGlobalAdmin }) }
        </Block.SubnavigationBlock>
      </Layout.Scene>
    </>
  );
};

Navigation.propTypes = propTypes;
Navigation.defaultProps = defaultProps;

export default Navigation;
