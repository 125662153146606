import React from 'react';
import PropTypes from 'prop-types';
import MdiIcon from '@mdi/react';
import { mdiLock } from '@mdi/js';
import ErrorBoundary from '~ui/ErrorBoundary';
import * as UserImage from '~ui/UserImage';
import * as Moment from '~ui/Moment';
import Attachment from '~ui/Attachment';
import { Tooltip, useTheme } from '@chakra-ui/react';
import translation from './Comment.translation';
import * as C from './Comment.styled';
import { attachmentType } from '../../../../../../utils/propTypes';

const { t } = translation;

const propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  body: PropTypes.string,
  created: PropTypes.string,
  isPrivate: PropTypes.bool,
  attachments: attachmentType,
};

const defaultProps = {
  name: '',
  email: '',
  body: '',
  created: '',
  isPrivate: false,
  attachments: [],
};

const Comment = ({
  name,
  email,
  body,
  created,
  isPrivate,
  attachments,
}) => {
  const { colors, breakpoints } = useTheme();

  return (
    <C.Comment colors={colors} isPrivate={isPrivate}>
      <C.Header colors={colors} breakpoints={breakpoints}>
        <UserImage.Circle
          size="1.875rem"
          name={name}
          email={email}
          href={`${process.env.REACT_APP_CDN}/profile-picture/${window.btoa(email)}`}
        />
        <C.Author>{name}</C.Author>
        <C.Created breakpoints={breakpoints}>
          <Moment.Ago timestamp={created} />
        </C.Created>

        {isPrivate && (
          <C.PrivacyIcon colors={colors} size={30}>
            <Tooltip hasArrow label={t('privateComment')}>
              <MdiIcon path={mdiLock} size={0.75} />
            </Tooltip>
          </C.PrivacyIcon>
        )}
      </C.Header>

      <ErrorBoundary fallback={t('errorParsing')}>
        <C.TicketBody foldQuotes markdown={body} />

        { attachments?.length > 0 && (
        <C.AttachmentSection isPrivate={isPrivate} colors={colors}>
          <span className="title">{t('attachments')}</span>
          <C.AttachmentsWrapper>
            {attachments?.map((attachment) => (
              <Attachment
                key={attachment.id || attachment.key}
                file={attachment}
              />
            ))}
          </C.AttachmentsWrapper>
        </C.AttachmentSection>
        )}
      </ErrorBoundary>
    </C.Comment>
  );
};

Comment.propTypes = propTypes;
Comment.defaultProps = defaultProps;

export default Comment;
