import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AutoActionMain',
  sv: {
    title: 'Automationsprocesser',
    create: 'Skapa process',
    noPermissions: 'Du har inte rättigheter att ändra/skapa automationsprocesser',
  },
  en: {
    title: 'Automation processes',
    create: 'Create process',
    noPermissions: 'You do not have permissions to create/edit Automation processes',
  },
});
