import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@chakra-ui/react';
import TicketListItem from './TicketListItem';
import * as C from './EntityGroupListItem.styled';

const propTypes = {
  title: PropTypes.string.isRequired,
  tickets: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  customerId: PropTypes.string.isRequired,
  backPathUrlParam: PropTypes.string.isRequired,
};

const defaultProps = {
  tickets: [],
};

const EntityGroupListItem = ({
  title, tickets, customerId, backPathUrlParam,
}) => (

  <>
    {
tickets?.length === 0 ? null
  : (
    <C.Wrapper>
      <Heading mb="1rem" variant="h5">
        {title}
      </Heading>

      <C.TicketList>
        {tickets?.map(({
          priority, subject, created, status, id,
        }) => (

          <TicketListItem
            key={id}
            customerId={customerId}
            priority={priority}
            subject={subject}
            created={created}
            status={status}
            id={id}
            backPathUrlParam={backPathUrlParam}
          />

        ))}
      </C.TicketList>
    </C.Wrapper>
  )
  }
  </>
);

EntityGroupListItem.propTypes = propTypes;
EntityGroupListItem.defaultProps = defaultProps;

export default EntityGroupListItem;
