import { LIST_FILTER_SET } from '../model';

const actionTypes = {
  LIST_FILTER_SET,
};

const actions = {
  onUpdateListFilter: (payload) => ({
    type: actionTypes.LIST_FILTER_SET,
    meta: { listFilter: { ...payload } },
  }),
};

export const getFilterListConnectMethods = (filterKey) => ({

  mapStateToProps: (state) => ({
    listFilter: state?.model?.listFilter?.[filterKey] || null,
  }),

  mapDispatchToProps: (dispatch) => ({
    onUpdateListFilter: (payload) => dispatch(actions.onUpdateListFilter(payload)),
  }),

});

export default {
  getFilterListConnectMethods,
};
