import styled from 'styled-components';

export { IconWrapper } from '../AlertStatus/AlertStatus.styled';

export const Wrapper = styled.div`
`;

export const PopOverTop = styled.div`
  padding: 1rem;
  padding-bottom: 0;
  border-bottom: ${({ colors }) => `1px solid ${colors?.gray?.['200']}`};
`;

export const PopOverBody = styled.div`
  padding: 1rem;
  `;

export const PopOverBottom = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    border-top: ${({ colors }) => `1px solid ${colors?.gray?.['200']}`};
`;

export const MetaGroup = styled.div`
  margin-bottom: 1rem;
`;

export const Tab = styled.div`
  padding: 0 5px;
  border-radius: 5px 5px 0 0;
  border-color:  ${({ colors }) => `${colors?.gray?.['200']}`};
  border-style: solid;
  border-width: 1px;
  border-bottom-color: ${({ isActive, colors }) => (isActive ? 'white' : `${colors?.gray?.['200']}`)};
  background-color: ${({ isActive, colors }) => (isActive ? 'white' : `${colors?.gray?.['200']}`)};
  height: max-content;
  `;

export const TabButtonInLineStyles = {
  paddingInline: 0,
  height: 'min-content',
  fontFamily: 'Poppins',
  fontSize: '10px',
  letterSpacing: 0.8,
  textTransform: 'uppercase',
  fontWeight: 'normal',
};
