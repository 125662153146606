import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'TagGroupOverviewList',
  sv: {
    search: 'sök',
    title: 'Pågående incidenter',
    customer: 'Kund',
    resourceGroup: 'Resursgrupp',
    entityType: 'Entitetstyp',
    tags: 'Taggar',
    name: 'Namn',
    changeCustomer: 'Ändra kund',
    changeResourceGroup: 'Ändra resursgrupp',
    filterDrawerTitle: 'Filtrera sökning',
    prioritySortLabel: 'Prioritet',
    noData: 'Ingen data kunde hittas med de valda filterinställningarna.',
    configNeeded: 'För att visa denna data måste du konfigurera din Azure miljö. Kontakta din TCA för hjälp.',
  },
  en: {
    search: 'search',
    title: 'Ongoing incidents',
    customer: 'Customer',
    resourceGroup: 'Recource group',
    entityType: 'Entity type',
    changeCustomer: 'Change customer',
    changeResourceGroup: 'Change resource group',
    filterDrawerTitle: 'Filter search',
    tags: 'Tags',
    name: 'Namn',
    prioritySortLabel: 'Priority',
    noData: 'No data could be found with the selected filter settings.',
    configNeeded: 'To show this data you need to configure your Azure environment. Contact your TCA for help.',
  },
});
