import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '~ui/Button';
import * as List from '~ui/List';
import { Heading } from '@chakra-ui/react';
import { mdiArrowRight } from '@mdi/js';
import * as C from '../Main.styled';
import translation from '../Main.translation';

const { t } = translation;

const getRow = ({ array, label }) => {
  if (array.length === 0) {
    return [{
      label,
      value: '-',
    }];
  }

  return array.map((entry, index) => ({
    label: index === 0 ? label : '',
    value: (
      <C.BorderedItem key={entry.name}>
        <p>{`${t('name')}: ${entry.name || '-'}`}</p>
        <p>{`${t('intervalInMinutes')}: ${entry.interval_in_minutes || '-'}`}</p>
      </C.BorderedItem>
    ),
  }));
};

const WebTests = ({
  vmConfig, entityId, customerId, canWrite,
}) => (
  <C.Type>
    <Heading as="h2">{t('multiStepWebTests')}</Heading>
    <List.Primary
      rows={[
        ...getRow({ label: t('webTest'), array: vmConfig.web_tests }),
        {
          row: canWrite && (
            <Button
              mt="1rem"
              p={0}
              variant="ghost"
              color="asurgent.500"
              rightIcon={mdiArrowRight}
              internalLink={`/my-environment/${customerId}/${entityId}/cloudops-vmagent/edit/${vmConfig.id}/multiStepWebTests`}
            >
              {t('editWebTests')}
            </Button>
          ),
        },
      ]}
    />
  </C.Type>
);
WebTests.propTypes = {
  canWrite: PropTypes.bool.isRequired,
  vmConfig: PropTypes.instanceOf(Object),
  customerId: PropTypes.string.isRequired,
  entityId:
        PropTypes.string.isRequired,
};
WebTests.defaultProps = { vmConfig: {} };

export default WebTests;
