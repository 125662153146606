import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'TicketDetailsComment',
  sv: {
    placeholder: 'Lämna en kommentar...',
    btnTitle: 'Kommentera',
    formFileLabel: 'Bifoga fil',
    success: 'Kommentar skapad',
    fail: 'Misslyckades att spara kommentar',
  },
  en: {
    placeholder: 'Leave a comment...',
    btnTitle: 'Comment',
    formFileLabel: 'Add attachment',
    success: 'Success! Comment submitted',
    fail: 'Failed to submit comment',
  },
});
