import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Team.Main',
  sv: {
    peopleInTeam: 'personer i ditt team',
    teamDesc: 'Det här är dina teammedlemmar på Asurgent',
    asurgentTeam: 'Asurgent team',
    noTeam: 'Kunde inte hitta någon information om dina teammedlemmar',
    back: 'Tillbaks',
    viewLess: 'Visa färre',
    viewAll: 'Visa alla',
  },
  en: {
    peopleInTeam: 'people in your team',
    teamDesc: 'These are your team members at Asurgent',
    asurgentTeam: 'Asurgent team',
    noTeam: 'Could not find any information about your team members',
    back: 'Back',
    viewLess: 'View less',
    viewAll: 'View all',
  },
});
