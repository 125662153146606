import {
  take,
  call,
  cancel,
  fork,
} from 'redux-saga/effects';
import moment from 'moment';
import * as t from './actionTypes';
import getMappedServicesForEntityService from './services/getMappedServicesForEntity.service';
import { getCost, getMonthlyCost } from '../../../CloudOpsService/scenes/Main/helpers';

const getActiveService = (servicesList) => {
  const list = (servicesList || [])
    .filter((service) => moment(service.valid_to) > moment())
    .map((service) => ({
      name: service.cloudops_service_name,
      description: service.cloudops_service_description,
      customBilling: service.custom_billing,
      isFree: service.price === 0 && !service.custom_billing,
      weekEnds: service.week_ends,
      weekDays: service.week_days,
      hourPrice: getCost(service),
      monthlyPrice: getMonthlyCost(service),
    }));

  return list;
};

function* getMapedServices(meta) {
  const { setServiceList, customerId, entityId } = meta;
  const payload = { customerId, entityId };

  const { error, success } = yield call(getMappedServicesForEntityService, payload);

  if (!error) {
    const service = yield call(getActiveService, success);
    setServiceList(service);
  }
}

export default function* rootWatcher() {
  while (true) {
    const { meta } = yield take(t.MOUNT);

    const tasks = [
      yield fork(getMapedServices, meta),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
