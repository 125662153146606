import Api from '../../../../../../../lib/api';

const getTicket = async ({ customerId, ticketId }) => {
  const path = `customer/${customerId}/ticket/${ticketId}`;

  return Api.get(path)
    .then((success) => ({ success }))
    .catch((error) => {
      if (error?.response?.data) {
        return { error: error.response.data };
      }
      return { error };
    });
};

export default getTicket;
