import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@chakra-ui/react';
import * as C from './Metadata.styled';

const propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  isEmpty: PropTypes.bool,
};

const defaultProps = {
  title: '',
  value: '',
  children: null,
  isEmpty: false,
};

const Metadata = (props) => {
  const { colors } = useTheme();

  const {
    title,
    value,
    children,
    isEmpty = false,
  } = props;

  return (
    <C.Metadata colors={colors}>
      <div className="title">{title}</div>
      { isEmpty === false && (
        <div className="value">
            {value || children || <i>undefined</i>}
        </div>
      )}
      { isEmpty === true
        && <div className="value"><i>undefined</i></div> }
    </C.Metadata>
  );
};

Metadata.propTypes = propTypes;
Metadata.defaultProps = defaultProps;

export default Metadata;
