import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Button } from '~ui/Button';
import {
  Tooltip, Text, Box, Divider, HStack, Tag, Wrap, Heading, useTheme,
} from '@chakra-ui/react';
import MdiIcon from '@mdi/react';
import { mdiChevronRight, mdiExclamation } from '@mdi/js';
import { useTemplateIsAvaliableInWorkspace } from '../../../../lib/services/analyticRules';

import * as C from './TemplateCard.styled';
import translation from './TemplateCard.translation';
import { handleTags } from '../handleTags';

const { t } = translation;

const propTypes = {
  navigationLink: PropTypes.string,
  analyticRuleName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  template: PropTypes.instanceOf(Object).isRequired,
  templateConnectors: PropTypes.instanceOf(Array),
  tactics: PropTypes.instanceOf(Array),
};
const defaultProps = {
  navigationLink: '',
  templateConnectors: [],
  tactics: [],
};

const titleStyle = { display: 'flex', flexOrientation: 'row', columnGap: '0.5rem' };

const TemplateCard = ({
  template,
  templateConnectors,
  navigationLink,
  analyticRuleName,
  description,
  tactics,
}) => {
  const { colors, breakpoints } = useTheme();

  const { params } = useRouteMatch();
  const { customerId, workspaceId } = params;
  const hasAllDataConnectors = useTemplateIsAvaliableInWorkspace(
    customerId,
    workspaceId,
    template,
  );

  return (
    <C.Card colors={colors} breakpoints={breakpoints}>
      <C.Content>
        <Heading as="h3" textTransform="capitalize" style={titleStyle}>
          {!hasAllDataConnectors && (

            <Tooltip
              hasArrow
              label={(
                <Box>
                  <Text>{t('connectorWarning')}</Text>
                  <Divider mt={2} mb={2} />
                  <HStack spacing={2} mb={2}>
                    {templateConnectors?.map((tC) => <Tag key={tC} bg="#f5edd8">{tC}</Tag>)}
                  </HStack>
                </Box>
                )}
            >
              <C.CircleWrapper>
                <C.Circle colors={colors}>
                  <MdiIcon path={mdiExclamation} />
                </C.Circle>
              </C.CircleWrapper>
            </Tooltip>

          )}
          {analyticRuleName}
        </Heading>
        <Text fontSize="sm" style={{ color: 'theme.gray600' }}>{description}</Text>

        <Box>
          <Text fontSize={12} mb={2}>{t('tactics')}</Text>
          <Wrap spacing={2} mb={2}>
            {handleTags({ items: tactics, maxLength: 5 })?.map((tactic) => <Tag key={tactic} bg="#f5edd8">{tactic}</Tag>)}
          </Wrap>
        </Box>

      </C.Content>

      <C.Navigator colors={colors} breakpoints={breakpoints}>
        <Button
          variant="ghost"
          internalLink={navigationLink}
          rightIcon={mdiChevronRight}
        />
      </C.Navigator>
    </C.Card>
  );
};

TemplateCard.propTypes = propTypes;
TemplateCard.defaultProps = defaultProps;

export default TemplateCard;
