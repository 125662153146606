import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AppMonitor.TestRuns',
  sv: {
    testName: 'Testnamn',
    entityName: 'Entitetsnamn',
    timestamp: 'Tidkod',
    date: 'Datum',
    elapsedTime: 'Kördes i tid',
    succeeded: 'Lyckades',
    failed: 'Misslyckades',
    testSucceeded: 'Testkörning lyckades',
    testFailed: 'Testkörning misslyckades',
    emptystate: 'Kunde inte hitta några testkörningar',
    searchLabel: 'Sök efter testkörningar',
    ranOn: 'Kördes på',
    testSteps: 'Teststeg',
    name: 'Namn',

    filterDrawerTitle: 'Filtrera testkörningar',
    changeSucceeded: 'Ändra lyckade/misslyckade testkörningar',

    afterDate: 'Efter',
    beforeDate: 'Före',
  },
  en: {
    testName: 'Test name',
    entityName: 'Entity name',
    timestamp: 'Timestamp',
    date: 'Date',
    elapsedTime: 'Elapsed time',
    succeeded: 'Succeeded',
    failed: 'Failed',
    testSucceeded: 'Test run succeeded',
    testFailed: 'Test run failed',
    emptystate: 'Could not find any test runs',
    searchLabel: 'Search for test runs',
    ranOn: 'Ran on',
    testSteps: 'Test steps',
    name: 'Name',

    filterDrawerTitle: 'Filter test runs',
    changeSucceeded: 'Change failed/succeeded test runs',

    afterDate: 'After',
    beforeDate: 'Before',
  },
});
