import {
  take,
  call,
  select,
  fork,
  cancel,
} from 'redux-saga/effects';
import * as t from './actionTypes';
import getVMConfig from './services/getVMConfig.service';
import getCloudopsMappings from './services/getCloudopsMappings.service';
import getIsAlive from './services/getIsAlive.service';
import putVMConfig from './services/putVMConfig.service';

const getEntityMeta = ({ model: { entity } }) => ({
  entityId: entity.id,
  customerId: entity.customer_id,
});

function* getIsAliveAction({ setHeartbeat, setLoadingHeartbeat }) {
  setLoadingHeartbeat(true);
  const { customerId, entityId } = yield select(getEntityMeta);
  const { success } = yield call(getIsAlive, { customerId, entityId });

  if (success) {
    const { result } = success;
    if (result.length > 0) {
      setHeartbeat(result[0]);
    } else {
      setHeartbeat(null);
    }
  }
  setLoadingHeartbeat(false);
}

function* loadVMConfigAction({ setVMConfig, setHasCloudOps, setLoadingConfig }) {
  setLoadingConfig(true);
  const { customerId, entityId } = yield select(getEntityMeta);
  const { success } = yield call(getVMConfig, { customerId, entityId });

  if (success) {
    setVMConfig(success);
  } else {
    // If no config, check if it is mapped or not to present different texts
    const { success: mappingsSuccess } = yield call(getCloudopsMappings, { customerId, entityId });
    if (mappingsSuccess) {
      setHasCloudOps(mappingsSuccess?.length > 0);
    }
    setVMConfig(null);
  }
  setLoadingConfig(false);
}

function* updateAgentConfigTask({ onFail, onSuccess }) {
  while (true) {
    const { meta } = yield take(t.REDEPLOY_AGENT);
    const { customerId, entityId } = yield select(getEntityMeta);

    const { success } = yield call(putVMConfig, { customerId, entityId, payload: meta });

    if (success) {
      onSuccess();
    } else {
      onFail();
    }
  }
}

export default function* rootWatcher() {
  while (true) {
    const { meta } = yield take(t.MOUNT);
    const {
      setLoadingConfig,
      setLoadingHeartbeat,
      setVMConfig,
      setHasCloudOps,
      setHeartbeat,
      onFail,
      onSuccess,
    } = meta;

    const tasks = [
      yield fork(loadVMConfigAction, { setVMConfig, setHasCloudOps, setLoadingConfig }),
      yield fork(getIsAliveAction, { setHeartbeat, setLoadingHeartbeat }),
      yield fork(updateAgentConfigTask, { onFail, onSuccess }),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
