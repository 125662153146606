import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Security.ListWorkspaces',
  sv: {
    emptystate: 'Inget resultat för',
    alertRule: 'Azure Sentinel Larmregel',
    customer: 'Kund',
    search: 'Sök bland sentinel workspaces',

    searchPlaceholder: 'Sök Sentinel Workspaces...',
    filterDrawerTitle: 'Filtrera Sentinel workspaces',
    customerDisplayName: 'Kund',
    workspace: 'Workspace',
  },
  en: {
    emptystate: 'No result for',
    alertRule: 'Azure Sentinel Alert Rule',
    customer: 'Customer',
    search: 'Search for sentinel workspaces',

    searchPlaceholder: 'Search Sentinel Workspaces...',
    filterDrawerTitle: 'Filter Sentinel Workspaces',
    customerDisplayName: 'Customer',
    workspace: 'Workspace',
  },
});
