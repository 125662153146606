import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  fill: PropTypes.string.isRequired,
};

const defaultProps = {};

const NotApplicableIcon = ({ fill }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill || 'currentColor'} d="M5.25 15.75L11.175 2.25H12.75L6.825 15.75H5.25Z" />
  </svg>
);

NotApplicableIcon.propTypes = propTypes;
NotApplicableIcon.defaultProps = defaultProps;

export default NotApplicableIcon;
