import React from 'react';
import Navigation from './components/Navigation';

const withNavigation = (Component) => () => (
  <Navigation>
    {
      ({ isGlobalAdmin }) => (<Component isGlobalAdmin={isGlobalAdmin} />)
    }
  </Navigation>
);

export default withNavigation;
