import {
  take,
  call,
  cancel,
  takeEvery,
} from 'redux-saga/effects';
import * as t from './actionTypes';

import getMetricDefinitionsService from './services/getMetricDefinitions.service';
import createAlertRuleService from './services/createAlertRule.service';

import {
  mapMetricDefinitions,
  parseFormDataToPayload,
  handleFormErrors,
  defaultValues,
} from '../../helpers';

function* loadMetricDefinitionsAction({ setMetricDefinitions, ...payload }) {
  const { success: definitions } = yield call(getMetricDefinitionsService, payload);

  if (definitions) {
    const result = yield call(mapMetricDefinitions, definitions.result);
    const metricOptions = result.map(({ value }) => value);

    setMetricDefinitions(metricOptions.map((item) => ({ value: item, label: item })));
  } else {
    setMetricDefinitions([]);
  }
}

function* createAlertRuleTask({ customerId, entityId }, { meta }) {
  const {
    values,
    onError,
    onSuccess,
  } = meta;

  const payload = yield call(parseFormDataToPayload, { values, defaultValues, enabled: 1 });

  const { success, error } = yield call(createAlertRuleService, { customerId, entityId, payload });
  if (success) {
    yield call(onSuccess);
  } else if (error) {
    yield call(onError, handleFormErrors(error, payload));
  }
}

export default function* rootWatcher() {
  while (true) {
    const { meta } = yield take(t.MOUNT);
    const {
      setLoading,
      setMetricDefinitions,
      entityId,
      customerId,
    } = meta;

    setLoading(true);
    yield call(loadMetricDefinitionsAction, { setMetricDefinitions, entityId, customerId });
    setLoading(false);

    const tasks = [
      yield takeEvery(t.CREATE_ALERTRULE, createAlertRuleTask, { customerId, entityId }),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
