import {
  take,
  call,
  cancel,
  fork,
} from 'redux-saga/effects';
import * as t from './actionTypes';
import getTicketsService from './services/getTicket.service';
import putTicketsService from './services/putTicket.service';

function* updateTicket({
  customerId, ticketId, onSuccess, onFail,
}) {
  while (true) {
    const { meta } = yield take(t.UPDATE_TICKET);

    const { payload, setIsLoading, setTouched } = meta;
    setIsLoading(true);
    const { success } = yield call(putTicketsService, { customerId, ticketId, payload });
    if (success) {
      setTouched(false);
      onSuccess();
    } else {
      onFail();
    }
    setIsLoading(false);
  }
}

function* loadTicket({ customerId, ticketId, setTicket }) {
  const { success } = yield call(getTicketsService, { customerId, ticketId });

  if (success) {
    setTicket(success);
  }
}

export default function* rootWatcher() {
  while (true) {
    const {
      meta: {
        customerId, ticketId, setTicket, onSuccess, onFail,
      },
    } = yield take(t.MOUNT);

    const tasks = [
      yield fork(loadTicket, { customerId, ticketId, setTicket }),
      yield fork(updateTicket, {
        customerId, ticketId, onSuccess, onFail,
      }),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
