import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Servicewindow.Edit.CustomerPicker',
  sv: {
    header: 'Kund',
    text: 'Välj den kund du vill att servicefönstret ska registreras på.',

    customer: 'Kund',
    customerPlaceholder: 'Ange en kund',
  },
  en: {
    header: 'Customer',
    text: 'Choose the customer you want the service window to be attached to.',

    customer: 'Customer',
    customerPlaceholder: 'Choose a customer',
  },
});
