import Api from '../../../../../../../lib/api';

const createAutoActionsForEntity = async ({
  customerId,
  entityId,
  model,
}) => {
  const path = `customer/${customerId}/entity/${entityId}/autoactionprocessmodel/`;
  const { id, ...rest } = model;

  return Api.post(path, rest)
    .then((success) => ({ success }))
    .catch((error) => {
      if (error?.response?.data) {
        return { error: error.response.data };
      }

      return { error };
    });
};

export default createAutoActionsForEntity;
