import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  fill: PropTypes.string.isRequired,
};

const defaultProps = {};

const UrgentIcon = ({ fill }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill || 'currentColor'} d="M7.33333 10.0013H4L8.66667 0.667969V6.0013H12L7.33333 15.3346V10.0013Z" />
  </svg>
);

UrgentIcon.propTypes = propTypes;
UrgentIcon.defaultProps = defaultProps;

export default UrgentIcon;
