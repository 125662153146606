import React from 'react';
import PropTypes from 'prop-types';

import * as C from './EntityGroups.styled';
import EntityGroupListItem from './components/EntityGroupListItem';

const propTypes = {
  entities: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  customerId: PropTypes.string.isRequired,
  backPathUrlParam: PropTypes.string.isRequired,
};

const defaultProps = {
  entities: [],

};

const EntityGroups = ({ entities, customerId, backPathUrlParam }) => (
  <C.Wrapper>
    {entities?.map(({
      id, name, tickets, type,
    }) => (
      <EntityGroupListItem
        title={name}
        tickets={tickets}
        customerId={customerId}
        type={type}
        key={id}
        backPathUrlParam={backPathUrlParam}
      />
    ))}
  </C.Wrapper>
);

EntityGroups.propTypes = propTypes;
EntityGroups.defaultProps = defaultProps;

export default EntityGroups;
