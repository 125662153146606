import React from 'react';
import PropTypes from 'prop-types';
import {
  Text, useTheme,
} from '@chakra-ui/react';
import { getBackPathUrlParam } from '~lib/hooks/useBackPath';
import * as C from './List.styled';
import ListItem from '../ListItem';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  customerId: PropTypes.string.isRequired,
};

const defaultProps = {};

const List = ({ items, columns, customerId }) => {
  const { colors } = useTheme();

  const backPathParemter = getBackPathUrlParam('Security hours');

  return (

    <C.Wrapper>
      <C.Table colors={colors}>
        <thead>
          <C.RowHead>
            {columns.map((column, index) => (
            // eslint-disable-next-line react/no-array-index-key
              <td key={index}>
                <C.Cell isLast={index === columns.length - 1}>
                  <Text width="max-content" fontWeight="bold" margin="0">{column.label}</Text>
                </C.Cell>
              </td>
            ))}
          </C.RowHead>
        </thead>
        <tbody>
          {items?.map((data) => (
            <ListItem
              key={data.renderKey}
              id={data.ticket_id}
              hours={data?.hours_spent}
              ticketSubject={data?.ticket_subject}
              group={data?.group}
              customerId={customerId}
              backPathParam={backPathParemter}
            />
          ))}
        </tbody>

      </C.Table>

    </C.Wrapper>
  );
};

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default List;
