import queryString from 'query-string';

export function sum(...values) {
  return values.reduce((acc, val) => acc + val, 0);
}

export function getUpdatedQueryString(key, state, search) {
  const query = {
    ...queryString.parse(search),
    [key]: window.btoa(JSON.stringify(state)),
  };

  return `?${queryString.stringify(query)}`;
}
