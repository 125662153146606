import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AppMonitor.List',
  sv: {
    name: 'Testnamn',
    lastRunTimeStamp: 'Senaste körningen',

    filterDrawerTitle: 'Filtrera tester',
    customer: 'Kund',
    changeCustomer: 'Ändra kund',
  },
  en: {
    name: 'Test name',
    lastRunTimeStamp: 'Last test run',

    filterDrawerTitle: 'Filter tests',
    customer: 'Customer',
    changeCustomer: 'Change customer',
  },
});
