import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'ServiceWindow.Step2',
  sv: {
    header: 'Resurser och resursgrupper',
    text: 'Välj de resurser (du kan välja flera) som du vill ska vara inaktiverade under hela varaktigheten av servicefönstret.',
    resourceGroup: 'Resursgrupp',
    name: 'Namn',
    cloudopsActive: 'CloudOps Aktivt',
    customer: 'Kund',
    entityType: 'Resurstyp',
    container: 'Container',
    add: 'Lägg till',
    remove: 'Ta bort',

    selectCustomerFirst: 'Välj en kund först - sedan kommer resurserna visas här',
  },
  en: {
    header: 'Entities and resource groups',
    text: 'Select the entities (you can add multiple) that you want disabled during the total duration of the service window.',
    resourceGroup: 'Resource group',
    name: 'Name',
    cloudopsActive: 'CloudOps Active',
    customer: 'Customer',
    entityType: 'Resource type',
    container: 'Container',
    add: 'Add',
    remove: 'Remove',

    selectCustomerFirst: 'Select a customer first - then resources will show here',
  },
});
