import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  fill: PropTypes.string.isRequired,
};

const defaultProps = {};

const AllGoodIcon = ({ fill }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill || 'currentColor'} d="M5.50013 11.4768L2.02513 8.00182L0.841797 9.17682L5.50013 13.8352L15.5001 3.83516L14.3251 2.66016L5.50013 11.4768Z" />
  </svg>
);

AllGoodIcon.propTypes = propTypes;
AllGoodIcon.defaultProps = defaultProps;

export default AllGoodIcon;
