/* eslint-disable camelcase */
import React, { useMemo, memo } from 'react';
import moment from 'moment';
import MdiIcon from '@mdi/react';
import PropTypes from 'prop-types';
import { mdiCheck, mdiCancel, mdiUpdate } from '@mdi/js';
import { Button } from '~ui/Button';
import {
  Text, useToast, useTheme,
} from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import * as C from '../Steps.styled';
import translation from './Summary.translation';
import SelectedEntities from './SelectedEntities';
import { useUserContext } from '../../../../../../lib/services/userContext';
import { useUpdateSW, useCreateSW } from '../../../../../../lib/services/serviceWindow';

const { t } = translation;

const getDurationText = (start = '', durationInSeconds = 0) => {
  const date = moment(start).format('YYYY-MM-DD');
  const hours = moment(start).format('HH:mm');
  const minutes = Math.floor(durationInSeconds / 60);

  return `${t('startsOn')} ${date}
  ${t('at')} ${hours} ${t('andLastsFor')} ${minutes} ${t('minutes')}`;
};

const errorMessage = (response) => `${response?.data?.errors[0]?.property} - ${response?.data?.errors[0]?.message}` || null;

const Summary = ({
  isEditMode,
  entities,
  serviceWindow,
  isReady,
  setEntities,
  customerPickerId,
}) => {
  const { colors, breakpoints } = useTheme();

  const toast = useToast();
  const { params } = useRouteMatch();
  const { swId, customerId } = params;
  const history = useHistory();

  const { data: userContext } = useUserContext();

  const onSuccess = (toastText) => {
    toast({
      description: toastText,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    history.goBack();
  };
  const onError = ({ response }) => {
    const error = errorMessage(response);
    toast({
      description: error || t('somethingWrong'),
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  };

  const updateMutation = useUpdateSW({
    onSuccess: () => onSuccess('updateSuccessful'),
    onError,
  });
  const deactivateMutation = useUpdateSW({
    onSuccess: () => onSuccess('deactivationSuccessful'),
    onError,
  });
  const createMutation = useCreateSW({
    onSuccess: () => onSuccess('createSuccessful'),
    onError,
  });

  const duration = useMemo(() => {
    if (serviceWindow?.schedule) {
      return getDurationText(
        serviceWindow?.schedule?.start,
        serviceWindow?.schedule?.duration_in_seconds,
      );
    }
    return null;
  }, [serviceWindow]);

  const handleUpdateCreate = () => {
    const payload = {
      ...serviceWindow,
      entity_ids: entities.map((ent) => ent.id),
      enabled: true,
      entity_type_metric_name_mappings:
      serviceWindow?.useMetrics ? serviceWindow.entity_type_metric_name_mappings : [],
    };

    delete payload.useMetrics;

    if (isEditMode) {
      Object.assign(payload, { customer_id: customerId });

      updateMutation
        .mutateAsync({ customerId, swId, payload });
    } else {
      // try with customerpicker (if global) else from url
      const cId = customerPickerId || customerId;
      Object.assign(payload, {
        customer_id: cId,
        requested_by: userContext?.email || '',
      });

      createMutation
        .mutateAsync({ customerId: cId, payload });
    }
  };

  const handleDeactivate = () => {
    const payload = {
      ...serviceWindow,
      enabled: false,
    };

    deactivateMutation
      .mutateAsync({ customerId, swId, payload });
  };

  return (
    <C.StepContainer
      isReady={isReady}
      breakpoints={breakpoints}
    >

      <C.Step colors={colors}><MdiIcon path={mdiCheck} size={0.75} /></C.Step>
      <C.StepTitle>{t('header')}</C.StepTitle>
      <C.Content>
        <Text fontWeight="600">{t('reason')}</Text>
        <Text>{serviceWindow?.reason || t('noReason')}</Text>
        <C.Divider colors={colors} />

        <Text fontWeight="600">{t('whenAndHowOften')}</Text>
        <Text>{duration}</Text>

        {serviceWindow?.schedule?.cron_expression && serviceWindow?.schedule?.cron_expression !== '' && (
        <>
          <Text>
            {`${t('repeatsAccordingToCron')} ${serviceWindow?.schedule?.cron_expression}`}
          </Text>
        </>
        )}

        {serviceWindow?.schedule?.cron_expression_description && serviceWindow?.schedule?.cron_expression_description !== '' && (
          <>
            <Text>
              {`${t('cronDescription')}: ${serviceWindow?.schedule?.cron_expression_description} (UTC)`}
            </Text>
          </>
        )}

        <C.Divider />

        <C.EntitySummation>
          <Text fontWeight="600">{`${t('entitiesAndResources')} (${entities.length})`}</Text>

          {entities.length > 0 && (
            <C.RemoveBtn variant="ghost" colors={colors} onClick={() => setEntities([])}>
              {t('removeAll')}
            </C.RemoveBtn>
          )}
        </C.EntitySummation>

        {entities.length > 0 && (<SelectedEntities />)}

        <Text style={{ margin: '2rem 0' }}>
          {t(`text`)}
        </Text>

        <C.ButtonContainer>

          <Button
            maxWidth="231"
            disabled={!isReady}
            leftIcon={mdiUpdate}
            onClick={handleUpdateCreate}
          >
            {isEditMode ? t('update') : t('create')}
          </Button>

          {isEditMode && (
            <Button
              bg="ruby.900"
              leftIcon={mdiCancel}
              onClick={handleDeactivate}
            >
              {t('disable')}
            </Button>
          )}
        </C.ButtonContainer>

      </C.Content>
    </C.StepContainer>
  );
};

Summary.propTypes = {
  isEditMode: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  serviceWindow: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  entities: PropTypes.array.isRequired,
  isReady: PropTypes.bool.isRequired,
  setEntities: PropTypes.func.isRequired,
  customerPickerId: PropTypes.string,
};
Summary.defaultProps = {
  isEditMode: false,
  serviceWindow: null,
  customerPickerId: '',
};

export default memo(Summary);
