import { useMutation } from 'react-query';
import Api from '../api';

export const searchCustomers = async ({ payload }) => Api.post('/customer/search', payload);

export const useSaveCustomerMutation = (config) => {
  const saveCustomer = async (payload) => Api.put(`/customer`, payload);

  return useMutation(saveCustomer, { ...config });
};

export const getCustomer = async (customerId) => Api.get(
  `customer/${customerId}`,
  null,
  null,
  {
    headers: {
      'Cache-Control': 'No-Cache',
    },
  },
);
