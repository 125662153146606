import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'CloudOpsServiceEntity',
  sv: {
    deactivateService: 'Avaktivera',
    editService: 'Editera',
    description: 'Beskrivning',
    activeTime: 'Aktiv tid på dagen',
    activeDays: 'Aktiv på dagar',
    hourlyPrice: 'Timpris',
    monthlyPrice: 'Månadspris',
    free: 'Gratis',
    custom: 'Specialanpassat',
  },
  en: {
    deactivateService: 'Deactivate',
    editService: 'Edit',
    description: 'Description',
    activeTime: 'Active time of day',
    activeDays: 'Active on days',
    hourlyPrice: 'Hourly price',
    monthlyPrice: 'Monthly price',
    free: 'Free',
    custom: 'Custom pricing',
  },
});
