import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as List from '~ui/List';
import * as Moment from '~ui/Moment';
import { Button } from '~ui/Button';
import { Heading } from '@chakra-ui/react';
import moment from 'moment';
import * as C from '../Main.styled';
import translation from '../Main.translation';

const { t } = translation;

const getRow = ({ array, label, showAll }) => {
  if (array.length === 0) {
    return [{
      label,
      value: '-',
    }];
  }

  const timeSorted = array.sort((a, b) => moment(b[0]) - moment(a[0]));

  return [...timeSorted
    .slice(0, showAll ? timeSorted.length : 1)
    .map((entry, index) => ({
      label: index === 0 ? label : '',
      value: (
        <C.BorderedItem key={entry.name}>
          <C.FitContent><Moment.DateTime timestamp={entry[0]} /></C.FitContent>
          <p>{entry[1]}</p>
        </C.BorderedItem>
      ),
    })),
  ];
};

const Deploy = ({ vmConfig }) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <C.Type>
      <Heading as="h2">{t('deploy')}</Heading>
      <List.Primary rows={[
        { label: t('status'), value: <C.Capitalized>{vmConfig.deploy_status}</C.Capitalized> || '-' },
        ...getRow({ array: Object.entries(vmConfig.deploy_status_message_history), label: t('statusMessage'), showAll }),
        Object.keys(vmConfig.deploy_status_message_history).length > 1 && {
          label: '',
          value: (
            <Button variant="ghost" color="asurgent.500" p={0} onClick={() => setShowAll(!showAll)}>
              {showAll ? t('collapse') : t('showMore')}
            </Button>),
        },
        {
          label: t('timestamp'),
          value: vmConfig.deploy_status_timestamp ? <Moment.DateTime timestamp={vmConfig.deploy_status_timestamp} /> : '-',
        },

        {
          label: t('operationStatusURI'),
          value: (vmConfig.deploy_operation_status_uri
            ? (
              <Button
                variant="link"
                whiteSpace="break-spaces"
                textAlign="left"
              >
                {vmConfig.deploy_operation_status_uri}
              </Button>
            )
            : '-'),
        },
      ]}
      />
    </C.Type>
  );
};
Deploy.propTypes = { vmConfig: PropTypes.instanceOf(Object) };
Deploy.defaultProps = { vmConfig: {} };

export default Deploy;
