import OverviewSaga from './scenes/Overview/saga';
// import TagGroupOverviewSaga from './scenes/TagGroupOverview/saga';

import OverviewCardModuleSaga from './modules/CustomerCard/saga';
import MetricCardModuleSaga from './modules/MetricCard/saga';

export default [
  OverviewSaga,
  // TagGroupOverviewSaga,
  MetricCardModuleSaga,
  OverviewCardModuleSaga,
];
