import styled from 'styled-components';

export const TimespanBorder = styled.div`
    border-bottom: ${({ colors }) => `1px solid ${colors?.gray?.['300']}`}; 
    flex-grow: 1; 
    align-items: center; 
    margin: 0 1.25rem;
`;

export const TimespanContainer = styled.div`
    display: flex; 
    align-items: center;
    p {
        margin: 1rem 0;
    }
    cursor: pointer;
`;
