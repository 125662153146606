import Api from '../api';

export const searchEntities = async ({
  payload,
  isGlobalAdmin,
  customerId,
  includeStale = false,
}) => {
  let path = `customer/${customerId}/entity/search`;

  if (isGlobalAdmin) {
    path = '/entity/search';
  }

  const filterPath = `${path}?includeStale=${includeStale}`;
  return Api.post(filterPath, payload);
};
