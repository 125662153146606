import Api from '../../../../../lib/api';

const searchTickets = async (payload) => {
  const path = '/ticket/search';

  return Api.post(path, payload)
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default searchTickets;
