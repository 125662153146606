import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'EntityGroupListItem',
  sv: {
    minutesAgo: 'minuter sedan',
    hoursAgo: 'timmar sedan',
    yesterday: 'igår',
    daysAgo: 'dagar sedan',
    priority0: 'Allt bra',
    priority1: 'Låg',
    priority2: 'Medium',
    priority3: 'Hög',
    priority4: 'Brådskande',
  },
  en: {
    minutesAgo: 'minutes ago',
    hoursAgo: 'hours ago',
    yesterday: 'igår',
    daysAgo: 'days ago',
    priority0: 'All good',
    priority1: 'Low',
    priority2: 'Medium',
    priority3: 'High',
    priority4: 'Urgent',
  },
});
