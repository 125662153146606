import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'MonthPicker',
  sv: {
    monthIndex0: 'Januari',
    monthIndex1: 'Februari',
    monthIndex2: 'Mars',
    monthIndex3: 'April',
    monthIndex4: 'Maj',
    monthIndex5: 'Juni',
    monthIndex6: 'Juli',
    monthIndex7: 'Augusti',
    monthIndex8: 'September',
    monthIndex9: 'Oktober',
    monthIndex10: 'November',
    monthIndex11: 'December',
  },
  en: {
    monthIndex0: 'January',
    monthIndex1: 'February',
    monthIndex2: 'March',
    monthIndex3: 'April',
    monthIndex4: 'May',
    monthIndex5: 'June',
    monthIndex6: 'July',
    monthIndex7: 'August',
    monthIndex8: 'September',
    monthIndex9: 'October',
    monthIndex10: 'November',
    monthIndex11: 'December',
  },
});
