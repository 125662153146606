import React from 'react';
import { Button } from '~ui/Button';
import * as Svg from '~ui/Icons';
import { useHistory } from 'react-router-dom';

import translation from './PageNotFound.translation';

import * as C from './PageNotFound.styled';

const PageNotFound = () => {
  const history = useHistory();
  const year = new Date().getFullYear();
  const { t } = translation;

  return (
    <C.Wrapper>
      <C.StyledErrorContent>
        <h1>{t('title')}</h1>
        <h2>{ t('content')}</h2>

        <Button variant="outline" onClick={() => history.push('/dashboard')}>
          {t('buttonTitle')}
        </Button>

      </C.StyledErrorContent>
      <C.StyledFooter>
        <Svg.IconAsurgent height="3rem" width="3rem" />
        <small>
          Asurgent
          {' '}
          {year}
        </small>
      </C.StyledFooter>
    </C.Wrapper>
  );
};

export default PageNotFound;

PageNotFound.propTypes = {};
PageNotFound.defaultProps = {};
