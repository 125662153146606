import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AlertRulesMain',
  sv: {
    tableTicketId: 'Ticket ID',
    emptystate: 'Inga ärende',
    searchLabel: 'Sök ärenden',
    tableSubject: 'Ämne',
    tableTags: 'Taggar',
    tableModified: 'Ändrad',
    tableType: 'Typ',
    tableStatus: 'Status',
    createNew: 'Skapa Larmregel',
    activeRules: 'Aktiva regler',
    inactiveRules: 'Inaktiva regler',
    title: 'Larmregler',
    description: `Larmregler styr hur resurser övervakas i CloudOps. Reglerna utvärderas i 5-minutersperioder mot insamlade metrics för respektive resurs och genererar incident-ärenden när tröskelvärdena matchar.
    Ärendet som skapas hanteras av Service Desk, antingen manuellt eller automatiskt. Kontakta Service Desk för frågor kring hur larmregler i CloudOps fungerar.`,
    dismissableCreateAlertRuleTitle: 'Skapa en larmregel',
    newUser: 'Övervakning av metrics?',
    dismissableCreateAlertRuleContent: `Skapa en ny larmregel här. Passa även på att se över resursens dokumentation och eskaleringsrutiner när larmregler förändras. Tänk på att det kan ta upp till en timme innan nya eller förändrade regler slår igenom överallt.`,

    success: 'Larmregel uppdaterad',
    fail: 'Larmregel kunde inte uppdateras',
  },
  en: {
    tableTicketId: 'Ticket ID',
    emptystate: 'No tickets found',
    searchLabel: 'Search tickets',
    tableSubject: 'Subject',
    tableTags: 'Tags',
    tableModified: 'Modified',
    tableType: 'Type',
    tableStatus: 'Status',
    createNew: 'Create Alert rule',
    activeRules: 'Active rules',
    inactiveRules: 'Inactive rules',
    title: 'Alert rules',
    description: `Alert rules are used to monitor a resource in CloudOps. The rules are evaluated in 5 minute periods against collected metrics for the resource and generates incident tickets when the thresholds are matched.
    The generated tickets are automatically or manually handled by Service Desk. Contact Service Desk for more information about Alert rules in CloudOps.`,
    createAlertRule: 'Create an alert rule',
    newUser: 'Want to monitor metrics?',
    dismissableCreateAlertRuleContent: `Create an alert rule here. While you're at it, verify that necessary documentation and escalation routines are up to date when creating or modifying alert rules. Note that any alert rule updates can take up to an hour before the change is propagated thoughout CloudOps.`,

    success: 'Larmregel uppdaterad',
    fail: 'Larmregel kunde inte uppdateras',
  },
});
