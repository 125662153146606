import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import PropTypes from 'prop-types';
import {
  Text, Box, VStack, HStack,
} from '@chakra-ui/react';

const defaultStackId = 'a';
const defaultFill = '#8884d8';
const barsConfigShape = PropTypes.shape({
  dataKey: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stackId: PropTypes.string,
});

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  barsConfig: PropTypes.arrayOf(barsConfigShape).isRequired,
  unit: PropTypes.string,
  onBarClick: PropTypes.func,
  onXLabelClick: PropTypes.func,
};

const defaultProps = {
  unit: '',
  onBarClick: () => { },
  onXLabelClick: null,
};

const CustomXAxisTick = ({
  x, y, payload, onClick, data,
}) => {
  const { value } = payload;

  let enableClick = typeof onClick === 'function';
  let clickHandler = () => { };

  let foundData = null;

  if (enableClick) {
    foundData = data.find((bar) => bar.name === value);
    enableClick = !foundData.disableXClick;
  }

  if (enableClick) {
    clickHandler = () => onClick(foundData);
  }

  const labelX = -1 * payload.value?.length * 4;

  return (
    <g
      style={{
        cursor: enableClick ? 'pointer' : 'default',
      }}
      transform={`translate(${x},${y})`}
      onClick={clickHandler}
    >
      <text x={labelX} y={0} dy={16} textAnchor="center" fill="#666">
        {value}
      </text>
    </g>

  );
};

const BarChartUi = ({
  data, barsConfig, unit, onBarClick, onXLabelClick,
}) => {
  const renderLegendContent = ({ payload }) => (
    <ul style={{
      padding: 0,
      margin: 0,
      marginTop: '1rem',
      textAlign: 'center',
      listStyle: 'none',
    }}
    >
      {
      payload?.map((entry) => (
        <li
          key={entry.value}
          style={{
            display: 'inline-block',
            marginRight: '20px',
          }}
        >
          <Box
            height="10px"
            width="10px"
            backgroundColor={entry?.color}
            display="inline-block"
            mr="0.5rem"
          />
          <Text fontSize={12} textTransform="uppercase" m={0} display="inline-block">{entry?.value}</Text>
        </li>
      ))
    }
    </ul>
  );

  const customTooltip = ({ active, payload }) => {
    if (!active || !payload.length) return null;

    const title = payload[0]?.payload?.name;

    const totalValue = payload.reduce((acc, { value }) => acc + value, 0);
    return (
      <Box
        background="white"
        padding="1rem"
        paddingBottom="0.5rem"
        boxShadow="0 0 10px rgba(0,0,0,0.2)"
        borderRadius={5}
      >
        <Text fontSize="18px" fontFamily="Poppins" fontWeight="bold" mt={0}>
          {title}
        </Text>

        <Text mt={0} fontSize={14}>
          <span style={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}
          >
            Total:
          </span>
          {' '}
          {totalValue}
          {unit}
        </Text>

        {payload.reverse().map(({ name, fill, value }) => (
          <VStack alignItems="baseline" borderTop="1px solid lightgrey" py="0.5rem">
            <HStack>
              <Box padding="0.5rem" background={fill} />
              <Text m={0} fontSize="14px" fontWeight="bold" textTransform="uppercase">{name}</Text>
            </HStack>

            <Box>
              <Text my={0} fontSize="14px" marginLeft="1.5rem" marginRight="1rem" display="inline-block">
                {value}
                {unit}
              </Text>
              {!!totalValue && (
              <Text my={0} fontSize="14px" display="inline-block">
                {Math.floor((value / totalValue) * 100)}
                %
              </Text>
              )}
            </Box>
          </VStack>
        ))}

      </Box>
    );
  };

  return (
    <ResponsiveContainer
      width="99%"
      aspect="1.5"
    >
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >

        <XAxis
          dataKey="name"
          tick={<CustomXAxisTick onClick={onXLabelClick} data={data} />}
        />
        <YAxis />
        <Tooltip cursor={{ fill: 'transparent' }} content={customTooltip} />
        <Legend content={renderLegendContent} />
        {barsConfig.map((bar) => (
          <Bar
            unit={unit}
            key={bar?.dataKey}
            dataKey={bar?.dataKey}
            stackId={bar?.stackId || defaultStackId}
            fill={bar.fill || defaultFill}
            maxBarSize={100}
            onClick={onBarClick}

          />
        ))}
      </BarChart>
    </ResponsiveContainer>

  );
};

BarChartUi.propTypes = propTypes;
BarChartUi.defaultProps = defaultProps;

export default BarChartUi;

CustomXAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.instanceOf(Object),
  onClick: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

CustomXAxisTick.defaultProps = {
  payload: {},
  x: 0,
  y: 0,
  data: {},
};
