import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Securtiy.Component.Navigation',
  sv: {
    activeAnalyticRules: 'Aktiva regler',
    templateRules: 'Templates',
    back: 'Tillbaka',
    workspaceNotExist: 'Workspacet finns inte',
  },
  en: {
    activeAnalyticRules: 'Active rules',
    templateRules: 'Templates',
    back: 'Back',
    workspaceNotExist: 'Workspace does not exist',
  },
});
