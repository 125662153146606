import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { InView as InViewModule } from 'react-intersection-observer';

const InView = ({ onEnter, triggerOnce }) => {
  const isTriggeredRef = useRef(false);

  const handleOnChange = useCallback((inView) => {
    if (inView) {
      if (isTriggeredRef.current > !triggerOnce) {
        return;
      }
      isTriggeredRef.current = true;
      onEnter();
    }
  }, [onEnter, triggerOnce]);

  return (
    <InViewModule onChange={handleOnChange} />
  );
};

InView.propTypes = {
  onEnter: PropTypes.func.isRequired,
  triggerOnce: PropTypes.bool,
};
InView.defaultProps = {
  triggerOnce: false,
};

export default InView;
