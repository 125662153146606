import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Permissions',
  sv: {
    customer_name: 'Kundnamn',
    customer: 'Kund',
    role: 'Roll',

    overview: 'Översikt',
    ticket: 'Ärenden',
    myenvironment: 'Min miljö',
    servicewindow: 'Servicefönster',
    report: 'Rapporter',
    appmonitor: 'Appövervakning',

    emptyState: 'Kunde inte hitta några kunder',
    searchLabel: 'Sök kunder',

    desc: 'Ändra läs- och skrivrättigheter för kunder på featurenivå. Ändringarna appliceras direkt, men om du laddar om sidan kan det ta ett tag innan det visas korrekt.',
  },
  en: {
    customer_name: 'Customer name',
    customer: 'Customer',
    role: 'Role',

    overview: 'Overview',
    ticket: 'Tickets',
    myenvironment: 'My environment',
    servicewindow: 'Service windows',
    report: 'Reports',
    appmonitor: 'App monitor',

    emptyState: 'Could not find any customers',
    searchLabel: 'Search customers',

    desc: 'Change read and write permissions for customers on a feature level. Changes are applied directly, but if the page is refreshed it may take a while before it shows correctly.',
  },
});
