import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import * as Block from '~ui/Block';
import { Button } from '~ui/Button';
import * as Layout from '~ui/Layout';
import * as Moment from '~ui/Moment';
import * as Permission from '~ui/Permission';
import * as Badge from '~ui/Badge';
import { Spinner, useTheme } from '@chakra-ui/react';
import { mdiArrowLeft, mdiCommentPlus } from '@mdi/js';
import useBackPath from '~lib/hooks/useBackPath';
import { ticketPermission } from '~lib/utils/featureKeys';
import NewComment from '../NewComment/NewComment';
import * as C from './TicketDetails.styled';
import Comment from '../Comment/Comment';
import Meta from './Meta';
import useTranslation from './TicketDetails.translation';

const propTypes = {
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  onUnmount: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  onSubmitComment: PropTypes.func.isRequired,
};

const TicketsPage = ({
  onSubmitComment,
  userName,
  email,
  customerName,
  ...props
}) => {
  const { colors, breakpoints } = useTheme();

  const { t } = useTranslation;
  const { params } = useRouteMatch();
  const [comments, setCommnets] = useState([]);
  const [ticket, setTicket] = useState(null);
  const [entity, setEntity] = useState(null);
  const [alertRule, setAlertRule] = useState(null);
  const [showDetailsOnMobile, setShowDetailsOnMobile] = useState(false);
  const [ticketCustomer, setTicketCustomer] = useState(null);

  const { customerId, ticketId } = params;
  useEffect(() => {
    props.onMount({
      customerId,
      ticketId,
      setCommnets,
      setTicket,
      setEntity,
      setAlertRule,
      onSubmitComment,
      setTicketCustomer,
    });

    return () => props.onUnmount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const backPath = useBackPath();

  const allAttachments = useMemo(() => {
    const a = comments?.reduce((
      acc, currentComment,
    ) => {
      const { attachments: commentAttachment } = currentComment;

      return acc.concat(commentAttachment ?? []);
    }, []);

    return a;
  }, [comments]);

  return (
    <>
      <Layout.Omnibar.Main>
        <Layout.Omnibar.LeftActions>
          <Button
            variant="ghost"
            leftIcon={mdiArrowLeft}
            goBack
            internalLink={backPath?.path || `/tickets/${customerId}`}
            searchParams={backPath?.searchParams}
          >
            {backPath?.backButtonText || t('back')}
          </Button>
          <Permission.Render withFeature={[ticketPermission.write]}>
            <div
              style={{
                display: 'flex',
                borderLeft: '1px solid #ccc',
                paddingLeft: '0.75rem',
              }}
            >
              <Button
                tooltip={t('addComment')}
                variant="link"
                rightIcon={mdiCommentPlus}
                onClick={() => document.getElementsByName('message')[0].focus()}
                style={{ marginLeft: '0.25rem', color: '#000' }}
              />
            </div>
          </Permission.Render>
        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>
      <Layout.Scene>
        <Block.Center renderTransparent>
          <C.Tabs breakpoints={breakpoints}>
            <C.Tab
              colors={colors}
              isActive={!showDetailsOnMobile}
              onClick={() => setShowDetailsOnMobile(false)}
            >
              <C.TabText>
                <p>{t('history')}</p>
              </C.TabText>
            </C.Tab>
            <C.Tab
              colors={colors}
              isActive={showDetailsOnMobile}
              onClick={() => setShowDetailsOnMobile(true)}
            >
              <C.TabText>
                <p>{t('details')}</p>
              </C.TabText>
            </C.Tab>
          </C.Tabs>
        </Block.Center>

        <Block.Center withPadding>
          {!ticket && (
            <Spinner
              color="#133A5D"
              emptyColor="#DADADA"
              speed="0.65s"
              thickness="4px"
              size="xl"
            />
          )}
          {ticket && (
            <C.Layout
              breakpoints={breakpoints}
              showDetailsOnMobile={showDetailsOnMobile}
            >
              <C.Heading colors={colors}>
                <C.PageTitle>
                  <span className="ticketId">{`#${ticket.id}`}</span>
                  <span>{` ${ticket.subject}`}</span>
                </C.PageTitle>
              </C.Heading>

              <C.Summary colors={colors}>
                <C.Status>
                  <Badge.Status status={ticket.status} />
                </C.Status>

                <C.MetaSummary>
                  <b>{ticket.requester_name}</b>
                  {` ${t('ticketsOpened')} `}
                  <C.Created>
                    <Moment.Ago timestamp={ticket.created} />
                  </C.Created>
                  <span style={{ margin: '0.5rem' }}>·</span>
                  {comments.length}
                  {` ${t('comments')}`}
                </C.MetaSummary>
              </C.Summary>

              <C.Body>
                {ticket && (
                  <Comment
                    filled
                    name={ticket.requester_name}
                    email={ticket.requester_id}
                    body={ticket.description}
                    created={ticket.created}
                  />
                )}

                {comments.map((comment) => (
                  <Comment
                    name={comment.user_name}
                    key={comment.id}
                    email={comment.user_id}
                    body={comment.body}
                    created={comment.created}
                    isPrivate={comment.is_private}
                    attachments={comment?.attachments || []}
                  />
                ))}
                <Permission.Render withFeature={[ticketPermission.write]}>
                  <C.BodyFooter
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <NewComment
                      userName={userName}
                      email={email}
                      customerName={customerName}
                      onSubmitComment={onSubmitComment}
                      comments={comments}
                      setComments={setCommnets}
                    />
                  </C.BodyFooter>
                </Permission.Render>
              </C.Body>

              <Meta
                alertRule={alertRule}
                ticket={ticket}
                entity={entity}
                ticketCustomer={ticketCustomer}
                attachments={allAttachments}
              />
            </C.Layout>
          )}
        </Block.Center>
      </Layout.Scene>
    </>
  );
};

TicketsPage.propTypes = propTypes;

export default TicketsPage;
