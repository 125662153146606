import React from 'react';
import { Button } from '~ui/Button';
import * as Layout from '~ui/Layout';
import * as Block from '~ui/Block';
import * as Permission from '~ui/Permission';
import { Heading } from '@chakra-ui/react';
import { mdiArrowLeft } from '@mdi/js';
import { useHistory } from 'react-router';
import { TableSearchProvider } from '~ui/Table';
import { useRouteMatch } from 'react-router-dom';
import * as Keys from '../../../../lib/utils/historyStateKeys';
import { searchEntities } from '../../../../lib/services/entities';
import { ServiceWindowMutationProvider } from '../../serviceWindowMutationContext';
import Notification from '../components/Notification';
import Steps from '../components/Steps';

import translation from './Create.translation';

const { t } = translation;

const Create = () => {
  const history = useHistory();
  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();
  const { params } = useRouteMatch();
  const { customerId } = params;

  const sortList = [
    { value: 'is_mapped', label: t('cloudopsActive') },
    { value: 'name', label: t('name'), default: true },
    { value: 'resource_group', label: t('resourceGroup') },
  ];

  const payload = (state, azureSearchParser) => {
    const newState = { ...state, filter: state.filter };

    const parsers = {
      filter: {
        customer_display_name: (val) => `customer_id eq '${val.match(/\((\d+)\)/)[1]}'`,
      },
    };

    if (state.isFilterTrigger) {
      return {
        payload: azureSearchParser.facets(newState, state.filterKey, parsers),
        customerId,
        includeStale: false,
        isGlobalAdmin,
      };
    }
    return {
      payload: azureSearchParser.items(newState, parsers),
      customerId,
      includeStale: false,
      isGlobalAdmin,
    };
  };

  return (
    <>
      <ServiceWindowMutationProvider>
        <Layout.Omnibar.Main>
          <Layout.Omnibar.LeftActions>
            <Button
              variant="ghost"
              leftIcon={mdiArrowLeft}
              onClick={() => history.goBack()}
            >
              {t('back')}
            </Button>
          </Layout.Omnibar.LeftActions>
        </Layout.Omnibar.Main>
        <Layout.Scene>

          <TableSearchProvider
            pageSize={15}
            payload={payload}
            fetcher={searchEntities}
            urlStateKey={Keys.SW_DETAILS}
            sort={sortList}
            noUrlState
          >
            <Block.Center style={{ padding: '1rem 0' }}>

              <Heading as="h2" style={{ marginTop: '2rem' }}>
                {t(`title`)}
              </Heading>

              <Steps isGlobalAdmin={isGlobalAdmin} isEditMode={false} />

              <Notification />

            </Block.Center>
          </TableSearchProvider>
        </Layout.Scene>
      </ServiceWindowMutationProvider>
    </>
  );
};

export default Create;
