import {
  take, cancel, call, fork,
} from 'redux-saga/effects';
import * as t from './actionTypes';

import getOnCallService from './services/getOnCall.service';
import postOnCallService from './services/postOnCall.service';

function* updateOnCall({ setCurrentOnCall }) {
  while (true) {
    const { meta } = yield take(t.UPDATE_ON_CALL);
    const { payload, onSuccess, onFailSave } = meta;
    const { success, error } = yield call(postOnCallService, payload);

    if (success) {
      yield call(setCurrentOnCall, success);
      yield call(onSuccess, success);
    } else {
      yield call(onFailSave, error);
    }
  }
}

function* loadOnCall(meta) {
  const {
    setCurrentOnCall,
    setErrorMessage,
  } = meta;
  const { success } = yield call(getOnCallService);

  if (success) {
    yield call(setCurrentOnCall, success);
  } else {
    yield call(setErrorMessage, 'Could not fetch on call');
  }
}

export default function* rootWatcher() {
  while (true) {
    const { meta } = yield take(t.MOUNT);

    const tasks = [
      yield fork(loadOnCall, meta),
      yield fork(updateOnCall, meta),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
