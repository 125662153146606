import React from 'react';
import PropTypes from 'prop-types';
import * as List from '~ui/List';
import { Button } from '~ui/Button';
import { Heading } from '@chakra-ui/react';
import {
  mdiArrowRight,
} from '@mdi/js';
import * as C from '../Main.styled';
import translation from '../Main.translation';
import { mapRow, mapUrls, mapFolders } from './helpers';

const { t } = translation;

const Monitor = ({
  vmConfig, customerId, entityId, canWrite,
}) => (
  <C.Type>
    <Heading as="h2">{t('monitor')}</Heading>
    <List.Primary
      rows={[
        ...mapRow({ label: t('services'), array: vmConfig.services_to_monitor }),
        ...mapRow({ label: t('servicesNotTo'), array: vmConfig.services_not_to_monitor }),
        ...mapUrls({ label: t('urls'), array: vmConfig.urls_to_monitor }),
        ...mapRow({ label: t('iisWebsitesNotTo'), array: vmConfig.iis_web_sites_not_to_monitor }),
        ...mapRow({ label: t('iisAppPoolsNotTo'), array: vmConfig.iis_app_pools_not_to_monitor }),
        ...mapFolders({ label: t('foldersLastModified'), array: vmConfig.folders_last_modified_to_monitor }),
        {
          row: canWrite && (
            <Button
              mt="1rem"
              p={0}
              variant="ghost"
              color="asurgent.500"
              internalLink={`/my-environment/${customerId}/${entityId}/cloudops-vmagent/edit/${vmConfig.id}/monitor`}
              rightIcon={mdiArrowRight}
            >
              {t('editMonitoring')}
            </Button>
          ),
        },
      ]}
    />
  </C.Type>
);
Monitor.propTypes = {
  canWrite: PropTypes.bool.isRequired,
  vmConfig: PropTypes.instanceOf(Object),
  customerId: PropTypes.string.isRequired,
  entityId:
    PropTypes.string.isRequired,
};
Monitor.defaultProps = { vmConfig: {} };

export default Monitor;
