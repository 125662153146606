import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
`;

export const PickersWrapper = styled.div`
  background: white;
  padding: 2rem;

  ${({ isFixed }) => isFixed
  && css`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 1rem 2rem;
    padding-bottom: 0.5rem;
    @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
      left: 60px;
    }
  `};
`;

export const ExpandedContentInner = styled.div`

${({ useOverflow }) => (useOverflow ? css`
  overflow-y: hidden;
  ` : ``)};
`;

export const ExpandedContent = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-out;
  ${({ isExpanded }) => (isExpanded ? css`
      grid-template-rows: 1fr;
  ` : ``)};
`;
