import styled from 'styled-components';

export const Card = styled.div`
    width: 100%; 
    border-radius: 5px;
    box-shadow: 0 3px 6px -2px rgba(32, 33, 36, 0.28);
    border: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
    display: grid;  
    grid-template-rows: auto;
    grid-column-gap: 3.2rem;
    margin-top: 1rem;
    grid-template-columns: auto 1fr ;
    grid-template-areas: 
        'content content'
        'navigate navigate';

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
        grid-template-columns: auto 1fr 8rem;
        grid-template-areas: 'circle content navigate';
    }
`;

export const Severity = styled.div`
    grid-area: severity;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;

    p {
        margin: 0;
        font-size: 0.8rem;
    }
`;

export const Navigator = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-width: 5rem;
    width: 100%;
    padding: 0.5rem;
    border-top: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
    grid-area: navigate;
    
    @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
        border-top: none;
        border-left: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
    }

    a {
        border-radius: 100%;
    }
`;

export const Content = styled.div`
    flex-grow: 1;
    overflow: hidden;
    grid-area: content;
    padding-top: 1.5rem ;
    padding-bottom: 1.5rem;

    p { white-space: normal; }

    display: grid;
    grid-template-rows: 1fr auto auto;
    grid-template-columns: 1fr auto;
    column-gap: 2rem;
    grid-template-areas: 
        'text severity'
        'description description'
        'tactics tactics'
        ; 
`;
