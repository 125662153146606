import React from 'react';
import PropTypes from 'prop-types';
import translation from './RowComponent.translation';
import * as C from './RowComponent.styled';

const propTypes = {
  service: PropTypes.instanceOf(Object).isRequired,
};

const ServicePricingColumn = ({ service }) => {
  const { t } = translation;
  const {
    isFree,
    monthlyPrice,
    customBilling,
  } = service;

  return (
    <C.Content style={{ alignItems: 'flex-end' }} className="pricing">
      { customBilling && (
        <span>{t('custom')}</span>
      )}
      { !customBilling && (
        <>
            { !isFree && (
              <span>
                {`${monthlyPrice}/${t('month')}`}
              </span>
            )}
            { isFree && (
              <span>
                {t('free')}
              </span>
            )}
        </>
      )}
    </C.Content>
  );
};

ServicePricingColumn.propTypes = propTypes;

export default ServicePricingColumn;
