import { useQuery } from 'react-query';

import Api from '../api';

export const searchReports = async ({
  payload,
  global,
  customerId,
}) => {
  let path = `customer/${customerId}/report/search`;

  if (global) {
    path = '/report/search';
  }

  return Api.post(path, payload);
};

export const useReports = (customerId, config) => useQuery(
  ['reports', customerId],
  () => searchReports(customerId), {
    ...config,
  },
);
