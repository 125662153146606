import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { MsalProvider } from '@azure/msal-react';
import { configureApi } from './lib/api';
import { authenticationProxyRenderer } from './utils/AuthenticationProxyRenderer';
import { startGoogleAnalytics, GoogleAnalyticsRouteTracker } from './utils/GoogleAnalytics';
import startSentryErrorLogging from './utils/Sentry';
import ApplicationRouter from './router';
import createStore from './redux';
import i18n from './i18n/i18n';
import Normalize from './style/Normalize.styled';
import { acquireAuthTokenHandler } from './router/acquireAuthToken';
import { msalClient } from './router/services/auth.service';

startSentryErrorLogging();
const store = createStore();

configureApi({
  redux: store,
  acquireAuthToken: acquireAuthTokenHandler(store),
});

msalClient.addEventCallback((message) => {
  const dispatchObject = { type: message.eventType, meta: message.payload || null };
  store.dispatch(dispatchObject);
});

const App = () => (
  <BrowserRouter>
    <MsalProvider instance={msalClient}>
      <Normalize />
      { startGoogleAnalytics() && <GoogleAnalyticsRouteTracker /> }
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          {authenticationProxyRenderer(<ApplicationRouter />)}
        </Provider>
      </I18nextProvider>
    </MsalProvider>
  </BrowserRouter>
);

export default App;
