import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'SelectedEntities',
  sv: {
    noResourceGroup: 'Utan resursgrupp',
    entities: 'Entiteter',
    remove: 'Ta bort',
    entity: 'Entitet',
    resourceGroup: 'Resursgrupp',
  },
  en: {
    noResourceGroup: 'No resource group',
    entities: 'Entities',
    remove: 'Remove',
    entity: 'Entity',
    resourceGroup: 'Resource group',
  },
});
