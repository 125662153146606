/**
 * App monitor
 */
export const DASHBOARD = 'dashboard';

/**
 * App monitor
 */
export const APP_MONITOR = 'app';
export const APP_MONITOR_TICKETS = 'app_t';
export const APP_MONITOR_TEST_RUNS = 'app_tr';

/**
 * My Environnment
 */
export const ENV_LIST = 'list';
export const ENV_SERVICE_WINDOW = 'entity_sw';
export const ENV_TICKETS = 'entity_t';
export const ENV_REPORTS = 'entity_r';

/**
 * Service window
 */
export const SW = 'sw';
export const SW_DETAILS = 'sw_details';

/**
 * Tickets
 */
export const TICKETS = 'tickets';

/**
 * Security
 */
export const SECURITY_TEMPLATES = 'security';
export const SECURITY_ACTIVE = 'activesecrules';
export const SECURITY_WORKSPACE = 'workspaces';

export const SECURITY_HOURS = 'sh';

/**
 * Alert Overview
 *
 */
export const ALERT_OVERVIEW = 'alert';
