import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AppMonitor.Screenshot',
  sv: {
    error: 'Kunde inte hämta screenshot',
  },
  en: {
    error: 'Could not get screenshot',
  },
});
