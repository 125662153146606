import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { mdiCancel, mdiPencilOutline } from '@mdi/js';
import * as Block from '~ui/Block';
import * as List from '~ui/List';
import { Button } from '~ui/Button';
import * as Permission from '~ui/Permission';
import { Tag, Wrap, Flex } from '@chakra-ui/react';
import translation from './RowAutoAction.translation';
import autoActionsTranslations from '../../../CreateEdit/AutoActions.translation';

import { myEnvironmentPermission } from '../../../../../../../../lib/utils/featureKeys';

const propTypes = {
  model: PropTypes.instanceOf(Object).isRequired,
  alertRules: PropTypes.instanceOf(Object).isRequired,
  onRemoveAction: PropTypes.func.isRequired,
};

const defaultProps = {};

const RowAutoAction = ({
  model,
  alertRules,
  onRemoveAction,
}) => {
  const { t } = translation;
  const { params } = useRouteMatch();
  const { entityId, customerId } = params;

  const activeOnAlertRules = useMemo(() => {
    const { alert_rule_ids: ids } = model;
    const match = alertRules
      .filter(({ id }) => ids.includes(id));

    if (match) {
      return match.reduce((acc, rule) => {
        const { name } = rule;

        return [name, ...acc];
      }, []);
    }

    return t('activeOnEntity');
  }, [alertRules, model, t]);

  const tags = useMemo(() => {
    const { fallback } = autoActionsTranslations;
    return model
      .autoActions
      .map(({ id, name }, i) => `#${i + 1} ${fallback(id, name)}`);
  }, [model.autoActions]);

  const rows = useMemo(() => [
    { label: t('description'), value: model.description || null },
    {
      label: t('activeOnAlertRule'),
      value: (
        <Wrap spacing={2}>
          {activeOnAlertRules.map((rule) => <Tag key={rule} bg="#f5edd8">{ rule}</Tag>)}
        </Wrap>
      ),
    },
    {
      label: t('sequence'),
      value: (
        <Wrap spacing={2}>
          {tags?.map((tag) => <Tag key={tag}>{tag}</Tag>)}
        </Wrap>),
    },
    { label: t('id'), value: model.id },
  ], [activeOnAlertRules, model.description, model.id, t, tags]);

  return (
    <Block.Accordion title={model.name} description={model.description}>
      {() => (
        <>
          <List.Primary rows={rows} />
          <Permission.Render withFeature={[myEnvironmentPermission.write]}>
            <Flex mt="1rem" flexDirection="row-reverse">
              <Button
                style={{ marginLeft: '1rem' }}
                internalLink={`/my-environment/${customerId}/${entityId}/auto-actions/edit/${model.id}`}
                leftIcon={mdiPencilOutline}
              >
                {t('edit')}
              </Button>
              <Button
                bg="ruby.900"
                onClick={onRemoveAction}
                leftIcon={mdiCancel}
              >
                {t('remove')}
              </Button>
            </Flex>
          </Permission.Render>
        </>
      )}
    </Block.Accordion>
  );
};

RowAutoAction.propTypes = propTypes;
RowAutoAction.defaultProps = defaultProps;

export default RowAutoAction;
