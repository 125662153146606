import styled from 'styled-components';

export { PriorityDot } from '../../../sharedStyles';

export const Wrapper = styled.div`
  margin-bottom: 2rem;
   &:last-of-type {
    margin-bottom: 0;
  }
`;

export const TicketList = styled.ul`
  padding: 0;
  list-style: none;
`;

export const TicketListItem = styled.li`
  display: grid;
  grid-template-columns: auto 100px minmax(200px, 1fr) 150px 100px auto;
  grid-gap: 20px;
  padding: 1rem 0;
  padding-left: 0.5rem;
  border-top: 1px solid ${({ colors }) => colors?.gray?.['200']};

  &:last-of-type {
  border-bottom: 1px solid ${({ colors }) => colors?.gray?.['200']};
  }

  p {
    margin: 0;
  }
  &:hover {
    background-color: ${({ colors }) => (colors?.gray?.['100'])};
    cursor: pointer;
  }

`;
