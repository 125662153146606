import React from 'react';
import { Heading } from '@chakra-ui/react';
import { useSecurityHours } from '~scenes/securityHours/context/SecurityHoursContext';
import MonthPicker from '~ui/MonthPicker';
import translation from './Month.translation';

import * as C from './Month.styled';

const Month = () => {
  const { t } = translation;
  const { selectedDate, handleDateChange, selectedCustomerId } = useSecurityHours();

  return (
    <C.Wrapper>
      <Heading mb="1rem" sx={{ opacity: selectedCustomerId ? '1' : '0.5' }} fontWeight="light" as="h3">{t('title')}</Heading>
      <MonthPicker
        disabled={!selectedCustomerId}
        initialDate={selectedDate}
        callback={handleDateChange}
      />
    </C.Wrapper>
  );
};

export default Month;
