import {
  take,
  call,
  cancel,
  fork,
  takeEvery,
} from 'redux-saga/effects';
import * as t from './actionTypes';

import getAutoActionsForEntityService from './services/getAutoActionForEntity.service';
import getAlertRulesForEntityService from './services/getAlertsForEntity.service';
import removeAlertRulesForEntityService from './services/removeAutoActionForEntity.service';

function* loadAutoActions(meta) {
  const {
    setProcessModels,
    setAlertRules,
    customerId,
    setLoading,
    entityId,
  } = meta;
  setLoading(true);
  const payload = { customerId, entityId };

  const {
    success: alertRulesForEntity,
    error: error1,
  } = yield call(getAlertRulesForEntityService, payload);

  const {
    success: autoActionsForEntity,
    error: error2,
  } = yield call(getAutoActionsForEntityService, payload);

  if (!error1 && !error2) {
    setAlertRules(alertRulesForEntity);
    setProcessModels(autoActionsForEntity);
  }

  setLoading(false);
}

function* removeModelAction(meta, { meta: { id: modelId } }) {
  const payload = {
    modelId,
    customerId: meta.customerId,
  };
  const { success } = yield call(removeAlertRulesForEntityService, payload);
  if (success) {
    yield call(loadAutoActions, meta);
  }
}

export default function* rootWatcher() {
  while (true) {
    const { meta } = yield take(t.MOUNT);

    const tasks = [
      yield fork(loadAutoActions, meta),
      yield takeEvery([t.REMOVE], removeModelAction, meta),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
