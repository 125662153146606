import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: -webkit-fill-available;
  min-height: 90vh;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledErrorContent = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-bottom: 0;
  }
  h2 {
    width: 70%;
    margin: 2rem 0;
    text-align: center;
  }
`;

export const SignOut = styled.div`
  width: auto;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid;
  border-color: ${(prop) => prop.theme.gray500};
  text-align: center;
`;
