import styled from 'styled-components';
import { Heading } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';

const H1Heading = ({ children, ...rest }) => <Heading as="h1" {...rest}>{children}</Heading>;
H1Heading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};
H1Heading.defaultProps = {
  children: '',
};

export const GraphContainer = styled.div`
    padding: 1.5rem 2.25rem;
    border: ${({ colors }) => `1px solid ${colors?.gray?.['300']}`};
    width: max-content;
    border-radius: 5px;
    margin-top: 1.25rem;
    margin-bottom: 2.5rem;
    width: 100%;
`;

export const Outcome = styled(H1Heading)`
    grid-area: title;
`;

export const OutcomeList = styled.div`
    grid-area: list;
`;

export const YearSelector = styled.div`
    grid-area: yearSelector;
`;

export const ListContainer = styled.div`
    display: flex;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto;
    grid-template-areas: "title" "yearSelector" "list";

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.sm}) {
      grid-template-columns: 1fr auto;
      grid-template-rows: auto 1fr;
      grid-template-areas: "title yearSelector" "list yearSelector";
    }
`;
