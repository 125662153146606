import moment from 'moment';

export const genreateSnoozeUntillPayload = (values, cache) => {
  const { snooze, snoozeCustom } = values;
  const body = { snooze_until: null, ...cache };
  if (snooze === null) {
    Object.assign(body, {
      snooze_until: moment()
        .toISOString(),
    });
  } else if (snooze === 'custom') {
    Object.assign(body, {
      snooze_until: moment(snoozeCustom)
        .toISOString(),
    });
  } else {
    Object.assign(body, {
      snooze_until: moment()
        .add(snooze, 'minutes')
        .toISOString(),
    });
  }

  return body;
};
