import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import * as Permission from '~ui/Permission';
import { useCustomers } from '~lib/services/customers';
import { useHistory } from 'react-router-dom';
import { getUpdatedQueryString } from '~lib/utils/helpers';
import { useSecurityHoursQuery } from './hooks';
import { SERVICE_LEVEL_ADVANCED, URL_STATE_KEY } from '../../constants';
import { includedServicesDict } from './utils';

export const SecurityHoursHandlersContext = React.createContext({});
export const SecurityHoursContext = React.createContext({});

export function useSecurityHoursHandlers() {
  return React.useContext(SecurityHoursHandlersContext);
}

export function useSecurityHours() {
  return React.useContext(SecurityHoursContext);
}

const propTypes = {
  children: PropTypes.node.isRequired,
  loggedInCustomerId: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  initialDate: PropTypes.instanceOf(Date),
  initialSelectedCustomerId: PropTypes.string,
};

const defaultProps = {
  initialDate: null,
  initialSelectedCustomerId: '',
};

function SecurityHoursProvider({
  children, loggedInCustomerId, customerName,
  initialDate, initialSelectedCustomerId,
}) {
  const history = useHistory();
  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();

  const { data: customerQueryData } = useCustomers();
  const customersResult = customerQueryData?.result;

  const [selectedCustomerId, setSelectedCustomerId] = useState(isGlobalAdmin
    ? initialSelectedCustomerId : loggedInCustomerId);
  const [selectedDate, setSelectedDate] = useState(initialDate);

  // Only use SOC- customers
  const customers = useMemo(() => {
    if (!customersResult || !isGlobalAdmin) {
      return null;
    }

    return customersResult.filter((customer) => customer?.cloudops_security_active);
  }, [customersResult, isGlobalAdmin]);

  const year = selectedDate.getFullYear();
  const monthNumber = selectedDate.getMonth() + 1;

  const selectedCustomer = useMemo(
    () => isGlobalAdmin
    && customers
    && selectedCustomerId
    && customers?.find((c) => c.id === selectedCustomerId),
    [customers, selectedCustomerId, isGlobalAdmin],
  );

  const hasSelectedCustomer = isGlobalAdmin && !!selectedCustomer;
  const selectedCustomerName = isGlobalAdmin ? selectedCustomer?.name : customerName;

  const {
    monthsRange,
    isMonthsRangeLoading,
    selectedMonth,
    isSelectedMonthLoading,
    isSelectedPreviousMonthLoading,
    mutateSelectedMonth,
  } = useSecurityHoursQuery(selectedCustomerId, year, monthNumber);

  const { cloudops_security_service_level: selectedCustomerServiceLevel } = selectedMonth || {};

  const selectedCustomerServices = selectedCustomerServiceLevel
   && includedServicesDict[selectedCustomerServiceLevel];
  const isServiceLevelAdvanced = selectedCustomerServiceLevel === SERVICE_LEVEL_ADVANCED;

  useEffect(() => {
    const current = {
      selectedCustomerId,
      selectedDate,
    };

    const { location, replace } = history;

    replace({
      ...location,
      search: getUpdatedQueryString(URL_STATE_KEY, current, location.search),
    });
  }, [selectedCustomerId, selectedDate, history]);

  // Handlers
  const handleCustomerChange = useCallback((id) => {
    if (!isGlobalAdmin) {
      return;
    }

    setSelectedCustomerId(id);
  }, [isGlobalAdmin, setSelectedCustomerId]);

  const handleDateChange = useCallback((d) => {
    setSelectedDate(d);
  }, [setSelectedDate]);

  // Memoize handlers context separately so that one can subscribe
  // to them without re-rendering on state updates.
  const handlersContextValue = useMemo(() => ({
    handleCustomerChange,
    handleDateChange,
    mutateSelectedMonth,
  }), [
    handleCustomerChange,
    handleDateChange,
    mutateSelectedMonth,
  ]);

  const contextValue = {
    ...handlersContextValue,
    customers,
    selectedDate,
    hasSelectedCustomer,
    isGlobalAdmin,
    isMonthsRangeLoading,
    isSelectedMonthLoading,
    isSelectedPreviousMonthLoading,
    isServiceLevelAdvanced,
    monthsRange,
    selectedCustomerId,
    selectedCustomerName,
    selectedCustomerServiceLevel,
    selectedCustomerServices,
    selectedMonth,
  };

  return (
    <SecurityHoursHandlersContext.Provider value={handlersContextValue}>
      <SecurityHoursContext.Provider value={contextValue}>{children}</SecurityHoursContext.Provider>
    </SecurityHoursHandlersContext.Provider>
  );
}

SecurityHoursProvider.propTypes = propTypes;
SecurityHoursProvider.defaultProps = defaultProps;

export default SecurityHoursProvider;
