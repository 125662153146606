import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'ApproveHoursForm',
  sv: {
    title: 'Godkänn timmar',
    description: 'Godkänn fler timmar',
    hours: 'Timmar',
    comment: 'Kommentar',
    submit: 'Godkänn',
    hoursFieldError: 'Vänligen ange antal timmar',
    descriptionFieldError: 'Vänligen lägg till en kommentar',
    oldDate: 'Du kan inte godkänna timmar för en tidigare månad',

  },
  en: {
    title: 'Approve hours',
    description: 'Approve more hours',
    hours: 'Hours',
    comment: 'Comment',
    submit: 'Approve',
    hoursFieldError: 'Please add hours',
    descriptionFieldError: 'Please add a comment',
    oldDate: 'You can not approve hours for a previous month',
  },
});
