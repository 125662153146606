import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '~ui/Button';
import * as Cards from '~ui/Cards';
import { Tooltip, useTheme } from '@chakra-ui/react';
import MdiIcon from '@mdi/react';
import {
  mdiFileTableBoxMultipleOutline,
  mdiChevronDown,
  mdiChevronLeft,
  mdiTrashCanOutline,
} from '@mdi/js';
import * as C from './SelectedEntities.styled';
import translation from './SelectedEntities.translation';
import { ServiceWindowMutationContext } from '../../../../../serviceWindowMutationContext';

const { t } = translation;

const ResourceGroup = ({ groupName, groupEntities }) => {
  const { colors } = useTheme();

  const { entities, setEntities } = useContext(ServiceWindowMutationContext);
  const [isOpen, setIsOpen] = useState(false);

  const resourceGroupText = useMemo(() => {
    const nrResources = `(${entities.length} ${t('entities')})`;
    return groupName === 'null'
      ? `${t('noResourceGroup')} ${nrResources}`
      : `${groupName} ${nrResources}`;
  }, [entities, groupName]);

  const removeResourceGroup = () => {
    const groupIds = groupEntities.map((ent) => ent.id);

    const filteredEntities = entities.filter((item) => !groupIds?.includes(item.id));
    setEntities(filteredEntities);
  };

  const removeEntity = ({ id }) => {
    const filteredEntities = entities.filter((ent) => ent.id !== id);
    setEntities(filteredEntities);
  };

  return (
    <>
      <C.ResourceGroup key={groupName + Math.random()}>
        <MdiIcon path={mdiFileTableBoxMultipleOutline} size={1.5} />
        <C.Info>

          <C.TopText>{t('resourceGroup')}</C.TopText>
          <C.BottomText>{resourceGroupText}</C.BottomText>

        </C.Info>
        <C.Action>
          <C.RemoveBtn variant="ghost" colors={colors} onClick={removeResourceGroup}>{t('remove')}</C.RemoveBtn>
        </C.Action>
        <C.Action onClick={() => setIsOpen(!isOpen)}>
          <MdiIcon path={isOpen ? mdiChevronLeft : mdiChevronDown} size={0.875} />
        </C.Action>
      </C.ResourceGroup>

      {isOpen && (
        <C.ListContainer>
          <C.ListMarker colors={colors} />
          <C.List>
            {entities.map((entity) => (
              <C.ListEntity key={entity.id}>
                <Tooltip
                  hasArrow
                  placement="top"
                  bg="white"
                  color="black"
                  label={(
                    <Cards.Entity
                      id={entity.id}
                      hasCloudops={entity?.properties?.cloudops_active}
                      name={entity.name}
                      type={entity.type}
                      resourceGroup={entity.resource_group}
                      region={entity.region}
                      displayName={entity.customer_display_name}
                      tags={Object.entries(entity.tags).map(([a, b]) => `${a}:${b}`)}
                    />
                )}
                >
                  <C.BottomText>{entity.name}</C.BottomText>
                </Tooltip>

                <Button
                  variant="ghost"
                  rightIcon={mdiTrashCanOutline}
                  onClick={() => removeEntity(entity)}
                />
              </C.ListEntity>
            ))}
          </C.List>
        </C.ListContainer>
      )}
    </>
  );
};
ResourceGroup.propTypes = {
  groupName: PropTypes.string,
  groupEntities: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

ResourceGroup.defaultProps = {
  groupName: 'null',
  groupEntities: [],
};

export default ResourceGroup;
