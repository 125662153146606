import React from 'react';
import PropTypes from 'prop-types';
import {
  useTheme, Text, Tooltip, Tag, TagLabel,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import MdiIcon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';

import * as C from './ListItem.styled';

const propTypes = {
  customerId: PropTypes.string.isRequired,
  hours: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  ticketSubject: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  backPathParam: PropTypes.string.isRequired,
};

const defaultProps = {
};

const ListItem = ({
  hours,
  customerId,
  id,
  ticketSubject,
  group,
  backPathParam,
}) => {
  const { colors } = useTheme();
  const history = useHistory();
  const link = `tickets/${customerId}/${id}/details${backPathParam}`;

  const onClick = () => {
    history.push(link);
  };
  return (

    <C.Row onClick={onClick}>
      <td>
        <C.Cell>
          <Text margin="0">{id}</Text>
        </C.Cell>
      </td>

      <td>
        <C.Cell>
          <Tag bg={colors?.blue?.['100']} position="relative" style={{ marginRight: '20px' }}>
            <TagLabel>
              {hours || 0}
              h
            </TagLabel>
          </Tag>
        </C.Cell>
      </td>

      <td>

        <C.Cell>
          <Tooltip hasArrow label={ticketSubject} placement="auto">
            <Text isTruncated maxWidth="500px" margin="0">{ ticketSubject }</Text>
          </Tooltip>
        </C.Cell>
      </td>

      <td>

        <C.Cell>

          <Tooltip hasArrow label={group} placement="auto">
            <Text isTruncated width="max-content" margin="0">{ group }</Text>
          </Tooltip>
        </C.Cell>
      </td>

      <td>

        <C.Cell isLast>
          <MdiIcon path={mdiChevronRight} size={0.75} />
        </C.Cell>
      </td>

    </C.Row>
  );
};

ListItem.propTypes = propTypes;
ListItem.defaultProps = defaultProps;

export default ListItem;
