import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '~ui/Button';
import * as List from '~ui/List';
import {
  Text, Tooltip, Tag, Wrap,
} from '@chakra-ui/react';
import moment from 'moment';
import translation from './MetaData.translation';

const URL_MITRE = 'https://attack.mitre.org/techniques/';

const propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

const Metadata = ({ data }) => {
  const { t } = translation;

  const dataConnetors = useMemo(() => {
    if (data?.properties?.requiredDataConnectors?.length > 0) {
      return data?.properties?.requiredDataConnectors
        .map(({ dataTypes }) => dataTypes).flat();
    }
    return [];
  }, [data]);

  const rows = useMemo(() => {
    if (data) {
      const { properties } = data;
      return [
        {
          label: t('severity'),
          value: properties.severity,
        },
        {
          label: t('tactics'),
          value: properties?.tactics?.length > 0
            ? (
              <Wrap spacing={2}>
                {properties?.tactics.map((tactic) => <Tag key={tactic} bg="#f5edd8">{tactic}</Tag>)}
              </Wrap>
            )
            : (<Text gray style={{ margin: '0', color: 'theme.gray600' }}>N/A</Text>),
        },
        {
          label: t('relevantTechniques'),
          value: properties?.techniques?.length > 0
            ? (
              properties
                .techniques
                .map((tech) => <Button key={tech} variant="link" style={{ marginRight: '0.5rem' }} externalLink={`${URL_MITRE}${tech}`}>{tech}</Button>)
            )
            : (<Text gray style={{ margin: '0', color: 'theme.gray600' }}>N/A</Text>),
        },
        {
          label: t('requiredDataConnectors'),
          value: properties?.requiredDataConnectors?.length > 0
            ? (
              <Wrap spacing={2}>
                {dataConnetors.map((connector) => <Tag key={connector} bg="#f5edd8">{connector}</Tag>)}
              </Wrap>
            )
            : (<Text gray style={{ margin: '0', color: 'theme.gray600' }}>N/A</Text>),
        },
        {
          label: t('tags'),
          value: properties?.tags?.length > 0
            ? (
              <Wrap spacing={2}>
                {properties?.tags.map((tag) => <Tag key={tag} bg="#f5edd8">{tag}</Tag>)}
              </Wrap>
            )
            : (<Text gray style={{ margin: '0', color: 'theme.gray600' }}>N/A</Text>),
        },
        {
          label: t('kind'),
          value: data.kind,
        },
        {
          label: t('queryFrequency'),
          value: (
            <Tooltip hasArrow label={properties.queryFrequency}>
              {`${t('every')} ${moment.duration(properties.queryFrequency).humanize(false)}`}
            </Tooltip>
          ),
        },
        {
          label: t('queryPeriod'),
          value: (
            <Tooltip hasArrow label={properties.queryPeriod}>
              {moment.duration(properties.queryPeriod).humanize(false)}
            </Tooltip>
          ),
        },
      ];
    }
    return [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <List.Primary rows={rows} />;
};

Metadata.propTypes = propTypes;

export default Metadata;
