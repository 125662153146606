import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Block from '~ui/Block';
import * as List from '~ui/List';
import { Button } from '~ui/Button';
import * as Permission from '~ui/Permission';
import { mdiCancel } from '@mdi/js';
import { useRouteMatch } from 'react-router-dom';
import translation from './ActiveService.translation';
import WeekList from '../WeekList';
import { RowComponentSelected } from '../CardRow';
import ServiceForm from '../ServiceForm';
import { myEnvironmentPermission } from '../../../../../../../../lib/utils/featureKeys';

const propTypes = {
  service: PropTypes.instanceOf(Object).isRequired,
  onDisableAction: PropTypes.func.isRequired,
};

const defaultProps = {};

const ActiveService = ({
  service,
  onDisableAction,
}) => {
  const { t } = translation;
  const { params: { customerId, entityId } } = useRouteMatch();
  const [loading, setLoading] = useState(false);

  const rows = useMemo(() => {
    const {
      description,
      hourPrice,
      monthlyPrice,
      weekDays,
      weekEnds,
      isFree,
      customBilling,
    } = service;
    return [
      { label: t('description'), value: description },
      { label: t('activeDays'), value: <WeekList onWeekDays={weekDays} onWeekEnds={weekEnds} /> },
      !customBilling && !isFree && { label: t('hourlyPrice'), value: hourPrice },
      !customBilling && !isFree && { label: t('monthlyPrice'), value: monthlyPrice },
      isFree && { label: t('monthlyPrice'), value: t('free') },
      customBilling && { label: t('monthlyPrice'), value: t('custom') },
    ];
  }, [service, t]);

  const handleonDisableAction = () => {
    setLoading(true);
    onDisableAction({ service });
  };

  return (
    <Block.Accordion
      title={service.name}
      description={service.description}
      override={(isOpen) => (
        <RowComponentSelected
          service={service}
          isOpen={isOpen}
          customerId={customerId}
          entityId={entityId}
        />
      )}
    >
      {() => (
        <>
          <List.Primary rows={rows} />

          <Permission.Render withFeature={[myEnvironmentPermission.write]}>
            <ServiceForm mode="EDIT" service={service}>
              <Button
                bg="ruby.900"
                onClick={handleonDisableAction}
                leftIcon={mdiCancel}
                isLoading={loading}
              >
                {t('deactivateService')}
              </Button>
            </ServiceForm>
          </Permission.Render>
        </>
      )}
    </Block.Accordion>
  );
};

ActiveService.propTypes = propTypes;
ActiveService.defaultProps = defaultProps;

export default ActiveService;
