import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '~ui/Button';
import * as List from '~ui/List';
import * as Badge from '~ui/Badge';
import translation from './AnalyticRule.translation';

const { t } = translation;

const TicketsList = ({ tickets, customerId }) => {
  const [showAll, setShowAll] = useState(false);

  const rows = tickets.map((ticket) => ({
    label: (<Badge.Status status={ticket.status} />),
    value: (
      <Button variant="link" internalLink={`/tickets/${customerId}/${ticket.ticket_id}/details`}>
        {ticket.subject}
      </Button>
    ),
  }));

  const btnText = showAll
    ? t('showLess')
    : `${t('showAll')} (${tickets.length})`;

  return (
    <List.Primary
      borderTop
      rows={[
        ...showAll ? rows : [rows[0]],
        tickets.length > 1 && {
          label: '',
          value: (
            <Button variant="link" onClick={() => setShowAll(!showAll)}>
              {btnText}
            </Button>
          ),
        }]}
    />
  );
};
TicketsList.propTypes = {
  tickets: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  customerId: PropTypes.string.isRequired,
};

export default TicketsList;
