import {
  take,
  call,
  takeEvery,
  cancel,
} from 'redux-saga/effects';
import * as t from './actionTypes';
import getActiveServices from './services/getActiveServices.service';
import saveService from './services/saveService.service';
import deleteService from './services/deleteService.service';

function* updateServiceWatcher(meta, { meta: { values, setSaving } }) {
  const {
    entityId,
    customerId,
    serviceName,
    onSuccess,
    onFail,
  } = meta;

  setSaving(true);
  const { activeServices } = yield call(getActiveServices, customerId, entityId);
  const selected = activeServices.filter(({ cloudops_service_name: name }) => name === serviceName);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < selected.length; i++) {
    const payload = { entityId, customerId, id: selected[i].cloudops_service_mapping_id };
    yield call(deleteService, payload);
  }

  const { success } = yield call(saveService, { entityId, customerId, id: values.operationHours });

  if (success) {
    yield call(onSuccess);
  } else {
    yield call(onFail);
  }

  setSaving(false);
}

export default function* rootWatcher() {
  while (true) {
    const { meta } = yield take(t.MOUNT);

    const tasks = [
      yield takeEvery(
        t.UPDATE_SERVICE,
        updateServiceWatcher,
        meta,
      ),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
