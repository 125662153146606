import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Block from '~ui/Block';
import * as Layout from '~ui/Layout';
import { Button } from '~ui/Button';
import { Spinner } from '@chakra-ui/react';
import ReactJson from 'react-json-view';
import { mdiArrowLeft } from '@mdi/js';

import translation from './TestRunLogs.translation';
import * as C from './TestRunLogs.styled';
import { useTestRunLog, useTestRunDetails } from '../../../../lib/services/appMonitor';

const { t } = translation;

const Log = ({ url }) => {
  const { data: log, isLoading: logLoading } = useTestRunLog(url);

  if (logLoading) {
    return (
      <Block.Center withPadding>
        <Spinner
          color="#133A5D"
          emptyColor="#DADADA"
          speed="0.65s"
          thickness="4px"
          size="xl"
        />
      </Block.Center>
    );
  }
  return (
    <C.StyledProperties>
      <ReactJson
        src={log || {}}
        theme="summerfruit:inverted"
        iconStyle="circle"
        name={null}
        indentWidth={3}
        collapsed={false}
        enableClipboard={false}
        displayObjectSize={false}
        displayDataTypes={false}
        sortKeys
      />
    </C.StyledProperties>
  );
};

Log.propTypes = {
  url: PropTypes.string,
};
Log.defaultProps = {
  url: null,
};

const TestRunLogs = () => {
  const { params } = useRouteMatch();
  const {
    customerId, entityId, testName, testId,
  } = params;

  const { data: testRunDetails, isLoading: detailsLoading } = useTestRunDetails({
    customerId, entityId, testName, testId,
  });

  if (detailsLoading) {
    return (
      <Block.Center withPadding>
        <Spinner
          color="#133A5D"
          emptyColor="#DADADA"
          speed="0.65s"
          thickness="4px"
          size="xl"
        />
      </Block.Center>
    );
  }

  return (
    <>
      <Layout.Omnibar.Main>
        <Layout.Omnibar.LeftActions>
          <Button
            variant="ghost"
            leftIcon={mdiArrowLeft}
            internalLink={`/app-monitor/${customerId}/${entityId}/${testName}/details/${testId}`}
          >
            {t('testRun')}
          </Button>
        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>
      <Layout.Scene>
        <Block.Stretch style={{ margin: '2rem' }}>

          {testRunDetails.log_file_urls.map((url) => <Log key={url} url={url} />) }

        </Block.Stretch>
      </Layout.Scene>
    </>
  );
};

export default TestRunLogs;
