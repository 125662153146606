import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as UserImage from '~ui/UserImage';
import { Button } from '~ui/Button';
import { Text, useTheme } from '@chakra-ui/react';
import * as C from '../Main.styled';

const propTypes = {
  person: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    mobile_number: PropTypes.string,
  }),

};
const defaultProps = {
  person: {
    email: '',
    name: '',
    role: '',
    mobile_number: '',
  },
};

const TeamRow = ({ person }) => {
  const { breakpoints } = useTheme();

  const {
    email, name, mobile_number: mobNr, role,
  } = person;

  const imgLink = useMemo(() => (email ? `${process.env.REACT_APP_CDN}/profile-picture/${window.btoa(email)}` : ''), [email]);

  return (
    <C.Person key={email} breakpoints={breakpoints}>
      <C.Picture>
        <UserImage.Circle
          size="2rem"
          name={name}
          email={email}
          href={imgLink}
        />
      </C.Picture>

      <C.Name>
        <Text fontWeight="600">
          {name}
        </Text>
      </C.Name>
      <C.Role>{role}</C.Role>

      <C.Number>
        <Button variant="link">{mobNr}</Button>
      </C.Number>

      <C.Email>
        <Button variant="link" mailto={email}>{email}</Button>
      </C.Email>
    </C.Person>
  );
};

TeamRow.propTypes = propTypes;
TeamRow.defaultProps = defaultProps;

export default TeamRow;
