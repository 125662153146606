import React, {
  useState, useMemo, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@chakra-ui/react';
import * as C from './Ring.styled';

const propTypes = {
  color: PropTypes.string,
  radius: PropTypes.number,
  stroke: PropTypes.number,
  progress: PropTypes.number,
  showPercentage: PropTypes.bool,
  useShadow: PropTypes.bool,
  useAnimation: PropTypes.bool,
  useValueAnimation: PropTypes.bool,
};

const defaultProps = {
  color: null,
  radius: 60,
  stroke: 4,
  progress: 95,
  showPercentage: false,
  useShadow: false,
  useAnimation: false,
  useValueAnimation: false,
};

const Ring = ({
  radius,
  stroke,
  progress,
  showPercentage,
  color,
  useShadow,
  useAnimation,
  useValueAnimation,
}) => {
  const { colors } = useTheme();

  const [value, setValue] = useState(useValueAnimation ? 0 : progress);

  const isValueSetRef = useRef(!useValueAnimation);

  const normalizedRadius = useMemo(
    () => radius - stroke * 0.5,
    [radius, stroke],
  );

  const circumference = useMemo(
    () => (normalizedRadius * 2 * Math.PI),
    [normalizedRadius],
  );

  const strokeDashoffset = useMemo(
    () => circumference - (value / 100) * circumference,
    [circumference, value],
  );

  // Effect for updating the value when the progress prop changes
  useEffect(() => {
    if (isValueSetRef.current) {
      setValue(progress);
    }
  }, [progress]);

  // Effect for setting the value on mount with delay, making the animation work
  useEffect(() => {
    if (!useValueAnimation) {
      isValueSetRef.current = true;
      return;
    }
    setTimeout(() => {
      setValue(progress);
      isValueSetRef.current = true;
    }, 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numberProgress = Math.floor(progress);
  return (
    <C.Container>
      <svg height={radius * 2} width={radius * 2}>
        {useShadow && (
        <C.Ring
          radius={radius}
          stroke={colors?.gray?.['200']}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset: 0 }}
          stroke-width={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        )}

        <C.Ring
          radius={radius}
          stroke={color || colors?.asurgent?.['500']}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          stroke-width={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          useAnimation={useAnimation}
        />
      </svg>
      {showPercentage && (
        <C.Text radius={radius}>
          {numberProgress}
          <C.Small>%</C.Small>
        </C.Text>
      )}
    </C.Container>
  );
};

Ring.propTypes = propTypes;
Ring.defaultProps = defaultProps;

export default Ring;
