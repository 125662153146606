import React, {
  useEffect, useMemo, useCallback, useState,
} from 'react';

import { useHistory } from 'react-router';
import DataBall from '~scenes/securityHours/Components/DataBalls/DataBall';
import {
  Heading, useTheme, Box, HStack, VStack, Text,
} from '@chakra-ui/react';
import {
  mdiDownload,
} from '@mdi/js';
import { useSecurityHours } from '~scenes/securityHours/context/SecurityHoursContext';
import * as Form from '~ui/Form';
import { Button } from '~ui/Button';
import * as Charts from '~ui/Charts';
import {
  TIER_1, TIER_2_ADVISORY, TIER_2_ANALYTICS, TIER_2_ENGINEERING, URL_STATE_KEY,
} from '~scenes/securityHours/constants';
import monthTranslation from '~ui/MonthPicker/MonthPicker.translation';
import LocalSort, { useLocalSort } from '~ui/Table/LocalSort';
import { getUpdatedQueryString } from '~lib/utils/helpers';
import translation from './SpentHoursSummary.translation';
import * as C from './SpentHoursSummary.styled';
import List from './components/List';

function removeSentinelPattern(string) {
  if (typeof string !== 'string') return '';
  const pattern = /\[Sentinel.*?]/g;
  return string.replace(pattern, '').trimStart();
}

function isSameYearAndMonth(date1, date2) {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
}

const formSpec = ({ t: _t }) => ({
  filter: {
    type: 'filterselect',
    label: _t('selectLabel'),
    placeholder: ' ',
    options: [
    ],
    tooltipPosition: 'left',
    props: {
      multiSelect: true,
      disableSearch: true,
    },
  },
});

const SpentHoursSummary = () => {
  const { colors } = useTheme();

  const { t } = translation;
  const { t: monthT } = monthTranslation;

  const [filter, setFilter] = useState([]);

  const history = useHistory();

  const {
    isServiceLevelAdvanced,
    monthsRange,
    selectedDate,
    selectedMonth, selectedCustomerId,
  } = useSecurityHours();

  const {
    year, month,
    hour_spent_summary: hourSpentSummary,
    tier_1_hours_spent_total: tier1HoursSpentTotal,
    tier_2_hours_spent_advisory: tier2HoursSpentAdvisory,
    tier_2_hours_spent_analytics: tier2HoursSpentAnalytics,
    tier_2_hours_spent_engineering: tier2HoursSpentEngineering,
    tier_2_hours_spent_total: tier2HoursSpentTotal,
  } = selectedMonth;

  const columns = [
    { label: t('id') },
    { label: t('hours') },
    { label: t('subject') },
    { label: t('service') },
    { label: '' },
  ];

  const sort = [
    { label: t('id'), value: 'ticket_id' },
    { label: t('hours'), value: 'hours_spent' },
    { label: t('subject'), value: 'ticket_subject' },
    { label: t('service'), value: 'group' },
  ];

  const defaultSortKey = 'ticket_id';

  const {
    sortKey,
    orderDesc,
    onSort,
  } = useLocalSort(defaultSortKey);

  const formData = Form.useFormBuilder(formSpec(translation));

  const trimmedSummary = useMemo(() => hourSpentSummary?.map((row) => {
    const { group, ticket_subject: ticketSubject } = row;

    return {
      ...row,
      group: group.replace('CloudOps Security SOC ', ''),
      ticket_subject: removeSentinelPattern(ticketSubject),
      renderKey: Math.random().toString(36).substring(2, 6),
    };
  }), [hourSpentSummary]);

  const tierValuesOptions = useMemo(() => {
    const tierValues = trimmedSummary?.map((row) => {
      const { group } = row;
      return group;
    });

    const uniqueTierValues = [...new Set(tierValues)];

    return uniqueTierValues?.map((tier) => ({ value: tier, label: tier }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trimmedSummary]);

  useEffect(() => {
    formData.updateFields([
      {
        name: 'filter',
        options: tierValuesOptions,
      },
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tierValuesOptions]);

  const {
    tier1Key, tier2AdvisoryKey, tier2AnalyticsKey, tier2EngineeringKey,
  } = useMemo(() => {
    const t1Key = TIER_1;
    const t2AdvisoryKey = TIER_2_ADVISORY;
    const t2AnalyticsKey = TIER_2_ANALYTICS;
    const t2EngineeringKey = TIER_2_ENGINEERING;

    return {
      tier1Key: t1Key,
      tier2AdvisoryKey: t2AdvisoryKey,
      tier2AnalyticsKey: t2AnalyticsKey,
      tier2EngineeringKey: t2EngineeringKey,
    };
  }, []);

  const filteredItems = useMemo(() => {
    if (filter.length === 0) {
      return trimmedSummary;
    }
    return trimmedSummary.filter((row) => filter.includes(row.group));
  }, [filter, trimmedSummary]);

  const exportData = useCallback(() => filteredItems.map((row) => {
    const { renderKey, ...rest } = row;
    return rest;
  }), [filteredItems]);

  const sortedItems = useMemo(() => {
    if (!sortKey) return filteredItems;

    return [...filteredItems].sort((a, b) => {
      const aValue = a[sortKey];
      const bValue = b[sortKey];

      if (aValue === bValue) return 0;

      if (orderDesc) {
        return aValue > bValue ? -1 : 1;
      }

      return aValue > bValue ? 1 : -1;
    });
  }, [filteredItems, sortKey, orderDesc]);

  const handleFormChange = useCallback((form) => {
    const { values } = form || {};
    const { filter: value } = values || {};

    if (value) {
      setFilter(value);
    } else {
      setFilter([]);
    }
  }, [setFilter]);

  const saveToFileName = `security-hours-${selectedCustomerId}-${year}-${month}.csv`;

  const chartData = [
    {
      name: tier1Key,
      value: tier1HoursSpentTotal,
    },
    {
      name: tier2EngineeringKey,
      value: tier2HoursSpentEngineering,
    },
    {
      name: tier2AdvisoryKey,
      value: tier2HoursSpentAdvisory,
    },
    {
      name: tier2AnalyticsKey,
      value: tier2HoursSpentAnalytics,
    },
  ];

  const tier1Color = colors?.blue?.['300'];
  const tier2EngineeringColor = colors?.gold?.['900'];
  const tier2AdvisoryColor = colors?.yellow?.['200'];
  const tier2AnalyticsColor = colors?.gold?.['300'];

  const chartColors = [
    tier1Color,
    tier2EngineeringColor,
    tier2AdvisoryColor,
    tier2AnalyticsColor,
  ];

  const handleHistoryGraphXLabelClick = useCallback((data) => {
    const { date, customerId } = data;

    const nextSocHoursUrlState = {
      selectedCustomerId: customerId,
      selectedDate: date,
    };

    const { location } = history;

    const updatedSearchQuery = getUpdatedQueryString(
      URL_STATE_KEY, nextSocHoursUrlState, location.search,
    );

    window.location.href = `${location.pathname}${updatedSearchQuery}`;
  }, [history]);

  const { historyGraphData, historyGraphBarsConfig } = useMemo(() => {
    if (!monthsRange || monthsRange?.length === 0) {
      return { historyGraphData: [], historyGraphBarsConfig: [] };
    }

    const graphData = monthsRange?.sort((a, b) => {
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);
      return aDate - bDate;
    })?.map(({ data, date }) => {
      const {
        tier_1_hours_spent_total: tier1,
        tier_2_hours_spent_advisory: tier2Advisory,
        tier_2_hours_spent_analytics: tier2Analytics,
        tier_2_hours_spent_engineering: tier2Engineering,
        cloudops_security_service_level: serviceLevel,
        month: monthNumber,
      } = data;

      const mIndex = monthNumber - 1;

      const monthTranslationKey = ['monthIndex', mIndex].join('');

      return {
        name: monthT(monthTranslationKey),
        date,
        serviceLevel,
        disableXClick: isSameYearAndMonth(selectedDate, new Date(date)),
        customerId: selectedCustomerId,
        [tier1Key]: tier1,
        [tier2AdvisoryKey]: tier2Advisory,
        [tier2AnalyticsKey]: tier2Analytics,
        [tier2EngineeringKey]: tier2Engineering,
      };
    });

    const barsConfig = [
      { dataKey: tier1Key, fill: tier1Color },
      { dataKey: tier2AdvisoryKey, fill: tier2AdvisoryColor },
      { dataKey: tier2AnalyticsKey, fill: tier2AnalyticsColor },
      { dataKey: tier2EngineeringKey, fill: tier2EngineeringColor },
    ];

    return { historyGraphData: graphData, historyGraphBarsConfig: barsConfig };
  }, [monthsRange,
    tier1Key,
    tier1Color,
    tier2AdvisoryKey,
    tier2AdvisoryColor,
    tier2AnalyticsKey,
    tier2AnalyticsColor,
    tier2EngineeringKey,
    tier2EngineeringColor,
    monthT,
    selectedDate,
    selectedCustomerId]);

  return (
    <C.Wrapper>
      <Heading as="h2" mb="2rem">
        {t('title')}
      </Heading>

      <Box sx={{ padding: '2rem 0', borderBottom: `1px solid ${colors?.gray?.['200']}` }}>

        <C.PieChartWrapper columns={!tier2HoursSpentTotal && !tier2HoursSpentTotal ? '1' : '2'}>
          <HStack justifyContent="center">
            <DataBall number={tier1HoursSpentTotal} label="tier 1" color={colors?.blue?.['300']} />
            <DataBall number={tier2HoursSpentTotal} label="tier 2" color={colors?.gold?.['300']} />
          </HStack>

          {!!tier2HoursSpentTotal && !!tier2HoursSpentTotal && (
          <VStack>
            <Charts.Pie data={chartData} unit="h" colors={chartColors} />
            {!isServiceLevelAdvanced && <Text fontSize={12} placeSelf="center" color="gray">{t('notGovernedServices')}</Text>}
          </VStack>
          )}

        </C.PieChartWrapper>
      </Box>

      { filteredItems.length > 0 && (
      <Box padding="2rem 0" paddingBottom="1rem" borderBottom={`1px solid ${colors?.gray?.['200']}`}>
        <Heading as="h2" mb="2rem">
          {t('summaryTitle')}
        </Heading>

        <HStack spacing="1rem" alignItems="flex-start">

          <Box>
            <Text fontSize="sm" fontWeight="bold">{t('sortOn')}</Text>
            <LocalSort
              sort={sort}
              activeSortKey={sortKey}
              orderDesc={orderDesc}
              onSort={onSort}
              size="lg"
            />
          </Box>

          <Box width="250px">
            <Form.Primary onChange={handleFormChange} form={formData}>
              {({ render }) => (
                <>
                  {render}
                </>
              )}
            </Form.Primary>
          </Box>

        </HStack>

        <List
          items={sortedItems}
          columns={columns}
          customerId={selectedCustomerId}
        />

        <Button mt="1rem" width="max-content" variant="outline" saveToFile={exportData} saveToFilename={saveToFileName} rightIcon={mdiDownload} sx={{ marginBottom: '1.5rem' }}>
          {t('export')}
        </Button>
      </Box>
      )}

      <Box padding="2rem 0">
        <Heading as="h2" mb="2rem">
          {t('historyTitle')}
        </Heading>

        {historyGraphData && historyGraphData?.length !== 0 && (
          <HStack justifyContent="center">
            <VStack maxWidth={800} width="100%">
              <Charts.Bar data={historyGraphData} barsConfig={historyGraphBarsConfig} unit="h" onXLabelClick={handleHistoryGraphXLabelClick} />
              {!isServiceLevelAdvanced && <Text fontSize={12} color="gray">{t('notGovernedServices')}</Text>}
            </VStack>
          </HStack>
        )}
      </Box>
    </C.Wrapper>
  );
};

export default SpentHoursSummary;
