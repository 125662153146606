import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'seviceWindowCard',
  sv: {
    relatedTicket: 'Relaterat ärende',
    customer: 'Kund',
    createdBy: 'Skapad av',
    viewDetails: 'Se detaljer',
    schedule: 'Schema',
    thisOccurrence: 'Detta tillfälle',
    finalOccurrence: 'Sista tillfället',
    cronExpression: 'Cronuttryck',
    cronDescription: 'Cronbeskrivning',
    duration: 'Varaktighet',
    seconds: 'sekunder',
    relations: 'Relationer',
    edit: 'Ändra',

    tempTicketIdMissing: 'Koppling till ärende saknas',

    deactivate: 'Avaktivera',
  },
  en: {
    relatedTicket: 'Related ticket',
    customer: 'Customer',
    createdBy: 'Created by',
    viewDetails: 'View details',
    schedule: 'Schedule',
    thisOccurrence: 'This occurrence',
    finalOccurrence: 'Final occurrence',
    cronExpression: 'Cron Expression',
    cronDescription: 'Cron description',
    duration: 'Duration',
    seconds: 'seconds',
    relations: 'Relations',
    edit: 'Edit',

    tempTicketIdMissing: 'Ticket-link is missing',

    deactivate: 'Deactivate',
  },
});
