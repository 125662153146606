import { useMemo } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export function getBackPathUrlParam(backButtonText = '') {
  const backPath = window.btoa(JSON.stringify({
    path: window.location.pathname,
    searchParams: window.location.search,
    backButtonText,
  }));

  return `?back=${backPath}`;
}

const defaultReturn = {
  path: '',
  searchParams: '',
  backButtonText: '',
};
export default function useBackPath() {
  const { search } = useLocation();
  return useMemo(() => {
    const { back } = queryString.parse(search);

    if (!back) {
      return defaultReturn;
    }

    try {
      const { path, searchParams, backButtonText } = JSON.parse(window.atob(back));

      return { path, searchParams, backButtonText };
    } catch (error) {
      return { ...defaultReturn, error };
    }
  }, [search]);
}
