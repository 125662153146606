import addTranslation from '../translations/addTranslation';

export default addTranslation({
  id: 'timeComponents',
  sv: {
    day0: 'Söndag',
    day1: 'Måndag',
    day2: 'Tisdag',
    day3: 'Onsdag',
    day4: 'Torsdag',
    day5: 'Fredag',
    day6: 'Lördag',
    month0: 'Jan',
    month1: 'Feb',
    month2: 'Mar',
    month3: 'Apr',
    month4: 'Maj',
    month5: 'Jun',
    month6: 'Jul',
    month7: 'Aug',
    month8: 'Sep',
    month9: 'Okt',
    month10: 'Nov',
    month11: 'Dec',
  },
  en: {
    day0: 'Sunday',
    day1: 'Monday',
    day2: 'Tuesday',
    day3: 'Wednesday',
    day4: 'Thursday',
    day5: 'Friday',
    day6: 'Saturday',
    month0: 'Jan',
    month1: 'Feb',
    month2: 'Mar',
    month3: 'Apr',
    month4: 'May',
    month5: 'Jun',
    month6: 'Jul',
    month7: 'Aug',
    month8: 'Sep',
    month9: 'Oct',
    month10: 'Nov',
    month11: 'Dec',
  },
});
