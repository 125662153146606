import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Wrap,
  Box,
  Spinner,
  Text,
} from '@chakra-ui/react';
import * as Layout from '~ui/Layout';
import * as Permission from '~ui/Permission';
import {
  TableSort,
  TableSearch,
  TableSearchProvider,
  TablePagination,
  TableHeader,
  TableFilterTags,
  TableFilterSelect,
} from '~ui/Table';
import * as Block from '~ui/Block';
import { TableContext } from '~ui/Table/data/context';

import { overviewSearch } from '~lib/services';

import * as Keys from '~lib/utils/historyStateKeys';
import Card from '../../../../modules/TagGroupCard';
import OverviewLayout from '../../../../components/OverviewLayout';
import translation from './TagGroupOverviewList.translation';
import { listFilterType } from '../../../../../../utils/propTypes';

const tagColors = {
  customer_display_name: 'blue',
  tags: 'gray',
};

const propTypes = {
  onUpdateListFilter: PropTypes.func.isRequired,
  listFilter: listFilterType,
  customerId: PropTypes.string.isRequired,
};

const defaultProps = {
  listFilter: null,
};

const TagGroupOverview = ({
  onUpdateListFilter, listFilter: globalListFilter, customerId,
}) => {
  const { t } = translation;
  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();

  const filterCollection = {
    customer_display_name: (_, value) => `${t('customer')}: ${value}`,
    tags_key_value: (_, value) => `${t('tags')}: ${value}`,
  };

  const sortList = [
    {
      value: [
        'ticket_priority_urgent_count',
        'ticket_priority_high_count',
        'ticket_priority_medium_count',
        'ticket_priority_low_count',

      ],
      label: t('prioritySortLabel'),
    },
  ];

  const callback = useCallback((state, azureSearchParser) => {
    const { is_stale: stale, ...restFiler } = state.filter;
    const newState = { ...state, filter: restFiler };
    const includeStale = stale !== undefined;
    const parsers = {
      filter: {
        tags_key_value: (value) => `tags_key_value/any(t: t eq '${value}')`,
      },
    };

    if (state.isFilterTrigger) {
      return {
        payload: azureSearchParser.facets(newState, state.filterKey, parsers),
        isGlobalAdmin,
        customerId,
      };
    }

    onUpdateListFilter({
      [Keys.DASHBOARD]: { ...newState },
    });

    return {
      payload: azureSearchParser.items(newState, parsers),
      isGlobalAdmin,
      customerId,
      includeStale,
    };
  }, [customerId, isGlobalAdmin, onUpdateListFilter]);

  const initialValues = useMemo(() => globalListFilter
  || { order_desc: true }, [globalListFilter]);

  return (
    <>
      <TableSearchProvider
        initialValues={initialValues}
        pageSize={15}
        payload={callback}
        fetcher={overviewSearch}
        urlStateKey={Keys.DASHBOARD}
        sort={sortList}
        globalStateCallback={onUpdateListFilter}
      >
        <Layout.Omnibar.Main>
          <Box flex="1">
            <TableHeader>
              <TableSearch />
              <TableSort />
            </TableHeader>
            <Wrap>
              {isGlobalAdmin && (
                <TableFilterSelect
                  label={t('customer')}
                  filterKey="customer_display_name"
                />
              )}

              <TableFilterSelect
                label={t('tags')}
                filterKey="tags_key_value"
              />
            </Wrap>
            <TableFilterTags
              configurations={filterCollection}
              colors={tagColors}
            />
          </Box>
        </Layout.Omnibar.Main>
        <Layout.Scene>

          <TableContext.Consumer>

            {({ rows, isLoading, state }) => {
              const { current } = state || {};
              const { query, filter } = current || {};

              const hasSearchOrFilter = query || (filter && Object.keys(filter).length > 0);

              if (isLoading) {
                return <Block.Center withPadding><Spinner /></Block.Center>;
              } if (rows && rows.length > 0) {
                return (
                  <OverviewLayout>
                    {rows.map((item) => (
                      <Card
                        key={item?.id}
                        customerId={item?.customer_id}
                        customerName={item?.customer_name}
                        entities={item?.entities}
                        urgent={item?.ticket_priority_urgent_count}
                        high={item?.ticket_priority_high_count}
                        medium={item?.ticket_priority_medium_count}
                        low={item?.ticket_priority_low_count}
                        tags={item?.tags}
                      />
                    ))}
                  </OverviewLayout>
                );
              }
              return (
                <Block.Center withPadding>

                  { hasSearchOrFilter
                    ? <Text>{t('noData')}</Text>
                    : <Block.Warning><Text>{t('configNeeded')}</Text></Block.Warning>}

                </Block.Center>
              );
            }}

          </TableContext.Consumer>

          <TablePagination />
        </Layout.Scene>
      </TableSearchProvider>
    </>
  );
};

TagGroupOverview.propTypes = propTypes;
TagGroupOverview.defaultProps = defaultProps;

export default TagGroupOverview;
