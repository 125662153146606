import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, Link } from 'react-router-dom';
import * as Layout from '~ui/Layout';
import * as Permission from '~ui/Permission';
import {
  Wrap,
  Flex,
  Box,
} from '@chakra-ui/react';
import {
  TableSort,
  TableSearch,
  TableSearchProvider,
  TablePagination,
  TableHeader,
  TableBody,
  TableRows,
  TableBodyHeader,
  TableFilterSelect,
  TableFilterTags,
  TableResultCount,
} from '~ui/Table';
import * as Keys from '../../../../../lib/utils/historyStateKeys';
import translation from './ReportsPage.translation';
import { searchReports } from '../../../../../lib/services/reports';
import { LIST_NAME } from '../constants';
import { listFilterType } from '../../../../../utils/propTypes';

const tagColors = {
  customer_display_name: 'blue',
  display_name: 'gold',
};

const ReportsPage = (
  { onUpdateListFilter, listFilter: globalListFilter },
) => {
  const permissions = Permission.usePermission();
  const { params: { customerId } } = useRouteMatch();
  const { t } = translation;
  const isGlobalAdmin = permissions.isGlobalAdmin();

  const callback = useCallback((state, azureSearchParser) => {
    const parsers = {};

    if (state.isFilterTrigger) {
      return {
        payload: azureSearchParser.facets(state, state.filterKey, parsers),
        global: isGlobalAdmin,
        customerId,
      };
    }

    onUpdateListFilter({
      [LIST_NAME]: { ...state },
    });
    return {
      payload: azureSearchParser.items(state, parsers),
      global: isGlobalAdmin,
      customerId,
    };
  }, [onUpdateListFilter, isGlobalAdmin, customerId]);

  const sortList = [
    {
      value: 'display_name',
      label: t('tableName'),
      default: true,
      desc: true,
    },
    // customer_name/id is not sortable
  ];

  const columns = [
    { label: t('tableName') },
    {
      label: t('tableCustomer'),
      render: permissions.isGlobalAdmin(),
    },
  ];

  const filterCollection = {
    customer_display_name: (_, value) => `${t('tableCustomer')}: ${value}`,
    display_name: (_, value) => `${t('tableName')}: ${value}`,
  };

  return (
    <>
      <TableSearchProvider
        pageSize={15}
        payload={callback}
        fetcher={searchReports}
        urlStateKey={Keys.ENV_LIST}
        sort={sortList}
        initialValues={globalListFilter}

      >
        <Layout.Omnibar.Main>
          <Box flex="1">
            <TableHeader>
              <TableSearch />
              <TableSort />
            </TableHeader>

            <Wrap>
              {isGlobalAdmin && (
                <TableFilterSelect
                  label={t('tableCustomer')}
                  filterKey="customer_display_name"
                />
              )}

              <TableFilterSelect
                label={t('tableName')}
                filterKey="display_name"
              />
            </Wrap>
            <TableFilterTags
              configurations={filterCollection}
              colors={tagColors}
            />
          </Box>
        </Layout.Omnibar.Main>
        <Layout.Scene>
          <TableResultCount fileName="reports.csv" />
          <TableBody columns={columns}>
            <TableBodyHeader />
            <TableRows>
              {(data, idx, RowComponent) => (
                <Link key={idx} to={`/reports/${data.customer_id}/${data.group_id}/report/${data.report_id}`} style={{ textDecoration: 'none' }}>

                  <RowComponent>
                    <Flex overflow="hidden" p={2}>
                      {data.display_name}
                    </Flex>

                    { isGlobalAdmin && (
                      <Flex p={2} alignItems="center">
                        {data.customer_display_name}
                      </Flex>
                    )}
                  </RowComponent>
                </Link>
              )}
            </TableRows>
          </TableBody>
          <TablePagination />
        </Layout.Scene>
      </TableSearchProvider>
    </>
  );
};

const propTypes = {
  onUpdateListFilter: PropTypes.func.isRequired,
  listFilter: listFilterType,
};

ReportsPage.propTypes = propTypes;

ReportsPage.defaultProps = {
  listFilter: null,
};

export default ReportsPage;
