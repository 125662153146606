import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Block from '~ui/Block';
import * as Graph from '~ui/Graph';
import { Spinner } from '@chakra-ui/react';
import transaltion from './AlertRuleMetricsWidget.translation';

const propTypes = {
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  style: PropTypes.instanceOf(Object),
  customerId: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  metricName: PropTypes.string,
  threshold: PropTypes.number,
  criticalThreshold: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Object),
  ]),
};

const defaultProps = {
  metricName: '',
  threshold: 0,
  criticalThreshold: null,
  style: {},
};

const AlertRuleMetricsWidget = ({
  onMount,
  onUnmount,
  onUpdate,
  style,
  customerId,
  entityId,
  metricName,
  threshold,
  criticalThreshold,
}) => {
  const [graphData, setGraphData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = transaltion;

  useEffect(() => {
    onMount({
      setGraphData,
      customerId,
      entityId,
      metricName,
      setLoading,
    });

    return () => onUnmount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (graphData !== null) {
      onUpdate({
        entityId,
        customerId,
        metricName,
        setGraphData,
        setLoading,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, entityId, metricName]);

  return (
    <Block.Center style={{ height: '250px', width: '100%', ...style }} renderTransparent>
      { loading && (
        <Spinner
          color="#133A5D"
          emptyColor="#DADADA"
          speed="0.65s"
          thickness="4px"
          size="xl"
        />
      )}
      { !loading && (
        <Graph.LineGraph
          data={graphData || []}
          xProp="timestamp"
          markerLines={[
            {
              value: threshold,
              title: t('threshold'),
              color: '#C62929',
            },
            criticalThreshold !== null && {
              value: criticalThreshold,
              title: t('criticalThreshold'),
              color: 'orange',
            },
          ]}
        />
      )}
    </Block.Center>
  );
};

AlertRuleMetricsWidget.propTypes = propTypes;
AlertRuleMetricsWidget.defaultProps = defaultProps;

export default AlertRuleMetricsWidget;
