import Api from '../api';

export const getSecurityHours = async ({ customerId, year, month }) => {
  const path = `/customer/${customerId}/security/hours/`;

  const params = {
    year,
    month,
  };

  return Api.get(path, null, params)
    .then((result) => (result))
    .catch((error) => ({ error }));
};
