import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import MdiIcon from '@mdi/react';
import { mdiPlus, mdiChevronRight } from '@mdi/js';
import { Button } from '~ui/Button';
import {
  Text, Heading, useTheme,
} from '@chakra-ui/react';
import * as C from './AvaliableActionRow.styled';
import translation from './AvaliableActionRow.translation';
import actionTranslation from '../../AutoActions.translation';
import { iconMapper } from './helpers';

const propTypes = {
  action: PropTypes.instanceOf(Object).isRequired,
  onAddAction: PropTypes.func.isRequired,
  isConfigurable: PropTypes.bool.isRequired,
};

const AvaliableActionRow = ({ action, onAddAction, isConfigurable }) => {
  const { colors, breakpoints } = useTheme();

  const { id, description, name } = action;
  const { t } = translation;
  const { fallback } = actionTranslation;

  const handleOnClick = useCallback(() => {
    onAddAction(action);
  }, [action, onAddAction]);

  return (
    <C.LinkCard breakpoints={breakpoints}>
      <C.Icon colors={colors}>
        <MdiIcon path={iconMapper(id)} size={1.5} />
      </C.Icon>
      <C.Content>
        <Heading as="h2" style={{ marginBottom: 0 }}>{fallback(id, name)}</Heading>
        <Text style={{ margin: 0 }}>
          {fallback(`${id}-desc`, description)}
        </Text>
      </C.Content>
      <C.Icon colors={colors}>
        <Button
          variant="ghost"
          tooltip={isConfigurable ? t('configureService') : t('addService')}
          rightIcon={isConfigurable ? mdiChevronRight : mdiPlus}
          onClick={handleOnClick}
        />
      </C.Icon>
    </C.LinkCard>
  );
};

AvaliableActionRow.propTypes = propTypes;

export default AvaliableActionRow;
