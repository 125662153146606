import * as t from './actionTypes';

const initalState = {
  errors: [],
  newComments: [],
  uploadedFiles: [],
};

export default (state = initalState, { type, meta }) => {
  switch (type) {
    case t.INIT_TASK_ADD_COMMENTS:

      return {
        ...state,
        ...initalState,
      };

    case t.COMMENT_ERROR:

      return {
        ...state,
        errors: [...state.errors, ...meta.errors],
      };

    case t.FILE_UPLOADED:
      return {
        ...state,
        uploadedFiles: [...state.uploadedFiles, meta.uploadedFile],
      };

    default:
      return state;
  }
};
