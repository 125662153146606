import moment from 'moment';
import { useQuery } from 'react-query';
import Api from '../api';

const fillStartEndArr = (start, end) => {
  const numberOfDays = moment(end).diff(moment(start), 'days') + 1;

  return [...Array(numberOfDays)].map((_, index) => {
    const curDate = moment(start).add(index, 'days').format('YYYY-MM-DD');
    return { date: curDate, value: null };
  });
};

const joinRealNullData = (apiRes, start, end) => {
  const startToEndArr = fillStartEndArr(start, end);

  const joinedArray = startToEndArr.reduce((acc, cur) => {
    const existsInApiRes = apiRes.some((entry) => entry.date === cur.date);
    // skip genenrated null-entry if exists in api-response
    if (existsInApiRes) {
      return acc;
    }
    return [...acc, cur];
  }, [...apiRes]);

  return joinedArray.sort((a, b) => moment(a.date) - moment(b.date));
};

export const searchWebTestRuns = async ({
  entityId, customerId, testName, payload,
}) => {
  const path = `customer/${customerId}/entity/${entityId}/webtest/${testName}/webtestrun/search`;

  return Api.post(path, payload);
};

export const searchWebTests = async ({ isGlobalAdmin, customerId, payload }) => {
  const path = isGlobalAdmin ? '/webtest/search' : `customer/${customerId}/webtest/search`;

  return Api.post(path, payload);
};

export const useScreenshot = (imgLink, config) => {
  const getScreenshot = async () => {
    const trimmedUrl = imgLink.replace(/^https.*asurgent.se\/v1\//, '');

    return Api
      .get(
        trimmedUrl,
        null,
        null,
        { responseType: 'blob' },
      )
      .then((res) => URL.createObjectURL(res))
      .catch(() => '');
  };

  return useQuery(
    ['useScreenshot', imgLink],
    () => getScreenshot(),
    { ...config },
  );
};

export const useTestRunLog = (url, config) => {
  const getTestRunLog = async () => {
    const trimmedUrl = url.replace(/^https.*asurgent.se\/v1\//, '');
    return Api.get(trimmedUrl);
  };

  return useQuery(
    ['useTestRunLog', url],
    () => getTestRunLog(),
    { ...config },
  );
};

export const useTestRunDetails = ({
  customerId, entityId, testName, testId,
}, config) => {
  const getTestRunDetails = async () => {
    const path = `customer/${customerId}/entity/${entityId}/webtest/${testName}/webtestrun/${testId}`;

    return Api.get(path);
  };

  return useQuery(
    ['useTestRunDetails'],
    () => getTestRunDetails(),
    { ...config },
  );
};

export const useSearchWebTestRuns = ({
  succeeded,
  customerId,
  entityId,
  testName,
  startDate,
  endDate,
}, config) => {
  const getYearlyRuns = async () => {
    const path = `customer/${customerId}/entity/${entityId}/webtest/${testName}/webtestrun/search`;
    const filter = `succeeded eq ${succeeded} and timestamp ge ${startDate} and timestamp lt ${endDate}`;

    const facetOffset = moment(startDate).format('Z');

    const payload = {
      search_string: '',
      filter,
      facets: [`timestamp,interval:day,timeoffset:${facetOffset}`],
      order_by: [],
      search_fields: [],
      page_size: 0,
      page: 1,
    };

    return Api.post(path, payload).then((res) => {
      const { facets: { timestamp } } = res;
      const fixedData = timestamp
        .map((fail) => ({ date: moment(fail.value).format('YYYY-MM-DD'), value: fail.count }));

      return joinRealNullData(
        fixedData,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
      );
    });
  };

  return useQuery(
    ['useYearlyTestRuns', succeeded, startDate,
      endDate, testName, entityId],
    () => getYearlyRuns(),
    { ...config },
  );
};
