import styled from 'styled-components';
import { Heading } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';

const H2Heading = ({ children, ...rest }) => <Heading as="h2" {...rest}>{children}</Heading>;
H2Heading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};
H2Heading.defaultProps = {
  children: '',
};

export const StepContainer = styled.div`
    width: 100%;
    padding: 2rem;
    max-width: 54rem;
    display: grid;
    overflow: hidden;
    grid-template-rows: 2rem 1fr;
    grid-template-columns: 2rem 1fr;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    grid-template-areas: 
        "step title" 
        "content content";

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.sm}) {
        grid-column-gap: 2.5rem;
        grid-template-areas: 
        "step title" 
        ". content";
    }
`;

export const ActionContainer = styled(StepContainer)`
    grid-template-areas: 
        "title title" 
        "content content";
    @media screen and (min-width: ${({ breakpoints }) => breakpoints.sm}) {
        grid-column-gap: 2.5rem;
        grid-template-areas: 
        ". title" 
        ". content";
    }
`;

export const Sequence = styled.div`
    display: grid;
    grid-template-columns: 2rem 1fr;
    position: relative;
    grid-gap: 2.5rem;
    grid-template-areas: 'stepAction stepAction'; 

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.sm}) {
        grid-template-areas: 'stepNr stepAction'; 
    }
`;

export const Content = styled.div`
    grid-area: content;
`;

export const Steps = styled(Content)`
    display: grid;
    width: 100%;
    max-width: 54rem;
    overflow: hidden;
    grid-template-columns: 2rem 1fr;
    grid-gap: 2.5rem;
    grid-template-areas: 
        "desc desc" 
        "selectedActions selectedActions"
        "createBtn createBtn";
    

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.sm}) {
        grid-column-gap: 2.5rem;
        grid-template-areas: ". desc" "selectedActions selectedActions" ". createBtn";
    }

`;

export const StepCircle = styled.div`
    width: 2rem;
    height: 2rem;
    background: white;
    border: 1px solid;
    border-color: ${({ colors }) => colors?.blue?.['900']};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    line-height: 0;
`;

export const StepNumber = styled.div`
   grid-area: stepNr;
    display: none;

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.sm}) {
        grid-template-areas: 'stepNr stepAction'; 
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 3.75rem;
    }
`;

export const StepAction = styled.div`
    grid-area: stepAction;
`;

export const SequenceContainer = styled.div`
    grid-area: selectedActions;
 
    & > ${Sequence}:not(:last-child) {
    
     ${StepNumber}:after {
            content: '';
            background: black;
            height: 100%;
            width: 1px;
            position: absolute;
            top: calc(2rem + 3.75rem); // stepnumber margin + number-circle height
        } 
    } 
`;

export const ChosenSteps = styled(StepContainer)`
     @media screen and (min-width: ${({ breakpoints }) => breakpoints.sm}) {
        grid-column-gap: 2.5rem;
        grid-template-areas: 
        "step title" 
        "content content";
    }
`;

export const Step = styled.div`
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background-color: ${({ colors }) => colors?.blue?.['900']};
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1rem;
    grid-area: step;
`;

export const Title = styled(H2Heading)`
    grid-area: title;
    margin-bottom: 0;
    line-height: initial;
    text-align: left;
    padding-left: 2rem;

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.sm}) {
        padding-left: 0;
        text-align: center;
    }
`;

export const StepTitle = styled(H2Heading)`
    grid-area: title;
    margin-bottom: 0;
    line-height: initial;
`;

export const AllActionsTextContainer = styled.div`
    padding: 1rem;
`;

export const AllActionsButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem 0;

    span {
        margin-right: 0.5rem;
    }
`;
