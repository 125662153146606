export const formSpec = ({ t: _t }, disabled) => ({
  hours: {
    type: 'number',
    label: _t('hours'),
    minValue: 1,
    validator: {
      condition: (value) => !!value,
      errorMessage: _t('hoursFieldError'),
    },
    disabled: () => disabled,
  },
  description: {
    type: 'textarea',
    label: _t('comment'),
    validator: {
      condition: (value) => !!value,
      errorMessage: _t('descriptionFieldError'),
    },
    disabled: () => disabled,
  },
}
);
