import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Notification',
  sv: {
    entitiesAndResources: 'Entiteter och resurer',
  },
  en: {
    entitiesAndResources: 'Entities and resource groups',
  },
});
