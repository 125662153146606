import styled from 'styled-components';

export const Days = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Day = styled.div`
    display: flex;
    padding: 0.25rem;
    margin-right: 0.25rem;
    font-size: 0.625rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    text-transform: uppercase;
    background: ${({ isActive, colors }) => {
    if (isActive) {
      return colors?.blue?.['900'];
    }
    return 'transparent';
  }};
    color: ${({ colors, isActive }) => (isActive ? colors?.white : colors?.gray?.['800'])};
`;
