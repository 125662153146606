import * as t from './actionTypes';

export const onMount = (payload) => ({
  type: t.MOUNT,
  meta: payload,
});

export const onUnmount = () => ({
  type: t.UNMOUNT,
  meta: {},
});
export const onUpdateAgentConfig = (payload) => ({
  type: t.UPDATE_AGENT_CONFIG,
  meta: payload,
});
