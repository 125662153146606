import styled from 'styled-components';

export const Container = styled.div`
    flex-grow: 1; 
    margin-right: 1.5rem;
    svg:first-child {
        margin-right: 1.25rem;
    }
    p {
        margin: 0;
    }
`;
