import React from 'react';
import { connect } from 'react-redux';
import Navigation from './components/Navigation';
import * as A from './actions';

const mapStateToProps = ({ model }) => ({
  entity: model.entity,
});

const mapDispatchToProps = (dispatch) => ({
  onMount: (payload) => dispatch(A.onMount(payload)),
  onUnmount: (payload) => dispatch(A.onUnmount(payload)),
});

const NavigationComponent = connect(mapStateToProps, mapDispatchToProps)(Navigation);

const withNavigation = (Component, asPlain = false) => (props) => (
  <NavigationComponent asPlain={asPlain}>
    {
      ({ customerId, entityId, entity }) => (
        <Component
          entityStatus={entity.status}
          customerId={customerId}
          entityId={entityId}
          entity={entity}
          {...props}
        />
      )
    }
  </NavigationComponent>
);

export default withNavigation;
