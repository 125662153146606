import {
  SERVICE_LEVEL_ADVANCED,
  SERVICE_LEVEL_BASIC,
  SERVICE_LEVEL_STANDARD,
  TIER_1, TIER_2_ADVISORY, TIER_2_ANALYTICS, TIER_2_ENGINEERING,
} from '../../constants';

export const includedServicesDict = {
  [SERVICE_LEVEL_BASIC]: [TIER_1],
  [SERVICE_LEVEL_STANDARD]: [
    TIER_1,
    TIER_2_ENGINEERING,
    TIER_2_ANALYTICS,
    TIER_2_ADVISORY,
  ],
  [SERVICE_LEVEL_ADVANCED]: [
    TIER_1,
    TIER_2_ENGINEERING,
    TIER_2_ANALYTICS,
    TIER_2_ADVISORY,
  ],
};
