import styled from 'styled-components';

export const Notification = styled.div`
    position: fixed;
    bottom: 0;
    right: 2rem;
    padding: 0.875rem 1.5rem;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    background: #fff;
    p {
        margin: 0;
    }
`;

export const Circle = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background: ${({ colors }) => colors?.green?.['50']};
    color: ${({ colors }) => colors?.green?.['700']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    border: 1px solid;
    border-color: ${({ colors }) => colors?.green?.['50']};
    animation: 1s ease anim;

    @keyframes anim { 
        0% {
            border-color: ${({ colors }) => colors?.green?.['50']};
        }
        50% {
            border-color: ${({ colors }) => colors?.green?.['100']};
        }
        100% {
            border-color: ${({ colors }) => colors?.green?.['50']};
        }
    }
`;
