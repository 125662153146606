import * as Sentry from '@sentry/browser';
import * as i18n from '~ui/translations';

import { GLOBAL_ADMIN } from './permissionRoles';

const { i18next } = i18n;

export const startSentry = () => {
  if (!process.env) {
    return null;
  }

  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      dsn: `https://${process.env.REACT_APP_SENTRY_ID}`,
      release: `cloudops@${process.env.REACT_APP_VERSION}`,
      environment: process.env.REACT_APP_SENTRY_ENV,
    });
  }

  return true;
};

export const setScopeUserEmailErrorLogging = ({ userId, userRoles, customerId }) => {
  Sentry.configureScope((scope) => {
    scope.setUser({ id: userId });
    scope.setLevel('error');
    scope.setTag('locale', i18next.language);
    scope.setTag('customer_id', customerId);

    if (typeof userRoles === 'object') {
      const isAdmin = Object.values(userRoles).find((r) => r === GLOBAL_ADMIN);
      if (isAdmin) {
        scope.setTag('global_admin', true);
      }
    }

    scope.setExtra('roles', userRoles);
  });
};
export default startSentry;
