import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Block from '~ui/Block';
import * as List from '~ui/List';
import { Button } from '~ui/Button';
import * as Permission from '~ui/Permission';
import {
  mdiCancel,
  mdiCheck,
  mdiArrowRight,
} from '@mdi/js';

import { myEnvironmentPermission } from '../../../../../../../../lib/utils/featureKeys';
import AlertRuleMetricsWidget from '../../../../../../../../modules/AlertRuleMetricsWidget';
import { alertRuleMapper } from './helpers';
import translation from './AlertRule.translation';

const propTypes = {
  alertRule: PropTypes.instanceOf(Object).isRequired,
  buttonAction: PropTypes.func.isRequired,
  isActiveRule: PropTypes.bool,
};

const defaultProps = {
  isActiveRule: false,
};

const AlertRulesPage = ({
  alertRule,
  isActiveRule,
  buttonAction,
}) => {
  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();

  const { t } = translation;
  const rows = useMemo(() => [
    ...alertRuleMapper(alertRule, t, isGlobalAdmin),
    permissions.withFeatures(myEnvironmentPermission.write) && {
      row: (
        <Button
          p={0}
          m="1rem 0"
          variant="ghost"
          style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
          rightIcon={mdiArrowRight}
          internalLink={`/my-environment/${alertRule.customer_id}/${alertRule.entity_id}/alert-rules/edit/${alertRule.id}`}
        >
          { t('edit') }
        </Button>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], [alertRule, isGlobalAdmin, t]);

  return (
    <Block.Accordion title={alertRule.name} description={alertRule.description}>
      {() => (
        <>
          <List.Primary rows={rows} />
          <Block.Stretch renderTransparent style={{ marginTop: '2.5rem' }}>
            <AlertRuleMetricsWidget
              customerId={alertRule.customer_id}
              entityId={alertRule.entity_id}
              threshold={alertRule.threshold || 0}
              metricName={alertRule.metric_name}
            />
          </Block.Stretch>
          <Permission.Render withFeature={[myEnvironmentPermission.write]}>
            <Block.Right renderTransparent style={{ marginTop: '1rem' }}>
              { isActiveRule && (
                <Button
                  colorScheme="ruby"
                  onClick={buttonAction}
                  leftIcon={mdiCancel}
                >
                  {t('deactivate')}
                </Button>
              )}
              { !isActiveRule && (
              <Button
                onClick={buttonAction}
                leftIcon={mdiCheck}
              >
                {t('activate')}
              </Button>
              )}
            </Block.Right>
          </Permission.Render>
        </>
      )}
    </Block.Accordion>
  );
};

AlertRulesPage.propTypes = propTypes;
AlertRulesPage.defaultProps = defaultProps;

export default AlertRulesPage;
