import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Block from '~ui/Block';
import * as Permission from '~ui/Permission';
import {
  Heading, Spinner, useToast, useTheme,
} from '@chakra-ui/react';
import Tables from './Tables';
import translation from './Main.translation';
import * as C from './Main.styled';

import { myEnvironmentPermission } from '../../../../../../../lib/utils/featureKeys';

const { t } = translation;

const propTypes = {
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  redeployAgent: PropTypes.func.isRequired,
  entityStatus: PropTypes.string,
};

const defaultProps = {
  entityStatus: '-',
};

const Main = ({
  onMount,
  onUnmount,
  redeployAgent,
  entityStatus,
}) => {
  const { colors } = useTheme();

  const toast = useToast();
  const { params } = useRouteMatch();
  const { entityId, customerId } = params;
  const permissions = Permission.usePermission();

  const [vmConfig, setVMConfig] = useState(null);
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [heartbeat, setHeartbeat] = useState(null);
  const [loadingHeartbeat, setLoadingHeartbeat] = useState(true);

  const [hasCloudOps, setHasCloudOps] = useState(null);

  useEffect(() => {
    onMount({
      setLoadingConfig,
      setLoadingHeartbeat,
      setVMConfig,
      setHasCloudOps,
      setHeartbeat,
      onSuccess: () => toast({
        description: t('success'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      }),
      onFail: () => toast({
        description: t('fail'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      }),
    });
    return () => onUnmount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingConfig || loadingHeartbeat) {
    return (
      <Block.Center>
        <Spinner
          color="#133A5D"
          emptyColor="#DADADA"
          speed="0.65s"
          thickness="4px"
          size="xl"
        />
      </Block.Center>
    );
  }

  if (vmConfig) {
    return (
      <>
        <Heading as="h2">{ t('title')}</Heading>
        <C.Paragraph colors={colors}>
          { t('description')}
        </C.Paragraph>
        <Tables.Details
          canWrite={permissions.withFeatures(myEnvironmentPermission.write)}
          vmConfig={vmConfig}
          heartbeat={heartbeat}
          entityStatus={entityStatus}
          redeployAgent={() => redeployAgent(vmConfig)}
        />

        <Tables.Deploy vmConfig={vmConfig} />
        <Tables.Monitor
          canWrite={permissions.withFeatures(myEnvironmentPermission.write)}
          vmConfig={vmConfig}
          entityId={entityId}
          customerId={customerId}
        />
        <Tables.Queries
          canWrite={permissions.withFeatures(myEnvironmentPermission.write)}
          vmConfig={vmConfig}
          entityId={entityId}
          customerId={customerId}
        />
        <Tables.WebTests
          canWrite={permissions.withFeatures(myEnvironmentPermission.write)}
          vmConfig={vmConfig}
          entityId={entityId}
          customerId={customerId}
        />
      </>
    );
  }
  return (
    <Block.Emptystate title={t('noAgentTitle')}>
      {hasCloudOps ? (<p>{t('errorHasCloudOps')}</p>) : (<p>{t('errorNoCloudOps')}</p>)}
    </Block.Emptystate>
  );
};

Main.propTypes = propTypes;
Main.defaultProps = defaultProps;

export default Main;
