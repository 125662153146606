import addTranslation from '../../../translations/addTranslation';

export default addTranslation({
  id: 'startEnd',
  sv: {
    started: 'Startade',
    remaining: 'Kvar',
    ends: 'Slutar',
    duration: 'Varar',
    lasted: 'Varade',
    day0: 'Sön',
    day1: 'Mån',
    day2: 'Tis',
    day3: 'Ons',
    day4: 'Tor',
    day5: 'Fre',
    day6: 'Lör',
    month0: 'Jan',
    month1: 'Feb',
    month2: 'Mar',
    month3: 'Apr',
    month4: 'Maj',
    month5: 'Jun',
    month6: 'Jul',
    month7: 'Aug',
    month8: 'Sep',
    month9: 'Okt',
    month10: 'Nov',
    month11: 'Dec',
    seconds: 'sekunder',

    utcTime: 'UTC tid',
  },
  en: {
    started: 'Started',
    remaining: 'Remaining',
    ends: 'Ends',
    duration: 'Duration',
    lasted: 'Lasted',
    day0: 'Sun',
    day1: 'Mon',
    day2: 'Tue',
    day3: 'Wed',
    day4: 'Thu',
    day5: 'Fri',
    day6: 'Sat',
    month0: 'Jan',
    month1: 'Feb',
    month2: 'Mar',
    month3: 'Apr',
    month4: 'May',
    month5: 'Jun',
    month6: 'Jul',
    month7: 'Aug',
    month8: 'Sep',
    month9: 'Oct',
    month10: 'Nov',
    month11: 'Dec',
    seconds: 'seconds',

    utcTime: 'UTC time',
  },
});
