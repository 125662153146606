import React, {
  memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import IncidentsCard from '../IncidentsCard';

const propTypes = {
  customerId: PropTypes.string.isRequired,
  customerName: PropTypes.string,
  entities: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  urgent: PropTypes.number.isRequired,
  high: PropTypes.number.isRequired,
  medium: PropTypes.number.isRequired,
  low: PropTypes.number.isRequired,
  tags: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

const defaultProps = {
  customerName: '',
  tags: [],
};

const TagGroupCard = ({
  customerId,
  customerName,
  entities,
  urgent,
  high,
  medium,
  low,
  tags,
}) => {
  const highestPriorityLevel = (() => {
    if (urgent) return 4;
    if (high) return 3;
    if (medium) return 2;
    if (low) return 1;
    return 0;
  })();

  const name = useMemo(() => tags?.map(({ value }) => value).join(' '), [tags]);
  const formatedTags = useMemo(() => tags?.map(({ key, value }) => [key, value].join(': ')), [tags]);

  return (

    <IncidentsCard
      customerName={customerName}
      name={name}
      urgent={urgent}
      high={high}
      medium={medium}
      low={low}
      highestPriorityLevel={highestPriorityLevel}
      customerId={customerId}
      entities={entities}
      tags={formatedTags}
    />
  );
};

TagGroupCard.propTypes = propTypes;
TagGroupCard.defaultProps = defaultProps;

export default memo(TagGroupCard);
