import Api from '../../../../../lib/api';

const getEntityById = async ({ customerId, entityId }) => {
  const path = `customer/${customerId}/entity/${entityId}`;

  return Api.get(path)
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default getEntityById;
