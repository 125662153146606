import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'ServiceLevel',
  sv: {
    title: 'Service nivå',
    basicServiceDescription: 'Er aktiva tjänst granskar high severity incidenter (i er miljö) och eskalerar dessa till Asurgent SOC. 20h ingår som kan spenderas på "hanterade tjänster". Resurser går att addera till denna tjänst, såsom: extra Tier 1-tid, utveckling, Tier 2-rådgivning och/eller hantering- av skarpa incidenter som identifierats av Tier 1.',
    standardServiceDescription: 'Er aktiva tjänst granskar medium och high severity larm (I er miljö) och eskalerar sessa till CloudOps Security. 40h inår som kan spenderas på "hanterade tjänster". Resurser går att addera till denna tjänsten, så som utveckling, rådgivning eller hantering av riktiga incidenter som inte ryms inom dessa 40h.',
    advancedServiceDescription: '',
    servicesTitle: 'Hanterade tjänster',
    dataBallLabelHoursIncluded: 'Timmar inkluderade',
    includedHoursTooltip: 'Antalet timmar som ingår i service nivån',
    dataBallLabelHoursLeft: 'Timmar kvar',
    hoursLeftTooltip: 'Antalet timmar som återstår att spendera i service nivån',
    dataBallLabelHoursSpentPercent: 'av inkluderade timmar spendare',
    hoursSpentTooltip: 'Antalet timmar som spenderats i service nivån',
    noService: 'Ingen security tjänst aktiv för vald månad.',
  },
  en: {
    title: 'Service level',
    basicServiceDescription: 'Your active service plan reviews high severity incidents (in your environment) and escalate them to Asurgent SOC. 20 hours per month is included, that can be spent on governed services. Resources can be added to this service, such as additional Tier 1 time, Tier 2 engineering tasks, advisory, or handling of true positive incidents discovered by tier 1.',
    standardServiceDescription: 'Active service plan reviews medium and high severity alerts (in your environment) and escalate them to Asurgent CloudOps security. 40 hours per month is included, that can be spent on governed services.  Recourses can be added to this service, as, engineering tasks, advisory, or handling of real incidents when the included 40 aren´t enough.',
    advancedServiceDescription: '',
    servicesTitle: 'Governed services',
    dataBallLabelHoursIncluded: 'Hours included',
    includedHoursTooltip: 'The number of hours included in the service level',
    dataBallLabelHoursLeft: 'Hours left',
    hoursLeftTooltip: 'The number of hours left to spend in the service level',
    dataBallLabelHoursSpentPercent: 'of included hours spent',
    hoursSpentTooltip: 'The number of hours spent in the service level',
    noService: 'No security service active for the selected month.',
  },
});
