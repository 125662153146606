import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useTheme, Flex, Text, CloseButton,
} from '@chakra-ui/react';
import { mdiArrowRight } from '@mdi/js';
import { Button as UiButton } from '~ui/Button';
import moment from 'moment';
import { getBackPathUrlParam } from '~lib/hooks/useBackPath';
import { Link } from 'react-router-dom';
import translation from './PopOverDetail.translation';

import * as C from './PopOverDetail.styled';

const ticketTab = 'ticket';
const resourceTab = 'resource';

const propTypes = {
  iconCircleColor: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  alertRule: PropTypes.instanceOf(Object),
  entity: PropTypes.instanceOf(Object).isRequired,
  Icon: PropTypes.func.isRequired,
  iconText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  alertRule: null,
};

const PopOverDetail = ({
  iconColor, iconCircleColor, entity, alertRule, Icon,
  iconText, onClose,
}) => {
  const [activeTab, setActiveTab] = useState(alertRule?.ticket_id ? ticketTab : resourceTab);

  const { colors } = useTheme();
  const { t } = translation;

  const backPathParam = getBackPathUrlParam(t('backButtonText'));

  const hasTicket = Boolean(alertRule?.ticket_id);
  const linkToEntity = `/my-environment/${entity?.customer_id}/${entity?.entity_id}/information${backPathParam}`;
  const linkToTicket = hasTicket && `/tickets/${entity?.customer_id}/${alertRule?.ticket_id}/details${backPathParam}`;

  const ticketContent = (
    <>
      <Flex justifyContent="space-between">

        <C.MetaGroup>
          <Text
            fontFamily="Poppins"
            fontSize="12px"
            letterSpacing={0.8}
            textTransform="uppercase"
            color={colors?.gray?.['700']}
            mb={0}
          >
            {t('ticketId')}
          </Text>
          <Text
            lineHeight={1.3}
            color={colors?.black}
            m={0}
            fontSize={12}
          >
            {alertRule?.ticket_id}

          </Text>
        </C.MetaGroup>

        <Flex>

          <Text
            mr="0.5rem"
            fontFamily="Poppins"
            fontSize="12px"
            letterSpacing={0.8}
            textTransform="uppercase"
          >
            {iconText}
          </Text>
          <C.IconWrapper circleColor={iconCircleColor}>
            <Icon fill={iconColor} />
          </C.IconWrapper>

        </Flex>

      </Flex>

      <C.MetaGroup>
        <Text
          fontFamily="Poppins"
          fontSize="12px"
          letterSpacing={0.8}
          textTransform="uppercase"
          color={colors?.gray?.['700']}
          mb={0}
        >
          {t('subject')}
        </Text>
        <Text
          lineHeight={1.3}
          color={colors?.black}
          m={0}
          fontSize={12}
        >
          {alertRule?.ticket_subject}

        </Text>
      </C.MetaGroup>

      <Flex justifyContent="space-between">
        <C.MetaGroup>
          <Text
            fontFamily="Poppins"
            fontSize="12px"
            letterSpacing={0.8}
            textTransform="uppercase"
            color={colors?.gray?.['700']}
            mb={0}
          >
            status
          </Text>
          <Text color={colors?.black} m={0} fontSize={12}>{alertRule?.ticket_status}</Text>
        </C.MetaGroup>

        <C.MetaGroup>
          <Text
            fontFamily="Poppins"
            fontSize="12px"
            letterSpacing={0.8}
            textTransform="uppercase"
            color={colors?.gray?.['700']}
            mb={0}
          >
            {t('modified')}
          </Text>
          <Text
            color={colors?.black}
            m={0}
            fontSize={12}
          >
            { moment(alertRule?.ticket_modified).fromNow()}

          </Text>
        </C.MetaGroup>

        <C.MetaGroup>
          <Text
            fontFamily="Poppins"
            fontSize="12px"
            letterSpacing={0.8}
            textTransform="uppercase"
            color={colors?.gray?.['700']}
            mb={0}
          >
            {t('type')}
          </Text>
          <Text color={colors?.black} m={0} fontSize={12}>{alertRule?.ticket_type}</Text>
        </C.MetaGroup>
      </Flex>

      <C.MetaGroup>
        <Text
          fontFamily="Poppins"
          fontSize="12px"
          letterSpacing={0.8}
          textTransform="uppercase"
          color={colors?.gray?.['700']}
          mb={0}
        >
          {t('alertRule')}
        </Text>
        <Text color={colors?.black} m={0} fontSize={12}>{alertRule?.alert_rule_name}</Text>
      </C.MetaGroup>

      <Text
        fontFamily="Poppins"
        fontSize="12px"
        letterSpacing={0.8}
        textTransform="uppercase"
        color={colors?.gray?.['700']}
        mb={0}
      >
        {t('alertDescription')}
      </Text>
      <Text
        lineHeight={1.3}
        color={colors?.black}
        m={0}
        fontSize={12}
      >
        {alertRule?.alert_rule_description}
      </Text>
    </>

  );

  const resourceContent = (
    <>
      <C.MetaGroup>
        <Text
          fontFamily="Poppins"
          fontSize="12px"
          letterSpacing={0.8}
          textTransform="uppercase"
          color={colors?.gray?.['700']}
          mb={0}
        >
          {t('resourceName')}
        </Text>
        <Text color={colors?.black} m={0} fontSize={12}>{entity?.name}</Text>
      </C.MetaGroup>

      <C.MetaGroup>
        <Text
          fontFamily="Poppins"
          fontSize="12px"
          letterSpacing={0.8}
          textTransform="uppercase"
          color={colors?.gray?.['700']}
          mb={0}
        >
          {t('resourceGroup')}
        </Text>
        <Text color={colors?.black} m={0} fontSize={12}>{entity?.resourse_group}</Text>
      </C.MetaGroup>

      {entity.region && (
      <C.MetaGroup>
        <Text
          fontFamily="Poppins"
          fontSize="12px"
          letterSpacing={0.8}
          textTransform="uppercase"
          color={colors?.gray?.['700']}
          mb={0}
        >
          region
        </Text>
        <Text color={colors?.black} m={0} fontSize={12}>{entity?.region}</Text>
      </C.MetaGroup>
      )}

      <Text
        fontFamily="Poppins"
        fontSize="12px"
        letterSpacing={0.8}
        textTransform="uppercase"
        color={colors?.gray?.['700']}
        mb={0}
      >
        { t('containerName')}
      </Text>
      <Text color={colors?.black} m={0} fontSize={12}>{entity?.container_name}</Text>

      <Text
        fontFamily="Poppins"
        fontSize="12px"
        letterSpacing={0.8}
        textTransform="uppercase"
        color={colors?.gray?.['700']}
        mb={0}
      >
        container id
      </Text>
      <Text color={colors?.black} m={0} fontSize={12}>{entity?.container_id}</Text>
    </>
  );

  return (
    <C.Wrapper>
      <C.PopOverTop colors={colors}>
        <Flex justifyContent="space-between">
          <Flex flexDirection="column" paddingBottom="1rem">
            <Text m={0} color={colors?.black} fontFamily="Poppins" fontSize="12px" letterSpacing={0.8} textTransform="uppercase">{entity?.customer_name}</Text>
            <Text m={0} color={colors?.black} fontFamily="Poppins" fontSize="12px" letterSpacing={0.8} textTransform="uppercase">{entity?.customer_id}</Text>
          </Flex>

          <Flex flexDirection="column" alignItems="end" justifyContent="space-between">

            <CloseButton
              color={colors?.red?.['700']}
              size="sm"
              onClick={onClose}
              style={{
                position: 'relative',
                top: '-0.5rem',
                right: '-0.5rem',
              }}
            />
            <Flex
              style={{
                position: 'relative',
                bottom: '-1px',
              }}
            >

              {hasTicket && (
                <C.Tab
                  colors={colors}
                  isActive={activeTab === ticketTab}
                  style={{
                    marginRight: '0.5rem',
                  }}
                >
                  <UiButton
                    onClick={() => setActiveTab(ticketTab)}
                    variant="ghost"
                    style={C.TabButtonInLineStyles}
                  >
                    {t('ticket')}
                  </UiButton>
                </C.Tab>
              )}

              <C.Tab
                colors={colors}
                isActive={activeTab === resourceTab}
              >

                <UiButton
                  onClick={() => setActiveTab(resourceTab)}
                  variant="ghost"
                  style={C.TabButtonInLineStyles}
                >
                  {t('resource')}
                </UiButton>
              </C.Tab>

            </Flex>
          </Flex>
        </Flex>

      </C.PopOverTop>
      <C.PopOverBody>

        {activeTab === ticketTab && ticketContent}
        {activeTab === resourceTab && resourceContent}
      </C.PopOverBody>

      <C.PopOverBottom colors={colors}>

        {hasTicket && activeTab === ticketTab && (

        <Link to={linkToTicket}>
          <UiButton
            color={colors?.blue?.['700']}
            variant="ghost"
            style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
            rightIcon={mdiArrowRight}
          >
            {t('goToTicket')}
          </UiButton>
        </Link>
        ) }

        {activeTab === resourceTab && (
          <Link to={linkToEntity}>
            <UiButton
              color={colors?.blue?.['700']}
              variant="ghost"
              style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
              rightIcon={mdiArrowRight}
            >
              {t('goToResource')}
            </UiButton>
          </Link>
        )}

      </C.PopOverBottom>
    </C.Wrapper>
  );
};

PopOverDetail.propTypes = propTypes;
PopOverDetail.defaultProps = defaultProps;

export default PopOverDetail;
