import React from 'react';
import {
  Heading, useTheme, Box, Text, HStack, SimpleGrid,
} from '@chakra-ui/react';
import { useSecurityHours } from '~scenes/securityHours/context/SecurityHoursContext';
import DataBall from '~scenes/securityHours/Components/DataBalls';

import translation from './ApprovedHours.translation';
import * as C from './ApprovedHours.styled';

import ApproveHours from './parts/ApproveHours';

import List from './components/List';

function getSpentPercent(hoursSpent, hoursAdded, hoursLeft) {
  if (hoursLeft === 0 || hoursLeft < 0) return 100;
  if (hoursLeft && hoursAdded && hoursLeft === hoursAdded) return 0;
  return Math.round((hoursSpent / hoursAdded) * 100);
}

const ApprovedHours = () => {
  const { colors } = useTheme();
  const { t } = translation;

  const columns = [
    { label: t('approvedHoursColumn') },
    { label: t('timeStampColumn') },
    { label: t('addedByColumn') },
    { label: t('commentColumn') },
  ];

  const { selectedMonth } = useSecurityHours();
  const {
    added_hourly_quotas: addedHourlyQuotas,
    service_level_total_hours_added: hoursAdded,
    service_level_total_hours_added_spent: hoursSpent,
    service_level_total_hours_added_left_to_spend: hoursLeft,
  } = selectedMonth;

  const spentOverLimit = hoursSpent > hoursAdded ? hoursSpent - hoursAdded : 0;
  const hoursSpentPercent = getSpentPercent(hoursSpent, hoursAdded, hoursLeft);

  return (
    <C.Wrapper colors={colors}>

      <C.InnerSection>

        <Heading as="h2" mb="2rem">
          {t('title')}
        </Heading>

        <SimpleGrid mb={['2rem', null, null, '1rem']} spacing="2rem" columns={[1, null, null, 2]}>

          <Text>
            {t(`approvedHoursDescription`)}
          </Text>

          <HStack justifyContent="center">
            <HStack alignItems="flex-start" wrap="wrap">

              <DataBall number={hoursAdded} label={t('dataBallLabelHoursAdded')} color={colors?.blue?.['100']} infoText={t('hoursAddedTooltip')} />
              <DataBall number={hoursLeft} label={t('dataBallLabelHoursLeft')} color={colors?.green?.['100']} infoText={t('hoursLeftTooltip')} />
              {!hoursAdded && !hoursSpent ? null : <DataBall number={hoursSpentPercent} label={t('dataBallLabelHoursSpentPercent')} color={colors?.blue?.['800']} showAsProgress infoText={t('hoursSpentTooltip')} />}
              {!!spentOverLimit && <DataBall number={spentOverLimit} label={t('dataBallLabelSpentOverLimit')} color={colors?.red?.['300']} infoText={t('hoursSpentOverLimitTooltip')} />}
            </HStack>
          </HStack>

        </SimpleGrid>
      </C.InnerSection>

      <C.InnerSection>
        <ApproveHours />
      </C.InnerSection>

      {addedHourlyQuotas?.length > 0 && (
      <Box pt="2rem">
        <Heading as="h2" mb="2rem">
          {t('approvedHoursLogTitle')}
        </Heading>

        <List
          items={addedHourlyQuotas}
          columns={columns}
        />
      </Box>
      )}
    </C.Wrapper>
  );
};

export default ApprovedHours;
