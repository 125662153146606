import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'IncidentsOverviewCard',
  sv: {
    view: 'Visa detaljer',
    total: 'Total',
    urgent: 'Brådskande',
    high: 'Hög',
    medium: 'Medium',
    low: 'Låg',
    activeIncidents: 'pågående incidenter',
  },
  en: {
    view: 'View details',
    total: 'Total',
    urgent: 'Urgent',
    high: 'High',
    medium: 'Medium',
    low: 'Low',
    activeIncidents: 'ongoing incidents',
  },
});
