import styled from 'styled-components';

import * as Form from '~ui/Form';
import { Button } from '~ui/Button';

export const MetaForm = styled(Form.Primary)`
    border-bottom: 1px solid #DADADA;
    margin-bottom: 1rem;

    /* match the metadata-headers */
    p {
        font-size: 0.625rem;
        text-transform: uppercase;
    }

`;

export const Cancel = styled(Button)`
    margin-right: 1rem;
    &:hover {
        background: ${({ colors }) => colors?.gray?.['50']};
    }
`;
