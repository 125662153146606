import navigationHocSaga from './hoc/Navigation/saga';
import alertRulesSaga from './scenes/AlertRules/saga';
import cloudOpsPageSaga from './scenes/CloudOpsService/saga';
import informationSaga from './scenes/Information/saga';
import cloudOpsVMAgentSaga from './scenes/CloudOpsVMAgent/saga';
import autoActionsSaga from './scenes/AutoActions/saga';

export default [
  navigationHocSaga,
  ...cloudOpsVMAgentSaga,
  ...cloudOpsPageSaga,
  ...informationSaga,
  ...alertRulesSaga,
  ...autoActionsSaga,
];
