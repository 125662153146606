import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Block from '~ui/Block';
import * as Layout from '~ui/Layout';
import * as Permission from '~ui/Permission';
import { Button } from '~ui/Button';
import { Spinner } from '@chakra-ui/react';
import { useRouteMatch } from 'react-router';
import { mdiArrowLeft } from '@mdi/js';
import translation from './Navigation.translation';
import { useWorkspace } from '../../../../lib/services/workspace';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

const Navigation = ({ children }) => {
  const { t } = translation;
  const { params } = useRouteMatch();
  const { customerId, workspaceId } = params;

  const permissions = Permission.usePermission();

  const ACTIVTE_PATH = `/security/${customerId}/${workspaceId}`;
  const TEMPLATS_PATH = `${ACTIVTE_PATH}/templates`;
  const ANALYTICRULES_PATH = `${ACTIVTE_PATH}/analytic-rules`;

  const {
    isSuccess, isLoading, data, isError,
  } = useWorkspace(customerId, workspaceId, {
    refetchOnMount: false,
  });

  const title = useMemo(() => {
    if (isSuccess) {
      const { workspace_name: workSpace, customer_name: name } = data;
      if (permissions.isGlobalAdmin()) {
        return `${workSpace} (${name})`;
      }
      return workSpace;
    }
    return null;
  }, [data, isSuccess, permissions]);

  if (isLoading) {
    return (
      <Layout.Scene>
        <Block.Center>
          <Spinner
            color="#133A5D"
            emptyColor="#DADADA"
            speed="0.65s"
            thickness="4px"
            size="lg"
          />
        </Block.Center>
      </Layout.Scene>
    );
  }

  if (isError) {
    return (
      <Layout.Scene>
        <Block.Center>
          { t('workspaceNotExist')}
        </Block.Center>
      </Layout.Scene>
    );
  }

  return (
    <>
      <Layout.Omnibar.Main>
        <Layout.Omnibar.LeftActions>
          <Button
            variant="ghost"
            internalLink={`/security/${customerId}`}
            leftIcon={mdiArrowLeft}
          >
            {t('back')}
          </Button>
        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>
      <Layout.Scene>
        <Block.SubnavigationBlock
          title={title}
          navigationList={[
            {
              label: t('activeAnalyticRules'),
              path: ANALYTICRULES_PATH,
              isActive: window.location.pathname === ANALYTICRULES_PATH,
            },
            {
              label: t('templateRules'),
              path: TEMPLATS_PATH,
              isActive: window.location.pathname === TEMPLATS_PATH,
            },
          ]}
        >
          { children(data) }
        </Block.SubnavigationBlock>
      </Layout.Scene>
    </>
  );
};

Navigation.propTypes = propTypes;

export default Navigation;
