import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import * as Block from '~ui/Block';
import * as Layout from '~ui/Layout';
import { Button } from '~ui/Button';
import * as Svg from '~ui/Icons';
import { Spinner, useTheme } from '@chakra-ui/react';
import { mdiArrowLeft } from '@mdi/js';
import useBackPath from '~lib/hooks/useBackPath';
import * as K from '../../../../../../lib/utils/historyStateKeys';
import * as C from './MyEnvironmentNavigation.styled';
import translation from './MyEnvironmentNavigation.translation';

const vmTypes = [
  'Microsoft.Compute/virtualMachines',
  'Microsoft.SqlVirtualMachine/SqlVirtualMachines',
  'ec2_instance',
];

const MyEnvironmentNavigation = ({
  entity,
  asPlain,
  children,
  ...props
}) => {
  const { colors } = useTheme();

  const { params } = useRouteMatch();
  const { entityId, customerId } = params;
  const { t } = translation;

  const backPath = useBackPath();

  useEffect(() => {
    props.onMount({ entityId, customerId });

    return () => props.onUnmount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!entity || entity.id !== entityId) {
    return (
      <Layout.Scene>
        <Block.Center withPadding>
          <Spinner
            color="#133A5D"
            emptyColor="#DADADA"
            speed="0.65s"
            thickness="4px"
            size="xl"
          />
        </Block.Center>
      </Layout.Scene>
    );
  }

  if (asPlain) {
    return (
      <>
        { children({ entity, entityId, customerId }) }
      </>
    );
  }

  return (
    <>
      <Layout.Omnibar.Main>
        <Layout.Omnibar.LeftActions>
          <Button
            variant="ghost"
            clearStateKeys={[
              K.ENV_SERVICE_WINDOW,
              K.ENV_TICKETS,
              K.ENV_REPORTS,
            ]}
            leftIcon={mdiArrowLeft}
            internalLink={backPath?.path || `/my-environment/${customerId}/`}
          >
            {backPath?.backButtonText || t('myEnv')}
          </Button>
        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>
      <Layout.Scene>
        <Block.SubnavigationBlock
          title={entity.name}
          navigationList={[
            [
              {
                label: t('information'),
                path: `/my-environment/${customerId}/${entityId}/information`,
                isActive: window.location.pathname === `/my-environment/${customerId}/${entityId}/information`,
              },
              {
                label: t('alertRules'),
                path: `/my-environment/${customerId}/${entityId}/alert-rules`,
                isActive: window.location.pathname === `/my-environment/${customerId}/${entityId}/alert-rules`,
              },
              {
                label: t('tickets'),
                path: `/my-environment/${customerId}/${entityId}/tickets`,
                isActive: window.location.pathname === `/my-environment/${customerId}/${entityId}/tickets`,
              },
              {
                label: t('serviceWindows'),
                path: `/my-environment/${customerId}/${entityId}/service-windows`,
                isActive: window.location.pathname === `/my-environment/${customerId}/${entityId}/service-windows`,
              },
            ],
            [
              {
                row: (
                  <C.AsurgentHeader colors={colors}>
                    <Svg.IconAsurgent width="1.875rem" />
                    <span className="label">CloudOps</span>
                    {/*
                      ToDO: When we can get if a entity is mapped from this endpoint
                      <span className="active" />
                    */}
                  </C.AsurgentHeader>
                ),
              },
              entity.source !== 'o365' && {
                label: t('services'),
                path: `/my-environment/${customerId}/${entityId}/cloudops`,
                isActive: window.location.pathname === `/my-environment/${customerId}/${entityId}/cloudops`,
                iconStyle: { margin: '0 0.75rem' },
              },
              entity.source !== 'o365' && {
                label: t('automation'),
                path: `/my-environment/${customerId}/${entityId}/auto-actions`,
                isActive: window.location.pathname === `/my-environment/${customerId}/${entityId}/auto-actions`,
              },
              vmTypes.includes(entity.type) && {
                label: t('cloudopsVMAgent'),
                path: `/my-environment/${customerId}/${entityId}/cloudops-vmagent`,
                isActive: window.location.pathname === `/my-environment/${customerId}/${entityId}/cloudops-vmagent`,
              },
            ],
          ]}
        >
          { children({ entity, entityId, customerId }) }
        </Block.SubnavigationBlock>
      </Layout.Scene>
    </>
  );
};

MyEnvironmentNavigation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  entity: PropTypes.instanceOf(Object),
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  asPlain: PropTypes.bool,
};

MyEnvironmentNavigation.defaultProps = {
  children: null,
  entity: {},
  asPlain: false,
};

export default MyEnvironmentNavigation;
