import * as t from './actionTypes';

export const onMount = (payload) => ({
  type: t.MOUNT,
  meta: payload,
});

export const onUnmount = (payload) => ({
  type: t.UNMOUNT,
  meta: payload,
});

export const onSaveProcessModel = (payload) => ({
  type: t.SAVE_PROCESSMODEL,
  meta: payload,
});

export const onRemoveProcessModel = (payload) => ({
  type: t.REMOVE_PROCESSMODEL,
  meta: payload,
});
