import styled from 'styled-components';

export const AsurgentHeader = styled.div`
  height: 3rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  border-bottom: 1px solid ${({ colors }) => colors?.gray?.['300']};
  
  svg {
    margin-right: 0.5rem;
  }

  .label {
    font-weight: bold;
    flex: 1;
  }

  .active {
    background-color: ${({ colors }) => colors?.ruby?.['800']};
    border-radius: 100%;
    width: 0.5rem;
    height: 0.5rem;
  }
`;
