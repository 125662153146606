import styled from 'styled-components';
import getContrastTextColor from '~ui/utils/getContrastText';
import {
  Text,
} from '@chakra-ui/react';

export const Wrapper = styled.div`
`;

export const TooltipOuterWrapper = styled.div`
  padding: 1rem;
  border-radius: 5px;
  background-color: ${({ bg }) => bg || '#fff'};
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  font-family: Poppins, sans-serif;
  `;

export const TooltipLabel = styled.div`
font-weight: bold;`;
export const TooltipValue = styled.div``;

const LegendText = styled(Text)`
  padding: 0.5rem;
  margin: 0;
  font-size: 14px;
  font-weight: medium;
`;

export const LegendValue = styled(LegendText)`
  min-width: ${({ valueLength }) => valueLength}rem;
  background-color: ${({ bg }) => bg};
  color: ${({ bg }) => getContrastTextColor(bg)};
  text-align: center;
`;

export const LegendLabel = styled(LegendText)`
  text-transform: uppercase;
`;
