import addTranslation from '../../../translations/addTranslation';

export default addTranslation({
  id: 'BlockAccortion',
  sv: {
    details: 'Visa detaljer',
  },
  en: {
    details: 'View details',
  },
});
