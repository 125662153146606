import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'ServiceWindowsMain',
  sv: {
    emptystate: 'Kunde inte hitta några servicefönster',
    searchLabel: 'Sök servicefönster',
    tableReason: 'Orsak',
    requestedBy: 'Skapad av',
    start: 'Start',
    end: 'Slut',
    modified: 'Ändrad',
    duration: 'Varaktighet',
    customer: 'Kund',
    filterDrawerTitle: 'Servicefönster',
    changeCustomer: 'Ändra kund',
    changeRequestedBy: 'Ändra skapare',
    onlyActive: 'Bara aktiva',
  },
  en: {
    emptystate: 'Could not find any service windows',
    searchLabel: 'Search service windows',
    tableReason: 'Reason',
    requestedBy: 'Requested by',
    start: 'Start',
    end: 'End',
    modified: 'Modified',
    duration: 'Duration',
    customer: 'Customer',
    filterDrawerTitle: 'Service windows',
    changeCustomer: 'Change customer',
    changeRequestedBy: 'Change requester',
    onlyActive: 'Only active',
  },
});
