import Api from '../../../../../../../../../lib/api';

const deleteService = ({ customerId, entityId, id }) => {
  const path = `/customer/${customerId}/entity/${entityId}/cloudopsservice/mapping/${id}`;

  return Api.delete(path)
    .then((success) => ({ success }))
    .catch((error) => {
      if (error?.response?.data) {
        return { error: error.response.data };
      }

      return { error };
    });
};

export default deleteService;
