import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AppMonitor.TestRunDetails',
  sv: {
    status: 'Status',
    date: 'Datum',
    executionTime: 'Exekveringstid',
    message: 'Meddelande',
    step: 'Steg',
    errorTitle: 'Något gick fel',
    errorMessage: 'Kunde inte hämta detaljer för testkörning',
    viewLogs: 'Se loggar',
    testRuns: 'Testkörningar',
    screnshots: 'Screenshots',
    success: 'Lyckades',
    failed: 'Misslyckades',
  },
  en: {
    status: 'Status',
    date: 'Date',
    executionTime: 'Execution time',
    message: 'Message',
    step: 'Step',
    errorTitle: 'Something went wrong',
    errorMessage: 'Could not get details for test run',
    viewLogs: 'View logs',
    testRuns: 'Test runs',
    screnshots: 'Screenshots',
    success: 'Succeeded',
    failed: 'Failed',
  },
});
