import Api from '../../../../../lib/api';

const getOnCall = async () => {
  const path = `/config/callservicedesk`;

  return Api.get(path)
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default getOnCall;
