import {
  take,
  call,
  cancel,
  takeEvery,
} from 'redux-saga/effects';
import * as t from './actionTypes';
import getAlertRuleService from './services/getAlertRule.service';
import getMetricDefinitionsService from './services/getMetricDefinitions.service';
import updateAlertRuleService from './services/updateAlertRule.service';

import {
  mapMetricDefinitions,
  parseFormDataToPayload,
  handleFormErrors,
} from '../../helpers';

function* loadAlertRule({
  setAlertRule,
  customerId,
  entityId,
  alertRuleId,
}) {
  const { success: rules } = yield call(
    getAlertRuleService,
    { customerId, entityId, alertRuleId },
  );

  if (rules) {
    setAlertRule(rules);
    return rules;
  }

  setAlertRule(null);
  return {};
}

function* loadMetricDefinitionsAction({ setMetricDefinitions, ...payload }) {
  const { success: definitions } = yield call(getMetricDefinitionsService, payload);

  if (definitions) {
    const result = yield call(mapMetricDefinitions, definitions.result);
    const metricOptions = result.map(({ value }) => value);

    setMetricDefinitions(metricOptions.map((item) => ({ value: item, label: item })));
  } else {
    setMetricDefinitions([]);
  }
}

function* updateAlertRuleTask({ customerId, entityId, alertRule }, { meta }) {
  const {
    values,
    onError,
    onSuccess,
  } = meta;

  const payload = yield call(parseFormDataToPayload, {
    values,
    enabled: 1,
    defaultValues: alertRule,
  });

  const {
    success,
    error,
  } = yield call(updateAlertRuleService, { customerId, entityId, payload });

  if (success) {
    yield call(onSuccess);
  } else if (error) {
    yield call(onError, handleFormErrors(error, values));
  }
}

export default function* rootWatcher() {
  while (true) {
    const { meta } = yield take(t.MOUNT);
    const {
      setLoading,
      setAlertRule,
      alertRuleId,
      setMetricDefinitions,
      entityId,
      customerId,
    } = meta;

    setLoading(true);
    const alertRule = yield call(loadAlertRule, {
      setAlertRule,
      customerId,
      entityId,
      alertRuleId,
    });
    yield call(loadMetricDefinitionsAction, {
      setMetricDefinitions,
      entityId,
      customerId,
    });
    setLoading(false);

    const tasks = [
      yield takeEvery([t.UPDATE_ALERTRULE], updateAlertRuleTask, {
        customerId,
        entityId,
        alertRule,
      }),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
