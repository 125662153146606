import React from 'react';
import { Button } from '~ui/Button';
import * as Layout from '~ui/Layout';
import * as Block from '~ui/Block';
import { mdiArrowLeft } from '@mdi/js';
import { useHistory } from 'react-router';
import { TableSearchProvider } from '~ui/Table';
import { useRouteMatch } from 'react-router-dom';
import { Spinner, Heading } from '@chakra-ui/react';
import * as Keys from '../../../../lib/utils/historyStateKeys';
import translation from './Edit.translation';
import { ServiceWindowMutationProvider } from '../../serviceWindowMutationContext';
import { searchEntities } from '../../../../lib/services/entities';
import { useGetServiceWindow, useGetServiceWindowEntities } from '../../../../lib/services/serviceWindow';

import Notification from '../components/Notification';
import Steps from '../components/Steps';

const { t } = translation;

const Edit = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const { customerId, swId } = params;

  const serviceWindow = useGetServiceWindow(
    customerId,
    swId,
  );

  const entities = useGetServiceWindowEntities(
    customerId,
    serviceWindow?.data?.entity_ids,
  );

  const sortList = [
    { value: 'is_mapped', label: t('cloudopsActive') },
    { value: 'name', label: t('name'), default: true },
    { value: 'resource_group', label: t('resourceGroup') },
  ];

  const payload = (state, azureSearchParser) => {
    const newState = { ...state, filter: { ...state.filter } };

    const parsers = { };

    if (state.isFilterTrigger) {
      return {
        payload: azureSearchParser.facets(newState, state.filterKey, parsers),
        includeStale: false,
        global: true,
        customerId,
      };
    }
    return {
      payload: azureSearchParser.items(newState, parsers),
      includeStale: false,
      global: true,
      customerId,
    };
  };

  if (serviceWindow.isLoading || entities.isLoading) {
    return (
      <Block.Center>
        <Spinner
          color="#133A5D"
          emptyColor="#DADADA"
          speed="0.65s"
          thickness="4px"
          size="lg"
        />
      </Block.Center>
    );
  }

  return (
    <ServiceWindowMutationProvider value={{
      customerId,
      serviceWindow: serviceWindow.data,
      entities: entities?.data?.result,
    }}
    >
      <Layout.Omnibar.Main>
        <Layout.Omnibar.LeftActions>
          <Button
            variant="ghost"
            leftIcon={mdiArrowLeft}
            onClick={() => history.goBack()}
          >
            {t('back')}
          </Button>
        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>
      <Layout.Scene>
        <TableSearchProvider
          pageSize={15}
          payload={payload}
          fetcher={searchEntities}
          urlStateKey={Keys.SW_DETAILS}
          sort={sortList}
        >
          <Block.Center style={{ padding: '1rem 0' }}>

            <Heading as="h2" style={{ marginTop: '2rem' }}>
              {t(`title`)}
            </Heading>
            <Steps isEditMode />
            <Notification />

          </Block.Center>
        </TableSearchProvider>
      </Layout.Scene>
    </ServiceWindowMutationProvider>
  );
};

export default Edit;
