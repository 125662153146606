import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Securtiy.Component.WorkspaceCard',
  sv: {
    view: 'Visa',
    avaliable: 'Tillgängliga datakällor för detta workspace',
  },
  en: {
    view: 'View',
    avaliable: 'Available data sources in workspace',
  },
});
