import styled from 'styled-components';

export const Squares = styled.g`
    cursor: pointer;
`;
export const Months = styled.g`
    text {
        font-size: 12px;
    }
`;

export const Weekdays = styled.g`
    text {
        font-size: 12px;
    }
`;
