import Api from '../../lib/api';

const getUserContext = (graph, userCameFromLogin) => {
  const payload = {};

  if (userCameFromLogin) {
    if (graph?.userPhoto) {
      Object.assign(payload, {
        profile_photo: graph.userPhoto,
      });
    }
  }

  return Api.post('authentication/usercontext', payload, null)
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default getUserContext;
