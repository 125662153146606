import styled from 'styled-components';

export const InfoBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    p {
        margin-top: 0;
        margin-bottom: 0.5rem;
    }
`;

export const BottomText = styled.div`
   padding: 0 2rem;
`;

export const TestStatus = styled.span`
    font-size: 0.625rem;
    line-height: 0.625rem;
    border-radius: 2px;
    display: flex;
    align-items: center;
    max-width: 100%;
    font-weight: 700;
    color: #fff;
    padding: 0.25rem;
    background: ${({ colors, succeeded }) => (succeeded ? colors?.green?.['400'] : colors?.ruby?.['400'])};
`;

const Centered = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Expander = styled(Centered)`
    cursor: pointer;
    min-width: 9.375rem;
    width: 100%;
    padding: 0.5rem;
    border-top: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
    grid-area: link;
    &:hover > :first-child {
        background: ${({ colors }) => colors?.gray?.['100']};
    }
    @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
        border-top: none;
        border-left: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
    }
    span {
        margin: 0;
    }
    a {
        border-radius: 100%;
    }
`;

export const Heatmap = styled.div`
    flex-basis: 350px;
    max-width: 100%;
    overflow: hidden;
    height: 100%;
    padding-right: 1.25rem; 
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    grid-area: heatmap;
    margin: 0 1.25rem 0 1.25rem;

    @media screen and (min-width: 800px) {
        margin:0;
        width: 350px;
    }
`;

export const MapContainer = styled.div`
    height: 7.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Text = styled.div`
    flex-grow: 1;
    grid-area: text;
    overflow: hidden;
    white-space: pre;
    word-break: break-word;
    padding: 1.25rem 1.25rem 0 1.25rem;
    
    @media screen and (min-width: 800px) {
        padding: 1.5625rem 2rem;
    }
`;

export const Card = styled.div`
    width: 100%; 
    overflow: hidden;
    border: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
    box-shadow: 0 3px 6px -2px rgba(32, 33, 36, 0.28);
    display: grid; 
    grid-template-areas: "text" "heatmap" "link";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto auto;
    grid-gap: 1rem;
    border-radius: 5px;
    min-height: 9.375rem;
    
    @media screen and (min-width: 800px) {
        grid-template-areas: 
        "text heatmap link";
        grid-template-columns: 1fr auto auto;
        grid-template-rows: 1fr;
    }
    margin-bottom: 8px;
`;
