function getContrastTextColor(backgroundColor) {
  if (!backgroundColor) return 'inherit';
  // Convert the background color to RGB format
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(backgroundColor);
  const r = parseInt(rgb[1], 16);
  const g = parseInt(rgb[2], 16);
  const b = parseInt(rgb[3], 16);

  // Calculate the brightness (luminance) of the background color
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Return black (#000000) if the brightness is greater than or equal to 128,
  // otherwise return white (#ffffff)
  return brightness >= 128 ? '#000000' : '#ffffff';
}

export default getContrastTextColor;
