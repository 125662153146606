import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/browser';

import { call, spawn, delay } from 'redux-saga/effects';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

function* watcher(saga) {
  while (true) {
    try {
      yield call(saga);
      break;
    } catch (error) {
      // Log error and restart saga within 2sek
      // eslint-disable-next-line no-console
      console.error('Saga crash:', error);
      Sentry.captureException(error);

      yield delay(2000);
    }
  }
}

function* root() {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rootSaga.length; i++) {
    yield spawn(watcher, rootSaga[i]);
  }
}

export default () => {
  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(
        thunk,
        sagaMiddleware,
      ),
    ),
  );

  sagaMiddleware.run(root);

  return store;
};
