import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AppMonitor.ListCard',
  sv: {
    testSucceeded: 'Testkörning lyckades',
    testFailed: 'Testkörning misslyckades',
    lastRun: 'Kördes senast för',
    runsOn: 'Körs på',
    customer: 'Kund',
    overview: 'Översikt',
    ago: 'sedan',
    mobileLabel: 'Se detaljer',
    search: 'Sök tester',
  },
  en: {
    testSucceeded: 'Test run succeeded',
    testFailed: 'Test run failed',
    lastRun: 'Last executed',
    runsOn: 'Runs on',
    customer: 'Customer',
    overview: 'Overview',
    ago: 'ago',
    mobileLabel: 'View details',
    search: 'Search tests',
  },
});
