import styled, { css } from 'styled-components';

export const AlertCell = styled.div`
  vertical-align: middle;
  height: 60px;
  min-width: 82px;
  max-width: 82px;
  overflow: hidden;
  border-right: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
  border-bottom: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
`;

export const AlertName = styled.p`
  font-size: 11px;
  line-height: 1.3;
  margin: 0;
  white-space: initial;
  max-height: 100%;
  position: relative;
  word-break: break-all;
`;

export const TextCellContent = styled.p`
  width: max-content;
`;
export const TextCell = styled.div`
  font-family: "Poppins";
  vertical-align: middle;
  border-bottom: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
  border-right: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
  display: flex;
  align-items: center;
  ${TextCellContent} {
    margin: 0;
    padding: 0 1rem;
    font-size: 12px;
  }

`;

export const Table = styled.table`
  position: relative;
   td {
      border-bottom: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
      border-left: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};

      &:last-of-type {
        border-right: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
      }
  }

  tr:first-of-type {

    font-weight: bold;
    ${AlertCell} {
      padding: 0.5rem;
    }
  }

`;

export const ExpandedContentInner = styled.div`
  overflow-y: hidden;
`;

export const ExpandedContent = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-out;
  ${({ isExpanded }) => (isExpanded ? css`
      grid-template-rows: 1fr;
  ` : ``)};
`;
