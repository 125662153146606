import React from 'react';
import PropTypes from 'prop-types';
import {
  useTheme, Text, Wrap, Tooltip,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import MdiIcon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';
import TagElements from '../TagElements';
import translation from './EntitiesListItem.translation';

import * as C from './EntitiesListItem.styled';

const propTypes = {
  name: PropTypes.string.isRequired,
  highestPriority: PropTypes.number,
  type: PropTypes.string,
  resourceGroup: PropTypes.string,
  containerName: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  region: PropTypes.string,
  id: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  backPathUrlParam: PropTypes.string.isRequired,
};

const defaultProps = {
  highestPriority: 0,
  type: '-',
  resourceGroup: '-',
  containerName: '-',
  tags: [],
  region: '-',
};

const EntitiesListItem = ({
  name,
  highestPriority,
  type,
  resourceGroup,
  containerName,
  tags,
  region,
  customerId,
  id,
  backPathUrlParam,
}) => {
  const { colors } = useTheme();
  const history = useHistory();

  const { t } = translation;
  const entityPath = `my-environment/${customerId}/${id}/information${backPathUrlParam}`;

  const onClick = () => {
    history.push(entityPath);
  };

  return (

    <C.Row onClick={onClick}>

      <td>
        <C.Cell>
          <Tooltip label={t(`priority${highestPriority || 0}`)}>
            <C.PriorityDot priority={highestPriority || 0} colors={colors} />
          </Tooltip>
          <Text margin="0" ml="1rem">{name}</Text>
        </C.Cell>
      </td>

      <td>
        <C.Cell>
          <Text margin="0">{type}</Text>
        </C.Cell>
      </td>

      <td>

        <C.Cell>
          <Text margin="0">{ resourceGroup }</Text>
        </C.Cell>
      </td>

      <td>

        <C.Cell>
          <Tooltip hasArrow label={containerName} placement="auto">
            <Text margin="0">{ containerName }</Text>
          </Tooltip>
        </C.Cell>
      </td>

      <td>
        <C.Cell>
          <Wrap spacing=".4rem">
            {tags.length !== 0 ? (<TagElements tags={tags} />) : (
              <Text>-</Text>
            )}
          </Wrap>
        </C.Cell>
      </td>

      <C.Cell>
        <Text margin="0">{ region }</Text>
      </C.Cell>

      <td>
        <C.Cell isLast>

          <MdiIcon path={mdiChevronRight} size={0.75} />

        </C.Cell>
      </td>

    </C.Row>

  );
};

EntitiesListItem.propTypes = propTypes;
EntitiesListItem.defaultProps = defaultProps;

export default EntitiesListItem;
