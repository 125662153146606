import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'CreateTicketPage',
  sv: {
    'property-validation-ticket-attachment': 'Ett fel uppstod vid filuppladdningen',
    'property-validation-ticket-description-missing': 'Beskrivning kan inte vara tomt',
    'property-validation-ticket-subject-missing': 'Ämne kan inte vara tomt',
    back: 'Tillbaka',
    cancel: 'Avbryt',
    change: 'Ändra',
    closeModal: 'Stäng',
    createTicketTitle: 'Skapa Nytt Ärende',
    failFeedback: 'Misslyckades att skicka feedback',
    failTicket: 'Misslyckades att skapa ärende',
    feedbackDescription: 'Hjälp oss förbättra CloudOps genom att rapportera buggar, begära nya funktioner eller ställa frågor om produkten. Tack för att du tar dig tid att dela dina tankar med oss!',
    feedbackTitle: 'Feedback',
    formDescription: 'Beskrivning',
    formDescriptionPlaceholder: 'Ange beskrivning...',
    formFileDescription: 'Här kan du bifoga en fil till ärendet',
    formFileLabel: 'Bifogad fil',
    formAreaName: 'Område',
    formSubject: 'Ämne',
    formSubjectPlaceholder: 'Ange ämne...',
    formType: 'Typ',
    prioHigh: 'Hög',
    prioLow: 'Låg',
    prioMedium: 'Mellan',
    priority: 'Prioritet',
    prioUrgent: 'Brådskande',
    send: 'Skicka',
    submit: 'Skapa',
    successFeedback: 'Tack för din feedback',
    successMessage: 'Vi har tagit emot ditt ärende och kommer behandla det så snart vi kan.',
    successTicket: 'Ärende skapat',
    successTitle: 'Tack!',

  },
  en: {

    'property-validation-ticket-attachment': 'An error occurred while uploading the attachment',
    'property-validation-ticket-description-missing': "Description can't be empty",
    'property-validation-ticket-subject-missing': "Subject can't be empty",
    back: 'Back',
    cancel: 'Cancel',
    change: 'Change',
    closeModal: 'Close',
    createTicketTitle: 'Create New Ticket',
    failFeedback: 'Failed to send feedback',
    failTicket: 'Failed to create ticket',
    feedbackDescription: 'Help us improve CloudOps by report bugs, request new features, or ask any questions you may have about the product. Thank you for taking the time to share your thoughts with us!',
    feedbackTitle: 'Feedback',
    formDescription: 'Description',
    formDescriptionPlaceholder: 'Enter description...',
    formFileDescription: 'Here you can attach a file to your ticket',
    formFileLabel: 'Attached file',
    formAreaName: 'Area',
    formSubject: 'Subject',
    formSubjectPlaceholder: 'Enter subject...',
    formType: 'Type',
    prioHigh: 'High',
    prioLow: 'Low',
    prioMedium: 'Medium',
    priority: 'Priority',
    prioUrgent: 'Urgent',
    send: 'Send',
    submit: 'Create',
    successFeedback: 'We have received your feedback and will process it as soon as possible',
    successMessage: 'We have received your ticket and will process it as soon as possible',
    successTicket: 'Success! Created Ticket',
    successTitle: 'Thanks!',
  },
});
