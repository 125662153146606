const hasFails = ({ fails = 0 }) => fails > 0;
const hasSuccesses = ({ fails = 0, total = 0 }) => fails === 0 && total > 0;
const hasNoData = ({ fails = 0, total = 0 }) => (fails === 0 || fails === null)
 && (total === 0 || total === null);

const getCondition = (entry) => {
  if (entry?.fails > 0) {
    return hasFails;
  }
  if (entry?.fails === 0 && entry?.total > 0) {
    return hasSuccesses;
  }
  return hasNoData;
};

const groupEntries = (
  inputArray,
  conditionForNextEntries,
  index = 0,
  groupedArray = [],
) => {
  const entry = inputArray[index];

  // if next entry also fulfills the initial condition
  //  push it to the array and continue
  if (entry && conditionForNextEntries(entry)) {
    const newIndex = index + 1;
    const newGroupedArray = [...groupedArray, entry];

    return groupEntries(inputArray, conditionForNextEntries, newIndex, newGroupedArray);
  }

  return groupedArray.length === 1 ? groupedArray[0] : groupedArray;
};

export const groupData = (inputArr) => inputArr.reduce((acc, cur, i, arr) => {
  const restOfArr = [...arr].splice(i, arr.length);

  if (hasFails(cur)) {
    return [...acc, cur];
  }
  const conditionForNextEntries = getCondition(cur);
  const clumped = groupEntries(restOfArr, conditionForNextEntries);
  arr.splice(i, clumped.length - 1);
  return [...acc, clumped];
}, []);
