import styled from 'styled-components';

export const ActionBar = styled.div`
    display:flex;
    flex-direction: flex-start;
    padding:1rem;
    position: relative;
    background: #fff;
    box-shadow: 0px 0.3125rem 0.4375rem -0.1875rem rgba(0, 0, 0, 0.1);

`;

export const Heading = styled.div`
    font-family: Poppins;
    font-weight: normal;
    text-align: left;
    display: flex;

    .ticketId {
        font-weight: normal;
        text-align: left;
        color: ${({ colors }) => colors?.gray?.['800']};
    }
`;

export const Title = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5rem;
`;

export const PageTitle = styled(Title)`
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
`;

export const TicketId = styled(Title)`
`;

export const Summary = styled.div`
    display: flex;
    align-items: center;
    color: ${({ colors }) => colors?.gray?.['800']};
    font-size: 0.875rem;
`;

export const Status = styled.div``;

export const MetaSummary = styled.div``;

export const Created = styled.div`
    display: inline-block;
`;

export const Body = styled.div``;

export const BodyFooter = styled.div`
    margin-top: 2rem;
`;

export const Meta = styled.div``;

export const Priority = styled.div`
    margin-right: 0.5rem;
    display: block;
    width: 8px;
    height: 8px;
    margin-top: 1px;
    border-radius: 100%;
    background: ${({ colors, priority }) => {
    switch (priority) {
      case 1:
        return colors?.priority?.low;
      case 2:
        return colors?.priority?.medium;
      case 3:
        return colors?.priority?.high;
      case 4:
        return colors?.priority?.urgent;
      default:
        return colors?.gray?.['300'];
    }
  }};
`;

export const Layout = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr minmax(0, 1fr);
    min-width: 0;
    grid-template-rows: auto auto;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    grid-template-areas:
        "heading heading heading"
        "status status status"
        "body body body";

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
        grid-template-areas:
            "heading heading ."
            "status status ."
            "body body meta";
    }

    ${Heading} {
        grid-area: heading;
    }

    ${Title} {
        grid-area: title;
    }

    ${TicketId} {
        grid-area: ticketId;
    }

    ${Summary} {
        grid-area: status;
    }

    ${Body} {
        grid-area: body;
        display: ${({ showDetailsOnMobile }) => (showDetailsOnMobile ? 'none' : 'block')};

        @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
            display: block;
        }
    }

    ${Meta} {
        grid-area: body;
        display: ${({ showDetailsOnMobile }) => (showDetailsOnMobile ? 'block' : 'none')};

        @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
            grid-area: meta;
            display: block;
            padding-left: 1.5rem;
        }
    }
`;

export const Tabs = styled.div`
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
        display: none;
    }
`;

export const TabText = styled.div`
    display: flex;
    flex: 0;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.875rem;
    text-transform: uppercase;

    svg {
        margin-right: 0.625rem;
    }
`;

export const Tab = styled(Title)`
    font-size:1rem;
    text-align: center;
    padding: 1rem;
    width: unset;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        margin: 0;
    }
    &:hover {
        cursor: pointer;
    }
    background: ${({ isActive, colors }) => (isActive ? colors?.white : colors?.gray?.['100'])};
`;
