import styled from 'styled-components';
import { Button } from '~ui/Button';
import { Text } from '@chakra-ui/react';

export const Entity = styled.div`
    svg {
        color: ${({ colors }) => colors?.blue?.['900']};
    }

    display: grid;
    grid-template-columns: 3.75rem 1fr auto 3.75rem;
    align-items: center;
`;

export const ResourceGroup = styled(Entity)``;

export const Divider = styled.div`
    height: 1px;
    width: 100%;
    background: ${({ colors }) => colors?.gray?.['300']};
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

export const ListMarker = styled.div`
    width: 1px;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    background: ${({ colors }) => colors?.gray?.['300']};
    grid-area: listMarker;
    margin-right: 2.25rem;
`;

export const List = styled.div`
    grid-area: list;
    display: flex;
    flex-direction: column;
`;

export const ListEntity = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ListContainer = styled.div`
    display: grid;
    grid-template-columns: 3.75rem auto 1fr 3.75rem;
    grid-template-areas: '. listMarker list .';
`;

export const Entities = styled(Entity)`
    flex-direction: column;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const TopText = styled(Text)`
    margin-top: 0;
    margin-bottom: 0.625rem;
`;

export const BottomText = styled(Text)`
    font-family: 'Poppins', sans-serif;
    font-size: 0.75rem;
    margin: 0;
`;

export const Action = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const RemoveBtn = styled(Button)`
    background: transparent;
    color: ${({ colors }) => colors?.ruby?.['800']};
    border: none;
    padding: 0.25rem 0.75rem;
    font-size: 0.75rem;

   &:hover {
        background: ${({ colors }) => colors?.ruby?.['50']};
        color: ${({ colors }) => colors?.ruby?.['800']};
    }
`;

export const Entry = styled.div``;

export const EntitiesContainer = styled.div`
    border-radius: 5px;
    border: 1px solid;
    border-color: ${({ colors }) => colors?.gray?.['300']};
    padding: 1.5rem 2.25rem;
    p {
        word-break: break-all;
    }
    & > ${Entry}:last-child ${Divider}{
        display: none;
    }
`;
