import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import * as Permission from '~ui/Permission';
import {
  TableSort,
  TableSearch,
  TableRowCards,
  TableSearchProvider,
  TablePagination,
  TableHeader,
  TableFilterTags,
  TableFilterSelect,
  TableDrawer,
  TableBody,
  TableResultCount,
} from '~ui/Table';
import {
  Box,
  Wrap,
} from '@chakra-ui/react';
import * as Keys from '../../../../lib/utils/historyStateKeys';
import translation from './ListAnalyticRules.translation';
import AnalyticRuleCard from '../../components/AnalyticRuleCard/AnalyticRuleCard';
import { searchAnalyticRules } from '../../../../lib/services/analyticRules';
import { listFilterType } from '../../../../utils/propTypes';
import { LIST_NAME } from './constants';

const tagColors = {
  'properties/severity': 'blue',
  'properties/tactics': 'gold',
};

const ListAnalyticRules = (
  { onUpdateListFilter, listFilter: globalListFilter },
) => {
  const { t } = translation;
  const { params } = useRouteMatch();
  const { customerId, workspaceId } = params;

  const permissions = Permission.usePermission();

  const filterCollection = {
    'properties/severity': (_, value) => `${t('severity')}: ${value}`,
    'properties/tactics': (_, value) => `${t('tactic')}: ${value}`,
  };

  const sortList = [
    {
      value: 'severity',
      label: t('severity'),
      desc: false,
      default: true,
    },
  ];

  const callback = useCallback((state, azureSearchParser) => {
    const { is_stale: stale, ...restFiler } = state.filter;

    const newState = { ...state, filter: restFiler };
    const parsers = {
      filter: {
        'properties/tactics': (value) => `properties/tactics/any(tactic: tactic eq '${value}')`,
      },
    };
    const payload = {
      payload: {},
      global: permissions.isGlobalAdmin(),
      customerId,
    };

    if (state.isFilterTrigger) {
      Object.assign(payload, {
        payload: azureSearchParser.facets(newState, state.filterKey, parsers),
      });
    } else {
      onUpdateListFilter({
        [LIST_NAME]: { ...state },
      });
      Object.assign(payload, {
        payload: azureSearchParser.items(newState, parsers),
      });
    }

    const { filter } = payload.payload;
    Object.assign(payload.payload, {
      filter: `(workspace_id eq '${workspaceId}') ${filter && `and ${filter}`}`.trim(),
    });

    return payload;
  }, [permissions, customerId, workspaceId, onUpdateListFilter]);

  return (
    <>
      <TableSearchProvider
        pageSize={15}
        payload={callback}
        fetcher={searchAnalyticRules}
        urlStateKey={Keys.SECURITY_ACTIVE}
        sort={sortList}
        initialValues={globalListFilter}
      >
        <Box flex="1">
          <TableHeader>
            <TableSearch />
            <TableSort />
            <TableDrawer title={t('filterDrawerTitle')}>
              <TableFilterSelect
                title={t('severity')}
                label={t('severity')}
                filterKey="properties/severity"
                renderTags
                color={tagColors['properties/severity']}
              />

              <TableFilterSelect
                title={t('tactics')}
                label={t('tactics')}
                filterKey="properties/tactics"
                renderTags
                color={tagColors['properties/tactics']}
              />

            </TableDrawer>
          </TableHeader>

          <Wrap>
            <TableFilterSelect
              label={t('severity')}
              filterKey="properties/severity"
            />

            <TableFilterSelect
              label={t('tactics')}
              filterKey="properties/tactics"
            />
          </Wrap>

          <TableFilterTags
            configurations={filterCollection}
            colors={tagColors}
          />
        </Box>
        <TableResultCount fileName="templates.csv" />
        <TableBody>
          <TableRowCards>
            {(data, idx) => {
              const navigationLink = `/security/${customerId}/${workspaceId}/analytic-rule/${data.id}`;
              return (
                <AnalyticRuleCard
                  key={idx}
                  properties={data.properties}
                  navigationLink={navigationLink}
                />
              );
            }}

          </TableRowCards>
        </TableBody>
        <TablePagination />

      </TableSearchProvider>

    </>
  );
};

const propTypes = {
  onUpdateListFilter: PropTypes.func.isRequired,
  listFilter: listFilterType,
};

ListAnalyticRules.propTypes = propTypes;

ListAnalyticRules.defaultProps = {
  listFilter: null,
};

export default ListAnalyticRules;
