import styled from 'styled-components';

export const Card = styled.div`
    width: 100%; 
    border-radius: 5px;
    box-shadow: 0 3px 6px -2px rgba(32, 33, 36, 0.28);
    border: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
    display: grid;  
    grid-template-rows: auto;
    grid-column-gap: 2rem;
    grid-template-columns: auto 1fr ;
    margin-top: 1rem;
    grid-template-areas: 
        'circle content'
        'navigate navigate';

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
        grid-template-columns: auto 1fr 5rem;
        grid-template-areas: 'circle content navigate';
    }
    margin-bottom: 1rem;
`;

export const CircleWrapper = styled.div`
    position: relative;
    justify-self: center;
    align-self: center;
    position: relative;
    grid-area: circle;
`;

export const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    width: 1.5rem;
    padding: 0.25rem;
    border-radius: 100%;
    border: 1px solid;
    border-color: ${({ colors }) => colors?.gold?.['600']};
    color: ${({ colors }) => colors?.gold?.['600']};

    * {
        width: 100%;
        height: 100%;
    }
`;

export const Navigator = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-width: 5rem;
    width: 100%;
    padding: 0.5rem;
    border-top: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
    grid-area: navigate;
    
    @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
        border-top: none;
        border-left: ${({ colors }) => `1px solid ${colors?.gray?.['100']}`};
    }

    a {
       border-radius: 100%;
    }

`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    overflow: hidden;
    grid-area: text;
    grid-area: content;
    padding-top: 1.5rem ;
    padding-bottom: 1.5rem ;
    padding-right: 1.5rem;

    p { white-space: normal; }
`;
