import React from 'react';
import PropTypes from 'prop-types';
import {
  Text, useTheme,
} from '@chakra-ui/react';
import * as C from './List.styled';
import ListItem from '../ListItem';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

const defaultProps = {};

const List = ({ items, columns }) => {
  const { colors } = useTheme();

  return (

    <C.Wrapper>

      <C.Table colors={colors}>

        <thead>
          <C.Row isHeadRow>
            {columns.map((column, index) => (
            // eslint-disable-next-line react/no-array-index-key
              <td key={index}>
                <C.Cell isLast={index === columns.length - 1}>
                  <Text fontWeight="bold" margin="0">{column.label}</Text>
                </C.Cell>
              </td>
            ))}
          </C.Row>
        </thead>
        <tbody>
          {items?.map((data) => (
            <ListItem
              key={data.created}
              addedHours={data?.added_hours}
              comment={data?.description}
              updatedBy={data?.updated_by}
              timeStamp={data?.created}
            />
          ))}
        </tbody>

      </C.Table>

    </C.Wrapper>
  );
};

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default List;
