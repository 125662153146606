import Api from '../../../../../lib/api';

const putOnCall = async (payload) => {
  const path = `/config/callservicedesk`;

  return Api.put(path, payload)
    .then((success) => ({ success, error: false }))
    .catch((error) => {
      if (error.response && error.response.data) {
        return { error: error.response.data, success: false };
      }

      return { error, success: false };
    });
};

export default putOnCall;
