import React, {
  useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Redirect,
  Switch,
  withRouter,
} from 'react-router-dom';

import * as Layout from '~ui/Layout';
import * as Permission from '~ui/Permission';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import {
  Spinner, ChakraProvider, Flex,
} from '@chakra-ui/react';

import { theme as chakraTheme } from '~ui/style/chakra.config';
import LoginView from './LoginView';
import PageNotFound from './PageNotFound';
import translations from './ApplicationRouter.translation';
import { filterRoutesByPermissions } from '../helpers';

import {
  redirectRoutes,
  applicationRoutes,
  mainNavigation,
  topCreateList,
} from '../../routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const LoadingView = () => (
  <Flex height="100vh" width="100vw" justifyContent="center" alignItems="center">
    <Spinner
      color="#133A5D"
      emptyColor="#DADADA"
      speed="0.65s"
      thickness="4px"
      width={50}
      height={50}
    />
  </Flex>
);

const ApplicationRouter = ({
  userContext,
  permissions,
  application,
  history,
  onMount,
  onUnmount,
  onSetRedirectOrigin,
  onLogout,
  onChangeLangauge,
}) => {
  const { t } = translations;

  const provider = Layout.useLayout({
    avaliableLanguagesConstructor: (selected) => [
      { value: 'en', label: t('english'), default: selected === 'en' },
      { value: 'sv', label: t('swedish'), default: selected === 'sv' },
    ],
    onLogout: () => { onLogout(); },
    onChangeLanguage: (lang) => {
      onChangeLangauge(lang);
    },
  });

  const routes = useMemo(() => filterRoutesByPermissions(
    permissions,
    applicationRoutes,
  ), [permissions]);

  useEffect(() => {
    if (userContext) {
      const navigation = mainNavigation(t, userContext);
      const createTopMenu = topCreateList(t, userContext);

      const navigationList = filterRoutesByPermissions(permissions, navigation, true);

      const createTopMenuList = filterRoutesByPermissions(permissions, createTopMenu, true);

      provider.setNavigationList(navigationList);
      provider.setCreateList(createTopMenuList);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext, permissions]);

  useEffect(() => {
    onMount({
      history,
      onUserContextCallback: (context, language) => {
        provider.setCurrentLanguage(language);
        provider.setCustomerId(context.customer_id);
        provider.setCustomerName(context.customer_name);
        provider.setUser({
          name: context.name,
          email: context.email,
          imageLink: `${process.env.REACT_APP_CDN}/profile-picture/${window.btoa(context.email)}`,
        });
      },
      onChangeLanguageCallback: () => {
        // Does not do anything but crashes if the function is not provided
      },
    });

    return () => {
      onUnmount();
    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  if (application.initiated === false && application.authed === undefined) {
    return null;
  }

  if (application.authed === false) {
    // If user is sent back to the loginpage,
    // save path in state so we can send them back again
    if (window.location.pathname !== '/login') {
      onSetRedirectOrigin(`${window.location.pathname}${window.location.search}`);
    }

    return (
      <ChakraProvider theme={chakraTheme}>
        <Redirect to="/login" />
        <Route exact path="/login" component={LoginView} />
      </ChakraProvider>
    );
  }

  if (application.ready === false) {
    return LoadingView;
  }

  if (application.redirectedFromOrigin) {
    const origin = application.redirectedFromOrigin;
    onSetRedirectOrigin(null);
    return <Redirect to={origin} />;
  }

  return (
    <ChakraProvider theme={chakraTheme}>
      <Permission.Context value={permissions}>
        <Layout.Main provider={provider}>
          <QueryClientProvider client={queryClient}>
            <Switch>
              {routes
                .map((route) => {
                  const {
                    component: ViewComponent,
                    path,
                    args = {},
                    props = {},
                  } = route;

                  return (
                    <Route
                      key={`${path}-applicationRoute`}
                      path={path}
                      {...args}
                      render={() => (<ViewComponent {...props} />)}
                    />
                  );
                })}
              {redirectRoutes.map(({
                to,
                from,
                args = {},
              }) => (
                <Route
                  path={from}
                  key={`${from}${to}-redirectRoute`}
                  {...args}
                  render={({ match }) => (
                    <Redirect to={to(match.params)} />
                  )}
                />
              ))}
              <Route path="*" render={() => <PageNotFound />} />
            </Switch>
          </QueryClientProvider>
        </Layout.Main>
      </Permission.Context>
    </ChakraProvider>
  );
};

ApplicationRouter.defaultProps = {
  userTheme: null,
  userContext: null,
};

ApplicationRouter.propTypes = {
  userContext: PropTypes.instanceOf(Object),
  permissions: PropTypes.instanceOf(Object).isRequired,
  userTheme: PropTypes.instanceOf(Object),
  application: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  onAuthenticationCallback: PropTypes.func.isRequired,
  onSetRedirectOrigin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onChangeLangauge: PropTypes.func.isRequired,
  onClearLoginTriggered: PropTypes.func.isRequired,
};

export default withRouter(ApplicationRouter);
