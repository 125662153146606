import Api from '../../../../../../../lib/api';

const getAlertsForEntity = async ({
  customerId, entityId, maxItems = -1,
}) => {
  const path = `customer/${customerId}/alertrule/entity/${entityId}`;
  const params = { maxItems };
  return Api.continuationToken.get(path, null, params)
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default getAlertsForEntity;
