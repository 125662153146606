/* eslint-disable camelcase */
import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import * as Form from '~ui/Form';
import PropTypes from 'prop-types';
import { Spinner, Text } from '@chakra-ui/react';
import * as Block from '~ui/Block';
import { ServiceWindowMutationContext } from '../../../../serviceWindowMutationContext';
import getMetricDefinitionsForEntities from '../../../../../../lib/services/getMetricDefinitionsForEntities';

import translation from './Metrics.translation';

const formSpec = ({ t: _t }, initialValue) => ({
  metrics: {
    type: 'filterselect',
    label: _t('metricOptionsLabel'),
    options: [],
    tooltipPosition: 'left',
    value: initialValue,
    props: {
      multiSelect: true,
      searchPlaceholder: _t('metricsFormPlaceholder'),
    },
  },
}
);

const defaultProps = {
  initialData: [],
};

const propTypes = {
  initialData: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  customerId: PropTypes.string.isRequired,
  entities: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

const MetricsOptions = ({
  entities,
  customerId,
  initialData,
}) => {
  const controllerRef = useRef(new AbortController());

  const { t } = translation;

  const [loading, setLoading] = useState(false);

  const [metricDefinitions, setMetricDefinitions] = useState(() => initialData
    ?.reduce((acc, { entity_type, metric_names }) => {
      metric_names.forEach((metricName) => {
        const metric = {
          name: metricName,
          type: entity_type,
        };

        if (acc[metricName]) {
          acc[metricName].push(metric);
        } else {
          acc[metricName] = [metric];
        }
      });
      return acc;
    }, {}) || {});

  const ctx = useContext(ServiceWindowMutationContext);

  const initialMetricsFormValue = useMemo(() => {
    const metricNameSet = initialData
      ?.reduce((acc, { metric_names }) => {
        metric_names.forEach((metricName) => {
          acc.add(metricName);
        });
        return acc;
      }, new Set()) || [];

    return [...metricNameSet];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const metricOptions = useMemo(() => Object.keys(metricDefinitions), [metricDefinitions]);

  const formData = Form.useFormBuilder(formSpec(translation, initialMetricsFormValue));

  useEffect(() => {
    const { values: { metrics } } = formData.getValues();

    if (metrics) {
      // When options are changed, any selected option that is not in the new list will be cleared
      const filteredValues = metrics.filter((option) => metricOptions.includes(option));

      formData.updateFields([
        { name: 'metrics', options: metricOptions, value: filteredValues },
      ]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricOptions]);

  useEffect(() => {
    const { values: { metrics } } = formData.getValues();

    if (metrics?.length) {
      const removedMetrics = metrics.filter((metric) => !metricOptions.includes(metric));

      if (removedMetrics.length) {
        /*
          When switching entities and load new options, we need to make sure that
          the previous selected options are removed if they are not part of the list.
          This clears the saved context state but same logic is applied to
          the internal hook state of the ui-components/FilterSelect component.
        */
        const { entity_type_metric_name_mappings } = ctx.serviceWindow;

        const updatedMetricNameMappings = entity_type_metric_name_mappings.reduce((acc, curr) => {
          const { metric_names } = curr;

          const updatedMetricNames = metric_names?.filter(
            (metric) => !removedMetrics.includes(metric),
          );

          if (!updatedMetricNames || updatedMetricNames?.length === 0) {
            return acc;
          }

          acc.push({
            ...curr,
            metric_names: updatedMetricNames,
          });

          return acc;
        }, []);

        ctx.setServiceWindow({
          ...ctx.serviceWindow,
          entity_type_metric_name_mappings: updatedMetricNameMappings,
        });
      }
    }
    formData.updateFields([
      { name: 'metrics', options: metricOptions },
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricOptions]);

  useEffect(() => {
    const controllerAbortStatus = controllerRef?.current?.signal?.aborted;
    // Make sure the we always have a fresh abort controller
    if (controllerAbortStatus) {
      controllerRef.current = new AbortController();
    } else if (controllerAbortStatus === false) {
      controllerRef.current.abort();
      controllerRef.current = new AbortController();
    }
    const controller = controllerRef.current;

    const entityIds = [
      ...entities?.reduce((acc, curr) => {
        const { id } = curr;
        acc.add(id);
        return acc;
      }, new Set()),
    ];

    const fetchMetricDefinitions = async () => {
      setLoading(true);
      const { signal } = controller;

      const { success } = await getMetricDefinitionsForEntities({
        customerId,
        entityIds,
        signal,
      });

      const { result } = success ?? null;

      if (result?.length) {
        const metricsDefinitionsResults = entityIds
          .reduce((acc) => {
            result.forEach((metric) => {
              if (acc[metric.name]) {
                acc[metric.name].push(metric);
              } else {
                acc[metric.name] = [metric];
              }
            });

            return acc;
          }, {});

        setMetricDefinitions(metricsDefinitionsResults);
      } else {
        setMetricDefinitions([]);
      }
      setLoading(false);
    };

    if (entityIds?.length && customerId) {
      fetchMetricDefinitions();
    } else {
      setMetricDefinitions([]);
      setLoading(false);
    }

    return () => {
      controller.abort();
    };
  }, [entities, customerId]);

  const handleFormChange = useCallback(({ values }) => {
    const { metrics } = values;

    const entitiesMetricsDict = metrics.reduce((acc, currentMetricName) => {
      const entitiesForCurrentMetric = metricDefinitions[currentMetricName];

      if (!entitiesForCurrentMetric) {
        return acc;
      }

      entitiesForCurrentMetric.forEach((entity) => {
        const { type: entityType, name: metricName } = entity;
        if (acc[entityType]) {
          acc[entityType].add(metricName);
        } else {
          acc[entityType] = new Set([metricName]);
        }
      });

      return acc;
    }, {});

    const entity_type_metric_name_mappings = Object.keys(entitiesMetricsDict).map((entityType) => ({
      entity_type: entityType,
      metric_names: [...entitiesMetricsDict[entityType]],
    }));

    ctx.setServiceWindow({
      ...ctx.serviceWindow,
      entity_type_metric_name_mappings,
    });
  }, [ctx, metricDefinitions]);

  useEffect(() => {
    if (!entities.length) {
      setMetricDefinitions([]);

      ctx.setServiceWindow({
        ...ctx.serviceWindow,
        entity_type_metric_name_mappings: [],
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities]);

  return (
    <div>

      {loading && <Spinner ml="1rem" />}

      {metricOptions?.length > 0 && (
      <Form.Primary onChange={handleFormChange} form={formData}>
        {({ render }) => (
          <>
            {render}
          </>
        )}
      </Form.Primary>
      )}

      {!loading && metricOptions?.length === 0 && entities.length !== 0 && (
      <Block.Warning title={t('noMetricsFoundTitle')} withBottomMargin>

        <Text>
          {t('noMetricsFound')}
        </Text>
      </Block.Warning>
      ) }
    </div>

  );
};

MetricsOptions.propTypes = propTypes;
MetricsOptions.defaultProps = defaultProps;

export default memo(MetricsOptions);
