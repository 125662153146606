import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import * as Permission from '~ui/Permission';
import * as Layout from '~ui/Layout';
import * as Badge from '~ui/Badge';
import moment from 'moment';
import {
  Flex,
  Wrap,
  Text,
  Box,
  Tooltip,
  Tag,
  TagLabel,
  useTheme,
} from '@chakra-ui/react';
import {
  TableSort,
  TableSearch,
  TableRows,
  TableSearchProvider,
  TablePagination,
  TableHeader,
  TableFilterTags,
  TableFilterSelect,
  TableDrawer,
  TableBody,
  TableBodyHeader,
  TableFilterTagGroup,
  TableResultCount,
} from '~ui/Table';
import { getBackPathUrlParam } from '~lib/hooks/useBackPath';
import * as Keys from '~lib/utils/historyStateKeys';
import { searchTickets } from '~lib/services/tickets';
import * as C from './TicketsForEntity.styled';
import translation from './TicketsForEntity.translation';
import { listFilterType } from '../../../../../utils/propTypes';
import { LIST_NAME } from './constants';

const tagColors = {
  type: 'blue',
  status: 'gold',
  priority: 'ruby',
};

const TicketsForEntity = (
  { onUpdateListFilter, listFilter: globalListFilter },
) => {
  const { params } = useRouteMatch();
  const { t } = translation;
  const { entityId, customerId } = params;
  const { colors } = useTheme();

  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();

  const columns = [
    { label: t('priority'), size: '8rem' },
    { label: t('ticketId'), size: '5rem' },
    { label: t('subject'), size: 'minmax(16.75rem, 2fr)' },
    { label: t('tags'), size: '9.375rem' },
    { label: t('modified'), size: '9.375rem' },
    { label: t('type'), size: '6.25rem' },
    { label: t('status'), size: '9rem' },
  ];

  const filterPills = {
    priority: (_, value) => `${t('priority')}: ${t(`priority${value}`)}`,
    type: (_, value) => `${t('type')}: ${value}`,
    status: (_, value) => `${t('status')}: ${value}`,
  };

  const sortList = [
    {
      value: 'modified', label: t('modified'), desc: true, default: true,
    },
  ];

  const callback = useCallback((state, azureSearchParser) => {
    const parsers = {
      filter: {
        priority: (val) => `priority eq ${parseInt(val, 10)}`,
      },
    };
    const payload = {
      payload: {},
      global: isGlobalAdmin,
      customerId,
    };

    if (state.isFilterTrigger) {
      Object.assign(payload, {
        payload: azureSearchParser.facets(state, state.filterKey, parsers),
      });
    } else {
      Object.assign(payload, {
        payload: azureSearchParser.items(state, parsers),
      });
    }

    onUpdateListFilter({
      [LIST_NAME]: { ...state },
    });

    const { search_string: searchString } = payload.payload;
    Object.assign(payload.payload, {
      search_string: `${entityId}+${searchString}`,
    });

    return payload;
  }, [customerId, entityId, isGlobalAdmin, onUpdateListFilter]);

  const backPath = getBackPathUrlParam(t('backPathButtonText'));

  return (

    <TableSearchProvider
      pageSize={15}
      payload={callback}
      fetcher={searchTickets}
      urlStateKey={Keys.ENV_TICKETS}
      sort={sortList}
      initialValues={globalListFilter}
    >

      <Box flex="1">
        <TableHeader>
          <TableSearch />
          <TableSort />
          <TableDrawer title={t('filterDrawerTitle')}>

            <TableFilterSelect
              title={t('changeType')}
              label={t('type')}
              filterKey="type"
              renderTags
              color={tagColors.type}
            />

            <TableFilterSelect
              title={t('changeStatus')}
              label={t('status')}
              filterKey="status"
              renderTags
              color={tagColors.status}
            />

            <TableFilterSelect
              title={t('changePriority')}
              label={t('priority')}
              filterKey="priority"
              configuration={(filter) => ({
                title: t(`priority${filter.value}`),
                subtitle: filter.count,
                value: filter.value,
              })}
            />
            <Wrap>
              <TableFilterTagGroup
                filterKey="priority"
                color={tagColors.priority}
                configure={(_, value) => `${t('priority')}: ${t(`priority${value}`)}`}
              />
            </Wrap>

          </TableDrawer>
        </TableHeader>

        <Wrap>
          <TableFilterSelect
            label={t('type')}
            filterKey="type"
          />
          <TableFilterSelect
            label={t('status')}
            filterKey="status"
          />
          <TableFilterSelect
            label={t('priority')}
            filterKey="priority"
            configuration={(filter) => ({
              title: t(`priority${filter.value}`),
              subtitle: filter.count,
              value: filter.value,
            })}
          />

        </Wrap>
        <TableFilterTags
          configurations={filterPills}
          colors={tagColors}
        />
      </Box>

      <Layout.Scene>
        <TableResultCount fileName="myenvironment-tickets.csv" />
        <TableBody columns={columns}>
          <TableBodyHeader />
          <TableRows>
            {(data, idx, RowComponent) => (
              <>

                <Link to={`/tickets/${customerId}/${data.ticket_id}/details${backPath}`} key={idx} style={{ textDecoration: 'none' }}>
                  <RowComponent>

                    <Flex overflow="hidden" p={2} alignItems="center">
                      <C.Priority colors={colors} priority={data.priority} />
                      {t(`priority${data.priority}`)}
                    </Flex>

                    <Flex overflow="hidden" p={2} alignItems="center">
                      <Text isTruncated margin="0">{data.ticket_id}</Text>
                    </Flex>

                    <Flex overflow="hidden" p={2} alignItems="center">
                      <Tooltip hasArrow label={data.subject} placement="auto">
                        <Text isTruncated margin="0">{data.subject}</Text>
                      </Tooltip>
                    </Flex>

                    <Box overflow="hidden" p={2}>
                      <Wrap p={2} spacing=".4rem">
                        {data.tags.length ? (
                          data.tags.map((label, index) => (
                            index === 0 ? (
                              <Tag key={label} bg="#f5edd8" position="relative" style={{ marginRight: '20px' }}>
                                <Tooltip hasArrow placement="top" label={data.tags.map((el) => `${el}`).join(', ')}>
                                  <TagLabel isTruncated>
                                    {data.tags[0]}
                                  </TagLabel>
                                </Tooltip>
                                { data.tags.length > 1 && (
                                <span style={{ position: 'absolute', right: '-20px', fontSize: '12px' }}>
                                  {(' +')}
                                  {data.tags.length - 1}
                                </span>
                                )}
                              </Tag>
                            )
                              : null
                          ))
                        ) : (
                          <Text padding="2px">-</Text>
                        )}
                      </Wrap>
                    </Box>

                    <Flex overflow="hidden" p={2} alignItems="center">
                      <Tooltip hasArrow label={data.modified} placement="auto">
                        <Text margin="0">{ moment(data.modified).fromNow()}</Text>
                      </Tooltip>
                    </Flex>

                    <Flex overflow="hidden" p={2} alignItems="center">
                      <Tooltip hasArrow label={data.type} placement="auto">
                        <Text textTransform="capitalize" isTruncated margin="0">{data.type}</Text>
                      </Tooltip>
                    </Flex>

                    <Flex overflow="hidden" p={2} alignItems="center">
                      <Badge.Status status={data.status} />
                    </Flex>

                  </RowComponent>
                </Link>
              </>
            )}
          </TableRows>
        </TableBody>
        <TablePagination />
      </Layout.Scene>
    </TableSearchProvider>

  );
};

const propTypes = {
  onUpdateListFilter: PropTypes.func.isRequired,
  listFilter: listFilterType,
};

TicketsForEntity.propTypes = propTypes;

TicketsForEntity.defaultProps = {
  listFilter: null,
};

export default TicketsForEntity;
