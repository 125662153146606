import { combineReducers } from 'redux';

import { modelReducer } from '../model';
import applicationReducer from '../router/reducer';
import ticketReducers from '../scenes/tickets/reducer';

export default combineReducers({
  ticket: ticketReducers,
  model: modelReducer,
  application: applicationReducer,
});
