import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as List from '~ui/List';
import * as Moment from '~ui/Moment';
import * as Layout from '~ui/Layout';
import { Button } from '~ui/Button';
import * as Block from '~ui/Block';
import MdiIcon from '@mdi/react';
import {
  mdiCheck,
  mdiAlertCircleOutline,
  mdiArrowLeft,
  mdiArrowRight,
} from '@mdi/js';
import {
  Spinner, Heading, Flex, useTheme,
} from '@chakra-ui/react';

import translation from './TestRunDetails.translation';
import * as C from './TestRunDetails.styled';
import Screenshot from './components/Screenshot';
import { useTestRunDetails } from '../../../../lib/services/appMonitor';

const { t } = translation;

const screenshotsRow = (arr = []) => {
  if (arr.length > 0) {
    return {
      row: arr.map((imgLink) => <Screenshot key={imgLink} imgLink={imgLink} />),
    };
  }

  return { label: t('screnshots'), value: null };
};

const TestRunDetails = () => {
  const { breakpoints } = useTheme();

  const { params } = useRouteMatch();
  const {
    customerId,
    entityId,
    testName,
    testId,
  } = params;

  const { data, isError, isLoading } = useTestRunDetails({
    customerId, entityId, testName, testId,
  });

  if (isLoading) {
    return (
      <Block.Center withPadding>
        <Spinner
          color="#133A5D"
          emptyColor="#DADADA"
          speed="0.65s"
          thickness="4px"
          size="xl"
        />
      </Block.Center>
    );
  }

  if (isError) {
    return (
      <Block.Emptystate title={t('errorTitle')}>
        <p>{t('errorMessage')}</p>
      </Block.Emptystate>
    );
  }

  return (
    <>
      <Layout.Omnibar.Main>
        <Layout.Omnibar.LeftActions>
          <Button
            variant="ghost"
            leftIcon={mdiArrowLeft}
            internalLink={`/app-monitor/${customerId}/${entityId}/${testName}/test-runs`}
          >
            {t('testRuns')}
          </Button>
        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>
      <Layout.Scene>
        <C.Grid breakpoints={breakpoints}>
          <C.Title>
            <Heading as="h1">{testName}</Heading>
          </C.Title>

          <C.Content style={{ maxWidth: '700px' }}>
            {data.steps.map((step, index) => (
              <C.Step key={step.timestamp}>

                <C.StatusIcon succeeded={step.succeeded}>
                  <MdiIcon path={step.succeeded ? mdiCheck : mdiAlertCircleOutline} size={0.75} />
                </C.StatusIcon>

                <div>
                  <Heading as="h1">{`${t('step')} ${index + 1}: ${step.name}`}</Heading>
                  <List.Primary
                    rows={[
                      { label: t('status'), value: step.succeeded ? t('success') : t('failed') },
                      { label: t('date'), value: <Moment.Full timestamp={step.timestamp} /> },
                      {
                        label: t('executionTime'),
                        value: step.step_elapsed_ms < 2000
                          ? `${step.step_elapsed_ms} ms`
                          : <Moment.Duration seconds={step.step_elapsed_ms / 1000} />,
                      },
                      { label: t('message'), value: step.message },
                      screenshotsRow(step?.image_urls || undefined),
                    ]}
                  />
                </div>
              </C.Step>
            ))}

            {data.log_file_urls?.length > 0 && (
              <Flex>
                <Button
                  variant="ghost"
                  style={{
                    marginLeft: '4.375rem',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                  p={0}
                  internalLink={`/app-monitor/${customerId}/${entityId}/${testName}/details/${testId}/logs`}
                  rightIcon={mdiArrowRight}
                >
                  {t('viewLogs')}
                </Button>
              </Flex>
            )}
          </C.Content>
        </C.Grid>
      </Layout.Scene>
    </>
  );
};

export default TestRunDetails;
