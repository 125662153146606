import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import * as Permission from '~ui/Permission';
import {
  TableSort,
  TableSearch,
  TableRowCards,
  TableSearchProvider,
  TablePagination,
  TableHeader,
  TableFilterTags,
  TableFilterSelect,
  TableFilterBool,
  TableDrawer,
  TableBody,
  TableResultCount,
} from '~ui/Table';
import {
  Box,
  Flex,
  Text,
  Wrap,
} from '@chakra-ui/react';
import * as Keys from '../../../../lib/utils/historyStateKeys';
import translation from './ListTemplates.translation';
import TemplateCard from '../../components/TemplateCard';
import { searchTemplates } from '../../../../lib/services/templates';
import { useWorkspace } from '../../../../lib/services/workspace';
import { listFilterType } from '../../../../utils/propTypes';
import { LIST_NAME } from './constants';

const createApplicableFilter = (connectors = []) => {
  const cons = connectors.reduce((acc, cur, ind) => {
    if (ind < connectors.length - 1) {
      return `${acc + cur}|`;
    }
    return acc + cur;
  }, '');
  return `properties/requiredDataConnectors/any(x: x/dataTypes/any(y: search.in(y, '${cons}', '|')))`;
};

const tagColors = {
  'properties/severity': 'blue',
  'properties/tactics': 'gold',
  'properties/entityMappings/entityType': 'ruby',
  'properties/tags': 'green',
};

const tactic = {
  key: 'properties/tactics',
  filter: (value) => `properties/tactics/any(tactic: tactic eq '${value}')`,
};
const tag = {
  key: 'properties/tags',
  filter: (value) => `properties/tags/all(tag: tag ne '${value}')`,
};
const type = {
  key: 'properties/entityMappings/entityType',
  filter: (value) => `properties/entityMappings/any(mapping: mapping/entityType eq '${value}')`,
};

const ListTemplates = (
  { onUpdateListFilter, listFilter: globalListFilter },
) => {
  const { t } = translation;
  const { params } = useRouteMatch();
  const { customerId, workspaceId } = params;

  const { data: workSpace } = useWorkspace(
    customerId,
    workspaceId,
    { refetchOnMount: false },
  );
  const permissions = Permission.usePermission();

  const filterCollection = {
    'properties/severity': (_, value) => `${t('severity')}: ${value}`,
    'properties/tactics': (_, value) => `${t('tactic')}: ${value}`,
    'properties/entityMappings/entityType': (_, value) => `${t('entityType')}: ${value}`,
    'properties/tags': (_, value) => `${t('tag')}: ${value}`,
    'properties/requiredDataConnectors': (_, value) => `${t('applicableDataConnectors')}: ${value}`,
  };

  const sortList = [
    {
      value: 'severity',
      label: t('severity'),
      desc: false,
      default: true,
    },
  ];

  const callback = useCallback((state, azureSearchParser) => {
    const { is_stale: stale, ...restFiler } = state.filter;
    const newState = { ...state, filter: restFiler };
    const connectors = workSpace?.workspace_tables?.map((table) => table.name);
    const parsers = {
      filter: {
        [tactic.key]: tactic.filter,
        [tag.key]: tag.filter,
        [type.key]: type.filter,
        'properties/requiredDataConnectors': (isTrue) => {
          if (isTrue) {
            return createApplicableFilter(connectors);
          }
          return null;
        },
      },
    };
    const payload = {
      payload: {},
      global: permissions.isGlobalAdmin(),
      customerId,
    };

    if (state.isFilterTrigger) {
      Object.assign(payload, {
        payload: azureSearchParser.facets(newState, state.filterKey, parsers),
      });
    } else {
      onUpdateListFilter({
        [LIST_NAME]: { ...state },
      });
      Object.assign(payload, {
        payload: azureSearchParser.items(newState, parsers),
      });
    }
    return payload;
  }, [workSpace?.workspace_tables, permissions, customerId, onUpdateListFilter]);

  return (
    <>
      <TableSearchProvider
        pageSize={15}
        payload={callback}
        fetcher={searchTemplates}
        urlStateKey={Keys.SECURITY_TEMPLATES}
        sort={sortList}
        initialValues={globalListFilter}
      >
        <Box flex="1">
          <TableHeader>
            <TableSearch />
            <TableSort />
            <TableDrawer title={t('filterDrawerTitle')}>
              <TableFilterSelect
                title={t('severity')}
                label={t('severity')}
                filterKey="properties/severity"
                renderTags
                color={tagColors['properties/severity']}
              />

              <TableFilterSelect
                title={t('tactics')}
                label={t('tactics')}
                filterKey={tactic.key}
                renderTags
                color={tagColors[tactic.key]}
              />

              <TableFilterSelect
                title={t('tags')}
                label={t('tags')}
                filterKey={tag.key}
                renderTags
                color={tagColors[tag.key]}
              />

              <TableFilterSelect
                title={t('entityType')}
                label={t('entityType')}
                filterKey={type.key}
                renderTags
                color={tagColors[type.key]}
              />

              <TableFilterBool
                switchSize="md"
                title={t('connectorsLabel')}
                label={t('connectorsLabel')}
                filterKey="properties/requiredDataConnectors"
                renderTags={false}
              />

            </TableDrawer>
          </TableHeader>

          <Flex alignItems="center" justifyContent="space-between">
            <Wrap>
              <TableFilterSelect
                label={t('severity')}
                filterKey="properties/severity"
              />

              <TableFilterSelect
                label={t('tactics')}
                filterKey="properties/tactics"
              />

              <TableFilterSelect
                label={t('tags')}
                filterKey="properties/tags"
              />

              <TableFilterSelect
                label={t('entityType')}
                filterKey="properties/entityMappings/entityType"
              />
            </Wrap>

            <Flex flexDirection="row" alignItems="center">
              <Text as="i" fontSize="xs" mr={2}>{t('connectorsLabel')}</Text>
              <TableFilterBool
                switchSize="sm"
                label={t('connectorsLabel')}
                filterKey="properties/requiredDataConnectors"
                renderTags={false}
              />
            </Flex>

          </Flex>

          <TableFilterTags
            configurations={filterCollection}
            colors={tagColors}
          />
        </Box>
        <TableResultCount fileName="templates.csv" />
        <TableBody>
          <TableRowCards>
            {(data, idx) => {
              const {
                displayName,
                description,
                tactics,
              } = data.properties;

              const templateConnectors = data.properties.requiredDataConnectors
                .map(({ dataTypes }) => dataTypes).flat();

              const templateOwner = data.customer_id === '*' ? 'g' : data.customer_id;
              const navigationLink = `/security/${customerId}/${workspaceId}/template/${templateOwner}/${data.id}`;

              return (
                <TemplateCard
                  key={idx}
                  template={{ data }}
                  templateConnectors={templateConnectors}
                  navigationLink={navigationLink}
                  analyticRuleName={displayName}
                  description={description}
                  tactics={tactics}
                />
              );
            }}
          </TableRowCards>
        </TableBody>
        <TablePagination />
      </TableSearchProvider>
    </>
  );
};

const propTypes = {
  onUpdateListFilter: PropTypes.func.isRequired,
  listFilter: listFilterType,
};

ListTemplates.propTypes = propTypes;

ListTemplates.defaultProps = {
  listFilter: null,
};

export default ListTemplates;
