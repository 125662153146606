import { createGlobalStyle } from 'styled-components';

const Normalize = createGlobalStyle`
    html {
        height: 100%;
        overflow: auto;
    }

    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1rem;
        margin: 0;
        padding: 0;
        font-family: 'Lato', sans-serif;
    }

    * {
        outline: none;
        text-decoration: none;
        box-sizing: border-box;
    }

    *:focus {
        outline: none;
    }

    input,
    select,
    label,
    textarea,
    button {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: inherit;
    font-size: 1rem;
    &::-ms-clear {
        display: none;
    }
    }


    button {
    font-size: 0.213623046875rem;
    line-height: 0.274658203125rem;
    letter-spacing: 0.018310546875rem;
    }
`;

export default Normalize;
