export function getPriorityFromAlert(alertRule) {
  const { ticket_priority: ticketPriority, alert_rule_severity: alertRuleSeverity } = alertRule;

  const priorityValues = [
    !ticketPriority || Number.isNaN(+ticketPriority) ? 0 : ticketPriority,
    !alertRuleSeverity || Number.isNaN(+alertRuleSeverity) ? 0 : alertRuleSeverity];

  return Math.max(...priorityValues);
}

export function getHighestPriority(alertRules) {
  return alertRules.reduce((acc, curr) => {
    const priority = getPriorityFromAlert(curr);

    acc.totalHighestPriority = Math.max(acc.totalHighestPriority, priority);
    acc.numOfPriorities[priority] += 1;
    return acc;
  }, {
    totalHighestPriority: 0,
    numOfPriorities: {
      1: 0, 2: 0, 3: 0, 4: 0,
    },
  });
}

export function sortAlertNames(aAlertName, bAlertName, alertRuleDict) {
  const { tickets: aTicketPriorities, alerts: aAlertPriorities } = alertRuleDict[aAlertName];
  const { tickets: bTicketPriorities, alerts: bAlertPriorities } = alertRuleDict[bAlertName];

  const aTicketPriorityKeys = Object.keys(aTicketPriorities)
    .map((key) => +key).filter((key) => !Number.isNaN(key));

  const bTicketPriorityKeys = Object.keys(bTicketPriorities)
    .map((key) => +key).filter((key) => !Number.isNaN(key));

  if (aTicketPriorityKeys.length > 0 && bTicketPriorityKeys.length > 0) {
    const highestATicketPriority = Math.max(...aTicketPriorityKeys);
    const highestBTicketPriority = Math.max(...bTicketPriorityKeys);

    if (highestATicketPriority !== highestBTicketPriority) {
      return highestBTicketPriority - highestATicketPriority;
    }

    const aNumOfPriority = aTicketPriorities[highestATicketPriority];
    const bNumOfpriority = bTicketPriorities[highestBTicketPriority];

    if (aNumOfPriority !== bNumOfpriority) {
      return bNumOfpriority - aNumOfPriority;
    }

    if (highestATicketPriority > 2) {
      const aNumOfNextHighestPriority = aTicketPriorities[highestATicketPriority - 1]
       || aTicketPriorities[highestATicketPriority - 2]
       || aTicketPriorities[highestATicketPriority - 3];
      const bNumOfNextHighestpriority = bTicketPriorities[highestBTicketPriority - 1]
      || bTicketPriorities[highestBTicketPriority - 2]
       || bTicketPriorities[highestBTicketPriority - 3];

      if (aNumOfNextHighestPriority !== bNumOfNextHighestpriority) {
        return bNumOfpriority - aNumOfPriority;
      }
    }

    return aAlertName.localeCompare(bAlertName);
  }

  if (aTicketPriorityKeys.length > 0 && bTicketPriorityKeys.length === 0) {
    return -1;
  }

  if (bTicketPriorityKeys.length > 0 && aTicketPriorityKeys.length === 0) {
    return 1;
  }

  const aAlertPriorityKeys = Object.keys(aAlertPriorities)
    .map((key) => +key).filter((key) => !Number.isNaN(key));

  const bAlertPriorityKeys = Object.keys(bAlertPriorities)
    .map((key) => +key).filter((key) => !Number.isNaN(key));

  const highestAAlertPriority = Math.max(...aAlertPriorityKeys);
  const highestBAlerPriority = Math.max(...bAlertPriorityKeys);

  if (highestAAlertPriority !== highestBAlerPriority) {
    return highestBAlerPriority - highestAAlertPriority;
  }

  return aAlertName.localeCompare(bAlertName);
}
