import styled from 'styled-components';

export const Picture = styled.div`
    grid-area: picture;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Name = styled.div`
    grid-area: name;
`;

export const Number = styled.div`
    grid-area: number;
`;

export const Role = styled.div`
    grid-area: role;
`;

export const Email = styled.div`
    grid-area: email;
`;

export const Content = styled.div`
    border: 1px solid;
    border-color: ${({ colors }) => colors?.gray?.['300']};
    border-radius: 0.625rem;
`;

export const Padding = styled.div`
    padding: 1.25rem 2rem;
`;

export const Person = styled.div`
    display: grid;
    grid-template-areas: 
            "name picture"
            "role picture"
            "number picture"
            "email picture";

    width: 100%;
    grid-template-columns: 1fr auto;
    gap: 1rem 2rem;
    margin: 1rem;
    a, p {
        margin: 0; 
        word-break: break-word; 
    }

    ${Email}, ${Number} {
        justify-self: flex-start;
    }

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.md}) {
        grid-template-columns: auto 1fr 1fr;
        grid-template-areas: 
            "picture name number"
            "picture role email";
            

        ${Email}, ${Number} {
            justify-self: flex-end;
        }
    }
`;

export const AllActionsButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem 0;
    padding: 1.25rem 2rem;
    border-top: 1px solid;
    border-color: ${({ colors }) => colors?.gray?.['300']};

    span {
        margin-right: 0.5rem;
    }
`;
