import { connect } from 'react-redux';
import Scene from './Scene';
import {
  onMount, onUnmount, onCreate,
} from './actions';

const mapStateToProps = (state) => (
  {
    isSaving: state?.ticket?.create?.isSaving,
    isTicketCreated: state?.ticket?.create?.isTicketCreated,
    isCreateTicketTaskDone: state?.ticket?.create?.isCreateTicketTaskDone,
    errors: state?.ticket?.create?.errors ?? [],
  });

const mapDispatchToProps = (dispatch, props) => ({
  onMount: (payload) => dispatch(onMount(payload)),
  onUnmount: (payload) => dispatch(onUnmount(payload)),
  onCreate: (payload) => dispatch(onCreate({ ...payload, isFeedback: props.isFeedbackModal })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Scene);
