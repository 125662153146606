import { useQuery } from 'react-query';
import Api from '../api';

export const searchWorkspaces = async ({
  customerId, isGlobalAdmin, payload,
}) => {
  let path = `/customer/${customerId}/security/workspace/search`;

  if (isGlobalAdmin) {
    path = `/security/workspace/search`;
  }

  return Api.post(path, payload);
};

export const useWorkspace = (customerId, workspaceId, config) => {
  const getWorkspace = async () => {
    const path = `/customer/${customerId}/security/workspace/${workspaceId}`;
    return Api.get(path);
  };

  return useQuery(
    ['sentinel_worspaces', customerId, workspaceId],
    () => getWorkspace(customerId, workspaceId), {
      ...config,
    },
  );
};
