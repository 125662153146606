import { connect } from 'react-redux';
import Report from './components/Report';
import {
  onMount, onUnmount,
} from './actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onMount: (payload) => dispatch(onMount(payload)),
  onUnmount: (payload) => dispatch(onUnmount(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
