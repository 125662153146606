import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'TicketDetailsSceene',
  sv: {
    type: 'Typ',
    status: 'Status',
    priority: 'Prioritet',
    assignee: 'Tilldelad',
    tags: 'Taggar',
    entity: 'Resurs',
    alertRule: 'Larmregel',
    history: 'Historik',
    details: 'Detaljer',
    back: 'Tillbaka till ärenden',
    comments: 'kommentarer',
    ticketsOpened: 'öppnade detta ärende för',
    externalId: 'Externt ID',
    customer: 'Kund',

    none: 'Ingen',
    addComment: 'Lägg till ny kommentar',
    edit: 'Ändra',

    priority1: 'Låg',
    priority2: 'Medium',
    priority3: 'Hög',
    priority4: 'Brådskande',
  },
  en: {
    type: 'Type',
    status: 'Status',
    priority: 'Priority',
    assignee: 'Assignee',
    tags: 'Tags',
    entity: 'Resource',
    alertRule: 'Alert Rule',
    history: 'History',
    details: 'Details',
    back: 'Back to tickets',
    comments: 'comments',
    ticketsOpened: 'opened this ticket',
    externalId: 'External ID',
    customer: 'Customer',

    none: 'None',
    addComment: 'Add new comment',
    edit: 'Edit',

    priority1: 'Low',
    priority2: 'Medium',
    priority3: 'High',
    priority4: 'Urgent',
  },
});
