import React, { useMemo } from 'react';
import { Button } from '~ui/Button';
import * as Permission from '~ui/Permission';
import {
  Box, Tag, Wrap, Text, Heading, useTheme,
} from '@chakra-ui/react';
import { mdiChevronRight } from '@mdi/js';
import PropTypes from 'prop-types';
import * as C from '../TemplateCard/TemplateCard.styled';
import translation from './WorkspaceCard.translation';
import { handleTags } from '../handleTags';

const { t } = translation;

const propTypes = {
  customer: PropTypes.string,
  navigationLink: PropTypes.string,
  workspaceName: PropTypes.string.isRequired,
  tags: PropTypes.instanceOf(Array),
};
const defaultProps = {
  customer: '',
  navigationLink: '',
  tags: [],
};

const uniqueKeys = (key = '', arr) => [...new Set(arr.map((el) => el[key]))];

const Card = ({
  tags,
  customer,
  navigationLink,
  workspaceName,
}) => {
  const { colors, breakpoints } = useTheme();

  const permissions = Permission.usePermission();

  const dataTags = useMemo(() => uniqueKeys('name', tags), [tags]);

  return (
    <C.Card colors={colors} breakpoints={breakpoints}>
      <C.Content>
        <Heading as="h3" textTransform="capitalize" style={{ display: 'flex', flexOrientation: 'row', columnGap: '0.5rem' }}>
          {`Workspace: ${workspaceName}`}
          {permissions.isGlobalAdmin() && customer && ` - ${customer}`}
        </Heading>

        <Box>
          <Text fontSize={12} mb={2}>{t('avaliable')}</Text>
          <Wrap spacing={2} mb={2}>
            {handleTags({ items: dataTags, maxLength: 5 })?.map((tag) => <Tag key={tag} bg="#f5edd8">{tag}</Tag>)}
          </Wrap>
        </Box>

      </C.Content>

      <C.Navigator colors={colors} breakpoints={breakpoints}>
        <Button
          variant="ghost"
          internalLink={navigationLink}
          rightIcon={mdiChevronRight}
        />
      </C.Navigator>
    </C.Card>
  );
};

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
