import moment from 'moment';

export const reduceOnMonth = (failsArr, successesArr) => failsArr.reduce((acc, cur) => {
  const { date } = cur;

  // if in the future return nothing
  if (moment() <= moment(date)) {
    return { ...acc };
  }

  const fails = cur.value;
  const successes = successesArr?.find((success) => success.date === date)?.value;

  const month = moment(date).month().toString();

  const entry = {
    date,
    fails: fails || 0,
    total: (fails || 0) + successes,
  };

  const monthEntries = acc[month] || [];

  monthEntries.push(entry);
  return { ...acc, [month]: monthEntries };
}, {});
