import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'ServiceWindow.Step1',
  sv: {
    header: 'Orsak & schemaläggning',
    text: 'Ange anledning till servicefönstret, samt datum och tid du vill att det ska inträffa. Du kan också schemalägga detta som ett återkommande event.',

    reason: 'Anledning *',
    reasonPlaceholder: 'Ange anledning...',

    cronDescription: 'Cronbeskrivning',
    cronDescriptionPlaceholder: 'Ange cronbeskrivning...',
  },
  en: {
    header: 'Reason & Schedule',
    text: 'Provide a reason for the service window, as well as the date and time you want it to occur. You can also schedule this as a reoccuring event.',

    reason: 'Reason *',
    reasonPlaceholder: 'Enter a reason...',

    cronDescription: 'Cron description',
    cronDescriptionPlaceholder: 'Enter cron description...',
  },
});
