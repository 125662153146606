import React from 'react';
import PropTypes from 'prop-types';
import * as List from '~ui/List';
import { Button } from '~ui/Button';
import * as Block from '~ui/Block';
import { Text, useTheme } from '@chakra-ui/react';
import * as C from './SequenceForm.styled';
import translation from './SequenceForm.translation';

const AvailableActions = ({ avaliableActionRows, showAll, setShowAll }) => {
  const { breakpoints } = useTheme();

  const { t } = translation;
  return (
    <Block.Center>
      <C.ActionContainer breakpoints={breakpoints}>
        <C.StepTitle>{t('availableActions')}</C.StepTitle>
        <C.Content>
          <Text>
            {t('availableActionsDescription')}
          </Text>
          <List.Primary
            borderBottom
            borderTop
            rows={avaliableActionRows}
            style={{ padding: '1rem' }}
          />
          <C.AllActionsButtonContainer>
            <Button variant="link" onClick={() => setShowAll(!showAll)}>
              {showAll ? t('viewLess') : t('viewAll')}
            </Button>
          </C.AllActionsButtonContainer>
        </C.Content>
      </C.ActionContainer>
    </Block.Center>
  );
};

AvailableActions.propTypes = {
  showAll: PropTypes.bool.isRequired,
  setShowAll: PropTypes.func.isRequired,
  avaliableActionRows: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

AvailableActions.defaultProps = {
  avaliableActionRows: [],
};

export default AvailableActions;
