export const authenticationProxyRenderer = (applicationRouter) => {
  // This is weird but Microsoft login redirects back to the
  // site and expects it to create the object before it closes the popup.
  // See https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/174
  if (window.location.hash.includes('id_token')) {
    return (null);
  }

  return applicationRouter;
};

export default () => {};
