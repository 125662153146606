import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Security.AnalyticCard',
  sv: {
    tactics: 'Taktiker',
    viewDetails: 'Se detaljer',
    severity: 'Allvarlighetsgrad',
    unknown: 'Okänd',
  },
  en: {
    tactics: 'Tactics',
    viewDetails: 'View details',
    severity: 'Severity',
    unknown: 'Unknown',
  },
});
