import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import Card from '../../../../modules/CustomerCard';
import OverviewLayout from '../../../../components/OverviewLayout';
import translatons from './List.translation';

/**
 *  In loving memory of the Old Dashboard,
 *  who served us well for many years.
 *  Here lies its dead code,
 *  now departed but never forgotten.
 *  May it rest in peace, and always be remembered for its contributions to the portal.
 *
 *  Always, up and forward
 */

const propTypes = {
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
};

const Incidents = ({ onMount, onUnmount }) => {
  const { t } = translatons;

  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);

  const onSuccess = useCallback(({ facets }) => {
    setCustomers(facets.customer_display_name);
    setLoading(false);
  }, []);

  const onFail = useCallback(() => {
    setCustomers([]);
    setLoading(false);
  }, []);

  useEffect(() => {
    onMount({ onSuccess, onFail });

    return () => onUnmount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OverviewLayout title={t('title')} loading={loading}>
      {!loading && customers.map((customer) => (
        <Card
          count={customer.count}
          customerDisplayName={customer.value}
          key={customer.value}
        />
      ))}
    </OverviewLayout>

  );
};

Incidents.propTypes = propTypes;

export default Incidents;
