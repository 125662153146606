import moment from 'moment';

const sortBy = (key, arr) => arr.sort((a, b) => a[key].localeCompare(b[key]));

export const splitAlertRules = (rules) => {
  const [active, inactive] = [
    rules.filter((rule) => moment(rule.valid_to_dt) >= moment() && rule.enabled === 1),
    rules.filter((rule) => moment(rule.valid_to_dt) < moment() || rule.enabled === 0),
  ];

  return {
    active: sortBy('valid_from_dt', active),
    inactive: sortBy('valid_from_dt', inactive),
  };
};
