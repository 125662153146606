import styled from 'styled-components';

export const PowerBI = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > iframe {
    border: none;
  }
`;
