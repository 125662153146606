import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'PageNotFound',
  sv: {
    signout: 'Logga ut från konto',
    title: 'CloudOoops!',
    content: 'Länken du klickat på kan vara trasig eller så har sidan tagits bort',
    buttonTitle: 'Tillbaka till startsidan',
  },
  en: {
    signout: 'Sign out of account',
    title: 'CloudOoops!',
    content: 'The link you clicked may be broken or the page may have been removed',
    buttonTitle: 'Go back home',
  },
});
