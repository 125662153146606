import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Block from '~ui/Block';
import * as Svg from '~ui/Icons';
import {
  Text, Heading, Spinner, useToast,
} from '@chakra-ui/react';
import translation from './CloudOpsServicePage.translation';
import ActiveService from '../ActiveService';
import { RowComponentAvaliable } from '../CardRow';
import * as C from './CloudOpsServicePage.styled';

const { t } = translation;

const propTypes = {
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  onDisableService: PropTypes.func.isRequired,
};

const CloudOpsServicePage = ({
  onMount,
  onUnmount,
  onDisableService,
  entityId,
  customerId,
}) => {
  const toast = useToast();
  const [isLoading, setLoading] = useState(null);
  const [services, setServices] = useState({
    activeServices: [],
    availableServices: [],
  });

  useEffect(() => {
    onMount({
      setLoading,
      setServices,
      entityId,
      customerId,
      onSuccess: () => toast({
        description: t('success'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      }),
      onFail: () => toast({
        description: t('fail'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      }),
    });

    return () => onUnmount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Block.Center>
        <Spinner
          color="#133A5D"
          emptyColor="#DADADA"
          speed="0.65s"
          thickness="4px"
          size="xl"
        />
      </Block.Center>
    );
  }

  return (
    <>
      <Heading as="h2" style={{ paddingBottom: '0.5rem', marginBottom: '0' }}>{ t('title')}</Heading>
      <Text style={{ padding: '0.5rem 0 2rem 0' }}>
        { t('description')}
      </Text>

      { services.activeServices.length > 0 && (
        <Block.Stretch>
          <Heading as="h3">{t('active')}</Heading>
          { services.activeServices
            .map((service) => (
              <ActiveService
                key={service.name}
                isActiveRule
                service={service}
                onDisableAction={onDisableService}
              />
            ))}
        </Block.Stretch>
      )}
      { services.activeServices.length === 0 && (
      <Block.Center>
        <Svg.IconEmptyStateGeneric width="8.75rem" />
        <Block.Center style={{ paddingBottom: '2rem' }}>
          <Heading as="h3">{t('noActive')}</Heading>
        </Block.Center>
      </Block.Center>
      )}
      { services.availableServices.length > 0 && (
      <C.AvailableServices>
        <div style={{ padding: '1.6rem' }}>
          <Heading as="h3">{t('availableServices')}</Heading>
          <Text>
            {t('availableServicesDescription')}
          </Text>
        </div>
        { services?.availableServices?.map((service) => (
          <RowComponentAvaliable
            customerId={customerId}
            entityId={entityId}
            service={service}
          />
        )) }
      </C.AvailableServices>
      )}
    </>
  );
};

CloudOpsServicePage.propTypes = propTypes;

export default CloudOpsServicePage;
