import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'CloudOpsPageSelectedServiceService',
  sv: {
    success: 'Cloud Ops Tjänst borttagen',
    fail: 'Misslyckades att ta bort Cloud Ops Tjänst',
  },
  en: {
    success: 'Success! Cloud Ops Service removed',
    fail: 'Failed to remove Cloud Ops Service',
  },
});
