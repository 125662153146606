import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import AlertRuleMetricsWidget from './components/AlertRuleMetricsWidget';
import {
  onMount, onUnmount, onUpdate,
} from './actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => {
  const instance = uuidv4();
  return ({
    onMount: (payload) => dispatch(onMount({ instance, ...payload })),
    onUnmount: (payload) => dispatch(onUnmount({ instance, ...payload }, instance)),
    onUpdate: (payload) => dispatch(onUpdate({ instance, ...payload }, instance)),
  });
};

const ProxyWidget = (props) => {
  const { customerId, metricName, entityId } = props;

  if (customerId && metricName && entityId) {
    return <AlertRuleMetricsWidget {...props} />;
  }

  return null;
};

const propTypes = {
  customerId: PropTypes.string,
  entityId: PropTypes.string,
  metricName: PropTypes.string,
};

const defaultProps = {
  customerId: '',
  entityId: '',
  metricName: '',
};

ProxyWidget.propTypes = propTypes;
ProxyWidget.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProxyWidget);
