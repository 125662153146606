import styled from 'styled-components';
import * as Markdown from '~ui/Markdown';

export const Divider = styled.div`
    grid-area: divider;
    position: relative;
    border-bottom: 1px solid;
    margin-top: 1rem;
    margin-bottom: 1rem;

    /* negative margins equal to "Comment"-padding
    so it expands to the sides */
    margin-left: -1rem;
    margin-right: -1rem;
`;

export const Comment = styled.div`
    display: grid;
    width: 100%;
    margin-bottom: 2.25rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 0.5rem;
    grid-column-gap: 1rem;
    grid-template-areas:
        "header"
        "body";

    background: ${({ isPrivate, colors, filled }) => {
    if (isPrivate) {
      return colors?.gold?.['100'];
    }
    return (filled ? colors?.gray?.['50'] : 'transparent');
  }};

    border: 1px solid;
    border-color: ${({ isPrivate, colors }) => (isPrivate ? colors?.gold?.['600'] : colors?.gray?.['200'])};
    border-radius: 5px;
`;

export const Header = styled.div`
    grid-area: header;
    position: relative;
    padding: 1rem;
    background: ${({ colors, isPrivate }) => (isPrivate ? colors?.gold?.['600'] : colors?.gray?.['50'])};
    border-bottom: 1px solid ${({ colors, isPrivate }) => (isPrivate ? colors?.gold?.['600'] : colors?.gray?.['200'])};
    border-radius: 4px 4px 0 0;
    display: grid;
    grid-template-columns: 1.875rem 1fr 1fr;
    grid-template-rows: auto;
    gap: 0.5rem 1rem;
    grid-template-areas:
        "image author author"
        "image created created";

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
        grid-template-areas:
            "image author created";
    }
`;

export const PrivacyIcon = styled.div`
    position: absolute;
    bottom: ${({ size }) => `-${(size / 2) + 1}px`};
    left: ${({ size }) => `${size / 2}px`};
    height: ${({ size }) => `${size}px`};
    width: ${({ size }) => `${size}px`};
    margin: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ colors }) => colors?.gold?.['600']};
    color: white;
`;

export const Author = styled.div`
    grid-area: author;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const Created = styled.div`
    position: relative;
    grid-area: created;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.75rem;

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.md}) {
        justify-content: flex-end;
    }
`;

export const TicketBody = styled(Markdown.Primary)`
    grid-area: body;
    padding: 1rem;
`;

export const AttachmentSection = styled.div`
  display: grid;
  padding: 1rem;
  background-color: ${({ isPrivate, colors }) => (isPrivate ? 'rgba(255, 255, 255, 0.4)' : colors?.gray?.['50'])};

   .title {
        font-size: 0.625rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        font-weight: bold;
    }
`;

export const AttachmentsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
`;
