import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AutoActionsCreateSelectedAction',
  sv: {
    removeAction: 'Ta bort',
    editAction: 'Editera',
    moveUp: 'Flytta upp',
    moveDown: 'Flytta ner',
    emptyStateTitle: 'Åtgärd kan ej konfigureras',
    emptyStateDesc: 'Denna åtgärd har inga konfigurerbara parametrar, utan kommer exekveras med inställningarna som blivit implementerade av Asurgent.',
    true: 'Ja',
    false: 'Nej',
  },
  en: {
    removeAction: 'Remove',
    editAction: 'Edit',
    moveUp: 'Move up',
    moveDown: 'Move down',
    emptyStateTitle: "This action can't be configured",
    emptyStateDesc: "This action doesn't have any configurable parameters. It will run with the settings implemented by Asurgent.",
    true: 'Yes',
    false: 'No',
  },
});
