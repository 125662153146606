import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'ReportMain',
  sv: {
    reports: 'Rapporter',
    powerBIFailed: 'Kunde inte generera rapporten',
    powerBITokenError: 'Kunde inte generera token för PowerBI',
  },
  en: {
    reports: 'Reports',
    powerBIFailed: 'Could not generate report',
    powerBITokenError: 'Could not generate token for PowerBI',
  },
});
