import Api from '../../../../../lib/api';

const searchTickets = async ({ customerId, ticketId }) => {
  const path = `customer/${customerId}/ticket/${ticketId}`;

  return Api.get(path)
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default searchTickets;
