import Api from '../../../../../../../lib/api';

const getAutoActionsAvailableForEntity = ({
  customerId,
  entityId,
  includeDisabled = false,
}) => {
  const path = `customer/${customerId}/entity/${entityId}/autoaction`;
  const params = { maxItems: -1, includeDisabled };

  return Api.get(path, null, params)
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default getAutoActionsAvailableForEntity;
