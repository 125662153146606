import styled from 'styled-components';
import { Button } from '~ui/Button';

export const Paragraph = styled.div`
    border-top: 1px solid ${({ colors }) => colors?.gray?.['300']};
    padding: 1rem 0 2rem 0;
`;

export const Type = styled.div`
    margin-bottom: 2rem;
`;

export const NullValue = styled.p`
    margin: 0;
`;

export const BorderedItem = styled.div`
    p {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const FitContent = styled.div`
    width: fit-content;
`;

export const Capitalized = styled.span`
    text-transform: capitalize;
`;

export const NullLink = styled(Button)`
    line-height: 1.25rem;
`;

export const Center = styled.div`
    display: flex;
    align-items: center;
`;
