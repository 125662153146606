/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo } from 'react';
import { Heading } from '@chakra-ui/react';
import * as Form from '~ui/Form';
import { useSecurityHours } from '~scenes/securityHours/context/SecurityHoursContext';
import * as C from './CustomerPicker.styled';
import translation from './CustomerPicker.translation';

const formSpec = ({ t: _t }) => ({
  customer: {
    type: 'filterselect',
    label: ' ',
    placeholder: ' ',
    options: [],
    tooltipPosition: 'left',
    props: {
      searchPlaceholder: _t('searchPlaceholder'),
    },
  },
}
);

const CustomerPicker = () => {
  const { selectedCustomerId, customers, handleCustomerChange } = useSecurityHours();
  const { t } = translation;

  const options = useMemo(() => (
    customers ? customers.map((customer) => ({ value: customer.id, label: customer.name })) : []
  ), [customers]);

  const formData = Form.useFormBuilder(formSpec(translation));

  useEffect(() => {
    formData.updateFields([
      { name: 'customer', options, value: selectedCustomerId },
    ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleFormChange = useCallback((form) => {
    const { values } = form || {};
    const { customer: customerId } = values || {};

    handleCustomerChange(customerId);
  }, [handleCustomerChange]);

  return (

    <C.Wrapper>

      <Heading mb="0" fontWeight="light" as="h3">{t('title')}</Heading>

      <Form.Primary onChange={handleFormChange} form={formData}>
        {({ render }) => (
          <>
            {render}
          </>
        )}
      </Form.Primary>

    </C.Wrapper>

  );
};

CustomerPicker.propTypes = {};
CustomerPicker.defaultProps = {};

export default CustomerPicker;
