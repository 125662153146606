import React from 'react';
import PropTypes from 'prop-types';
import {
  useTheme, Text, Tooltip, Tag, TagLabel,
} from '@chakra-ui/react';
import moment from 'moment';
import * as C from './ListItem.styled';

const propTypes = {

  addedHours: PropTypes.number.isRequired,
  timeStamp: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  updatedBy: PropTypes.string.isRequired,
};

const defaultProps = {
};

const ListItem = ({
  addedHours,
  timeStamp,
  comment,
  updatedBy,

}) => {
  const { colors } = useTheme();

  return (

    <C.Row>

      <td>

        <C.Cell>
          <Tag bg={colors?.blue?.['100']} position="relative" style={{ marginRight: '20px' }}>
            <TagLabel>
              {addedHours || 0}
              h
            </TagLabel>
          </Tag>
        </C.Cell>
      </td>
      <td>

        <C.Cell>
          <Text width="max-content" margin="0">{moment(timeStamp).format('YYYY-MM-DD HH:MM')}</Text>
        </C.Cell>
      </td>
      <td>

        <C.Cell>
          <Tooltip hasArrow label={updatedBy} placement="auto">
            <Text margin="0" width="max-content">
              {updatedBy}
            </Text>
          </Tooltip>
        </C.Cell>
      </td>
      <td>

        <C.Cell isLast>
          <Tooltip hasArrow label={comment} placement="auto">
            <Text maxWidth={500} margin="0" isTruncated>{ comment }</Text>
          </Tooltip>
        </C.Cell>
      </td>

    </C.Row>
  );
};

ListItem.propTypes = propTypes;
ListItem.defaultProps = defaultProps;

export default ListItem;
