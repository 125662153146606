import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import * as Permission from '~ui/Permission';
import * as Layout from '~ui/Layout';
import {
  Wrap,
  Box,
} from '@chakra-ui/react';
import {
  TableSort,
  TableSearch,
  TableSearchProvider,
  TablePagination,
  TableHeader,
  TableFilterTags,
  TableFilterSelect,
  TableDrawer,
  TableBody,
  TableBodyHeader,
  TableResultCount,
  TableRowCards,
} from '~ui/Table';
import * as Keys from '../../../../../../lib/utils/historyStateKeys';
import withNavigation from '../../../../hoc/Navigation';
import translation from './List.translation';
import Card from '../../../../../serviceWindow/scenes/List/components/Card/Card';
import { searchServiceWindows } from '../../../../../../lib/services/serviceWindow';
import { listFilterType } from '../../../../../../utils/propTypes';
import { LIST_NAME } from './constants';

const tagColors = {
  requested_by: 'blue',
};

const List = (
  { onUpdateListFilter, listFilter: globalListFilter },
) => {
  const { params } = useRouteMatch();
  const { t } = translation;
  const { entityId, customerId } = params;

  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();

  const columns = [];

  const filterPills = {
    requested_by: (_, value) => `${t('requestedBy')}: ${value}`,
  };

  const sortList = [
    {
      value: 'start', label: t('start'), desc: true, default: true,
    },
    { value: 'end', label: t('end') },
    { value: 'modified', label: t('modified') },
    { value: 'duration_in_seconds', label: t('duration') },
  ];

  const callback = useCallback((state, azureSearchParser) => {
    const parsers = { };
    const payload = {
      payload: {},
      global: isGlobalAdmin,
      customerId,
    };

    if (state.isFilterTrigger) {
      Object.assign(payload, {
        payload: azureSearchParser.facets(state, state.filterKey, parsers),
      });
    } else {
      Object.assign(payload, {
        payload: azureSearchParser.items(state, parsers),
      });
    }

    onUpdateListFilter({
      [LIST_NAME]: { ...state },
    });

    const { filter } = payload.payload;
    Object.assign(payload.payload, {
      filter: `entity_ids / any (x: x eq '${entityId}') ${filter && `and ${filter}`}`.trim(),
    });

    return payload;
  }, [customerId, entityId, isGlobalAdmin, onUpdateListFilter]);

  return (

    <TableSearchProvider
      pageSize={15}
      payload={callback}
      fetcher={searchServiceWindows}
      urlStateKey={Keys.ENV_SERVICE_WINDOW}
      sort={sortList}
      initialValues={globalListFilter}
    >

      <Box flex="1">
        <TableHeader>
          <TableSearch />
          <TableSort />
          <TableDrawer title={t('filterDrawerTitle')}>

            <TableFilterSelect
              title={t('changeRequestedBy')}
              label={t('requestedBy')}
              filterKey="requested_by"
              renderTags
              color={tagColors.requested_by}
            />

          </TableDrawer>
        </TableHeader>

        <Wrap>
          <TableFilterSelect
            label={t('requestedBy')}
            filterKey="requested_by"
          />

        </Wrap>
        <TableFilterTags
          configurations={filterPills}
          colors={tagColors}
        />
      </Box>

      <Layout.Scene>
        <TableResultCount fileName="myenvironment-sw.csv" />
        <TableBody columns={columns}>
          <TableBodyHeader />
          <TableRowCards>
            {(row) => <Card row={row} key={row.id} />}
          </TableRowCards>
        </TableBody>
        <TablePagination />
      </Layout.Scene>
    </TableSearchProvider>

  );
};

const propTypes = {
  onUpdateListFilter: PropTypes.func.isRequired,
  listFilter: listFilterType,
};

List.propTypes = propTypes;

List.defaultProps = {
  listFilter: null,
};

export default withNavigation(List);
