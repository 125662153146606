import React from 'react';
import * as Layout from '~ui/Layout';
import { Button } from '~ui/Button';
import * as Block from '~ui/Block';
import {
  Spinner, Text, Heading, useTheme,
} from '@chakra-ui/react';
import { mdiArrowLeft } from '@mdi/js';
import { useRouteMatch } from 'react-router';
import translation from './AnalyticRule.translation';
import * as C from './AnalyticRule.styled';
import MetaData from '../../components/MetaData';
import Tickets from './Tickets';

import {
  useAnalyticRule,
} from '../../../../lib/services/analyticRules';

const { t } = translation;

const AnalyticRule = () => {
  const { breakpoints } = useTheme();

  const { params } = useRouteMatch();
  const {
    customerId,
    workspaceId,
    analyticRuleId,
  } = params;

  const {
    isError,
    isLoading,
    isSuccess,
    data: analyticRule,
  } = useAnalyticRule(customerId, analyticRuleId);

  if (isError) {
    return (
      <Layout.Scene>
        <Block.Center style={{ padding: '2rem' }}>
          <Block.Error>
            {t('error')}
          </Block.Error>
        </Block.Center>
      </Layout.Scene>
    );
  }

  return (
    <>
      <Layout.Omnibar.Main>
        <Layout.Omnibar.LeftActions>
          <Button
            variant="ghost"
            internalLink={`/security/${customerId}/${workspaceId}/analytic-rules`}
            leftIcon={mdiArrowLeft}
          >
            {t('back')}
          </Button>
        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>
      <Layout.Scene>
        <Block.Left withPadding>

          { isLoading && (
            <Block.Center withPadding>
              <Spinner
                color="#133A5D"
                emptyColor="#DADADA"
                speed="0.65s"
                thickness="4px"
                size="xl"
              />
            </Block.Center>
          )}

          {isSuccess && (
            <C.Layout breakpoints={breakpoints}>

              <Block.Left>
                <Heading as="h2">{analyticRule?.properties?.displayName}</Heading>
                <Text>{analyticRule?.properties?.description}</Text>

                <Tickets />
              </Block.Left>

              <Block.Left>
                <MetaData data={analyticRule} />
              </Block.Left>

            </C.Layout>
          )}

        </Block.Left>
      </Layout.Scene>
    </>
  );
};

export default AnalyticRule;
