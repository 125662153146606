import { connect } from 'react-redux';
import TicketsPage from './components/TicketDetails';
import {
  onMount, onUnmount, onSubmitComment,
} from './actions';

const mapStateToProps = ({ model }) => ({
  userName: model.userContext.name,
  email: model.userContext.email,
  customerName: model.userContext.customer_name,
});

const mapDispatchToProps = (dispatch) => ({
  onMount: (payload) => dispatch(onMount(payload)),
  onUnmount: (payload) => dispatch(onUnmount(payload)),
  onSubmitComment: (payload) => dispatch(onSubmitComment(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketsPage);
