import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useRouteMatch } from 'react-router-dom';
import * as Layout from '~ui/Layout';
import * as Permission from '~ui/Permission';
import {
  TableSort,
  TableSearch,
  TableSearchProvider,
  TablePagination,
  TableHeader,
  TableFilterSelect,
  TableBody,
  TableResultCount,
  TableRowCards,
  TableFilterTags,
  TableFilterBool,
  TableFilterStack,
} from '~ui/Table';
import { Box } from '@chakra-ui/react';
import * as Keys from '../../../../../lib/utils/historyStateKeys';
import translation from './List.translation';
import Card from './Card/Card';
import { searchServiceWindows } from '../../../../../lib/services/serviceWindow';
import { LIST_NAME } from '../constants';
import { listFilterType } from '../../../../../utils/propTypes';

const tagColors = {
  customer_display_name: 'blue',
  requested_by: 'gold',
};

const ServiceWindows = (
  { onUpdateListFilter, listFilter: globalListFilter },
) => {
  const { t } = translation;
  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();

  const { params } = useRouteMatch();
  const { customerId } = params;

  const sort = [
    {
      value: 'start', label: t('start'), default: true, desc: true,
    },
    { value: 'end', label: t('end') },
    { value: 'modified', label: t('modified') },
    { value: 'duration_in_seconds', label: t('duration') },
  ];

  const filterCollection = {
    customer_display_name: (_, value) => `${t('customer')}: ${value}`,
    requested_by: (_, value) => `${t('requestedBy')}: ${value}`,
    end: { render: false },
  };

  const callback = useCallback((state, azureSearchParser) => {
    const parsers = {
      filter: {
        end: (value) => (value === true ? `end gt ${moment().toISOString()} and enabled eq true` : null),
      },
    };

    if (state.isFilterTrigger) {
      return {
        payload: azureSearchParser.facets(state, state.filterKey, parsers),
        global: permissions.isGlobalAdmin(),
        isGlobalAdmin,
        customerId,
      };
    }

    onUpdateListFilter({
      [LIST_NAME]: { ...state },
    });
    return {
      payload: azureSearchParser.items(state, parsers),
      global: permissions.isGlobalAdmin(),
      isGlobalAdmin,
      customerId,
    };
  }, [onUpdateListFilter, permissions, isGlobalAdmin, customerId]);

  const { filter: initialFilterValues, ...restInitialValues } = globalListFilter || {};

  const initialEndFilter = globalListFilter ? {} : { end: [true] };
  const initialValues = {
    ...restInitialValues,
    filter: {
      ...initialFilterValues,
      ...initialEndFilter,
    },
  };

  return (
    <>
      <TableSearchProvider
        pageSize={15}
        payload={callback}
        fetcher={searchServiceWindows}
        urlStateKey={Keys.SW}
        sort={sort}
        initialValues={initialValues}
      >
        <Layout.Omnibar.Main>
          <Box flex="1">
            <TableHeader>
              <TableSearch />
              <TableSort />
            </TableHeader>
            <TableFilterStack>
              {isGlobalAdmin && (
                <TableFilterSelect
                  label={t('customer')}
                  filterKey="customer_display_name"
                />
              )}

              <TableFilterSelect
                label={t('requestedBy')}
                filterKey="requested_by"
              />

              <TableFilterBool
                title={t('onlyActive')}
                label={t('onlyActive')}
                filterKey="end"
                horizontal
              />
            </TableFilterStack>

            <TableFilterTags
              configurations={filterCollection}
              colors={tagColors}
            />

          </Box>
        </Layout.Omnibar.Main>
        <Layout.Scene>
          <TableResultCount fileName="my-enviornment.csv" />
          <TableBody>
            <Box m={4}>
              <TableRowCards>
                {(data) => (
                  <Card row={data} key={data.id} />
                )}
              </TableRowCards>
            </Box>
          </TableBody>
          <TablePagination />
        </Layout.Scene>
      </TableSearchProvider>
    </>
  );
};

const propTypes = {
  onUpdateListFilter: PropTypes.func.isRequired,
  listFilter: listFilterType,
};

ServiceWindows.propTypes = propTypes;

ServiceWindows.defaultProps = {
  listFilter: null,
};

export default ServiceWindows;
