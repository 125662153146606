import { connect } from 'react-redux';
import Main from './components/Main';
import * as A from './actions';
import withNavigation from '../../../../hoc/Navigation';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onMount: (payload) => dispatch(A.onMount(payload)),
  onUnmount: (payload) => dispatch(A.onUnmount(payload)),
  onUpdateAlertRule: (payload) => dispatch(A.onUpdateAlertRule(payload)),
  onDisableAlertRule: (alertRule) => dispatch(A.onUpdateAlertRule({ ...alertRule, enabled: 0 })),
  onActivateAlertRule: (alertRule) => dispatch(A.onUpdateAlertRule({ ...alertRule, enabled: 1 })),
});

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Main));
