import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Dismissable from '~ui/Dismissable';
import { Button } from '~ui/Button';
import translate from './CloudOpsDescription.translation';

const id = 'banner.cloudOpsInformation.dismissed.v2';

const CloudOpsDescription = ({ customerId, entityId }) => {
  const { t } = translate;

  return (
    <Dismissable.Primary
      id={id}
      title={t('whatIsCloudOps')}
      fadeOutSpeed={500}
      withBottomMargin
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <div>
          <p>{t('cloudOpsText')}</p>
        </div>

        <Link to={`/my-environment/${customerId}/${entityId}/cloudops`}>
          <Button colorScheme="gold">{t('availableOptions')}</Button>
        </Link>
      </div>
    </Dismissable.Primary>
  );
};

CloudOpsDescription.propTypes = {
  customerId: PropTypes.string,
  entityId: PropTypes.string,
};

CloudOpsDescription.defaultProps = {
  customerId: '',
  entityId: '',
};

export default CloudOpsDescription;
