import Api from '../../../../../lib/api';

const searchTickets = async ({ customerId, ticketId }) => {
  const path = `customer/${customerId}/ticket/${ticketId}/comment`;
  const params = { maxItems: -1 };

  return Api.continuationToken.get(path, null, params)
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default searchTickets;
