import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Month',
  sv: {
    title: 'Välj månad',
  },
  en: {
    title: 'Select month',
  },
});
