import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Security.AnalyticRule',
  sv: {
    back: 'Tillbaka',
    openTickets: 'Öppna ärenden',
    closedTickets: 'Stängda ärenden',
    noRelatedTickets: 'Denna regel har inte genererat några ärenden sedan den aktiverades',
    showAll: 'Visa alla',
    showLess: 'Vissa färre',
  },
  en: {
    back: 'Back',
    openTickets: 'Open tickets',
    closedTickets: 'Closed tickets',
    noRelatedTickets: 'This analytic rule have not generated any tickets since activation',
    showAll: 'Show all',
    showLess: 'Show less',
  },
});
