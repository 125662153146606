import { connect } from 'react-redux';
import EditAlertRule from './components/EditAlertRule';
import * as A from './actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onMount: (payload) => dispatch(A.onMount(payload)),
  onUnmount: (payload) => dispatch(A.onUnmount(payload)),
  onSubmit: (payload) => dispatch(A.onUpdateAlertRule(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAlertRule);
