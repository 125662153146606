import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'MyEnvInformationDetails',
  sv: {
    back: 'Tillbaka',
  },
  en: {
    back: 'Back',
  },
});
