import Api from '../../../../../../../lib/api';

const updateAutoActionsForEntity = async ({
  customerId,
  entityId,
  model,
}) => {
  const path = `customer/${customerId}/entity/${entityId}/autoactionprocessmodel/${model.id}`;

  return Api.put(path, model)
    .then((success) => ({ success }))
    .catch((error) => {
      if (error?.response?.data) {
        return { error: error.response.data };
      }

      return { error };
    });
};

export default updateAutoActionsForEntity;
