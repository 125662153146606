import { useMemo } from 'react';
import { useQueries, useMutation, useQueryClient } from 'react-query';
import { getSecurityHours } from '~lib/services/getSecurityHours';
import { addSecurityHours } from '~lib/services/addSecurityHours';

/*
  Hook for handling queries for security hours
*/

const queryStaleTimeCurrentMonth = 60 * 1000;
const queryStaleTimePreviousMonths = 60 * 1000 * 60 * 24 * 7;
const QUERY_BASE_KEY = 'security_hours';

const buildQueryKey = (({ customerId, year, month }) => [QUERY_BASE_KEY, customerId, year, month]);

function generateMonthQueries(monthsDates, customerId) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  return monthsDates.map((date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    const isCurrentMonth = year === String(currentYear) && month === String(currentMonth);
    const staleTime = isCurrentMonth ? queryStaleTimeCurrentMonth : queryStaleTimePreviousMonths;

    const queryKey = buildQueryKey({ customerId, year, month });

    return {
      queryKey,
      queryFn: () => getSecurityHours({ customerId, year, month }),
      enabled: !!customerId,
      staleTime,
    };
  });
}

function getMonths(startDate, numberOfMonths, customerId) {
  const monthsDates = Array.from({ length: numberOfMonths }, (_, index) => {
    const newDate = new Date(startDate);
    newDate.setMonth(newDate.getMonth() - index);
    return newDate;
  });

  const monthsQueries = useQueries(generateMonthQueries(monthsDates, customerId));

  const months = monthsQueries.map((query, index) => {
    const { isLoading, data, isFetched } = query;

    return ({
      date: monthsDates[index],
      data,
      isLoading,
      isFetched,
    });
  });

  return months;
}

function useSecurityHoursQuery(selectedCustomerId, selectedYear, selectedMonthNumber) {
  const queryClient = useQueryClient();
  const selectedMonthIndex = selectedMonthNumber - 1;
  const selectedDate = new Date(selectedYear, selectedMonthIndex, 1);
  const selectedMonthQueryKey = buildQueryKey({
    customerId: selectedCustomerId,
    year: selectedYear,
    month: selectedMonthNumber,
  });

  const [
    selectedMonthQueryData] = getMonths(selectedDate, 1, selectedCustomerId);

  /* Mutations */

  const mutateSelectedMonth = useMutation((form) => addSecurityHours({
    ...form, customerId: selectedCustomerId, year: selectedYear, month: selectedMonthNumber,
  }), {

    onSuccess: (data) => {
      const { error } = data;

      if (!error) {
        // invalidate query to trigger refetch data
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey?.join() === selectedMonthQueryKey.join(),
        });
      }
    },

  });

  /* Month Rate */
  const monthsRangeQueryData = getMonths(selectedDate, 6, selectedCustomerId);

  const isMonthsRangeLoading = useMemo(() => monthsRangeQueryData.some(
    (monthQuery) => monthQuery.isLoading,
  ), [monthsRangeQueryData]);

  const isAllMonthsRateQueriesSettled = useMemo(() => monthsRangeQueryData.every(
    (monthQuery) => monthQuery.isFetched,
  ), [monthsRangeQueryData]);

  const monthsRange = useMemo(() => {
    if (!isAllMonthsRateQueriesSettled) {
      return null;
    }
    return monthsRangeQueryData;
  }, [isAllMonthsRateQueriesSettled, monthsRangeQueryData]);

  /* Selected Month Rate */
  const isSelectedMonthLoading = selectedMonthQueryData.isLoading;
  const selectedMonth = selectedMonthQueryData.data || null;

  return {
    monthsRange,
    isMonthsRangeLoading,
    selectedMonth,
    isSelectedMonthLoading,
    mutateSelectedMonth,
  };
}

export default useSecurityHoursQuery;
