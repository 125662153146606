import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Block from '~ui/Block';
import { Button } from '~ui/Button';
import * as Permission from '~ui/Permission';
import {
  Spinner, useToast, Heading, useTheme,
} from '@chakra-ui/react';
import { mdiPlus } from '@mdi/js';
import * as C from './AlertRulesPage.styled';
import translation from './AlertRulesPage.translation';
import AlertRuleDisclaimer from './AlertRuleDisclaimer';
import AlertRule from '../AlertRule/AlertRule';

import { myEnvironmentPermission } from '../../../../../../../../lib/utils/featureKeys';

const { t } = translation;

const propTypes = {
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  onDisableAlertRule: PropTypes.func.isRequired,
  onActivateAlertRule: PropTypes.func.isRequired,
};

const AlertRulesPage = ({
  onMount,
  onUnmount,
  onDisableAlertRule,
  onActivateAlertRule,
  entityId,
  customerId,
}) => {
  const { colors } = useTheme();

  const toast = useToast();
  const [isLoading, setLoading] = useState(null);
  const [alertRules, setAlertRules] = useState({
    active: [],
    inactive: [],
  });

  useEffect(() => {
    onMount({
      setLoading,
      setAlertRules,
      onSuccess: () => toast({
        description: t('success'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      }),
      onError: () => toast({
        description: t('fail'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      }),
    });

    return () => onUnmount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Block.Center>
        <Spinner
          color="#133A5D"
          emptyColor="#DADADA"
          speed="0.65s"
          thickness="4px"
          size="xl"
        />
      </Block.Center>
    );
  }

  return (
    <>
      <Heading as="h2">{ t('title')}</Heading>
      <C.Paragraph colors={colors}>
        { t('description')}
      </C.Paragraph>

      <AlertRuleDisclaimer
        entityId={entityId}
        customerId={customerId}
      />

      <Permission.Render withFeature={[myEnvironmentPermission.write]}>
        <Button
          variant="block"
          internalLink={`/my-environment/${customerId}/${entityId}/alert-rules/create`}
          rightIcon={mdiPlus}
        >
          {t('createNew')}
        </Button>
      </Permission.Render>

      {alertRules?.active?.length > 0 && (
        <Block.Stretch style={{ marginTop: '2rem' }}>
          <Heading as="h2">{`${t('activeRules')} (${alertRules.active.length})`}</Heading>
          { alertRules.active
            .map((rule) => (
              <AlertRule
                key={rule.id}
                isActiveRule
                alertRule={rule}
                buttonAction={() => {
                  onDisableAlertRule(rule);
                }}
              />
            ))}
        </Block.Stretch>
      )}

      {alertRules?.inactive?.length > 0 && (
        <Block.Stretch style={{ marginTop: '2rem' }}>
          <Heading as="h2">{`${t('inactiveRules')} (${alertRules.inactive.length})`}</Heading>
          { alertRules.inactive
            .map((rule) => (
              <AlertRule
                key={rule.id}
                alertRule={rule}
                buttonAction={() => {
                  onActivateAlertRule(rule);
                }}
              />
            ))}
        </Block.Stretch>
      )}
    </>
  );
};

AlertRulesPage.propTypes = propTypes;

export default AlertRulesPage;
