import React from 'react';
import PropTypes from 'prop-types';
import * as TimeComponents from '~ui/TimeComponents';
import { useTheme } from '@chakra-ui/react';
import * as C from './Card.styled';
import translation from './Card.translation';

const { t } = translation;

const TimeComponentss = ({ isOpen, serviceWindow }) => {
  const { colors, breakpoints } = useTheme();

  const { dyn_is_passed: hasExpired } = serviceWindow;
  return (
    <C.Icons breakpoints={breakpoints}>

      {isOpen && <C.ScheduleText>{t('schedule')}</C.ScheduleText>}
      <C.DateSpan isOpen={isOpen}>
        <TimeComponents.DateSpan serviceWindow={serviceWindow} />
      </C.DateSpan>

      <C.Repeat>
        <TimeComponents.Repeat
          serviceWindow={serviceWindow}
          useAnimation
          showPercentage
        />
      </C.Repeat>

      {isOpen && <C.OccurrenceText>{hasExpired ? t('finalOccurrence') : t('thisOccurrence')}</C.OccurrenceText>}
      <C.StartEnd colors={colors}>
        <TimeComponents.StartEnd serviceWindow={serviceWindow} />
      </C.StartEnd>
    </C.Icons>
  );
};

TimeComponentss.propTypes = {
  isOpen: PropTypes.bool,
  serviceWindow: PropTypes.instanceOf(Object),
};

TimeComponentss.defaultProps = {
  isOpen: false,
  serviceWindow: null,
};

export default TimeComponentss;
