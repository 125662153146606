import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '~ui/Button';
import {
  Tag, Box, Text, Heading, Wrap, useTheme,
} from '@chakra-ui/react';
import { mdiChevronRight } from '@mdi/js';
import * as C from './AnalyticRuleCard.styled';
import translation from './AnalyticRuleCard.translation';
import { handleTags } from '../handleTags';

const { t } = translation;

const AnalyticRuleCard = ({ properties, navigationLink }) => {
  const { colors, breakpoints } = useTheme();

  const {
    tactics, severity, description, displayName,
  } = properties;

  return (
    <C.Card colors={colors} breakpoints={breakpoints}>
      <C.Content>
        <Heading
          as="h3"
          textTransform="capitalize"
          style={{
            display: 'flex', flexOrientation: 'row', columnGap: '.8rem', gridArea: 'text',
          }}
        >
          {displayName}
        </Heading>
        <C.Severity>
          <Text>{t('severity')}</Text>
          <Tag bg="#f5edd8">
            {severity || t('unknown')}
          </Tag>
        </C.Severity>

        <Text fontSize="sm" style={{ gridArea: 'description', color: 'theme.gray800' }}>{description}</Text>

        <Box>
          <Text fontSize={12} mb={2}>{t('tactics')}</Text>
          <Wrap spacing={4} mb={2}>
            {handleTags({ items: tactics, maxLength: 5 })?.map((tactic) => <Tag key={tactic} bg="#f5edd8">{tactic}</Tag>)}
          </Wrap>
        </Box>

      </C.Content>

      <C.Navigator colors={colors} breakpoints={breakpoints}>
        <Button
          variant="ghost"
          internalLink={navigationLink}
          rightIcon={mdiChevronRight}
        />
      </C.Navigator>
    </C.Card>
  );
};

AnalyticRuleCard.propTypes = {
  properties: PropTypes.instanceOf(Object).isRequired,
  navigationLink: PropTypes.string.isRequired,
};

export default AnalyticRuleCard;
