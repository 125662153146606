import styled from 'styled-components';

export const ListItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border-radius: 5px;
    &:hover {
        background: ${({ colors }) => colors?.gray?.['50']};
    }
    div {
        background: transparent;
    }
    
`;
