/* eslint-disable prefer-object-spread */
export const moveInArray = (list, from, to) => {
  const copy = list.slice();
  const element = copy[from];
  copy.splice(from, 1);
  copy.splice(to, 0, element);

  return copy;
};

export const buildProcessModel = (processModel, sequence) => {
  const rootAction = {};
  let latestActionNode = null;

  sequence.forEach((action, index) => {
    const actionCopy = Object.assign({}, action);

    if (index === 0) {
      latestActionNode = Object.assign(rootAction, actionCopy);
    } else {
      Object.assign(latestActionNode, {
        succeeded_child: actionCopy,
      });
      latestActionNode = actionCopy;
    }
  });

  const {
    autoActions, // Remove local attribute from payload
    ...parsedModel
  } = processModel;

  return Object.assign({}, parsedModel, {
    root_action: rootAction,
  });
};
