import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { mdiArrowLeft } from '@mdi/js';
import { useHistory } from 'react-router';
import { Button } from '~ui/Button';
import * as Layout from '~ui/Layout';
import translation from './CreateTicket.translation';
import Scene from './Scene';

const { t } = translation;

const propTypes = {
  customerId: PropTypes.string.isRequired,
};

const CreateTicketLayout = ({ customerId }) => {
  const history = useHistory();

  const gotToTickets = useCallback(() => {
    history.push(`/tickets/${customerId}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  return (
    <>
      <Layout.Omnibar.Main>
        <Layout.Omnibar.LeftActions>
          <Button
            variant="ghost"
            leftIcon={mdiArrowLeft}
            onClick={() => history.goBack()}
          >
            {t('back')}
          </Button>
        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>

      <Layout.Scene>
        <Scene onTicketCreatedCallback={gotToTickets} />
      </Layout.Scene>
    </>
  );
};

CreateTicketLayout.propTypes = propTypes;
export default CreateTicketLayout;
