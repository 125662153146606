import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useTheme,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Portal,
  Tooltip,
} from '@chakra-ui/react';
import * as C from './AlertStatus.styled';
import * as icons from './icons';
import translation from './AlertStatus.translation';
import PopOverDetail from '../PopOverDetail';

const propTypes = {
  alertName: PropTypes.string.isRequired,
  entity: PropTypes.instanceOf(Object).isRequired,
  isVisible: PropTypes.bool.isRequired,
  isScrolling: PropTypes.bool.isRequired,
};

const defaultProps = {
};

const getComponentParts = (level, hasActiveServiceWindow, ticketId, t, colors) => {
  if (!level && level !== 0) {
    return ({
      Icon: icons.NotApplicableIcon,
      text: t('alertNotApplicable'),
      shortText: t('alertNotApplicableShort'),
      color: colors?.white,
      circleColor: colors?.gray?.['300'],
    });
  }

  if (hasActiveServiceWindow) {
    return ({
      Icon: icons.ServiceWindowIcon,
      text: t('serviceWindow'),
      shortText: t('serviceWindowShort'),
      color: colors?.white,
      circleColor: colors?.black,
    });
  }

  if (ticketId === null) {
    return ({
      Icon: icons.AllGoodIcon,
      text: t('allGood'),
      shortText: t('allGoodShort'),
      color: colors?.white,
      circleColor: colors?.green?.['700'],
    });
  }

  const switchObject = {
    1: {
      Icon: icons.IncidentIcon,
      text: t('low'),
      color: colors?.white,
      circleColor: colors?.priority?.low,
    },
    2: {
      Icon: icons.IncidentIcon,
      text: t('medium'),
      color: colors?.white,
      circleColor: colors?.priority?.medium,
    },
    3: {
      Icon: icons.IncidentIcon,
      text: t('high'),
      color: colors?.white,
      circleColor: colors?.priority?.high,
    },
    4: {
      Icon: icons.IncidentIcon,
      text: t('urgent'),
      color: colors?.ruby?.['100'],
      circleColor: colors?.priority?.urgent,
    },
    default: {
      error: true,
    },
  };

  return switchObject[level === 0 ? 1 : level] ?? switchObject.default;
};

const AlertStatus = ({
  alertName, entity, isVisible, isScrolling,
}) => {
  const { colors } = useTheme();
  const { t } = translation;

  const foundAlertRule = useMemo(
    () => entity?.alert_rules?.find((alertRule) => alertRule?.alert_rule_name
      ?.toLocaleLowerCase() === alertName?.toLocaleLowerCase()),
    [entity, alertName],
  );

  const {
    ticket_priority: ticketPriority,
    alert_rule_severity: alertRuleSeverity,
    has_active_service_window: hasActiveServiceWindow,
    ticket_id: ticketId,
  } = foundAlertRule ?? {};

  const priorityLevel = ticketPriority || alertRuleSeverity;

  const componentParts = getComponentParts(
    priorityLevel, hasActiveServiceWindow, ticketId, t, colors,
  );

  const {
    Icon,
    text: iconText,
    color, circleColor, error,
  } = componentParts ?? {};

  const {
    onOpen, onClose, isOpen, onToggle,
  } = useDisclosure();

  useEffect(() => {
    if (isScrolling && isOpen) {
      onClose();
    }
  }, [isScrolling, onClose, isOpen]);

  const iconContent = (
    <C.IconWrapper isPopOverOpen={isOpen} onClick={onToggle} circleColor={circleColor}>
      <Icon fill={color} />
    </C.IconWrapper>
  );

  return (
    <>
      {!error && (
      <Popover
        strategy="fixed"
        variant="responsive"
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isVisible && isOpen}
        matchWidth
        placement="auto"
        isLazy
      >
        <PopoverTrigger>
          <C.GridCell isPopOverOpen={isOpen} colors={colors}>

            { isVisible && !isScrolling ? (
              <Tooltip label={iconText}>
                {iconContent}
              </Tooltip>
            ) : (iconContent)}

          </C.GridCell>
        </PopoverTrigger>

        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopOverDetail
              alertRule={foundAlertRule}
              iconCircleColor={circleColor}
              iconColor={color}
              Icon={Icon}
              iconText={iconText}
              entity={entity}
              onClose={onClose}
            />
          </PopoverContent>
        </Portal>

      </Popover>

      )}
    </>
  );
};

AlertStatus.propTypes = propTypes;
AlertStatus.defaultProps = defaultProps;

export default AlertStatus;
