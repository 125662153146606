import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import IncidentsCard from '../IncidentsCard';

const propTypes = {
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  customerDisplayName: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

const CustomerCard = ({
  onMount,
  onUnmount,
  customerDisplayName,
  count,
}) => {
  const [loading, setLoading] = useState(true);
  const [incidents, setIncidents] = useState({
    urgent: 0, high: 0, medium: 0, low: 0,
  });
  const [graph, setGraph] = useState([]);
  const [customerName, customerId, link] = useMemo(() => {
    const user = customerDisplayName.match(/^(.+) \((\d+)\)$/);

    if (user) {
      const [, name, id] = user;
      return [name, id, `/dashboard/${id}/`];
    }

    return ['', '', ''];
  }, [customerDisplayName]);

  const onSuccess = useCallback((incidentsCount, graphData) => {
    const result = incidentsCount.reduce((acc, item) => {
      const numValue = parseInt(item.value, 10);
      if (numValue === 1) { acc.low = item.count; }
      if (numValue === 2) { acc.medium = item.count; }
      if (numValue === 3) { acc.high = item.count; }
      if (numValue === 4) { acc.urgent = item.count; }

      if (numValue > acc.highestPriority) {
        acc.highestPriority = numValue;
      }
      return acc;
    }, {
      urgent: 0,
      high: 0,
      medium: 0,
      low: 0,
      highestPriority: -1,
    });

    setIncidents(result);
    setGraph(graphData);
    setLoading(false);
  }, []);

  const onFail = useCallback(() => {
    setIncidents([]);
    setGraph([]);
    setLoading(false);
  }, []);

  useEffect(() => {
    onMount({
      onSuccess,
      onFail,
      customerId,
    });

    return () => onUnmount({ customerId });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <IncidentsCard
      name={customerName}
      urgent={incidents.urgent}
      high={incidents.high}
      medium={incidents.medium}
      low={incidents.low}
      highestPriorityLevel={incidents.highestPriority}
      customerId={customerId}
      total={count}
      link={link}
      graph={graph}
      loading={loading}
    />
  );
};

CustomerCard.propTypes = propTypes;

export default CustomerCard;
