import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 100vw;
  position: relative;
  top: -1px;

  @media screen and (min-width: ${({ breakpoints }) => breakpoints.lg}) {
    max-width: calc(100vw - 60px);
  }
`;

export const BottomButtonWrapper = styled.div`
  transition: transform 0.3s ease-in-out;
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(90deg)' : 'rotate(0)')};
  margin-right: 0.75rem;
`;

export const Header = styled.div`
  background-color: ${({ colors }) => colors?.gray?.['200']};
  border-top: 1px solid ${({ colors }) => colors?.gray?.['300']};
  padding: 1.25rem 1rem;
  cursor: pointer;
`;

export const Title = styled.p`
  font-family: "Poppins";
  font-size: 16px;
  color:  ${({ colors }) => colors?.black};
  margin: 0;
  @media screen and (min-width: ${({ breakpoints }) => breakpoints.md}) {
    font-size: 18px;
  }
`;
