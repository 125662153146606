import Api from '../../../../../lib/api';

const getCustomer = async ({ customerId }) => {
  const path = `customer/${customerId}`;

  return Api.get(path)
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default getCustomer;
