import Api from '../../../../../../../lib/api';

const getAvailableServices = (customerId, entityId) => Api
  .get(`customer/${customerId}/entity/${entityId}/CloudOpsServices`)
  // .then(response => response.map(s => Object.assign(s, {
  //   isRecommendedService: is24Hour(s),
  //   labelHourCost: getCost(s),
  //   labelMonthlyCost: getMonthlyCost(s),
  // })))
  .then((response) => ({ availableServices: response }))
  .catch(() => ({ availableServices: [] }));

export default getAvailableServices;
