import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import * as Layout from '~ui/Layout';
import * as Permission from '~ui/Permission';
import {
  TableSort,
  TableSearch,
  TableRows,
  TableSearchProvider,
  TablePagination,
  TableHeader,
  TableFilterTags,
  TableFilterSelect,
  TableBody,
  TableResultCount,
} from '~ui/Table';
import {
  Box,
  Flex,
} from '@chakra-ui/react';
import * as Keys from '../../../../lib/utils/historyStateKeys';
import translation from './ListWorkspaces.translation';
import Card from '../../components/WorkspaceCard';
import { searchWorkspaces } from '../../../../lib/services/workspace';
import { listFilterType } from '../../../../utils/propTypes';
import { LIST_NAME } from './constants';
import { LIST_NAME as LIST_TEMPLATES } from '../ListTemplates/constants';
import { LIST_NAME as LIST_ANALYTICS_RULES } from '../ListAnalyticRules/constants';

const tagColors = {
  customer_display_name: 'gold',
};

const ListWorkspaces = (
  { onUpdateListFilter, listFilter: globalListFilter },
) => {
  const { t } = translation;
  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();
  const { params } = useRouteMatch();
  const { customerId } = params;

  const sortList = [
    {
      value: 'workspace_name',
      label: t('workspace'),
      desc: false,
      default: true,
    },
  ];

  const filterPills = {
    ...(isGlobalAdmin && { customer_display_name: (_, value) => `${t('customer')}: ${value}` }),
  };

  const callback = useCallback((state, azureSearchParser) => {
    const parsers = {};

    if (state.isFilterTrigger) {
      return {
        payload: azureSearchParser.facets(state, state.filterKey, parsers),
        isGlobalAdmin,
        customerId,
      };
    }
    onUpdateListFilter({
      [LIST_NAME]: { ...state },
      [LIST_TEMPLATES]: null,
      [LIST_ANALYTICS_RULES]: null,
    });
    return {
      payload: azureSearchParser.items(state, parsers),
      isGlobalAdmin,
      customerId,
    };
  }, [onUpdateListFilter, isGlobalAdmin, customerId]);

  return (
    <>
      <TableSearchProvider
        pageSize={15}
        payload={callback}
        fetcher={searchWorkspaces}
        urlStateKey={Keys.SECURITY_WORKSPACE}
        sort={sortList}
        initialValues={globalListFilter}
      >
        <Layout.Omnibar.Main>
          <Box flex="1">
            <TableHeader>
              <TableSearch placeholder={t('searchPlaceholder')} />
              <TableSort />
            </TableHeader>

            {isGlobalAdmin && (
              <>
                <Flex alignItems="center" justifyContent="space-between">
                  <TableFilterSelect
                    label={t('customerDisplayName')}
                    filterKey="customer_display_name"
                  />
                </Flex>

                <TableFilterTags
                  configurations={filterPills}
                  colors={tagColors}
                />
              </>
            )}

          </Box>
        </Layout.Omnibar.Main>

        <Layout.Scene>
          <TableResultCount fileName="workspaces.csv" />
          <Box p={4}>
            <TableBody>
              <TableRows>
                {(data) => (
                  <Card
                    key={data.id}
                    customer={data.customer_display_name}
                    tags={data.workspace_tables}
                    navigationLink={`/security/${data.customer_id}/${data.id}/analytic-rules`}
                    workspaceName={data.workspace_name}
                  />
                )}
              </TableRows>
            </TableBody>
          </Box>
        </Layout.Scene>
        <TablePagination />
      </TableSearchProvider>
    </>
  );
};

const propTypes = {
  onUpdateListFilter: PropTypes.func.isRequired,
  listFilter: listFilterType,
};

ListWorkspaces.propTypes = propTypes;

ListWorkspaces.defaultProps = {
  listFilter: null,
};

export default ListWorkspaces;
