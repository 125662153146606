import Api from '../../../../../../../lib/api';

const updateAutoActionsForEntity = async ({
  customerId,
  modelId,
}) => {
  const path = `customer/${customerId}/autoactionprocessmodel/${modelId}`;

  return Api.delete(path)
    .then((success) => ({ success }))
    .catch((error) => {
      if (error?.response?.data) {
        return { error: error.response.data };
      }

      return { error };
    });
};

export default updateAutoActionsForEntity;
