import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'CloudOpsPageServiceRowComponent',
  sv: {
    view: 'Visa',
    addService: 'Konfigurerar tjänst',
    custom: 'Specialanpassat pris',
    hour: 'timme',
    month: 'månad',
    free: 'Gratis',
    activeInterval: 'Aktivt intervall',
    cancel: 'Avbryt',
    activate: 'Aktivera',
    perMonth: '/månad',
    btnTooltip: 'Konfigurera innan aktivering',
  },
  en: {
    view: 'View More',
    addService: 'Configure Service',
    custom: 'Custom pricing',
    hour: 'hour',
    month: 'month',
    free: 'Free',
    activeInterval: 'Active interval',
    cancel: 'Cancel',
    activate: 'Activate',
    perMonth: '/month',
    btnTooltip: 'Configure before activation',
  },
});
