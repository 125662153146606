import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AdminOnCall',
  sv: {

  },
  en: {

  },
});
