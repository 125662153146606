import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { mdiArrowLeft } from '@mdi/js';
import * as Block from '~ui/Block';
import { Button } from '~ui/Button';
import * as Layout from '~ui/Layout';
import { Spinner } from '@chakra-ui/react';
import translation from './CreateEditProcessModel.translation';
import ConfigureService from '../ConfigureService';
import SequenceForm from '../SequenceForm';
import { moveInArray } from '../../helpers';

const propTypes = {
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  onSaveProcessModel: PropTypes.func.isRequired,
};

const defaultProps = {};

const AutoActionsPage = ({
  onMount,
  onUnmount,
  onSaveProcessModel,
}) => {
  const { t } = translation;
  const { params } = useRouteMatch();
  const { entityId, customerId, processModelId } = params;

  const backLink = `/my-environment/${customerId}/${entityId}/auto-actions`;

  const [loading, setLoading] = useState(true);
  const [selectedNewAction, setSelectedNewAction] = useState(null);
  const [selectedEditAction, setSelectedEditAction] = useState(null);

  const [metadata, setMetaData] = useState({});
  const [sequence, setSequence] = useState([]);
  const [alertRules, setAlertRules] = useState([]);
  const [avaliableAutoActions, setAvaliableAutoActions] = useState([]);

  const [canApplyOnAlertRule, setCanApplyOnAlertRule] = useState(true);
  const isEditFormOpen = (selectedNewAction || selectedEditAction);

  const [showAvailableActions, setShowAvailableActions] = useState(false);

  useEffect(() => {
    onMount({
      entityId,
      customerId,
      setLoading,
      setAlertRules,
      setAvaliableAutoActions,
      setCanApplyOnAlertRule,
      processModelId,
      setMetaData,
      setSequence,
    });

    return () => onUnmount();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const handleMoveDown = (index) => {
    const newSequence = moveInArray(sequence, index, index + 1);
    setSequence(newSequence);
  };
  const handleMoveUp = (index) => {
    const newSequence = moveInArray(sequence, index, index - 1);
    setSequence(newSequence);
  };

  const handleGoToStepOne = useCallback(() => {
    setSelectedNewAction(null);
    setSelectedEditAction(null);
    setShowAvailableActions(false);
  }, []);

  const onAddAction = useCallback((action) => {
    if (selectedEditAction) {
      const newList = sequence.reduce((acc, item) => {
        if (item.localId === action.localId) {
          return [...acc, action];
        }
        return [...acc, item];
      }, []);
      setSequence([...newList]);
      handleGoToStepOne();
    } else {
      const localId = `${new Date().getTime()}-${sequence.length}`;
      const newAction = { ...action, localId };
      setSequence([...sequence, newAction]);
      handleGoToStepOne();
    }
  }, [handleGoToStepOne, selectedEditAction, sequence]);

  const onRemoveAction = useCallback((action) => {
    const newSequence = sequence.filter((s) => s.localId !== action.localId);
    setSequence(newSequence);
  }, [sequence]);

  return (
    <>
      <Layout.Omnibar.Main>
        <Layout.Omnibar.LeftActions>
          {(!selectedNewAction && !selectedEditAction && !showAvailableActions)
            ? (
              <Button
                variant="ghost"
                leftIcon={mdiArrowLeft}
                internalLink={backLink}
              >
                {t('back')}
              </Button>
            )
            : (
              <Button
                variant="ghost"
                leftIcon={mdiArrowLeft}
                onClick={handleGoToStepOne}
              >
                {t('cancel')}
              </Button>
            )}

        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>
      <Layout.Scene>
        { loading && (
          <Block.Center withPadding>
            <Spinner
              color="#133A5D"
              emptyColor="#DADADA"
              speed="0.65s"
              thickness="4px"
              size="xl"
            />
          </Block.Center>
        )}
        {!loading && (
          <>
            { isEditFormOpen && (
              <ConfigureService
                selectedAction={(selectedNewAction || selectedEditAction)}
                isEdit={Boolean(selectedEditAction)}
                onAddAction={onAddAction}
              />
            )}
            <SequenceForm
              onMoveDown={handleMoveDown}
              onMoveUp={handleMoveUp}
              backLink={backLink}
              onSaveProcessModel={onSaveProcessModel}
              onRemoveAction={onRemoveAction}
              avaliableAutoActions={avaliableAutoActions}
              alertRules={alertRules}
              sequence={sequence}
              canApplyOnAlertRule={canApplyOnAlertRule}
              processModelId={processModelId}
              metadata={metadata}
              setSelectedNewAction={setSelectedNewAction}
              selectedEditAction={selectedEditAction}
              setSelectedEditAction={setSelectedEditAction}
              selectedNewAction={selectedNewAction}
              onAddAction={onAddAction}
              showAvailableActions={showAvailableActions}
              setShowAvailableActions={setShowAvailableActions}
            />
          </>
        )}
      </Layout.Scene>
    </>
  );
};

AutoActionsPage.propTypes = propTypes;
AutoActionsPage.defaultProps = defaultProps;

export default AutoActionsPage;
