import addTranslation from '../../translations/addTranslation';

export default addTranslation({
  id: 'LineGraph',
  sv: {
    noData: 'Ingen grafdata',
  },
  en: {
    noData: 'No graph data',
  },
});
