import styled from 'styled-components';

export const Wrapper = styled.div`
  max-height: 500px;
  overflow: scroll;
`;

export const Cell = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem;
`;

export const Row = styled.tr`
  `;

export const Table = styled.table`
  width: 100%;
  ${Row}:not(:last-child) {
    border-bottom: ${({ colors }) => `1px solid ${colors?.gray?.['200']}`};
  }
`;
