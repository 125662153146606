import * as i18n from '~ui/translations';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const {
  i18next, findTranslations, addComponentTranslations, addTranslation,
} = i18n;
const context = require.context('./../', true, /\.translation.js$/);
const moduleTranslations = findTranslations(context);

const resources = {
  en: {
    translations: moduleTranslations.en,
  },
  sv: {
    translations: moduleTranslations.sv,
  },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // we init with resources
    resources,
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations', 'autoactions', 'asurgentui', 'ui'],
    defaultNS: 'translations',
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },
    react: { wait: true },
  });

/**
 * Add asurgent-ui translations to resource-bundle
 */
addComponentTranslations();
addTranslation();

export default i18next;

export const setUserLanguage = () => {
  const browserLanguage = navigator.language;

  if (browserLanguage === 'sv' || browserLanguage === 'en-US') {
    i18next.changeLanguage(browserLanguage);
  } else {
    i18next.changeLanguage('en');
  }
};
