import React from 'react';
import { Heading, Text, useTheme } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import MdiIcon from '@mdi/react';
import * as C from './SelectedCloudOpsServices.styled';
import translation from '../../../../../CloudOpsService/scenes/Main/components/CardRow/RowComponent.translation';
import { iconMapper } from '../../../../../CloudOpsService/scenes/Main/iconMapperHelper';
import WeekList from '../../../../../CloudOpsService/scenes/Main/components/WeekList';

const propTypes = {
  service: PropTypes.instanceOf(Object).isRequired,
};

const RowComponentSelected = ({ service }) => {
  const { colors, breakpoints } = useTheme();

  const { t } = translation;

  return (
    <C.Card colors={colors} breakpoints={breakpoints}>
      <C.Icon colors={colors}>
        <MdiIcon path={iconMapper(service.name)} size={2} />
      </C.Icon>
      <C.Content>
        <Heading as="h2" style={{ marginBottom: 0 }}>{service.name}</Heading>
        <Text isTruncated>{service.description}</Text>
        <WeekList onWeekDays={service.weekDays} onWeekEnds={service.weekEnds} style={{ paddingTop: '0.5rem' }} />
      </C.Content>
      <C.Content style={{ alignItems: 'flex-end' }} className="pricing">
        { service.customBilling && (
          <span>{t('custom')}</span>
        )}
        { !service.customBilling && (
          <>
              { !service.isFree && (
                <span>
                  {`${service.monthlyPrice}/${t('month')}`}
                </span>
              )}
              { service.isFree && (
                <span>
                  {t('free')}
                </span>
              )}
          </>
        )}
      </C.Content>
    </C.Card>
  );
};

RowComponentSelected.propTypes = propTypes;

export default RowComponentSelected;
