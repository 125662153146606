import {
  take,
  call,
  cancel,
  delay,
  fork,
} from 'redux-saga/effects';
import * as t from './actionTypes';
import ticketsSearch from './services/ticketsSearch.service';

function* getCustomerIncitents(meta) {
  const { onSuccess, onFail } = meta;
  const payload = {
    facets: ['customer_display_name, count: 1000'],
    filter: `(type eq 'incident') and 
      (status ne 'closed') and 
      (alert_rule_name ne '') and 
      (alert_rule_name ne null)`,
    order_by: [],
    page: 1,
    page_size: 0,
    search_fields: [],
    search_string: '',
  };
  const { success } = yield call(ticketsSearch, payload);

  if (success) {
    yield call(onSuccess, success);
  } else {
    yield call(onFail, 'Could not load tickets');
  }
}

function* startWebPoll(meta) {
  while (true) {
    yield call(getCustomerIncitents, meta);
    yield delay(60 * 1000);
  }
}

export default function* rootWatcher() {
  while (true) {
    const { meta } = yield take(t.MOUNT);

    const tasks = [
      yield fork(startWebPoll, meta),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
