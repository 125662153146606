import dompurify from 'dompurify';
import showdown from 'showdown';

const ALLOWED_TAGS = [
  'a',
  'b',
  'blockquote',
  'br',
  'code',
  'div',
  'em',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'i',
  'img',
  'li',
  'p',
  'pre',
  'strong',
  'table',
  'tbody',
  'td',
  'th',
  'thead',
  'tr',
  'ul',
  'span',
];
const ALLOWED_ATTR = ['href', 'colspan', 'mailto', 'src', 'style'];

export const unescapeBackslashes = (str = '') => str.replace(/\\n?/g, '');

export const makeDirtyHTML = ({ markdown, flavor }) => {
  const converter = new showdown.Converter();
  converter.setFlavor(flavor);
  const dirtyHTML = converter.makeHtml(markdown);
  return dirtyHTML;
};

export const sanitizeHtml = ({ dirtyHTML }) => {
  const cleanHTML = dompurify.sanitize(dirtyHTML, { ALLOWED_TAGS, ALLOWED_ATTR });
  return cleanHTML;
};

function isPlainObject(variable) {
  return typeof variable === 'object' && variable !== null && Object.prototype.toString.call(variable) === '[object Object]';
}

export function objectToCodeMarkup(data, startIndent = 0) {
  const isRecursiveRoot = startIndent === 0;
  if (!isPlainObject(data)) {
    return '';
  }

  const comma = '<span style="color: var(--code-color-1) !important;">,</span>';

  function valueToMarkup(value, indent, isArrayItem = false) {
    let valueString = '';

    const indentStyle = `display: block; margin-left: ${10 * (indent + 1)}px;`;
    const dynamicIndentStyle = isArrayItem ? indentStyle : '';

    if (typeof value === 'string') {
      valueString = `<span style="${dynamicIndentStyle} color: var(--code-color-3)">"${value}"${comma}</span>`;
    } else if (Array.isArray(value)) {
      valueString = `<span style="${dynamicIndentStyle} color: var(--code-color-1)">[</span>
        ${value.map((v) => valueToMarkup(v, isArrayItem ? indent + 1 : indent, true)).join('')}
      <span style="${dynamicIndentStyle} color: var(--code-color-1)">]${comma}</span>`;
    } else if (isPlainObject(value)) {
      valueString = `<span style="color: var(--code-color-1)">{</span>
        ${objectToCodeMarkup(value, indent)}
      <spanstyle="color: var(--code-color-1)">}${comma}</span>`;
    } else if (typeof value === 'number') {
      valueString = `<span style="${dynamicIndentStyle} color: var(--code-color-4)">${value}${comma}</span>`;
    } else if (value === null) {
      valueString = `<span style="${dynamicIndentStyle} color: var(--code-color-5)">null${comma}</span>`;
    } else if (value === true) {
      valueString = `<span style="${dynamicIndentStyle} color: var(--code-color-5)">true${comma}</span>`;
    } else if (value === false) {
      valueString = `<span style="${dynamicIndentStyle} color: var(--code-color-5)">false${comma}</span>`;
    } else if (typeof value === 'undefined') {
      valueString = `<span style="${dynamicIndentStyle} color: var(--code-color-6)">undefined${comma}</span>`;
    } else {
      valueString = `<span style="${dynamicIndentStyle} color: var(--code-color-3)">${JSON.stringify(value, null, 2)}${comma}</span>`;
    }

    return `${valueString}`;
  }

  function getNestedDataType(_data) {
    return Object.entries(_data).map(([key, value]) => (
      `<div style="margin-left: ${10 * (startIndent + 1)}px;">
            <span style="color: var(--code-color-2)">"${key}"<span /><span style="color: var(--code-color-1)">:
            ${valueToMarkup(value, startIndent + 1)}
            </div>`)).join('');
  }

  // Adding support for overriding the style in with parent css-variables
  return (
    `
    ${isRecursiveRoot ? `<code style="
    --code-color-1: var(--override-code-color-1, #5a758e);
    --code-color-2: var(--override-code-color-2, #C53030);
    --code-color-3: var(--override-code-color-3, #4d836f);
    --code-color-4: var(--override-code-color-4, #133a5d);
    --code-color-5: var(--override-code-color-5, #ED64A6);
    --code-color-6: var(--override-code-color-6, #979797);
    --code-background-color: var(--override-code-backgroundcolor-2, #F2F2F2);
    display: block;
    background-color: var(--override-code-backgroundcolor-2, #F2F2F2) !important;
    border-radius: 5px;
    line-height: 2;
    margin-top: 10px;
    padding: 10px;
    font-size: 12px;
    ">` : `<div>`}
      ${isRecursiveRoot ? '<span style="color: var(--code-color-1)">{</span>' : ''}
       ${getNestedDataType(data)}
    ${isRecursiveRoot ? '<span style="color: var(--code-color-1)">}</span>' : ''}
    ${isRecursiveRoot ? '</code>' : '</div>'}
    `
  );
}
