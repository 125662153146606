import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'CreateTicketModalModuleService',
  sv: {
    success: 'Ärende skapat',
    fail: 'Misslyckades att skapa Ärende',
  },
  en: {
    success: 'Success! Created Ticket',
    fail: 'Failed to create Ticket',
  },
});
