import styled from 'styled-components';

export const TestStatus = styled.div`
    margin-right: 0.5rem;
    display: block;
    width: 8px;
    height: 8px;
    margin-top: 1px;
    border-radius: 100%;
    background: ${({ colors, succeeded }) => (succeeded ? colors?.green?.['700'] : colors?.ruby?.['800'])};
`;
