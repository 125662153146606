import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'EntityGroups',
  sv: {
    test: '',
  },
  en: {
    test: '',
  },
});
