import { useQuery } from 'react-query';

import Api from '../api';

export const useUserContext = (config) => {
  const getUserContext = async () => {
    const path = `authentication/usercontext`;
    return Api.post(path, {});
  };

  return useQuery(
    ['user_context'],
    () => getUserContext(), {
      ...config,
    },
  );
};
