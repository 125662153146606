import ReactGA from 'react-ga';
import * as Permission from '~ui/Permission';
import NotAuthorized from './components/NotAuthorized/scene';

export const filterRoutesByPermissions = (permissions, list, isUIMenu) => {
  if (permissions) {
    const parsed = list.reduce((acc, route) => {
      const { featureKeys, roleKeys } = route;
      const render = Permission.hasPermission(permissions, roleKeys, featureKeys);

      if (render) {
        const authorizedRoute = {
          ...route,
          render: true,
        };
        return [...acc, authorizedRoute];
      }

      if (isUIMenu) {
        return acc;
      }

      // return acc;
      const notAuthorizedRoute = {
        ...route,
        component: NotAuthorized,
        render: false,
      };

      return [...acc, notAuthorizedRoute];
    }, []);

    if (isUIMenu) {
      return parsed;
    }

    /*
      *  Some routese render different components for different roles. (e.g /dashboard)
      *  We need to filter out the blocked route
    */
    const withoutDuplicatedRoutes = parsed.filter(({ path, render }) => {
      const countPaths = parsed.filter((item) => path && item.path === path).length;
      return !(countPaths > 1 && !render);
    });

    return withoutDuplicatedRoutes;
  }

  return [];
};

export const setGAUserInfo = ({ customer, userContext }) => {
  // GA is only initialized on this condition in GoogleAnalytics.js
  const GAIsEnabled = process.env.NODE_ENV !== 'development';

  const customerRoles = customer.role_feature_mappings.map((roleFeature) => roleFeature?.role);

  if (GAIsEnabled) {
    try {
      // for regular reports using custom dimensions
      ReactGA.set({
        dimension1: customer.id,
        dimension2: customer.name,
        dimension3: customerRoles,
        dimension4: userContext.oid,
      });
      // for specific user report, test it out and delete later if it doesn't add anything
      ReactGA.set({ userId: userContext.oid });
    } catch (e) {
      // google analytics failed initializing
    }
  }
};

export const msalCacheKiller = () => {
  const { localStorage: store } = window;

  if (store) {
    // Find all keys that start with msal.
    const match1 = Object.keys(store)
      .filter((key) => key.match(/^(msal\.)(.+)?/));

    // Find all keys that start with {"authority": }
    const match2 = Object.keys(store)
      .filter((key) => key.match(/^{"authority":.+?$/));

    [...match1, ...match2].forEach((key) => {
      localStorage.removeItem(key);
    });
  }
};
