/* eslint-disable react/no-array-index-key */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { mdiChevronDown, mdiChevronLeft } from '@mdi/js';
import MdiIcon from '@mdi/react';
import { Text, Collapse, useTheme } from '@chakra-ui/react';
import { groupData } from './helpers';
import * as C from './Month.styled';
import translation from './Month.translation';
import Entry from '../Entry';

const { t } = translation;

const Month = ({ month, data }) => {
  const { colors } = useTheme();
  const [isOpen, setIsOpen] = useState(true);

  // group the data if multiple days with no fails or no data in a row
  const monthData = useMemo(() => {
    if (data) {
      return groupData([...data]);
    }
    return null;
  }, [data]);

  return (
    <>
      <C.TimespanContainer onClick={() => setIsOpen(!isOpen)}>
        <Text>{t(`month${month}`)}</Text>
        <C.TimespanBorder colors={colors} />
        <MdiIcon path={isOpen ? mdiChevronDown : mdiChevronLeft} size={0.75} />
      </C.TimespanContainer>
      <Collapse in={isOpen} animateOpacity>
        {monthData && monthData.map((group, index) => <Entry key={`entry-${index}`} group={group} />)}
      </Collapse>

    </>
  );
};
Month.propTypes = {
  month: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};
Month.propTypes = {

};

export default Month;
