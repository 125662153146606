import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import * as Block from '~ui/Block';
import * as List from '~ui/List';
import { Button } from '~ui/Button';
import { useRouteMatch } from 'react-router-dom';
import {
  mdiCancel,
  mdiPencilOutline,
  mdiArrowUp,
  mdiArrowDown,
} from '@mdi/js';
import { Flex } from '@chakra-ui/react';
import translation from './SelectedAction.translation';
import SelectedActionRow from './SelectedActionRow';
import actionTranslations from '../../AutoActions.translation';

const propTypes = {
  action: PropTypes.instanceOf(Object).isRequired,
  onRemoveAction: PropTypes.func.isRequired,
  onEditAction: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
};

const defaultProps = {};

const SelectedAction = ({
  action,
  onRemoveAction,
  onEditAction,
  onMoveUp,
  onMoveDown,
}) => {
  const { t } = translation;
  const { params: { customerId, entityId } } = useRouteMatch();

  const rows = useMemo(() => action
    .required_parameter_specifications
    .map((field) => {
      const { fallback } = actionTranslations;

      const {
        name,
        description,
        name_translation_key: nameKey,
        description_translation_key: descKey,
      } = field;

      const fallbackName = `${name}`.replace(/([a-z])([A-Z])/g, '$1 $2');
      const rawValue = `${action.parameters[name]}`;
      const value = translation.fallback(rawValue, rawValue);

      return ({
        tooltip: fallback(descKey, description),
        label: fallback(nameKey, fallbackName),
        value: `${value}`,
      });
    }), [action]);

  const hasParameters = !!action.required_parameter_specifications?.length;

  return (
    <Block.Accordion
      title={action.name}
      description={action.description}
      override={(isOpen) => (
        <SelectedActionRow
          service={action}
          isOpen={isOpen}
          customerId={customerId}
          entityId={entityId}
        />
      )}
    >
      {() => (
        <>
          { hasParameters && (<List.Primary rows={rows} />)}
          { !hasParameters && (

            <Block.Info
              withMargins
              withBottomMargin
              title={t('emptyStateTitle')}
            >
              <p>{t('emptyStateDesc')}</p>
            </Block.Info>
          )}

          <Flex mt="1rem" flexDirection="row-reverse">
            { hasParameters && (
              <Button
                onClick={onEditAction}
                style={{ marginLeft: '1rem' }}
                leftIcon={mdiPencilOutline}
              >
                {t('editAction')}
              </Button>
            )}
            <Button
              bg="ruby.900"
              style={{ marginLeft: '1rem' }}
              onClick={onRemoveAction}
              leftIcon={mdiCancel}
            >
              {t('removeAction')}
            </Button>

            <Button
              tooltip={t('moveUp')}
              variant="link"
              style={{ marginLeft: '1rem' }}
              rightIcon={mdiArrowUp}
              onClick={onMoveUp}
            />

            <Button
              tooltip={t('moveDown')}
              variant="link"
              rightIcon={mdiArrowDown}
              onClick={onMoveDown}
            />

          </Flex>
        </>
      )}

    </Block.Accordion>
  );
};

SelectedAction.propTypes = propTypes;
SelectedAction.defaultProps = defaultProps;

export default SelectedAction;
