import React from 'react';
import PropTypes from 'prop-types';
import MdiIcon from '@mdi/react';
import * as Block from '~ui/Block';
import * as Permission from '~ui/Permission';
import { Text, Heading, useTheme } from '@chakra-ui/react';
import { iconMapper } from '../../iconMapperHelper';
import * as C from './RowComponent.styled';
import ServiceForm from '../ServiceForm';
import translation from './RowComponent.translation';

import { myEnvironmentPermission } from '../../../../../../../../lib/utils/featureKeys';

const propTypes = {
  service: PropTypes.instanceOf(Object).isRequired,
};

const RowComponentAvaliable = ({ service }) => {
  const { colors } = useTheme();

  const { t } = translation;
  return (
    <>
      <Block.Accordion
        noHorizontalBorder
        btnTooltip={t('btnTooltip')}
        compact
        override={() => (
          <C.LinkCard>
            <C.Icon colors={colors}>
              <MdiIcon path={iconMapper(service.name)} size={2} />
            </C.Icon>
            <C.Content>
              <Heading as="h3" fontWeigt="bold" style={{ marginBottom: 0 }}>{service.name}</Heading>
              <Text isTruncated>{ service.description}</Text>
            </C.Content>
          </C.LinkCard>
        )}
      >
        {() => (
          <Permission.Render withFeature={[myEnvironmentPermission.write]}>
            <ServiceForm mode="CREATE" service={service} />
          </Permission.Render>
        )}
      </Block.Accordion>
    </>
  );
};

RowComponentAvaliable.propTypes = propTypes;

export default RowComponentAvaliable;
