import styled from 'styled-components';

export const Wrapper = styled.div`
 display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  text-align: center;
`;

export const DataBall = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DataBallText = styled.p`
  font-size: 40px;
  font-family: Poppins;
  margin: 0;
`;

export const DataLabel = styled.p`
  text-transform: uppercase;
  font-size: 12px;
`;
