import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  fill: PropTypes.string.isRequired,
};

const defaultProps = {};

const ServiceWindowIcon = ({ fill }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill || 'currentColor'} d="M10.9169 0.859375V0.877708V2.71104C14.9411 3.20604 17.7919 6.86354 17.2969 10.8877C16.8752 14.2244 14.2536 16.8735 10.9169 17.2677V19.101C15.9586 18.5969 19.6252 14.1235 19.1211 9.08187C18.7086 4.72771 15.2527 1.29021 10.9169 0.859375ZM9.08356 0.886875C7.29606 1.06104 5.59106 1.74854 4.19773 2.90354L5.50856 4.26021C6.53523 3.43521 7.77273 2.90354 9.08356 2.72021V0.886875ZM2.90523 4.19604C1.75023 5.58937 1.06273 7.28521 0.879395 9.08187H2.71273C2.88689 7.78021 3.40023 6.54271 4.21606 5.50687L2.90523 4.19604ZM0.888561 10.9152C1.07189 12.7119 1.77773 14.4077 2.91439 15.801L4.21606 14.4902C3.40939 13.4544 2.88689 12.2169 2.72189 10.9152H0.888561ZM5.50856 15.8377L4.19773 17.0935C5.58189 18.2485 7.28689 18.9727 9.08356 19.1652V17.3319C7.78189 17.1669 6.54439 16.6444 5.50856 15.8377ZM14.4186 12.9227L10.6511 9.15521C11.0269 8.20187 10.8161 7.08354 10.0277 6.30437C9.20273 5.47021 7.96523 5.30521 6.96606 5.76354L8.74439 7.54187L7.50689 8.78854L5.68273 7.00104C5.18773 8.00021 5.41689 9.23771 6.22356 10.0719C7.01189 10.8602 8.13023 11.0619 9.08356 10.6952L12.8511 14.4535C13.0161 14.6277 13.2636 14.6277 13.4286 14.4535L14.3819 13.5094C14.5836 13.3444 14.5836 13.051 14.4186 12.9227Z" />
  </svg>
);

ServiceWindowIcon.propTypes = propTypes;
ServiceWindowIcon.defaultProps = defaultProps;

export default ServiceWindowIcon;
