import React from 'react';
import moment from 'moment';
import * as Moment from '~ui/Moment';
import { Tag, Wrap } from '@chakra-ui/react';

export const alertRuleMapper = (alertRule, t, isGlobalAdmin) => {
  const {
    severity,
    threshold,
    aggregation,
    metric_name: metricName,
    depends_on_metrics: dependsOnMetrics,
    threshold_comparison: thresholdComparison,
    alerts_before_action: alertsBeforeAction,
    critical_threshold: criticalThreshold,
    valid_from_dt: validFromDt,
    valid_to_dt: validToDt,
    customer_id: customerId,
    auto_close_delay_in_seconds: autoCloseDelayInSeconds,
  } = alertRule;

  const isValidFrom = !(moment(validFromDt) < moment());
  const isValidTo = !(moment(validToDt).toISOString() === moment('9999-12-31').toISOString());
  const isDependingOnMetrics = dependsOnMetrics.length > 0;

  return [
    isGlobalAdmin && { label: t('customerId'), value: customerId },
    { label: t('severity'), value: t(`severity${severity}`) },
    { label: t('aggregation'), value: t(`aggregation${aggregation}`) },
    { label: t('threshold'), value: threshold },
    { label: t('criticalThreshold'), value: criticalThreshold },
    { label: t('thresholdComparison'), value: t(`thresholdComparison${thresholdComparison}`) },
    { label: t('alertsBeforeAction'), value: alertsBeforeAction },
    { label: t('metricName'), value: metricName },
    {
      label: t('dependsOnMetrics'),
      value:
        isDependingOnMetrics
          ? (
            <Wrap spacing={2}>
              {dependsOnMetrics.map((metric) => <Tag key={metric} bg="#f5edd8">{ metric}</Tag>)}
            </Wrap>
          )
          : null,
    },
    {
      label: t('autoCloseDelayInSeconds'),
      value: autoCloseDelayInSeconds && (
        <>
          <Moment.Duration seconds={autoCloseDelayInSeconds} />
          <span style={{ marginLeft: '0.25rem' }}>{t('delay')}</span>
        </>
      ),
    },
    isValidFrom && {
      label: t('validFrom'),
      value: <Moment.Ago timestamp={validFromDt} />,
    },
    isValidTo && {
      label: t('validTo'),
      value: <Moment.Ago timestamp={validToDt} />,
    },
    !isValidTo && { label: t('valid'), value: t('untillDisabled') },
  ];
};
