import React, { useState, useEffect } from 'react';
import { Button } from '~ui/Button';
import * as Layout from '~ui/Layout';
import { Spinner } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { mdiArrowLeft } from '@mdi/js';
import PowerBI from './PowerBI';
import translation from './Report.translation';
import * as C from './Report.styled';

const { t } = translation;

const propTypes = {
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
};

const Report = ({ onMount, onUnmount }) => {
  const { params } = useRouteMatch();

  const [pbiConfig, setPbiConfig] = useState(null);
  const [pbiService, setPbiService] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const { customerId, groupId, reportId } = params;

  useEffect(() => {
    onMount({
      customerId,
      groupId,
      reportId,
      setPbiConfig,
      setPbiService,
      setIsLoading,
      setError,
    });

    return () => onUnmount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Layout.Omnibar.Main>
        <Layout.Omnibar.LeftActions>
          <Button variant="ghost" leftIcon={mdiArrowLeft} internalLink={`/reports/${customerId}`}>
            {t('reports')}
          </Button>
        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>
      <Layout.Scene style={{ paddingBottom: '0', overflow: 'hidden' }}>
        {isLoading && (
        <C.Center>
          <Spinner
            color="#133A5D"
            emptyColor="#DADADA"
            speed="0.65s"
            thickness="4px"
            size="xl"
          />
        </C.Center>
        )}
        { (pbiService && pbiConfig) && (
          <PowerBI
            pbiService={pbiService}
            pbiConfig={pbiConfig}
          />
        )}
        {error && <C.Center><h3>{t('powerBITokenError')}</h3></C.Center>}
      </Layout.Scene>
    </>
  );
};

Report.propTypes = propTypes;

export default Report;
