import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Route.ErrorViews.Component',
  sv: {
    more: 'Läs mer',
    title: 'Cloudops',
    intro: 'Marknadens enda automatiserade driftstjänst för molnet. Vår CloudOps-tjänst ger er molnmiljö övervakning och incidenthantering alla timmar på dygnet.',
    login: 'Logga in',
  },
  en: {
    more: 'Learn more',
    title: 'Cloudops',
    intro: 'The only automated IT-operations service on the market developed for the public clouds. Our system monitors and manages your cloud environment around the clock.',
    login: 'Sign in',
  },
});
