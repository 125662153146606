import styled from 'styled-components';

export const Icon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ colors }) => colors?.blue?.['900']};
`;

export const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
`;

export const Card = styled.div`
     padding: 1.5rem 2rem 1.5rem 0;
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 6rem 1fr;
    grid-template-areas: "icon content";
    color:  #000;
    border: 1px solid ${({ colors }) => colors?.blue?.['900']};
        border-radius: 5px;
    margin-bottom: 1rem;


    .pricing {
        display: none;
    }

    @media screen and (min-width: ${({ breakpoints }) => breakpoints.md}) {
        grid-template-columns: 6rem 1fr 12.5rem;
        grid-template-areas: 
            "icon"
            "content"
            "content";
        
        .pricing {
            display: flex;
        }
    }
      &:last-of-type {
        margin-bottom: 0;
    }
    &:first-of-type {
        margin-top: 1.5rem;
    }
`;
