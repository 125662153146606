import React from 'react';
import {
  useTheme, Text, Heading, HStack, Tag, Box, SimpleGrid, Wrap,
} from '@chakra-ui/react';
import { useSecurityHours } from '~scenes/securityHours/context/SecurityHoursContext';
import DataBall from '~scenes/securityHours/Components/DataBalls/DataBall';
import * as Block from '~ui/Block';
import translation from './ServiceLevel.translation';
import * as C from './ServiceLevel.styled';
import { SERVICE_LEVEL_ADVANCED, SERVICE_LEVEL_BASIC, SERVICE_LEVEL_STANDARD } from '../../constants';

const getServiceColor = (serviceLevel, colors) => {
  if (serviceLevel === SERVICE_LEVEL_ADVANCED) return colors.gold[500];

  switch (serviceLevel) {
    case SERVICE_LEVEL_BASIC:
      return colors?.blue[100];

    case SERVICE_LEVEL_STANDARD:
      return colors?.gold[200];

    case SERVICE_LEVEL_ADVANCED:
      return colors?.gold[500];

    default:
      return 'white';
  }
};

const ServiceLevel = () => {
  const { colors } = useTheme();
  const { t } = translation;

  const {
    selectedCustomerServiceLevel,
    selectedCustomerServices,
    selectedMonth,
    isServiceLevelAdvanced,
  } = useSecurityHours();

  if (!selectedMonth) return null;

  const {
    service_level_hours_included: includedHours,
    service_level_hours_included_spent: hoursSpent,
    service_level_hours_included_left_to_spend: hoursLeft,
  } = selectedMonth;

  const hoursSpentPercent = hoursSpent === includedHours
    ? 0 : Math.round((hoursSpent / includedHours) * 100);

  const serviceColor = getServiceColor(selectedCustomerServiceLevel, colors);

  return (
    <C.Wrapper>
      <SimpleGrid spacing="2rem" columns={[1, null, null, 2]}>
        <Box mb="2rem">
          <Text fontSize={12} textTransform="uppercase">{t('title')}</Text>
          {selectedCustomerServiceLevel && (
          <Heading
            as="h3"
            fontWeight={300}
            borderRadius="5px"
            padding="0.5rem"
            backgroundColor={serviceColor}
            textTransform="capitalize"
            width="min-content"
            mb="2rem"
          >
            {selectedCustomerServiceLevel}
          </Heading>
          )}
          {selectedCustomerServiceLevel ? (
            <>
              <Text>
                {t(`${selectedCustomerServiceLevel}ServiceDescription`)}
              </Text>
              <Box>
                <Text fontSize={12} textTransform="uppercase">{t('servicesTitle')}</Text>
                <Wrap>
                  {selectedCustomerServices?.map((service) => (
                    <Tag
                      key={service}
                      color="black"
                      colorScheme={service?.includes('2') ? 'gold' : 'blue'}
                      width="max-content"
                    >
                      {service}
                    </Tag>
                  ))}
                </Wrap>
              </Box>
            </>
          ) : (
            <Box mt="2rem">
              <Block.Info>{t('noService')}</Block.Info>
            </Box>
          )}
        </Box>
        {!isServiceLevelAdvanced && (
        <HStack justifyContent="center">
          <HStack alignItems="flex-start" wrap="wrap">
            <DataBall
              number={includedHours}
              label={t('dataBallLabelHoursIncluded')}
              color={colors?.blue?.['100']}
              infoText={t('includedHoursTooltip')}
            />
            <DataBall
              number={hoursLeft}
              label={t('dataBallLabelHoursLeft')}
              color={colors?.green?.['100']}
              infoText={t('hoursLeftTooltip')}
            />
            <DataBall
              number={hoursSpentPercent}
              label={t('dataBallLabelHoursSpentPercent')}
              color={colors?.blue?.['800']}
              showAsProgress
              infoText={t('hoursSpentTooltip')}
            />
          </HStack>
        </HStack>
        )}
      </SimpleGrid>
    </C.Wrapper>

  );
};

export default ServiceLevel;
