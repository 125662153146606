import Api from '../../../../../../../lib/api';

const getVMConfig = async ({ customerId, entityId }) => {
  const path = `customer/${customerId}/entity/${entityId}/vmagentconfig`;

  return Api.get(path)
    .then((success) => ({ success }))
    .catch((error) => {
      if (error?.response?.data) {
        return { error: error.response.data };
      }

      return { error };
    });
};

export default getVMConfig;
