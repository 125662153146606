import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1rem;
  background-color: ${({ colors }) => colors?.gray?.['100']};
  @media screen and (min-width: ${({ breakpoints }) => breakpoints.md}) {
  padding: 2rem;
  height: 100%;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
`;
