import * as t from './actionTypes';

const initalState = {
  isSaving: false,
  isCreateTicketTaskDone: false,
  errors: [],
  isTicketCreated: false,
};

export default (state = initalState, { type, meta }) => {
  switch (type) {
    case t.INIT_CREATE_NEW_TICKET:

      return {
        ...state,
        isSaving: true,
        isCreateTicketTaskDone: false,
        errors: [],
      };

    case t.TICKET_ERROR:
      return {
        ...state,
        errors: [...state.errors, ...meta.errors],
      };

    case t.TICKET_CREATED:
      return {
        ...state,
        isTicketCreated: true,
      };

    case t.CREATE_TICKET_TASK_DONE:
      return {
        ...state,
        isSaving: false,
        isCreateTicketTaskDone: true,
      };

    case t.UNMOUNT:

      return {
        ...state,
        ...initalState,
      };

    default:
      return state;
  }
};
