import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import * as Permission from '~ui/Permission';
import { generateFilter } from '~ui/data/azureSearch/useAzureSeachPayload';
import { searchEntityTickets } from '~lib/services/entityTicketSearch';
import { getHighestPriority, sortAlertNames } from './helpers';
import OverviewTable from './OverviewTable';

const propTypes = {
  typeName: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  customerId: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(Object),
  searchQuery: PropTypes.string,
};

const defaultProps = {
  filter: null,
  searchQuery: '',
};

const OverviewTableContainer = ({
  typeName, isExpanded, customerId, filter, searchQuery,
}) => {
  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();

  // Each section represented by this component don't respect the type filters from search
  // because it need to fetch documents only for its own type.

  const payload = {
    search_string: searchQuery,
    filter: generateFilter({ ...filter, type_name: [typeName] }),
    facets: [],
    order_by: [
    ],
    search_fields: [],
    page_size: 1000,
    page: 1,
  };

  const stringifiedPayload = window.btoa(JSON.stringify(payload));

  const queryName = `alertOverview-${stringifiedPayload}`;

  const { isLoading, data } = useQuery([queryName, payload, customerId, isGlobalAdmin],
    () => searchEntityTickets({ payload, customerId, isGlobalAdmin }), {
      staleTime: 60 * 1000, // Chaches the result for 1 minute
      enabled: isExpanded,
    });

  const tableData = useMemo(() => {
    if (!data) {
      return { alertNames: [], entities: [] };
    }
    const { result: entities } = data;

    const allAlertRules = entities?.reduce((acc, curr) => {
      const { alert_rules: alertRules } = curr;
      return acc.concat(alertRules);
    }, []) ?? [];

    const alertRuleDict = allAlertRules?.reduce((acc, curr) => {
      const { ticket_priority: ticketPriority, alert_rule_severity: alertRuleSeverity } = curr;

      const existingNonCaseSensitiveAlertRules = Object.keys(acc).filter(
        (key) => key.toLocaleLowerCase()
        === curr?.alert_rule_name?.toLocaleLowerCase(),
      );

      const alertRuleKey = existingNonCaseSensitiveAlertRules[0] || curr.alert_rule_name;

      if (acc[alertRuleKey]) {
        if (ticketPriority) {
          acc[alertRuleKey].tickets[ticketPriority] = (
            acc[alertRuleKey].tickets[ticketPriority] || 0
          ) + 1;
        } else {
          acc[alertRuleKey].alerts[alertRuleSeverity] = (
            acc[alertRuleKey].alerts[alertRuleSeverity] || 0
          ) + 1;
        }
      } else {
        acc[alertRuleKey] = {
          tickets: {},
          alerts: {},
        };

        if (ticketPriority) {
          acc[alertRuleKey].tickets[ticketPriority] = 1;
        } else {
          acc[alertRuleKey].alerts[alertRuleSeverity] = 1;
        }
      }

      return acc;
    }, {}) ?? {};

    const sortedAlertNames = Object.keys(alertRuleDict).sort(
      (aAlertName, bAlertName) => sortAlertNames(aAlertName, bAlertName, alertRuleDict),
    );

    const sortedEntities = entities.sort((a, b) => {
      const {
        totalHighestPriority: aHighestPriority,
        numOfPriorities: aNumOfPriorities,
      } = getHighestPriority(a.alert_rules);
      const {
        totalHighestPriority: bHighestPriority,
        numOfPriorities: bNumOfPriorities,
      } = getHighestPriority(b.alert_rules);

      if (aHighestPriority !== bHighestPriority) {
        return bHighestPriority - aHighestPriority;
      }

      const aNumOfPriority = aNumOfPriorities[aHighestPriority];
      const bNumOfpriority = bNumOfPriorities[bHighestPriority];

      return bNumOfpriority - aNumOfPriority;
    });
    return { alertNames: sortedAlertNames, entities: sortedEntities };
  }, [data]);

  return (
    <OverviewTable
      alertNames={tableData?.alertNames}
      entities={tableData?.entities}
      isExpanded={isExpanded}
      isLoading={isLoading}
      hasSearchQuery={!!searchQuery}
      isGlobalAdmin={isGlobalAdmin}
    />
  );
};

OverviewTableContainer.propTypes = propTypes;
OverviewTableContainer.defaultProps = defaultProps;

export default OverviewTableContainer;
