import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Edit.Ticket',
  sv: {
    success: 'Ärende uppdaterat. Det kan ta ett tag innan det syns på sidan.',
    fail: 'Kunde inte uppdatera ärende, försök igen senare',
  },
  en: {
    success: 'Ticket updated. it might take a while before it shows on this page.',
    fail: 'Could not update the ticket, try again later',
  },
});
