import React from 'react';
import { Button } from '~ui/Button';
import PropTypes from 'prop-types';
import { useToast } from '@chakra-ui/react';
import * as C from './Card.styled';
import translation from './Card.translation';

const { t } = translation;

const TicketLink = ({ getRelatedTicket, tempTicketId }) => {
  const toast = useToast();
  return (
    <C.LinkContainer>
      <Button
        variant="link"
        onClick={() => {
          if (tempTicketId) {
            getRelatedTicket();
          } else {
            toast({
              description: t('tempTicketIdMissing'),
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          }
        }}
      >
        <C.TextValue>{t('relatedTicket')}</C.TextValue>
      </Button>
    </C.LinkContainer>
  );
};
TicketLink.propTypes = {
  getRelatedTicket: PropTypes.func.isRequired,
  tempTicketId: PropTypes.string,
};
TicketLink.defaultProps = {
  tempTicketId: null,
};

export default TicketLink;
