import styled from 'styled-components';

export const Wrapper = styled.div`
`;

export const ContentWrapper = styled.div`
  background-color: ${({ colors }) => colors?.gray?.['50']};
  border-top: 1px solid ${({ colors }) => colors?.gray?.['200']};
`;

export const Content = styled.div`
  padding: 2rem;
`;

export const TabButtonWrappers = styled.div`
  position: relative;
  top: 1px;
  margin-left: 1rem;
`;

export const TabButton = styled.button`
  padding: 0.2rem 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${({ isActive, colors }) => colors?.gray?.[isActive ? '50' : '200']};
  border: 1px solid ${({ colors }) => colors?.gray?.['200']};
  border-bottom-color: ${({ isActive, colors }) => colors?.gray?.[isActive ? '50' : '0']};
  font-size: 12px;
  text-transform: uppercase;
`;

export const Bottom = styled.div`
  border-top: 1px solid ${({ colors }) => colors?.gray?.['200']};
  background-color: ${({ colors }) => colors?.gray?.['50']};
  padding: 0.8rem 1rem;
  display: grid;
`;

export const BottomButtonWrapper = styled.div`
  grid-row: 1;
  grid-column: 1 / -1;
  display: flex;
  justify-content: flex-end;
 `;
