import * as Permission from '~ui/Permission';
import { useQuery } from 'react-query';
import Api from '../api';

export const useCustomers = () => {
  const permissions = Permission.usePermission();
  const isAdmin = permissions.isGlobalAdmin();

  const getCustomers = async () => {
    const path = `customer`;

    if (isAdmin) {
      const res = await Api.get(path);
      return res;
    }
    return null;
  };

  return useQuery(
    ['sw_customers'],
    () => getCustomers(), {},
  );
};
