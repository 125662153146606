import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AdminActionsList',
  sv: {
    permissions: 'Rättigheter',
    onCall: 'On Call',
  },
  en: {
    permissions: 'Permissions',
    onCall: 'On Call',
  },
});
