import styled from 'styled-components';

export const StatusIcon = styled.div`
    height: 2rem; 
    width: 2rem; 
    border-radius: 50%; 
    border: 1px solid;
    border-color: ${({ theme, succeeded }) => (succeeded ? theme.black : theme.ruby800)}; 
    background: ${({ theme, succeeded }) => (succeeded ? 'transparent' : theme.ruby800)}; 
    margin-right: 2.5rem; 
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        color: ${({ theme, succeeded }) => (succeeded ? theme.green700 : theme.white)}; 
    }
`;

export const Grid = styled.div`
          display: grid;
          grid-column-gap: 1rem;
          padding: 1rem;
          grid-template-columns: 15.625rem 1fr;
          grid-template-areas:  "title title"
                                "content content";

          @media screen and (min-width: ${({ breakpoints }) => breakpoints.md}) {
            
          grid-template-areas:  ". title"
                                ". content";
          };
         
`;

export const Content = styled.div`
    grid-area: content;
`;

export const Progress = styled.div`
    display: none;
    grid-area: progess;
    display: flex;
    justify-content: center;
    & > div {
        position: fixed;
    }
`;

export const Title = styled.div`
    grid-area: title;
`;

export const Step = styled.div`
    display: grid; 
    grid-template-columns: auto 1fr;
    margin-bottom: 1.25rem;
`;
