import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '~ui/Button';
import * as List from '~ui/List';
import { Heading } from '@chakra-ui/react';
import { mdiArrowRight } from '@mdi/js';
import * as C from '../Main.styled';
import translation from '../Main.translation';

const { t } = translation;

const getRow = ({ array, label }) => {
  if (array.length === 0) {
    return [{
      label,
      value: '-',
    }];
  }

  return array.map((entry, index) => ({
    label: index === 0 ? label : '',
    value: (
      <C.BorderedItem key={entry.name}>
        <p>{`${t('queryName')}: ${entry.query_name || '-'}`}</p>
        <p>{`${t('logName')}: ${entry.log_name || '-'}`}</p>
        <p>{`${t('level')}: ${entry.level || '-'}`}</p>
        <p>{`${t('regexString')}: ${entry.regex_string || '-'}`}</p>
        <p>{`${t('eventId')}: ${entry.event_id || '-'}`}</p>
        <p>{`${t('sourceRegexString')}: ${entry.source_regex_string || '-'}`}</p>
      </C.BorderedItem>
    ),
  }));
};

const Queries = ({
  vmConfig, entityId, customerId, canWrite,
}) => (
  <C.Type>
    <Heading as="h2">{t('queries')}</Heading>
    <List.Primary
      rows={[
        ...getRow({ label: t('eventLogQueries'), array: vmConfig.event_log_queries }),
        {
          row: canWrite && (
            <Button
              mt="1rem"
              p={0}
              variant="ghost"
              color="asurgent.500"
              internalLink={`/my-environment/${customerId}/${entityId}/cloudops-vmagent/edit/${vmConfig.id}/queries`}
              rightIcon={mdiArrowRight}
            >
              {t('editQueries')}
            </Button>
          ),
        },
      ]}
    />
  </C.Type>
);

Queries.propTypes = {
  canWrite: PropTypes.bool.isRequired,
  vmConfig: PropTypes.instanceOf(Object),
  customerId: PropTypes.string.isRequired,
  entityId:
      PropTypes.string.isRequired,
};
Queries.defaultProps = { vmConfig: {} };

export default Queries;
