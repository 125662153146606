import styled from 'styled-components';

export const Status = styled.div`
    margin-top: 1.5rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 0.8px;
    text-transform: uppercase;

    display: flex;
    flex-direction:row;
    align-items: center;
    


    &:after {
        content: " ";
        display: block;
        width: 0.625rem;
        height: 0.625rem;
        background: ${({ status }) => (status === true ? '#13BE69' : '#C6403B')};
        margin-left: 1rem;
        border-radius: 100%;
        
    }
`;
