import styled from 'styled-components';

function getPriorityColor(colors, priority) {
  const switchObject = {
    0: {
      color: colors?.green?.['700'],
    },
    1: {
      color: colors.priority?.low,

    },
    2: {
      color: colors.priority?.medium,
    },
    3: {
      color: colors.priority?.high,

    },
    4: {
      color: colors.priority?.urgent,
    },
    default: {
      color: colors?.green?.['700'],
    },
  };

  return switchObject[priority]?.color ?? switchObject.default;
}

export const PriorityDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({ colors, priority }) => getPriorityColor(colors, priority)};
  border-radius: 50%;
  align-self: center;
`;
