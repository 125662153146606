export const overviewPermission = {
  read: '@feature.overview.read',
};
export const ticketPermission = {
  read: '@feature.ticket.read',
  write: '@feature.ticket.write',
};
export const myEnvironmentPermission = {
  read: '@feature.myenvironment.read',
  write: '@feature.myenvironment.write',
};
export const serviceWindowPermission = {
  read: '@feature.servicewindow.read',
  write: '@feature.servicewindow.write',
};
export const securityPermission = {
  read: '@feature.security.read',
  write: '@feature.security.write',
};
export const reportPermission = {
  read: '@feature.report.read',
};
export const appMonitorPermission = {
  read: '@feature.appmonitor.read',
};
