import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AlertRuleEntity',
  sv: {
    activate: 'Aktivera',
    deactivate: 'Avaktivera',
    edit: 'Uppdatera Larmregel',
    customerId: 'Kund ID',
    severity: 'Allvarlighetsgrad',
    aggregation: 'Aggregering',
    thresholdComparison: 'Tröskeljämförelse',
    threshold: 'Tröskelvärde',
    useCriticalThreshold: 'Använd kritiskt tröskelvärde',
    criticalThreshold: 'Kritiskt tröskelvärde',
    name: 'Namn',
    description: 'Beskrivning',
    alertsBeforeAction: 'Antal överträdelser',
    metricName: 'Metricnamn',
    dependsOnMetrics: 'Beror på metric',
    autoCloseDelayInSeconds: 'Stäng ärende efter',
    delay: 'inom tröskelvärde',
    validFrom: 'Giltig från',
    validTo: 'Giltig till',
    valid: 'Giltig',
    severity0: 'Låg',
    severity1: 'Medel',
    severity2: 'Hög',
    severity3: 'Brådskande',
    aggregationavg: 'Medelvärde',
    aggregationsum: 'Summa',
    useCritical1: 'Ja',
    useCritical2: 'Nej',
    untillDisabled: 'Tills avaktivering',
    'thresholdComparison<': 'Lägre än',
    'thresholdComparison>': 'Högre än',
    'thresholdComparison>=': 'Högre eller lika med',
    'thresholdComparison<=': 'Lägre eller lika med',
    'thresholdComparison=': 'Lika med',
  },
  en: {
    activate: 'Activate',
    deactivate: 'Deactivate',
    edit: 'Edit Alert Rule',
    customerId: 'Customer ID',
    severity: 'Severity',
    aggregation: 'Aggregation',
    thresholdComparison: 'Threshold Comparison',
    threshold: 'Threshold',
    useCriticalThreshold: 'Use critical threshold',
    criticalThreshold: 'Critical threshold',
    name: 'Name',
    description: 'Description',
    alertsBeforeAction: 'Number of violations',
    metricName: 'Metric name',
    dependsOnMetrics: 'Depends on metric',
    autoCloseDelayInSeconds: 'Close ticket after',
    delay: 'within threshold',
    validFrom: 'Valid from ',
    validTo: 'Valid to',
    valid: 'Valid',
    severity0: 'Low',
    severity1: 'Medium',
    severity2: 'High',
    severity3: 'Urgent',
    aggregationavg: 'Average',
    aggregationsum: 'Sum',
    useCritical1: 'Yes',
    useCritical2: 'No',
    untillDisabled: 'Until deactivated',
    'thresholdComparison<': 'Less than',
    'thresholdComparison>': 'More than',
    'thresholdComparison>=': 'More or equal to',
    'thresholdComparison<=': 'Less or equal to',
    'thresholdComparison=': 'Equal to',
  },
});
