import styled from 'styled-components';

export const Metadata = styled.div`
    display: flex;
    margin-bottom: 1rem;
    flex-direction: column;
    
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    border-bottom: 1px solid ${({ colors }) => colors?.gray?.['300']};
    padding-bottom: 1rem;
    &:nth-child(1), &:nth-child(2) {
        text-transform: capitalize;
    }

    .title {
        font-size: 0.625rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        font-weight: bold;
    }

    .value {
        border-radius: 5px;
        display: flex;
        word-break: break-all;
        color: ${({ colors }) => colors?.gray?.['800']};
        align-items: center;
        
    }
`;
