import {
  mdiSecurity,
  mdiChartDonut,
  mdiDatabase,
  mdiClockOutline,
  mdiCloudOutline,
  mdiDeveloperBoard,
  mdiPower,
} from '@mdi/js';

export const PAAS = 'PAAS';
export const SVEVIA_BIZTALK = 'Svevia Biztalk';
export const IAAS = 'IAAS';
export const INCLUDED = 'Included';
export const CLOUDOPS_SECURITY = 'CloudOps Security';
export const CLOUDOPS_DATABASE = 'CloudOps Database';

export const iconMapper = (serviceName) => {
  const name = `${serviceName}`
    .replace('Advanced', '')
    .replace('Basic', '')
    .replace('Standard', '')
    .trim();
  switch (name) {
    case PAAS:
      return mdiDeveloperBoard;
    case SVEVIA_BIZTALK:
      return mdiClockOutline;
    case IAAS:
      return mdiCloudOutline;
    case INCLUDED:
      return mdiPower;
    case CLOUDOPS_SECURITY:
      return mdiSecurity;
    case CLOUDOPS_DATABASE:
      return mdiDatabase;
    default:
      return mdiChartDonut;
  }
};
