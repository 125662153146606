import Api from '../api';

export const searchEntityTickets = async ({ payload, customerId, isGlobalAdmin }) => {
  let path = `customer/${customerId}/entityticket/search`;

  if (isGlobalAdmin) {
    path = '/entityticket/search';
  }

  return Api.post(path, payload)
    .then((result) => (result))
    .catch((error) => ({ error }));
};
