import translation from './Entry.translation';

const { t } = translation;

export const getText = (group) => {
  const hasFails = group.fails > 0;
  if (hasFails) {
    return `${group.fails} ${t('of')} ${group.total} ${t('runsFailed')}`;
  } if (group.total > 0) {
    return t(`noFailedTests`);
  }
  return t(`noData`);
};
