import {
  take,
  call,
  cancel,
  takeEvery,
  select,
} from 'redux-saga/effects';
import * as t from './actionTypes';

import getAlertRulesService from './services/getAlertRules.service';
import updateAlertRule from './services/updateAlertRule.service';

import { splitAlertRules } from './helpers';

const getEntityMeta = ({ model: { entity } }) => ({
  entityId: entity.id,
  customerId: entity.customer_id,
});

function* loadAlertRulesAction({ setAlertRules }) {
  const { customerId, entityId } = yield select(getEntityMeta);
  const { success: rules } = yield call(
    getAlertRulesService,
    { customerId, entityId },
  );

  if (rules) {
    const result = yield call(splitAlertRules, rules);
    setAlertRules(result);
  } else {
    // could not get metric definitions
    setAlertRules([]);
  }
}

function* createUpdateAlertRuleTask({
  setAlertRules,
  onError,
  onSuccess,
}, { meta: payload }) {
  const { customerId } = yield select(getEntityMeta);

  const { success } = yield call(updateAlertRule, { customerId, payload });
  if (success) {
    yield call(onSuccess);
    yield call(loadAlertRulesAction, { setAlertRules });
  } else {
    yield call(onError);
  }
}

export default function* rootWatcher() {
  while (true) {
    const { meta } = yield take(t.MOUNT);
    const {
      setLoading,
      setAlertRules,
      onError,
      onSuccess,
    } = meta;

    setLoading(true);
    yield call(loadAlertRulesAction, { setAlertRules });
    setLoading(false);

    const tasks = [
      yield takeEvery([t.UPDATE_ALERTRULE],
        createUpdateAlertRuleTask, {
          setAlertRules,
          onError,
          onSuccess,
        }),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
