import styled from 'styled-components';

export const Wrapper = styled.div`
`;

export const IconWrapper = styled.div`
  height: ${({ large }) => (large ? '2.5rem' : '2rem')};
  width: ${({ large }) => (large ? '2.5rem' : '2rem')};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ circleColor }) => circleColor};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const GridCell = styled.div`
  background-color: ${({ isPopOverOpen, colors }) => (isPopOverOpen ? colors?.gray?.['100'] : 'transparent')};
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
