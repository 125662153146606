import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'EntitiesListItem',
  sv: {
    title: '',
    priority0: 'Allt bra',
    priority1: 'Låg',
    priority2: 'Medium',
    priority3: 'Hög',
    priority4: 'Brådskande',
  },
  en: {
    title: '',
    priority0: 'All good',
    priority1: 'Low',
    priority2: 'Medium',
    priority3: 'High',
    priority4: 'Urgent',
  },
});
