import React, {
  useRef, useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  useDisclosure, Flex, Box, Popover,
  PopoverTrigger,
  PopoverContent,
  useOutsideClick,
  Text,
} from '@chakra-ui/react';
import MdiIcon from '@mdi/react';
import {
  mdiChevronRight, mdiChevronLeft, mdiChevronDown,
} from '@mdi/js';
import { Button } from '~ui/Button';
import translation from './MonthPicker.translation';
import { getYearAndMonthIndex } from './helpers';

const propTypes = {
  initialDate: PropTypes.instanceOf(Date).isRequired,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disableFutureDate: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  disableFutureDate: true,
};

const MonthPicker = ({
  initialDate, callback, disabled, disableFutureDate,
}) => {
  const { t } = translation;
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [showingYear, setShowingYear] = useState(initialDate);

  const [currentYear, currentMonthIndex] = getYearAndMonthIndex(currentDate);
  const [selectedYear, selectedMonthIndex] = getYearAndMonthIndex(selectedDate);

  const showingFullYear = showingYear.getFullYear();
  const canShowNextYear = disableFutureDate ? showingFullYear < currentYear : true;

  const isShowingCurrentYear = showingFullYear === currentYear;
  const isShowingSelectedYear = showingFullYear === selectedYear;
  const canSelectNextMonth = disableFutureDate && isShowingCurrentYear
    ? selectedMonthIndex < currentMonthIndex : true;

  const {
    onOpen, onClose, isOpen,
  } = useDisclosure();

  const popoverRef = useRef();

  useOutsideClick({
    ref: popoverRef,
    handler: () => onClose(),
  });

  const handleToggleExpandMonths = () => {
    if (isOpen) {
      onClose();
    } else {
      if (disabled) return;
      setShowingYear(selectedDate);
      onOpen();
    }
  };

  const handleShowNextYear = () => {
    if (disabled) return;
    if (!canShowNextYear) {
      return;
    }

    const nextDate = new Date(showingYear);
    nextDate.setFullYear(showingYear.getFullYear() + 1);
    setShowingYear(nextDate);
  };

  const handleShowPrevYear = () => {
    if (disabled) return;
    const nextDate = new Date(showingYear);
    nextDate.setFullYear(showingYear.getFullYear() - 1);
    setShowingYear(nextDate);
  };

  const handleSelectPrevMonth = () => {
    if (disabled) return;
    setSelectedDate((prevState) => {
      const [y, m] = getYearAndMonthIndex(prevState);

      const newDate = new Date(y, m - 1);
      setShowingYear(newDate);

      return newDate;
    });
  };

  const handleSelectNextMonth = () => {
    if (disabled) return;
    setSelectedDate((prevState) => {
      const [y, m] = getYearAndMonthIndex(prevState);

      const newDate = new Date(y, m + 1);
      setShowingYear(newDate);
      return newDate;
    });
  };

  const handleSelectDate = useCallback((event) => {
    if (disabled) return;
    const value = event?.target?.value;

    if (typeof value !== 'string') {
      return;
    }

    const [year, monthIndex] = value.split('-');

    // Validate the selectedYear and selectedMonthIndex values
    const parsedYear = parseInt(year, 10);
    const parsedMonthIndex = parseInt(monthIndex, 10);

    if (Number.isNaN(parsedYear) || Number.isNaN(parsedMonthIndex)) {
      return;
    }

    // Ensure monthIndex is within the valid range of 0-11
    if (parsedMonthIndex < 0 || parsedMonthIndex > 11) {
      return;
    }

    const newDate = new Date(parsedYear, parsedMonthIndex);

    if (Number.isNaN(newDate.getTime())) {
      return;
    }

    setSelectedDate(newDate);
    onClose();
  }, [onClose, disabled]);

  useEffect(() => {
    if (disabled) return;
    if (selectedDate !== initialDate) {
      callback(selectedDate);
    }
  }, [callback, initialDate, selectedDate, disabled]);

  return (
    <Box maxWidth="310px">
      <Popover
        strategy="fixed"
        variant="responsive"
        isOpen={isOpen}
        matchWidth
        placement="bottom"
        isLazy
      >
        <PopoverTrigger>
          <Flex justifyContent="space-between" alignItems="center">

            <Button
              colorScheme="gray"
              variant="solid"
              padding={0}
              borderRadius="50%"
              onClick={handleSelectPrevMonth}
              disabled={disabled}
            >
              <MdiIcon path={mdiChevronLeft} size={1} />
            </Button>

            <Button
              colorScheme="gray"
              variant="solid"
              padding="2rem 1.5rem"
              width="200px"
              onClick={handleToggleExpandMonths}
              disabled={disabled}

            >
              <Text m="0" mr="1rem">
                {t(`monthIndex${selectedMonthIndex}`, 'ui')}
                {' '}
                {selectedYear}
              </Text>
              {' '}
              <MdiIcon path={mdiChevronDown} size={1} />
            </Button>

            <Button
              colorScheme="gray"
              variant="solid"
              padding={0}
              borderRadius="50%"
              disabled={disabled || !canSelectNextMonth}
              onClick={handleSelectNextMonth}

            >
              <MdiIcon path={mdiChevronRight} size={1} />
            </Button>
          </Flex>
        </PopoverTrigger>

        <PopoverContent width="max-content" ref={popoverRef}>

          <Box padding="0.5rem">
            <Flex width="100%" justifyContent="space-between">

              <Button
                variant="outline"
                border="none"
                onClick={handleShowPrevYear}
              >
                <MdiIcon path={mdiChevronLeft} size={0.75} />

              </Button>
              <Text fontWeight="bold">{showingFullYear}</Text>

              <Button
                variant="outline"
                border="none"
                onClick={handleShowNextYear}
                disabled={isShowingCurrentYear}
              >
                <MdiIcon path={mdiChevronRight} size={0.75} />

              </Button>
            </Flex>
            <Box
              display="grid"
              gridTemplateColumns="repeat(3, 1fr)"
              gridGap="0.5rem"
            >
              {Array.from({ length: 12 }, (_, monthIndex) => (
                <Button
                  fontWeight="normal"
                  border="none"
                  key={`${showingFullYear}-${monthIndex}`}
                  value={`${showingFullYear}-${monthIndex}`}
                  onClick={handleSelectDate}
                  variant={isShowingSelectedYear && monthIndex === selectedMonthIndex ? 'solid' : 'outline'}
                  disabled={disableFutureDate
                     && isShowingCurrentYear && monthIndex > currentMonthIndex}
                  width="min-content"
                  padding="0.75rem"
                  placeSelf="center"
                >
                  {t(`monthIndex${monthIndex}`, 'ui')}
                </Button>
              ))}
            </Box>
          </Box>
        </PopoverContent>

      </Popover>

    </Box>
  );
};

MonthPicker.propTypes = propTypes;
MonthPicker.defaultProps = defaultProps;

export default MonthPicker;
