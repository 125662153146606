import React from 'react';

import * as Svg from '~ui/Icons';
import * as Block from '~ui/Block';
import * as Dismissable from '~ui/Dismissable';

import * as C from './AlertRulesPage.styled';
import translation from './AlertRulesPage.translation';

const { t } = translation;

const propTypes = {};

const AlertRulesDisclaimer = () => (
  <Dismissable.Primary
    id="banner.alertRulesInformation.dismissed4"
    title={t('newUser')}
    fadeOutSpeed={500}
    withBottomMargin
  >
    <Block.Plain renderTransparent>
      <Block.Wrap renderTransparent>
        <C.DescriptionText>
          <p>{t('dismissableCreateAlertRuleContent')}</p>
        </C.DescriptionText>
        <C.AsurgentIcon>
          <Svg.IconAsurgent width="9.375rem" />
        </C.AsurgentIcon>
      </Block.Wrap>
    </Block.Plain>
  </Dismissable.Primary>
);

AlertRulesDisclaimer.propTypes = propTypes;

export default AlertRulesDisclaimer;
