import { connect } from 'react-redux';
import ApplicationRouter from './components/ApplicationRouter';
import {
  clearLoginTriggered,
  onMount,
  onSetRedirectOrigin,
  onUnmount,
  setLanguage,
  triggerLogout,
} from './actions';

const GLOBAL_ADMIN_KEY = 'api-permission-global-admin';

const mapStateToProps = ({ application, model }) => {
  const result = {
    application,
    userTheme: null,
    permissions: {},
    userContext: null,
  };

  if (model.userContext) {
    Object.assign(result, {
      application,
      userTheme: model.userContext.theme_config,
      userContext: model.userContext,
    });
  }
  if (model.customer) {
    const customerRolesAndFeatures = model.customer.role_feature_mappings;

    const { permissions: userContextPermissions } = model.userContext;

    const isAsurgent = model.userContext?.customer_id === process.env.REACT_APP_ADMIN_CUSTOMER_ID;

    const mappedRoleToFeatures = customerRolesAndFeatures
      .reduce((acc, item) => {
        if (item.role === GLOBAL_ADMIN_KEY && !isAsurgent) {
          return { ...acc };
        }
        // only use the customer roles/features that are present
        // on the usercontext
        if (userContextPermissions.includes(item.role)) {
          return {
            ...acc,
            [item.role]: item.features,
          };
        }
        return { ...acc };
      }, {});

    Object.assign(result, {
      permissions: {
        globalAdminKey: GLOBAL_ADMIN_KEY,
        ...mappedRoleToFeatures,
      },
    });
  }

  return result;
};

const mapDispatchToProps = (dispatch) => ({
  onMount: (payload) => dispatch(onMount(payload)),
  onUnmount: (payload) => dispatch(onUnmount(payload)),
  onSetRedirectOrigin: (payload) => dispatch(onSetRedirectOrigin(payload)),
  onLogout: () => dispatch(triggerLogout()),
  onChangeLangauge: (payload) => dispatch(setLanguage(payload)),
  onClearLoginTriggered: () => dispatch(clearLoginTriggered()),
});

const ConnectedRouterComponent = connect(mapStateToProps, mapDispatchToProps)(ApplicationRouter);

export default ConnectedRouterComponent;
