import Api from '../../../../../lib/api';

const postTicketResponse = async ({
  customerId, ticketId, email, name, message,
}) => {
  const path = `customer/${customerId}/ticket/${ticketId}/comment`;
  const payload = {
    email,
    name,
    comment: message,
    is_private: false,
  };

  return Api.post(path, payload)
    .then((success) => ({ success }))
    .catch((error) => {
      if (error?.response?.data) {
        return { error: error.response.data };
      }

      return { error };
    });
};

export default postTicketResponse;
