import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
  Text,
  Box,
  Checkbox,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { useUpdateCustomerPermissions } from './useUpdateCustomerPermissions';

const PermissionRow = ({
  roleFeatureMapping,
  availableFeatures,
  customerName,
  customerId,
}) => {
  const updateCustomer = useUpdateCustomerPermissions(customerId);
  const mappedFeatures = Object.values(roleFeatureMapping);
  const featureReadWriteGrouping = Object.entries(availableFeatures.reduce((acc, fe) => {
    const group = fe.replace('.read', '').replace('.write', '').replace('@feature.', '');
    const isWrite = fe.includes('.write');

    if (acc[group]) {
      return { ...acc, [group]: { ...acc[group], [isWrite ? 'write' : 'read']: fe } };
    }

    return { ...acc, [group]: { [isWrite ? 'write' : 'read']: fe } };
  }, {}));

  return (
    <Box w="fit-content" border="1px solid" borderColor="gray.300" borderRadius={5} mt={5} width="100%">
      <Heading as="h3" fontWeight="500" p={3} borderBottom="1px solid" borderColor="gray.300">
        {customerName}
      </Heading>
      { mappedFeatures.length === 0 && (
        <Box p={3}>
          <Text fontSize="s" as="i">No mapped roles</Text>
        </Box>
      )}
      { mappedFeatures.map(({ role, features: activeFeatures }) => (
        <Box mt={6} key={role}>
          <Text fontWeight="600" textColor="gray.800" ml={4} textDecoration="underline" mb={2}>{role}</Text>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Area</Th>
                <Th>Read</Th>
                <Th>Write</Th>
              </Tr>
            </Thead>
            <Tbody>
              { featureReadWriteGrouping.map(([group, features]) => (
                <Tr key={`${features}${group}`}>
                  <Td>{group}</Td>
                  <Td>
                    {features.read ? (
                      <Checkbox
                        mb={2}
                        isDisabled={mappedFeatures.length === 0}
                        onChange={({ target: { checked } }) => updateCustomer({
                          checked, role, feature: features.read,
                        })}
                        defaultIsChecked={activeFeatures.includes(features.read)}
                      />
                    ) : <Text textColor="gray.400" as="i" fontSize="xs">N/A</Text>}
                  </Td>
                  <Td>
                    {features.write ? (
                      <Checkbox
                        mb={2}
                        isDisabled={mappedFeatures.length === 0}
                        onChange={({ target: { checked } }) => updateCustomer({
                          checked, role, feature: features.write,
                        })}
                        defaultIsChecked={activeFeatures.includes(features.write)}
                      />
                    ) : <Text textColor="gray.400" as="i" fontSize="xs">N/A</Text>}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ))}
    </Box>
  );
};

PermissionRow.propTypes = {
  roleFeatureMapping: PropTypes.instanceOf(Object).isRequired,
  availableFeatures: PropTypes.instanceOf(Array).isRequired,
  customerName: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
};

export default PermissionRow;
