import React, { useContext } from 'react';
import { TableContext } from '~ui/Table/data/context';
import {
  Box,
  Text,
} from '@chakra-ui/react';
import translation from './AlertOverviewList.translation';
import AlertOverviewSection from '../../../../modules/AlertOverviewCard/AlertOverviewSection';

const AlertOverviewList = () => {
  const {
    facets, isLoading, state,
  } = useContext(TableContext);
  const { t } = translation;

  const { type_name: list } = facets || [];

  const searchQuery = state?.current?.query ? `${state.current.query}*` : '';

  return (
    !isLoading && (
    <div>
      {list && list?.map((typeName) => (
        <AlertOverviewSection
          typeName={typeName?.value}
          name={typeName?.value}
          key={typeName?.value}
          filter={state?.current?.filter}
          searchQuery={searchQuery}
        />
      ))}

      {!isLoading && list?.length === 0 && (
        <Box p={4}>
          <Text textAlign="center">
            {t('noResults')}
          </Text>
        </Box>
      )}
    </div>
    )
  );
};

export default AlertOverviewList;
