import Api from '../api';

export const searchTickets = async ({
  payload,
  global,
  customerId,
}) => {
  let path = `customer/${customerId}/ticket/search`;

  if (global) {
    path = '/ticket/search';
  }

  return Api.post(path, payload);
};
