import {
  take,
  call,
  cancel,
  select,
  fork,
  all,
  put,
} from 'redux-saga/effects';
import { objectToCodeMarkup } from '~ui/Markdown/helpers';
import * as t from './actionTypes';

import {
  ticketError,
  initCreateNewTicket,
  ticketCreated,
  createTicketTaskDone,
} from './actions';
import createTicketService from '../../services/createTicket.service';
import createTicketAttachment from '../../services/createTicketAttachmentFromTempId.service';

const getFeedbackValues = (values, metaContextValues) => {
  const { description: formDescription, ...restValues } = values;

  // if description is not in values, return the values so the form can be marked as invalid
  if (!formDescription) return { subject: '[FEEDBACK]', ...values };

  const metaValues = {
    ...metaContextValues,
    location: window?.location?.href,
    viewport: {
      width: window?.innerWidth,
      height: window?.innerHeight,
    },
    userAgent: navigator?.userAgent,
  };

  const subject = `[FEEDBACK] ${formDescription.substring(0, 150)}`;
  const description = `${formDescription} \n ${objectToCodeMarkup(metaValues)}`;
  const type = 'question';
  const area = 'feedback';

  return {
    subject,
    description,
    type,
    area,
    priority: 1,
    ...restValues,
  };
};

const getUser = ({ model }) => {
  const {
    customer_id: customerId,
    email,
    name,
    permissions,
    customer_name: customerName,

  } = model.userContext;

  return {
    email, customerId, name, permissions, customerName,
  };
};

function* uploadSingleFile({ file, customerId, tempId }) {
  const { error } = yield call(createTicketAttachment, { file, customerId, tempId });
  if (error) {
    yield put(ticketError(
      [{
        propterty: 'file',
        message: 'error',
        message_translation_key: `property-validation-ticket-attachment`,
      }],
    ));
  }
}

function* uploadFiles({ files, customerId, tempId }) {
  yield all(files.map((file) => call(uploadSingleFile, { file, customerId, tempId })));
}

function* createTicketHandler() {
  while (true) {
    const { meta } = yield take(t.CREATE);

    yield put(initCreateNewTicket());
    const {
      values,
      isFeedback,
    } = meta;
    const {
      email, customerId, name, permissions, customerName,
    } = yield select(getUser);

    const formValues = (isFeedback ? getFeedbackValues(values, {
      name, email, customerId, customerName, permissions,
    }) : values);

    const { success, error } = yield call(createTicketService, {
      customerId, email, ...formValues,
    });

    if (success) {
      yield put(ticketCreated());
    }

    if (error) {
      yield put(ticketError(error.errors));
    } else {
      const { result: { temp_id: tempId } } = success;
      const { file: files } = values;

      if (files && tempId) {
        yield call(uploadFiles, { files, customerId, tempId });
      }
    }
    yield put(createTicketTaskDone());
  }
}

export default function* moduleRootWatcher() {
  while (true) {
    yield take(t.MOUNT);

    const tasks = [
      yield fork(createTicketHandler),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
