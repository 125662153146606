import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AutoActionsCreateMain',
  sv: {
    cancel: 'Avbryt',
    back: 'Tillbaka',
  },
  en: {
    cancel: 'Cancel',
    back: 'Back',
  },
});
