import Api from '../../../../../../../lib/api';

const getAlertRules = async ({ customerId, entityId, payload }) => {
  const path = `customer/${customerId}/alertrule/entity/${entityId}`;

  return Api.continuationToken.get(path, payload)
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default getAlertRules;
