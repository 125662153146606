/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as C from './OutcomeList.styled';
import { reduceOnMonth } from './helpers';
import Month from './components/Month';

const OutcomeList = ({ failData, successData }) => {
  const monthData = useMemo(() => {
    if (failData && successData) {
      const sortedFails = failData.sort((a, b) => moment(b.date) - moment(a.date));
      const sortedSuccesses = successData.sort((a, b) => moment(b.date) - moment(a.date));
      return reduceOnMonth(sortedFails, sortedSuccesses);
    }
    return null;
  }, [failData, successData]);

  const sortedMonths = Object.keys(monthData).sort((a, b) => b - a);
  return (
    <C.Container>
      {sortedMonths.map((month, index) => {
        const data = monthData[month];
        return <Month key={`month-${index}`} month={month} data={data} />;
      })}
    </C.Container>
  );
};

const propTypes = {
  failData: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  successData: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

const defaultProps = {
  failData: [],
  successData: [],
};

OutcomeList.propTypes = propTypes;
OutcomeList.defaultProps = defaultProps;

export default OutcomeList;
