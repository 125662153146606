import {
  take,
  call,
  takeEvery,
  cancel,
} from 'redux-saga/effects';
import * as t from './actionTypes';
import getActiveServices from './services/getActiveServices.service';
import getAvailableServices from './services/getAvailableServices.service';
import deleteServices from './services/deleteServices.service';
import { groupsServices } from './helpers';

function* loadServices({
  customerId, entityId, setLoading, setServices,
}) {
  yield call(setLoading, true);

  const { activeServices } = yield call(getActiveServices, customerId, entityId);
  const { availableServices } = yield call(getAvailableServices, customerId, entityId);

  const result = yield call(groupsServices, availableServices, activeServices);

  yield call(setServices, result);
  yield call(setLoading, false);

  return availableServices;
}

function* disableService(meta, availableServices, { meta: { service } }) {
  const {
    entityId, customerId, setServices, onSuccess, onFail,
  } = meta;
  const payload = { entityId, customerId, id: service.activeServiceMappingId };
  const { success } = yield call(deleteServices, payload);

  if (success) {
    yield call(onSuccess);
    const result = yield call(groupsServices, availableServices, success);
    yield call(setServices, result);
  } else {
    yield call(onFail);
  }
}

export default function* rootWatcher() {
  while (true) {
    const { meta } = yield take(t.MOUNT);

    const availableServices = yield call(loadServices, meta);
    const tasks = [
      yield takeEvery(t.DISABLE, disableService, meta, availableServices),
    ];

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
