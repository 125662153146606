import addTranslation from '../../../translations/addTranslation';

export default addTranslation({
  id: 'dateSpan',
  sv: {
    day0: 'Sön',
    day1: 'Mån',
    day2: 'Tis',
    day3: 'Ons',
    day4: 'Tor',
    day5: 'Fre',
    day6: 'Lör',
    month0: 'Jan',
    month1: 'Feb',
    month2: 'Mar',
    month3: 'Apr',
    month4: 'Maj',
    month5: 'Jun',
    month6: 'Jul',
    month7: 'Aug',
    month8: 'Sep',
    month9: 'Okt',
    month10: 'Nov',
    month11: 'Dec',

    utcTime: 'UTC tid',
  },
  en: {
    day0: 'Sun',
    day1: 'Mon',
    day2: 'Tue',
    day3: 'Wed',
    day4: 'Thu',
    day5: 'Fri',
    day6: 'Sat',
    month0: 'Jan',
    month1: 'Feb',
    month2: 'Mar',
    month3: 'Apr',
    month4: 'May',
    month5: 'Jun',
    month6: 'Jul',
    month7: 'Aug',
    month8: 'Sep',
    month9: 'Oct',
    month10: 'Nov',
    month11: 'Dec',

    utcTime: 'UTC time',
  },
});
