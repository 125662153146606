import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'ApprovedHours',
  sv: {
    title: 'Godkända timmar',
    approvedHoursDescription: 'Här kan du utöka antalet timmar i din servicenivå för innevarande månad. Godkända timmar faktureras endast om de används. Exempel: Om du godkänner ytterligare 10 timmar varav endast 2 nyttjas, kommer ni endast faktureras för de 2 nyttjade timmarna.',
    dataBallLabelHoursAdded: 'Godkända timmar',
    hoursAddedTooltip: 'Antal godkända timmar som utökas i service nivån',
    dataBallLabelHoursLeft: 'Timmar kvar',
    hoursLeftTooltip: 'Antal timmar som återstår att spendera av de utökade timmarna',
    dataBallLabelHoursSpentPercent: 'av godkända timmar spendare',
    hoursSpentTooltip: 'Hur mycket av de utökade timmarna som spenderats',
    dataBallLabelSpentOverLimit: 'Övertrasserade timmar',
    hoursSpentOverLimitTooltip: 'Antal timmar som spenderats över de godkända timmarna',
    approvedHoursLogTitle: 'Logg av godkända timmar',
    approvedHoursColumn: 'Godkända timmar',
    timeStampColumn: 'Tidsstämpel',
    addedByColumn: 'Godkänd av',
    commentColumn: 'Kommentar',
  },
  en: {
    title: 'Approved hours',
    approvedHoursDescription: 'Here you can extend the number of hours in your service agreement for the current month. Approved hours are only debited when used. Example: You approve 10 additional hours but only 2 get used, you will only be billed for 2 hours.',
    dataBallLabelHoursAdded: 'Hours approved',
    hoursAddedTooltip: 'The number of hours approved to the service level',
    dataBallLabelHoursLeft: 'Hours left',
    hoursLeftTooltip: 'The number of hours left to spend of the approved hours',
    dataBallLabelHoursSpentPercent: 'of approved hours spent',
    hoursSpentTooltip: 'How much of the approved hours that have been spent',
    dataBallLabelSpentOverLimit: 'Excess spent hours',
    hoursSpentOverLimitTooltip: 'The number of hours spent over the approved hours limit',
    approvedHoursLogTitle: 'Approved hours log',
    approvedHoursColumn: 'Approved hours',
    timeStampColumn: 'Timestamp',
    addedByColumn: 'Approved by',
    commentColumn: 'Comment',
  },
});
