import React, { useContext } from 'react';
import { Text, useTheme } from '@chakra-ui/react';
import * as C from './Notification.styled';
import translation from './Notification.translation';
import { ServiceWindowMutationContext } from '../../../serviceWindowMutationContext';

const { t } = translation;

const Notification = () => {
  const { entities } = useContext(ServiceWindowMutationContext);
  const { colors } = useTheme();

  return (
    <C.Notification>
      <C.Circle key={entities?.length || 0} colors={colors}>
        <Text>{entities?.length || 0}</Text>
      </C.Circle>
      <Text fontWeight="600">{t('entitiesAndResources')}</Text>
    </C.Notification>
  );
};

export default Notification;
