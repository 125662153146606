import React from 'react';
import { Button } from '~ui/Button';
import * as Graph from '~ui/Graph';
import * as Permission from '~ui/Permission';
import * as Block from '~ui/Block';
import {
  Spinner, Tooltip, Text, Heading, useTheme,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { mdiChevronRight } from '@mdi/js';
import * as C from './Card.styled';
import translation from './Card.translation';
import { useSearchWebTestRuns } from '../../../../../../../../lib/services/appMonitor';

const { t } = translation;

const propTypes = {
  row: PropTypes.instanceOf(Object),
};
const defaultProps = {
  row: {},
};

const Card = ({ row }) => {
  const { colors, breakpoints } = useTheme();

  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();
  const {
    name: testName,
    entity_id: entityId,
    last_run_succeeded: lastRunSucceeded,
    last_run_timestamp: lastRunTimestamp,
    customer_id: customerId,
    customer_display_name: customerDisplayName,
    entity_name: entityName,
  } = row;

  const { data: dailyFails, failsLoading } = useSearchWebTestRuns({
    customerId,
    entityId,
    testName,
    succeeded: false,
    startDate: moment().subtract(4, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });

  const { data: dailySuccesses, successLoading } = useSearchWebTestRuns({
    customerId,
    entityId,
    testName,
    succeeded: false,
    startDate: moment().subtract(4, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });

  const durationText = moment
    .duration(
      moment(lastRunTimestamp).diff(moment()),
    )
    .humanize(true);

  if (successLoading || failsLoading) {
    return (
      <Block.Center>
        <Spinner
          color="#133A5D"
          emptyColor="#DADADA"
          speed="0.65s"
          thickness="4px"
          size="xl"
        />
      </Block.Center>
    );
  }

  return (
    <C.Card colors={colors}>
      <C.Text>
        <Heading as="h3">{testName}</Heading>

        { isGlobalAdmin && (
          <C.InfoBlock>
            <Text fontSize="sm">{customerDisplayName}</Text>
          </C.InfoBlock>
        )}
        <C.InfoBlock>
          <Text style={{ marginRight: '0.1875rem' }}>{t('runsOn')}</Text>
          <Button variant="link" internalLink={`/my-environment/${customerId}/${entityId}/cloudops-vmagent`}>
            <Text fontSize="sm">{entityName}</Text>
          </Button>
        </C.InfoBlock>

        <C.InfoBlock>
          <Text fontSize="sm" style={{ marginRight: '0.1875rem' }}>{t('lastRun')}</Text>
          <Text fontSize="sm" style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip hasArrow label={lastRunSucceeded ? t('testSucceeded') : t('testFailed')}>
              <C.TestStatus
                colors={colors}
                succeeded={lastRunSucceeded}
              >
                {durationText}
              </C.TestStatus>
            </Tooltip>
          </Text>
        </C.InfoBlock>
      </C.Text>

      <C.Heatmap>
        <C.MapContainer>
          <Graph.Heatmap
            primaryData={dailyFails}
            secondaryData={dailySuccesses}
            steps={5}
            cellGap={4}
            showLegend={() => false}
          />
        </C.MapContainer>
      </C.Heatmap>

      <C.Expander colors={colors} breakpoints={breakpoints}>
        <Button
          internalLink={`/app-monitor/${customerId}/${entityId}/${testName}/overview`}
          style={{ borderRadius: '100%', padding: '0' }}
          variant="ghost"
          rightIcon={mdiChevronRight}
        />
      </C.Expander>
    </C.Card>
  );
};

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
