import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import MdiIcon from '@mdi/react';
import { mdiCheck, mdiPlus } from '@mdi/js';
import { useHistory } from 'react-router-dom';
import * as Block from '~ui/Block';
import { Button } from '~ui/Button';
import * as Form from '~ui/Form';
import { Text, useToast, useTheme } from '@chakra-ui/react';
import translation from './SequenceForm.translation';
import * as C from './SequenceForm.styled';
import AvaliableActionRow from '../AvaliableActionRow';
import SelectedAction from '../SelectedAction';
import AvailableActions from './AvailableActions';

const propTypes = {
  onSaveProcessModel: PropTypes.func.isRequired,
  onRemoveAction: PropTypes.func.isRequired,
  avaliableAutoActions: PropTypes.instanceOf(Array).isRequired,
  alertRules: PropTypes.instanceOf(Array).isRequired,
  sequence: PropTypes.instanceOf(Array).isRequired,
  canApplyOnAlertRule: PropTypes.bool.isRequired,
  processModelId: PropTypes.string,
  metadata: PropTypes.instanceOf(Object).isRequired,
  backLink: PropTypes.string.isRequired,
  selectedNewAction: PropTypes.instanceOf(Object),
  setSelectedNewAction: PropTypes.func.isRequired,
  selectedEditAction: PropTypes.instanceOf(Object),
  setSelectedEditAction: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  onAddAction: PropTypes.func.isRequired,
  showAvailableActions: PropTypes.bool.isRequired,
  setShowAvailableActions: PropTypes.func.isRequired,
};
const defaultProps = {
  processModelId: '',
  selectedNewAction: null,
  selectedEditAction: null,
};

const SequenceForm = ({
  onSaveProcessModel,
  onRemoveAction,
  avaliableAutoActions,
  alertRules,
  sequence,
  canApplyOnAlertRule,
  processModelId,
  metadata,
  backLink,
  selectedNewAction,
  setSelectedNewAction,
  selectedEditAction,
  setSelectedEditAction,
  onMoveDown,
  onMoveUp,
  onAddAction,
  showAvailableActions,
  setShowAvailableActions,
}) => {
  const { colors, breakpoints } = useTheme();

  const { t } = translation;
  const toast = useToast();
  const [saving, setSaving] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const history = useHistory();
  const formHook = Form.useFormBuilder({
    name: {
      type: 'text',
      props: {},
      value: metadata.name || '',
      label: t('formNameLabel'),
    },
    description: {
      type: 'textarea',
      props: {},
      value: metadata.description || '',
      label: t('formDescriptionDesc'),
    },
    applyOnAllAlertRules: {
      type: 'switch',
      value: metadata.applyOnAllAlertRules || false,
      wrapperStyle: { border: 'none' },
      render: () => canApplyOnAlertRule,
      label: t('formApplyOnAllAlertRulesLabel'),
      description: t('formApplyOnAllAlertRulesDesc'),
    },
    selectedAlertRule: {
      type: 'filterselect',
      label: t('formAlertRuleLabel'),
      props: { multiSelect: true },
      options: alertRules,
      value: metadata.selectedAlertRule,
      render: ({ applyOnAllAlertRules }) => {
        if (!canApplyOnAlertRule || !applyOnAllAlertRules) {
          return true;
        }
        return false;
      },

    },
  });

  const isEditFormOpen = (selectedNewAction || selectedEditAction);

  const handleOnSave = useCallback(() => {
    setSaving(true);
    const { values } = formHook.getValues();

    onSaveProcessModel({
      metadata: values,
      sequence,
      processModelId,
      callback: ({ error }) => {
        setSaving(false);
        if (!error) {
          toast({
            description: t('success'),
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          history.push(backLink);
        } else {
          toast({
            description: t('fail'),
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      },
    });
  }, [backLink, formHook, history, onSaveProcessModel, processModelId, sequence, t, toast]);

  const avaliableActionRows = useMemo(() => {
    const rows = avaliableAutoActions
      .map((action) => {
        const isConfigurable = action.required_parameter_specifications.length > 0;
        return ({
          row: (<AvaliableActionRow
            key={action.id}
            action={action}
            isConfigurable={isConfigurable}
            onAddAction={isConfigurable
              ? setSelectedNewAction
              : onAddAction}
          />
          ),
        });
      });

    if (showAll) {
      return rows;
    }

    return rows.splice(0, 4);
  }, [avaliableAutoActions, onAddAction, setSelectedNewAction, showAll]);

  const SHOW_ACTIONS = showAvailableActions && !isEditFormOpen;

  return (
    <>

      {SHOW_ACTIONS && (
      <AvailableActions
        avaliableActionRows={avaliableActionRows}
        showAll={showAll}
        setShowAll={setShowAll}
      />
      )}

      <Block.Center style={{ display: isEditFormOpen || showAvailableActions ? 'none' : 'flex' }}>
        {processModelId && (
        <C.Title breakpoints={breakpoints} style={{ marginTop: '2rem' }}>
          {t('editTitle')}
        </C.Title>
        )}
        {!processModelId && (
        <C.Title
          breakpoints={breakpoints}
          style={{ marginTop: '2rem' }}
        >
          {t('createTitle')}
        </C.Title>
        )}

        <C.StepContainer breakpoints={breakpoints} style={{ overflow: 'visible' }}>
          <C.Step colors={colors}>1</C.Step>
          <C.StepTitle>{t('details')}</C.StepTitle>
          <C.Content>
            <Form.Primary form={formHook}>
              {({ render }) => render}
            </Form.Primary>
          </C.Content>
        </C.StepContainer>

        {!SHOW_ACTIONS && (
        <>
          <C.ChosenSteps breakpoints={breakpoints}>
            <C.Step colors={colors}>2</C.Step>
            <C.StepTitle>{t('sequence')}</C.StepTitle>
            <C.Steps breakpoints={breakpoints}>

              <Text style={{ gridArea: 'desc' }}>{t('sequenceDesc')}</Text>

              <C.SequenceContainer>
                { sequence
                  .map((action, index) => (
                    <C.Sequence breakpoints={breakpoints} key={action.localId}>
                      <C.StepNumber breakpoints={breakpoints}>
                        <C.StepCircle colors={colors}>
                          {index + 1}
                        </C.StepCircle>
                      </C.StepNumber>

                      <C.StepAction>
                        <SelectedAction
                          key={action.localId}
                          onMoveDown={() => onMoveDown(index)}
                          onMoveUp={() => onMoveUp(index)}
                          onEditAction={() => setSelectedEditAction(action)}
                          onRemoveAction={() => onRemoveAction(action)}
                          action={action}
                        />
                      </C.StepAction>
                    </C.Sequence>
                  ))}
              </C.SequenceContainer>

              <Button
                variant="block"
                style={{ gridArea: 'createBtn' }}
                leftIcon={mdiPlus}
                onClick={() => setShowAvailableActions(!showAvailableActions)}
              >
                {t('addNewAction')}
              </Button>

            </C.Steps>
          </C.ChosenSteps>

          <C.StepContainer breakpoints={breakpoints}>
            <C.Step colors={colors}><MdiIcon path={mdiCheck} size={0.75} /></C.Step>
            <C.StepTitle>{t('summary')}</C.StepTitle>
            <C.Content>
              <Text>{t('sequenceDesc')}</Text>
              <Block.SpaceBetween>
                <Button isLoading={saving} onClick={handleOnSave}>{t('save')}</Button>

              </Block.SpaceBetween>
            </C.Content>
          </C.StepContainer>
        </>
        )}
      </Block.Center>
    </>
  );
};

SequenceForm.propTypes = propTypes;
SequenceForm.defaultProps = defaultProps;

export default SequenceForm;
