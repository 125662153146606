import Api from '../api';

export const addSecurityHours = async ({
  customerId, year, month, hours, description,
}) => {
  const path = `/customer/${customerId}/security/hours/`;

  const payload = {
    year,
    month,
    hours,
    description,
  };

  return Api.post(path, payload)
    .then((result) => (result))
    .catch((error) => ({ error }));
};
