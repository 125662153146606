import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AutoActionsCreateAvaliableActionRow',
  sv: {
    addService: 'Lägg till tjänst',
    configureService: 'Konfigurerar tjänst',
  },
  en: {
    configureService: 'Configure Service',
    addService: 'Add Service',
  },
});
