import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Block from '~ui/Block';
import * as List from '~ui/List';
import * as Layout from '~ui/Layout';
import { Button } from '~ui/Button';
import { Spinner, Text, useTheme } from '@chakra-ui/react';
import { mdiArrowLeft } from '@mdi/js';
import { useHistory } from 'react-router';
import * as C from './Main.styled';
import translation from './Main.translation';
import TeamRow from './TeamRow';

const { t } = translation;

const propTypes = {
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
};

const numberOfVisiblePeople = 2;

const MyTeam = ({ onMount, onUnmount }) => {
  const { colors } = useTheme();

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [myTeam, setMyTeam] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    onMount({ setLoading, setMyTeam });

    return () => onUnmount();
  }, [onMount, onUnmount]);

  const teamRows = useMemo(() => {
    if (myTeam.length > 0) {
      return myTeam
        .filter((p, index) => (showAll ? p : index < numberOfVisiblePeople))
        .map((person) => ({ row: (<TeamRow person={person} />) }));
    }
    return [];
  }, [myTeam, showAll]);

  if (loading) {
    return (
      <Block.Center>
        <Spinner
          color="#133A5D"
          emptyColor="#DADADA"
          speed="0.65s"
          thickness="4px"
          size="xl"
        />
      </Block.Center>
    );
  }

  return (
    <>
      <Layout.Omnibar.Main>
        <Layout.Omnibar.LeftActions>
          <Button
            variant="ghost"
            leftIcon={mdiArrowLeft}
            onClick={() => history.goBack()}
          >
            {t('back')}
          </Button>
        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>

      <Layout.Scene>
        <Block.SubnavigationBlock
          title={t('asurgentTeam')}
          navigationList={[
            {
              label: 'My Team',
              path: '/team',
              isActive: window.location.pathname === '/team',
            },
          ]}
        >
          <C.Content colors={colors}>
            <C.Padding>
              <Text>
                {`${myTeam.length} ${t('peopleInTeam')}`}
              </Text>

              <Text>
                {t('teamDesc')}
              </Text>
            </C.Padding>

            <C.Padding>
              {myTeam.length > 0
                ? (<List.Primary borderTop rows={teamRows} />)
                : (<Text>{t('noTeam')}</Text>)}
            </C.Padding>

            {myTeam.length > numberOfVisiblePeople && (

            <C.AllActionsButtonContainer colors={colors}>
              <Button variant="link" onClick={() => setShowAll(!showAll)}>
                {showAll ? t('viewLess') : `${t('viewAll')} (${myTeam.length})`}
              </Button>
            </C.AllActionsButtonContainer>
            )}

          </C.Content>
        </Block.SubnavigationBlock>
      </Layout.Scene>
    </>
  );
};

MyTeam.propTypes = propTypes;

export default MyTeam;
