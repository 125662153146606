import Api from '../api';

export const searchTemplates = async ({
  payload,
  global,
  customerId,
}) => {
  let path = `customer/${customerId}/security/analyticruletemplate/search`;

  if (global) {
    path = 'security/analyticruletemplate/search';
  }

  return Api.post(path, payload);
};
