import { connect } from 'react-redux';
import Create from './components/Main';
import * as A from './actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onMount: (payload) => dispatch(A.onMount(payload)),
  onUnmount: (payload) => dispatch(A.onUnmount(payload)),
  onSaveProcessModel: (payload) => dispatch(A.onSaveProcessModel(payload)),
  onRemoveProcessModel: (callback) => dispatch(A.onRemoveProcessModel({ callback })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);
