import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Security.ListAnalyticRules',
  sv: {
    alertRule: 'Azure Sentinel Larmregel',
    customer: 'Kund',
    search: 'Sök bland analytic rule templates',
    emptystate: 'Inget resultat för',

    severity: 'Allvarlighetsgrad',
    tactics: 'Taktiker',
    tactic: 'Tactik',
    tag: 'Tagg',
    tags: 'Taggar',
    type: 'Typ',

    filterDrawerTitle: 'Filtrera analytic rule templates',

    connectorsLabel: 'Bara templates som innehåller applicerbara data connectors',
    applicableDataConnectors: 'Bara applicerbara data connectors',
  },
  en: {
    alertRule: 'Azure Sentinel Alert Rule',
    customer: 'Customer',
    search: 'Search for analytic rule templates',
    emptystate: 'No result for',

    severity: 'Severity',
    tactics: 'Tactics',
    tactic: 'Tactic',
    tag: 'Tag',
    tags: 'Tags',
    type: 'Type',

    filterDrawerTitle: 'Filter analytic rules templates',

    connectorsLabel: 'Only templates containing applicable data connectors',
    applicableDataConnectors: 'Only applicable data connectors',
  },
});
