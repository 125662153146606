import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { mdiArrowLeft } from '@mdi/js';
import * as Form from '~ui/Form';
import { Button } from '~ui/Button';
import * as Block from '~ui/Block';
import * as Layout from '~ui/Layout';
import { Spinner, useToast } from '@chakra-ui/react';
import translation from './Edit.translation';

import { formObjects } from './FormObjects';

const { t } = translation;

const propTypes = {
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const Edit = (props) => {
  const toast = useToast();
  const { onMount, onUnmount, onSubmit } = props;
  const { params } = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const [vmConfig, setVMConfig] = useState(true);

  const { customerId, entityId, type } = params;
  const formObj = formObjects(type);

  const backLink = `/my-environment/${customerId}/${entityId}/cloudops-vmagent`;
  const history = useHistory();

  useEffect(() => {
    onMount({
      customerId,
      entityId,
      setLoading,
      setVMConfig,
    });

    return () => onUnmount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formData = Form.useFormBuilder({ ...formObj });

  useEffect(() => {
    if (vmConfig) {
      const updatedFields = Object.keys(vmConfig)
        .map((key) => ({ name: key, value: vmConfig[key] }));

      formData.updateFields(updatedFields);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vmConfig]);

  const handleSubmit = () => {
    const { values } = formData.getValues();
    const newVmConfig = { ...vmConfig, ...values };
    onSubmit({
      vmConfig: newVmConfig,
      setVMConfig,
      onSuccess: () => {
        toast({
          description: t('success'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        history.push(backLink);
      },
      onFail: (formErrors) => {
        toast({
          description: t('fail'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        formData.errors(formErrors, translation);
        setLoading(false);
      },
    });
  };

  return (
    <>
      <Layout.Omnibar.Main>
        <Layout.Omnibar.LeftActions>
          <Button
            variant="ghost"
            leftIcon={mdiArrowLeft}
            internalLink={backLink}
          >
            {t('back')}
          </Button>
        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>

      <Layout.Scene>
        { loading && (
          <Block.Center withPadding>
            <Spinner
              color="#133A5D"
              emptyColor="#DADADA"
              speed="0.65s"
              thickness="4px"
              size="xl"
            />
          </Block.Center>
        )}
        {!loading && (
          <Block.Left withPadding style={{ padding: '2rem' }}>
            <h1>
              {t(type)}
            </h1>
            <Form.Primary form={formData}>
              {({ render, isDirty }) => (
                <>
                  {render}
                  <Block.SpaceBetween>
                    <Button disabled={!isDirty} onClick={handleSubmit}>{t('update')}</Button>
                  </Block.SpaceBetween>
                </>
              )}
            </Form.Primary>
          </Block.Left>
        )}
      </Layout.Scene>
    </>
  );
};

Edit.propTypes = propTypes;

export default Edit;
