import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@chakra-ui/react';
import * as Progress from '~ui/Progress';
import * as C from './DataBall.styled';

const propTypes = {
  number: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  showAsProgress: PropTypes.bool,
  infoText: PropTypes.string,
};

const defaultProps = {
  color: '',
  showAsProgress: false,
  infoText: '',
};

const DataBall = ({
  number, label, color, showAsProgress, infoText,
}) => {
  const renderContent = (
    <C.Wrapper>

      {showAsProgress ? (
        <Progress.Ring
          progress={number}
          color={color}
          useShadow
          showPercentage
          radius={50}
          stroke={7}
          useValueAnimation
        />
      )
        : (
          <C.DataBall color={color}>
            <C.DataBallText>
              {number}
            </C.DataBallText>
          </C.DataBall>
        )}

      <C.DataLabel>
        {label}
      </C.DataLabel>

    </C.Wrapper>
  );

  if (!infoText) return renderContent;

  return (
    <Tooltip label={infoText}>
      {renderContent}
    </Tooltip>

  );
};

DataBall.propTypes = propTypes;
DataBall.defaultProps = defaultProps;

export default DataBall;
