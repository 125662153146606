import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  PieChart, Pie, Cell, Legend, Tooltip,
} from 'recharts';
import {
  useTheme, HStack, VStack, useMediaQuery, Box, Text,
} from '@chakra-ui/react';
import * as C from './Pie.styled';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  unit: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  unit: '',
  colors: ['#1D4044', '#68C7EC', '#dcbc72', '#FAF089', '#4FD1C5', '#805AD5'],
};

const PieUi = ({
  data, unit, colors: colorsProp,
}) => {
  const { breakpoints } = useTheme();
  const [isSmall] = useMediaQuery(`(max-width: ${breakpoints?.md})`);

  const totalValue = useMemo(() => data.reduce((acc, { value }) => acc + value, 0), [data]);

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    outerRadius,
    innerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
    const x = cx + radius * Math.cos((-midAngle) * RADIAN);
    const y = cy + radius * Math.sin((-midAngle) * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="#000"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const renderTooltipContent = ({ payload }) => {
    if (payload && payload.length > 0) {
      const {
        name, value, fill,
      } = payload[0].payload;

      return (

        <C.TooltipOuterWrapper>

          <HStack>
            <Box display="inline-block" padding="0.3rem" background={fill} />
            <C.TooltipLabel>{name}</C.TooltipLabel>
          </HStack>
          <HStack spacing="0.5rem">
            <Text my={0} fontSize="14px">
              {value}
              {unit}
            </Text>

            <Text my={0} fontSize="14px">
              {Math.floor((value / totalValue) * 100)}
              %
            </Text>
          </HStack>

        </C.TooltipOuterWrapper>
      );
    }
    return null;
  };

  const renderLegendContent = ({ payload }) => (
    <VStack alignItems="baseline" spacing="0" position="relative" top="10px">
      {payload?.map((item) => (
        <HStack key={item?.value} sx={{ fontFamily: 'Poppins, sans-serif' }}>
          <Box
            background={item?.color}
            padding="0.6rem"
          />
          <C.LegendLabel
            padding="0.5rem 0"
            fontSize={14}
            fontWeight="medium"
            textTransform="uppercase"
          >
            {item?.value}
          </C.LegendLabel>
        </HStack>
      ))}
    </VStack>
  );

  return (
    <C.Wrapper>
      <PieChart
        width={isSmall ? 300 : 540}
        height={isSmall ? 450 : 300}
      >
        <Pie
          data={data}
          cx={150}
          cy={150}
          labelLine
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Cell key={index} fill={colorsProp[index % colorsProp.length]} />

          ))}
        </Pie>
        <Legend
          content={renderLegendContent}
          verticalAlign="middle"
          align="center"
          wrapperStyle={{
            ...(isSmall ? {
              display: 'flex',
              justifyContent: 'center',
              bottom: 5,
              width: '100%',
            } : {
              right: 50,
              width: 'max-content',
            }),
          }}
        />
        <Tooltip
          content={renderTooltipContent}
        />

      </PieChart>
    </C.Wrapper>
  );
};

PieUi.propTypes = propTypes;
PieUi.defaultProps = defaultProps;

export default PieUi;
