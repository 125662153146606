import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  fill: PropTypes.string.isRequired,
};

const defaultProps = {};

const MediumIcon = ({ fill }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill || 'currentColor'} d="M11.25 9.75V3.75C11.25 3.15326 11.0129 2.58097 10.591 2.15901C10.169 1.73705 9.59674 1.5 9 1.5C8.40326 1.5 7.83097 1.73705 7.40901 2.15901C6.98705 2.58097 6.75 3.15326 6.75 3.75V9.75C6.12035 10.2222 5.65525 10.8806 5.42057 11.6319C5.1859 12.3831 5.19355 13.1892 5.44244 13.9359C5.69133 14.6825 6.16884 15.332 6.80734 15.7922C7.44583 16.2524 8.21294 16.5 9 16.5C9.78706 16.5 10.5542 16.2524 11.1927 15.7922C11.8312 15.332 12.3087 14.6825 12.5576 13.9359C12.8065 13.1892 12.8141 12.3831 12.5794 11.6319C12.3448 10.8806 11.8796 10.2222 11.25 9.75ZM9 3C9.19891 3 9.38968 3.07902 9.53033 3.21967C9.67098 3.36032 9.75 3.55109 9.75 3.75V9H8.25V3.75C8.25 3.55109 8.32902 3.36032 8.46967 3.21967C8.61032 3.07902 8.80109 3 9 3Z" />
  </svg>
);

MediumIcon.propTypes = propTypes;
MediumIcon.defaultProps = defaultProps;

export default MediumIcon;
