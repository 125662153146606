import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AutoActionsCreateSequenceForm',
  sv: {
    save: 'Spara',
    summary: 'Summering',
    viewAll: 'Visa alla',
    viewLess: 'Visa färre',
    details: 'Detaljer',
    createTitle: 'Skapa Processmodell',
    editTitle: 'Editera Processmodell',
    sequence: 'Automationssekvens',
    sequenceDesc: 'En automationssekvens är en samling åtgärder som exekveras i kronologisk ordning när en entitet larmar. Du kan ha så många åtgärder du vill i en automationsprocess och det krävs minst en åtgärd för att spara en process.',
    availableActions: 'Tillgängliga åtgärder',
    availableActionsDescription: 'Dessa är de tillgängliga åtgärderna som kan konfigureras efter behov. Det krävs minst en åtgärd för att kunna spara eller skapa en process.',

    formNameLabel: 'Namn',
    formDescriptionDesc: 'Detaljerad beskrivning',
    formApplyOnAllAlertRulesLabel: 'Applicera som generell process för hela entiteten',
    formApplyOnAllAlertRulesDesc: 'Om du väljer detta alternativ, kommer denna process och dess automationssekvens köras när entiteten får ett larm.',
    formAlertRuleLabel: 'Applicera på Larmregel',

    addNewAction: 'Lägg till ny åtgärd',

    fail: 'Kunde inte skapa/uppdatera sekvens',
    success: 'Sekvens skapad/uppdaterad',
  },
  en: {
    save: 'Save',
    summary: 'Summary',
    viewAll: 'View all',
    viewLess: 'View less',
    details: 'Details',
    createTitle: 'Create Process model',
    editTitle: 'Edit Process model',
    sequence: 'Automation Sequence',
    sequenceDesc: 'An automation action is the action you want to be taken when there is an alert. You can have as many actions as you want but you need at least one to create the process.',
    availableActions: 'Available Actions',
    availableActionsDescription: 'These are the actions available to you which can be configured to your needs. You need to add at least one action to your process.',

    formNameLabel: 'Name',
    formDescriptionDesc: 'Detailed description',
    formApplyOnAllAlertRulesLabel: 'Apply as general process for this Entity',
    formApplyOnAllAlertRulesDesc: 'If you select this option this process will be the general chain of actions taken when there is an alert on this Entity.',
    formAlertRuleLabel: 'Apply on Alert Rule',

    addNewAction: 'Add new action',

    fail: 'Failed to create/update sequence',
    success: 'Sequence created/updated',
  },
});
