import moment from 'moment';

export const populateFormFromAlertRule = ({ alertRule, metricDefinitions }) => {
  const {
    name,
    description,
    severity,
    threshold,
    aggregation,
    valid_to_dt: validToDt,
    valid_from_dt: validFromDt,
    metric_name: metricName,
    depends_on_metrics: dependsOnMetrics,
    critical_threshold: criticalThreshold,
    alerts_before_action: alertsBeforeAction,
    threshold_comparison: thresholdComparison,
    auto_close_delay_in_seconds: autoCloseDelayInSeconds,
  } = alertRule;

  const isValidFrom = (moment(validFromDt) < moment());
  const isValidTo = (moment(validToDt).toISOString() === moment('9999-12-31').toISOString());
  const hasAutoClose = autoCloseDelayInSeconds !== null;
  const autoCloseDelayInMinutes = hasAutoClose ? (autoCloseDelayInSeconds / 60) : 30;

  return [
    { name: 'severity', value: severity.toString() },
    { name: 'aggregation', value: aggregation },
    { name: 'threshold', value: threshold },
    { name: 'threshold_comparison', value: thresholdComparison },
    { name: 'name', value: name.toString() },
    { name: 'description', value: description },
    { name: 'useCritical', value: criticalThreshold !== undefined && criticalThreshold !== null ? '1' : '2' },
    { name: 'critical_threshold', value: criticalThreshold },
    { name: 'alerts_before_action', value: alertsBeforeAction },
    { name: 'metric_name', value: metricName, options: metricDefinitions },
    { name: 'depends_on_metrics', value: dependsOnMetrics, options: metricDefinitions },
    { name: 'valid_from ', value: isValidFrom ? '0' : '1' },
    { name: 'valid_from_dt', value: moment(validFromDt) },
    { name: 'valid_to', value: isValidTo ? '0' : '1' },
    { name: 'valid_to_dt', value: moment(validToDt) },
    { name: 'auto_close_delay_in_seconds_bool', value: hasAutoClose ? '1' : '0' },
    { name: 'auto_close_delay_in_seconds', value: autoCloseDelayInMinutes },
  ];
};

export const handleFormErrors = ({ errors }, values) => {
  // errors.length === 0 -> backend crashed on null-conversion, so set manually
  if (errors?.length === 0) {
    const crashedErrors = Object.keys(values).filter((key) => Number.isNaN(values[key]));
    crashedErrors.forEach((ent) => {
      errors.push({
        propterty: ent,
        message: 'error',
        message_translation_key: `property-validation-alert-rule-${ent}-missing`,
      });
    });
  }
  return errors;
};

export const defaultValues = {
  valid_from_dt: moment().toISOString(),
  valid_to_dt: moment('9999-12-31').toISOString(),
  customer_id: '',
  id: null,
  entity_id: '',
  is_template: false,
};

export const parseFormDataToPayload = ({
  values,
  enabled,
  defaultValues: defaultAlertRuleValues,
}) => {
  // if dates have not changed and filtered due to radios, re-add them
  if (!values.valid_from_dt) {
    Object.assign(values, { valid_from_dt: defaultAlertRuleValues.valid_from_dt });
  }
  if (!values.valid_to_dt) {
    Object.assign(values, { valid_to_dt: defaultAlertRuleValues.valid_to_dt });
  }

  // if it has an old valid_from_date but the user has decided to activate it,
  // add "until turnoff"
  if (enabled === 1 && moment(values.valid_from_dt) < moment()) {
    Object.assign(values, { valid_to_dt: moment('9999-12-31').toISOString() });
  }

  const hasAutoDelay = values.auto_close_delay_in_seconds_bool === '1';
  const autoDelay = values.auto_close_delay_in_seconds;

  if (hasAutoDelay) {
    Object.assign(values, { auto_close_delay_in_seconds: autoDelay * 60 });
  } else {
    Object.assign(values, { auto_close_delay_in_seconds: null });
  }

  // prepare for form-submital
  Object.assign(values,
    { customer_id: defaultAlertRuleValues.customer_id },
    { id: defaultAlertRuleValues.id },
    { entity_id: defaultAlertRuleValues.entity_id },
    { is_template: defaultAlertRuleValues.is_template },
    { severity: parseInt(values.severity, 10) },
    { enabled });

  // remove radios from being sent
  const {
    // eslint-disable-next-line camelcase
    valid_from,
    // eslint-disable-next-line camelcase
    valid_to,
    // eslint-disable-next-line camelcase
    auto_close_delay_in_seconds_bool,
    ...rest
  } = values;

  return rest;
};

const sortBy = (key, arr) => arr.sort((a, b) => a[key].localeCompare(b[key]));

export const mapMetricDefinitions = (definitions) => {
  const metricDefinitions = definitions
    .map(({ name }) => ({ value: name, label: name }));

  return sortBy('label', metricDefinitions);
};

export const formSpec = ({ t }) => ({
  name: {
    type: 'text',
    label: t('name'),
    tooltip: t('tooltipName'),
    tooltipPosition: 'left',
  },
  description: {
    type: 'textarea',
    label: t('description'),
    tooltip: t('tooltipDescription'),
    tooltipPosition: 'left',
  },
  metric_name: {
    type: 'filterselect',
    label: t('metricName'),
    options: [],
    tooltip: t('tooltipMetricName'),
    tooltipPosition: 'left',
    props: {
      multiSelect: false,
      searchPlaceholder: t('placeholderMetricName'),
    },
  },
  depends_on_metrics: {
    type: 'filterselect',
    label: t('dependsOnMetrics'),
    options: [],
    tooltip: t('tooltipDependsOnMetrics'),
    tooltipPosition: 'left',
    props: {
      multiSelect: true,
      searchPlaceholder: t('placeholderDependsOnMetrics'),
    },
  },
  severity: {
    type: 'select',
    label: t('severity'),
    options: [
      { value: 0, label: t('low') },
      { value: 1, label: t('medium'), default: true },
      { value: 2, label: t('high') },
      { value: 3, label: t('urgent') },
    ],
    tooltip: t('tooltipSeverity'),
    tooltipPosition: 'left',
  },
  aggregation: {
    type: 'select',
    label: t('aggregation'),
    options: [
      { value: 'avg', label: t('average'), default: true },
      { value: 'sum', label: t('sum') },
    ],
    tooltip: t('tooltipAggregation'),
    tooltipPosition: 'left',
  },
  threshold_comparison: {
    type: 'select',
    label: t('thresholdComparison'),
    options: [
      { value: '<', label: t('lessThan') },
      { value: '>', label: t('moreThan'), default: true },
      { value: '>=', label: t('moreEqual') },
      { value: '<=', label: t('lessEqual') },
      { value: '=', label: t('equal') },
    ],
    tooltip: t('tooltipThresholdComparison'),
    tooltipPosition: 'left',
  },
  threshold: {
    type: 'number',
    label: t('threshold'),
    tooltip: t('tooltipThreshold'),
    tooltipPosition: 'left',
    minValue: 0,
  },
  useCritical: {
    type: 'radiogroup',
    label: t('useCriticalThreshold'),
    value: '2',
    options: [
      { label: t('no'), value: '2', default: true },
      { label: t('yes'), value: '1' },
    ],
  },
  critical_threshold: {
    type: 'number',
    label: t('criticalThreshold'),
    render: (s) => s.useCritical && s.useCritical === '1',
    maxValue: (values) => {
      const { threshold, threshold_comparison: comp } = values;
      switch (comp) {
        case '<':
          return threshold - 1;
        case '<=':
          return threshold;
        case '=':
          return threshold;
        default:
          return null;
      }
    },
    minValue: (values) => {
      const { threshold, threshold_comparison: comp } = values;
      switch (comp) {
        case '>':
          return threshold + 1;
        case '>=':
          return threshold;
        case '=':
          return threshold;
        default:
          return 0;
      }
    },
  },
  alerts_before_action: {
    type: 'number',
    label: t('alertsBeforeAction'),
    tooltip: t('tooltipAlertsBeforeAction'),
    tooltipPosition: 'left',
  },
  auto_close_delay_in_seconds_bool: {
    type: 'radiogroup',
    label: t('autoCloseDelayInSecondsBool'),
    value: '0',
    options: [
      { label: t('no'), value: '0' },
      { label: t('yes'), value: '1' },
    ],
  },
  auto_close_delay_in_seconds: {
    type: 'number',
    label: t('autoCloseDelayInSeconds'),
    minValue: 30,
    render: (s) => s.auto_close_delay_in_seconds_bool && s.auto_close_delay_in_seconds_bool === '1',
  },
  valid_from: {
    type: 'radiogroup',
    label: t('validFrom'),
    options: [
      { label: t('now'), value: '0' },
      { label: t('specificStart'), value: '1' },
    ],
  },
  valid_from_dt: {
    type: 'datepicker',
    label: t('validFromDT'),
    // valid_from is set to '1' if incoming date is larger than now
    render: (s) => s.valid_from && s.valid_from === '1',
  },
  valid_to: {
    type: 'radiogroup',
    label: t('validTo'),
    options: [
      { label: t('untilTurnoff'), value: '0' },
      { label: t('specificTurnoff'), value: '1' },
    ],
  },
  valid_to_dt: {
    type: 'datepicker',
    label: t('validToDT'),
    // valid_to is set to '1' if incoming date is less than 10 years
    render: (s) => s.valid_to && s.valid_to === '1',
  },
});
