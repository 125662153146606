import styled from 'styled-components';
import * as Form from '~ui/Form';

export const Container = styled.div`
    margin-bottom: 1rem;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    background: ${({ colors }) => colors?.gray?.['50']};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid;
    border-color: ${({ colors }) => colors?.gray?.['200']};
    padding: 1rem;
`;

export const CommentForm = styled(Form.Primary)`

& > button {
  margin-top: 1.25rem;
}
textarea {
  height: 9.0625rem;
  border: none;
  border-top: none;

}
[type="textarea"] {
  border-top: none;
  max-width: none;
}
div {
  margin: 0;
}


div[type="file"] {
      background: blue;
      border: 1px solid;
      background: ${({ colors }) => colors?.gray?.['100']};
      border-color: ${({ colors }) => colors?.gray?.['200']};
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top: none;
      width: 100%;
      max-width: 100%;

      div[type="file"] {
        border: none;
        padding: 0;
        & > div > div {
          padding: 0;
        }
        & > div > div > div {
           background: white;
        }
       }
      padding: 1rem;
    }

`;
