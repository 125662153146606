import { useQuery, useMutation } from 'react-query';
import * as Permission from '~ui/Permission';
import { useMemo } from 'react';
import { useWorkspace } from './workspace';

import Api from '../api';

export const useAnalyticRuleTickets = (customerId, analyticRuleId, config) => {
  const getAnalyticRuleTickets = async () => {
    const path = `/customer/${customerId}/ticket/search`;
    const payload = {
      search_string: '',
      filter: `custom_properties/any(x: x eq 'sentinel_analytic_rule_id:${analyticRuleId}')`,
      facets: [],
      order_by: ['modified desc'],
      search_fields: [],
      page_size: 10,
      page: 1,
    };
    return Api.post(path, payload);
  };

  return useQuery(
    ['analytic_rule_tickets', customerId, analyticRuleId],
    () => getAnalyticRuleTickets(customerId, analyticRuleId), { ...config },
  );
};

export const searchAnalyticRules = async ({
  payload,
  global,
  customerId,
}) => {
  let path = `customer/${customerId}/security/analyticrule/search`;

  if (global) {
    path = 'security/analyticrule/search';
  }

  return Api.post(path, payload);
};

export const useAnalyticRuleSearch = (customerId, config) => {
  const permissions = Permission.usePermission();

  const analyticRules = async ({ payload }) => {
    let path = `/customer/${customerId}/security/analyticrule/search`;

    if (permissions.isGlobalAdmin() && customerId === null) {
      path = `/security/analyticrule/search`;
    }

    return Api.post(path, payload);
  };

  return useMutation(analyticRules, { ...config });
};

export const useAnalyticRuleTemplateSearch = (customerId, config) => {
  const permissions = Permission.usePermission();

  const searchAnalyticRuleTemplates = async ({ payload }) => {
    let path = `/customer/${customerId}/security/analyticruletemplate/search`;

    if (permissions.isGlobalAdmin() && customerId === null) {
      path = `/security/analyticruletemplate/search`;
    }

    return Api.post(path, payload);
  };

  return useMutation(searchAnalyticRuleTemplates, { ...config });
};

export const useAnalyticRuleTemplate = (templateId, templateCustomerId, config) => {
  const getAnalyticRuleTemplate = async () => {
    const customerId = templateCustomerId === 'g' ? '*' : templateCustomerId;
    const path = `/customer/${customerId}/security/analyticruletemplate/${templateId}`;

    return Api.get(path);
  };

  return useQuery(
    ['analytic_template', templateId, templateCustomerId],
    () => getAnalyticRuleTemplate(templateId, templateCustomerId), { ...config },
  );
};

export const useAnalyticRule = (customerId, analyticRuleId, config) => {
  const getAnalyticRule = async () => {
    const path = `/customer/${customerId}/security/analyticrule/${analyticRuleId}`;

    return Api.get(path);
  };

  return useQuery(
    ['analytic_rule', analyticRuleId],
    () => getAnalyticRule(analyticRuleId), { ...config },
  );
};

export const useDeployTemplateMutation = (customerId, workspaceId, config) => {
  const { data: workspace } = useWorkspace(customerId, workspaceId, { refetchOnMount: false });

  const deployTemplate = async ({ template }) => {
    const payload = {
      customer_id: workspace.customer_id,
      subscription_id: workspace.subscription_id,
      resource_group_name: workspace.resource_group_name,
      workspace_name: workspace.workspace_name,
      analytic_rule_template_id: template.id,
      template_parameters: null,
    };

    const path = `/customer/${workspace.customer_id}/security/analyticruletemplate/deploy`;
    return Api.post(path, payload);
  };

  return useMutation(deployTemplate, { ...config });
};

export const useTemplateIsAvaliableInWorkspace = (
  customerId,
  workspaceId,
  templateObject = null, // If we already have the template object in eg a list, we wont fetch it
  templateId,
  templateCustomerId,
) => {
  // Dont refetch on every mount, we rely on the cache to be valid here
  const workspace = useWorkspace(customerId, workspaceId, { refetchOnMount: false });

  let template = templateObject;
  if (templateObject === null) {
    template = useAnalyticRuleTemplate(templateId, templateCustomerId, {
      enabled: !!workspace.data,
    });
  }

  const isAvaliable = useMemo(() => {
    if (
      (template.isSuccess && workspace.isSuccess)
      || (templateObject !== null && workspace.isSuccess)
    ) {
      const reqConnectors = workspace.data.workspace_tables.map(({ name }) => name);
      const templateConnectors = template.data.properties.requiredDataConnectors
        .map(({ dataTypes }) => dataTypes).flat();

      const avaliable = templateConnectors.reduce((acc, con) => {
        if (!acc) return acc;
        return reqConnectors.includes(con);
      }, true);
      return avaliable;
    }

    return false;
  }, [template, workspace, templateObject]);

  return isAvaliable;
};
