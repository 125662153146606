import React, {
  useState, useCallback, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Button } from '~ui/Button';
import {
  Heading, useTheme, Box, Text, Badge,
} from '@chakra-ui/react';
import { mdiArrowRight } from '@mdi/js';
import translation from './IncidentsCard.translation';
import * as C from './IncidentsCard.styled';
import AlertLevel from './components/AlertLevel';
import ExpandedContent from './components/ExpandedContent';

const propTypes = {
  name: PropTypes.string.isRequired,
  entities: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  urgent: PropTypes.number.isRequired,
  high: PropTypes.number.isRequired,
  medium: PropTypes.number.isRequired,
  low: PropTypes.number.isRequired,
  highestPriorityLevel: PropTypes.number.isRequired,
  customerId: PropTypes.string,
  customerName: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  entities: [],
  customerId: '',
  customerName: '',
  tags: [],
};

const IncidentsCard = ({
  name,
  entities,
  urgent,
  high,
  medium,
  low,
  highestPriorityLevel,
  customerId,
  customerName,
  tags,

}) => {
  const { colors, breakpoints } = useTheme();

  const scrollRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isGlowing, setIsGlowing] = useState(false);
  const { t } = translation;

  const handleExpand = () => {
    setIsExpanded(true);
  };

  const handleClose = useCallback(() => {
    setIsExpanded(false);
  }, []);

  const isMountedRef = useRef(false);

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      return;
    }
    window.scrollTo({
      top: scrollRef.current.offsetTop + 100,
      behavior: 'smooth',
    });
    setIsGlowing(true);
    setTimeout(() => setIsGlowing(false), 200);
  }, [isExpanded]);

  return (
    <C.Wrapper
      className={highestPriorityLevel === 0 ? 'all-good' : ''}
      breakpoints={breakpoints}
      colors={colors}
      alert={urgent}
      isExpanded={isExpanded}
      isGlowing={isGlowing}
      ref={scrollRef}
    >
      <C.Content>

        {customerName && <Text fontSize="12">{customerName}</Text>}
        <Heading
          as="h3"
          style={{
            color: '#000',
            fontWeight: 'normal',
            textTransform: 'uppercase',
          }}
        >
          {name}
        </Heading>

        {tags.length > 0 && (
        <Box
          display="flex"
          flexWrap="wrap"
        >
          {tags?.map((tag) => <Badge mt={1} mr={1} fontSize={10}>{tag}</Badge>)}
        </Box>
        )}

        <Box my="1.5rem">

          <AlertLevel priorityLevel={highestPriorityLevel} />
        </Box>
        <C.ServerityList>

          <C.SeverityRow colors={colors} urgent hasIncidents={+urgent > 0}>
            <C.SeverityLabel>{t('urgent')}</C.SeverityLabel>
            <C.SeverityCount>{urgent}</C.SeverityCount>
          </C.SeverityRow>

          <C.SeverityRow colors={colors} urgent={high} hasIncidents={+high > 0}>
            <C.SeverityLabel>{t('high')}</C.SeverityLabel>
            {' '}
            <C.SeverityCount>{high}</C.SeverityCount>
          </C.SeverityRow>

          <C.SeverityRow colors={colors} hasIncidents={+medium > 0}>
            <C.SeverityLabel>{ t('medium')}</C.SeverityLabel>
            {' '}
            <C.SeverityCount>{medium}</C.SeverityCount>
          </C.SeverityRow>

          <C.SeverityRow colors={colors} hasIncidents={+low > 0}>
            <C.SeverityLabel>{ t('low')}</C.SeverityLabel>
            {' '}
            <C.SeverityCount>{low}</C.SeverityCount>
          </C.SeverityRow>
        </C.ServerityList>

      </C.Content>

      {isExpanded ? (
        <ExpandedContent
          customerId={customerId}
          entities={entities}
          handleClose={handleClose}
        />
      ) : (
        <C.Bottom colors={colors} alert={urgent}>
          <Button
            variant="ghost"
            style={{
              width: '100%', display: 'flex', justifyContent: 'space-between', textTransform: 'uppercase', color: colors?.blue?.['700'], fontSize: '0.8rem', padding: 0,
            }}
            onClick={handleExpand}
            rightIcon={mdiArrowRight}
          >
            {t('view')}
          </Button>
        </C.Bottom>
      )}

    </C.Wrapper>
  );
};

IncidentsCard.propTypes = propTypes;
IncidentsCard.defaultProps = defaultProps;

export default IncidentsCard;
