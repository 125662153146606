import styled, { css } from 'styled-components';

export const LoaderWrapper = styled.div`
    display: flex;
    height: 5.4rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    `;

export const Graph = styled.div`
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    color: red;
`;

export const Count = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 1.375rem;
    line-height: 1.125rem;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    color: #000;
    font-weight: 800;
`;

export const SeverityLabel = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    color: ${({ colors }) => colors?.gray?.['600']};
`;

export const SeverityCount = styled(SeverityLabel)`
    color: #000;
`;

export const ServerityList = styled.div`
    display: flex;
`;

export const SeverityRow = styled.span`
  display: flex;
  flex-direction: row;
  margin-right: 0.5rem;

  /* color: ${({ colors, urgent }) => (urgent ? colors?.ruby?.['800'] : colors?.black)}; */

  &:last-of-type {
    margin-right: 0;
  }

  ${SeverityLabel} {
    ${({ colors, hasIncidents, urgent }) => (hasIncidents
    ? css`
            font-weight: bold;
            color: ${urgent ? colors?.ruby?.['800'] : colors?.gray?.['500']};
          `
    : css`
            color: ${colors?.blue?.['200']};
          `)}
    &:first-of-type {
      margin-right: 0.3rem;
    }
  }
`;

export const Content = styled.div`
    position: relative;
    padding: 1.2rem;
    width: 100%;
`;

export const Bottom = styled.div`
    width:100%;
    padding: 0.5rem 1.2rem;
    border-top: 1px solid ${({ colors, alert }) => (alert ? colors?.ruby?.['100'] : colors?.gray?.['100'])};
`;

export const Wrapper = styled.div`
    display: block;
    height: max-content;
    width: 100%;
    max-width: 100%;
    border-radius: 7px;
    filter: ${({ isGlowing }) => (isGlowing
    ? 'drop-shadow(0px 2px 4px rgba(68, 173, 225, 1))'
    : 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1))')};
    background-color: ${({ colors, alert }) => (alert ? colors?.ruby?.['50'] : 'white')};
    ${({ isExpanded }) => isExpanded && css`grid-column: 1 / -1;`}
    transition: filter 0.2s ease-in-out;
`;
