import Api from '../../../../../lib/api';

const getPBIEmbedToken = async ({
  customerId, payload, groupId, reportId,
}) => {
  const path = `/customer/${customerId}/report/${reportId}/group/${groupId}/embeddtoken`;

  return Api.get(path, payload)
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default getPBIEmbedToken;
