import React from 'react';
import PropTypes from 'prop-types';
import * as Svg from '~ui/Icons';
import { mdiArrowRight } from '@mdi/js';
import { Button } from '~ui/Button';
import { Heading, useTheme } from '@chakra-ui/react';
import translation from './Login.translation';
import * as C from './LoginView.styled';

const logoLink = 'https://www.asurgent.se/';
const moreLink = 'https://www.asurgent.se/vara-tjanster/cloudops/';

const propTypes = {
  onTriggerLogin: PropTypes.func.isRequired,
};

const LoginView = ({ onTriggerLogin }) => {
  const { t } = translation;

  const { breakpoints } = useTheme();

  return (
    <C.Wrapper breakpoints={breakpoints}>
      <C.Header>
        <C.Logo>
          <Button variant="ghost" externalLink={logoLink}>
            <Svg.IconAsurgent width="4rem" fill="#fff" />
          </Button>
          Asurgent
        </C.Logo>
      </C.Header>
      <C.Content breakpoints={breakpoints}>
        <Heading as="h1" textTransform="uppercase">
          {t('title')}
        </Heading>
        <C.Border />
        <C.Actions breakpoints={breakpoints}>
          <p>{t('intro')}</p>
          <C.Buttons breakpoints={breakpoints}>
            <Button
              variant="ghost"
              externalLink={moreLink}
              onClick={onTriggerLogin}
              style={{ color: 'rgb(232, 230, 227)' }}
            >
              {t('more')}
            </Button>

            <Button
              onClick={onTriggerLogin}
              rightIcon={mdiArrowRight}
              size="xl"
              colorScheme="asurgent"
              textTransform="uppercase"
              style={{
                border: '1px solid',
                color: 'rgb(0, 0, 0)',
                transition: 'all 0.03s ease 0s',
                whiteSpace: 'nowrap',
                background: 'rgb(161, 240, 232)',
                boxShadow: 'rgb(161 240 232 / 30%) 0px 10px 30px',
                borderRadius: '6.25rem',
                fontWeight: 'bold',
                padding: '1.25rem 2rem',
              }}
            >
              {t('login')}
            </Button>
          </C.Buttons>
        </C.Actions>
      </C.Content>
    </C.Wrapper>

  );
};

LoginView.propTypes = propTypes;

export default LoginView;
