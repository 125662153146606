import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'MyEnvInformationPage',
  sv: {
    customerId: 'Kund ID',
    entityStatus: 'Status',
    entityName: 'Namn',
    entityRegion: 'Region',
    entityType: 'Typ',
    entitySource: 'Källa',
    resource: 'Resurs',
    resourceGroup: 'Resursgrupp',
    entityId: 'ID',
    currentlyNotMapped: 'CloudOps är inte aktiverad på denna resurs',
    showNittyGritty: 'Visa detaljer',
    hideNittyGritty: 'Göm detaljer',
    details: 'Detaljer',
    cloudOpsServices: 'CloudOps tjänster',
    tags: 'Taggar',
    viewJson: 'Visa JSON',
  },
  en: {
    customerId: 'Customer ID',
    entityStatus: 'Status',
    entityName: 'Name',
    entityRegion: 'Region',
    entityType: 'Type',
    entitySource: 'Source',
    resource: 'Resource',
    resourceGroup: 'Resource Group',
    entityId: 'ID',
    currentlyNotMapped: 'CloudOps is not active on this resource',
    showNittyGritty: 'Show details',
    hideNittyGritty: 'Hide details',
    details: 'Details',
    cloudOpsServices: 'CloudOps services',
    tags: 'Tags',
    viewJson: 'View JSON',
  },
});
