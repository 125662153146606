import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '~ui/Button';
import * as Form from '~ui/Form';
import * as UserImage from '~ui/UserImage';
import { useToast, Text, useTheme } from '@chakra-ui/react';
import * as C from './NewComment.styled';
import translation from './NewComment.translation';

const { t } = translation;

const propTypes = {
  userName: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onSubmitComment: PropTypes.func.isRequired,
  comments: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  setComments: PropTypes.func.isRequired,
};

const NewComment = ({
  userName, email, customerName, onSubmitComment, comments, setComments,
}) => {
  const { colors, breakpoints } = useTheme();

  const [isSaving, setIsSaving] = useState(false);
  const toast = useToast();

  const formData = Form.useFormBuilder({
    message: {
      type: 'textarea',
      noLabel: true,
      placeholder: t('placeholder'),
      value: '',
      // validator: { condition: (v) => v !== '' },
    },
    file: {
      type: 'file',
      label: t('formFileLabel'),
    },
  });

  return (
    <C.Container>
      <C.Header colors={colors} breakpoints={breakpoints}>
        <UserImage.Circle
          size="1.875rem"
          name={userName}
          email={email}
          href={`${process.env.REACT_APP_CDN}/profile-picture/${window.btoa(email)}`}
        />

        <Text style={{ margin: '0', marginLeft: '0.625rem' }}>
          {`${userName} @ ${customerName}`}
        </Text>
      </C.Header>

      <C.CommentForm
        style={{ maxWidth: 'none' }}
        colors={colors}
        form={formData}
        msTimer={15}
        onSubmit={({ values, dirty }) => {
          if (dirty) {
            const { message, file } = values;
            onSubmitComment({
              message,
              setIsSaving,
              comments,
              setComments,
              file,
              clearForm: () => formData.resetValues(),
              onSuccess: () => toast({
                description: t('success'),
                status: 'success',
                duration: 3000,
                isClosable: true,
              }),
              onFail: () => toast({
                description: t('fail'),
                status: 'error',
                duration: 3000,
                isClosable: true,
              }),
            });
          }
        }}
      >
        {({
          render, onSubmitAction, isDirty,
        }) => (
          <>
            {render}
            <>
              <Button
                onClick={onSubmitAction}
                disabled={!isDirty || isSaving}
                isLoading={isSaving}
                style={{ alignSelf: 'flex-end' }}
              >
                {t('btnTitle')}
              </Button>
            </>
          </>
        )}
      </C.CommentForm>
    </C.Container>
  );
};

NewComment.propTypes = propTypes;

export default NewComment;
