import Api from '../../../../../lib/api';
import getFileBuffer from '../../../../../utils/getFileBuffer';

const createTicketAttachment = async ({
  customerId,
  file,
  ticketId,
}) => {
  const path = `/customer/${customerId}/ticket/${ticketId}/attachment/${file?.name}`;

  const payload = await getFileBuffer(file);

  return Api.post(path, payload)
    .then((success) => ({ success }))
    .catch((error) => {
      if (error?.response?.data) {
        return { error: error.response.data };
      }

      return { error };
    });
};

export default createTicketAttachment;
