import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'CloudOpsPageSelectedServiceComponent',
  sv: {
    title: 'CloudOps',
    description: `Bör denna resurs bli bevakad med hjälp av CloudOps? Välj då en utav våra tillgängliga tjänster. CloudOps faktureras per timme. En tjänst kan därmed konfigureras under vilka dagar & timmar på dygnet den skall vara aktiv. Om detta är en kritisk del av ditt system så rekommenderar vi att du väljer vår 24/7-tjänst, då bevakar vi din resurs 24 timmar om dygnet 7 dagar i veckan. Det är viktigt att din dokumentation, dina larmregler samt eskaleringsrutiner är uppdaterade innan du aktiverar CloudOps på en resurs. Kontakta vår Service Desk om det är någon tjänst du saknar, eller om du har några andra frågor om CloudOps.`,
    active: 'Aktiva tjänster',
    noActive: 'Inga aktiva tjänster för tillfället',
    availableServices: 'Tillgängliga tjänster',
    availableServicesDescription: 'Dessa är de tillgängliga tjänsterna som kan konfigureras på denna resurs. Innan du aktiverar en tjänst behöver du konfigurera under vilka timmar den ska vara aktiv.',

    success: 'Cloud Ops Tjänst borttagen',
    fail: 'Misslyckades att ta bort Cloud Ops Tjänst',
  },
  en: {
    title: 'CloudOps',
    description: `Should this resource be monitored and managed by CloudOps? Choose one of the available choices. CloudOps is billed per hour, the only difference between the choices is when the service is active during the day. If this is a critical part of your system we recommend you to choose our 24/7 service, with this service we will monitor your resource 24 hours a day 7 days a week. It's also important to verify that alert rules, documentation and escalation routines are up to date when activating CloudOps on a resource. Contact Service Desk if you are missing an alternative or have any other questions.`,
    active: 'Active services',
    noActive: 'No active services currently',
    availableServices: 'Available Services',
    availableServicesDescription: 'These are the services available to you and can be configured to your needs. Before activating a service you need to configure its operating hours.',

    success: 'Success! Cloud Ops Service removed',
    fail: 'Failed to remove Cloud Ops Service',
  },
});
