import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'EditTicketPage',
  sv: {
    back: 'Tillbaka',
    formSubject: 'Ämne',
    formType: 'Typ',
    formDescription: 'Beskrivning',
    createTicketTitle: 'Skapa Nytt Ärende',
    cancel: 'Avbryt',
    submit: 'Skapa',
    successTitle: 'Tack!',
    successMessage: 'Vi har tagit emot ditt ärende och kommer behandla det så snart vi kan.',
    closeModal: 'Stäng',
    'property-validation-ticket-name-missing': 'Namn måste anges',
    'property-validation-ticket-comment-missing': 'Kommentar måste anges',
    'property-validation-ticket-email-missing': 'Email måste anges',

    btnUpdate: 'Uppdatera',
    btnCancel: 'Avbryt',

    name: 'Namn',
    comment: 'Kommentar',

    commentError: 'Måste ha en kommentar',
    commentPlaceholder: 'Anledning till ändrad status...',

    success: 'Uppdaterat',
    fail: 'Kunde inte uppdatera',
  },
  en: {
    back: 'Back',
    formSubject: 'Subject',
    formType: 'Type',
    formDescription: 'Description',
    createTicketTitle: 'Create New Ticket',
    cancel: 'Cancel',
    submit: 'Create',
    successTitle: 'Thanks!',
    successMessage: 'We have received your ticket and will process it as soon as possible',
    closeModal: 'Close',
    'property-validation-ticket-name-missing': "Name can't be empty",
    'property-validation-ticket-comment-missing': "Comment can't be empty",
    'property-validation-ticket-email-missing': "Email can't be empty",

    btnUpdate: 'Update',
    btnCancel: 'Cancel',

    name: 'Name',
    comment: 'Comment',

    commentError: 'Need a comment',
    commentPlaceholder: 'Reason for changing status...',

    success: 'Updated',
    fail: 'Could not update',
  },
});
