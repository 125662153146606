import React from 'react';
import PropTypes from 'prop-types';
import MdiIcon from '@mdi/react';
import { Text, Heading, useTheme } from '@chakra-ui/react';
import * as C from './RowComponent.styled';
import { iconMapper } from '../../iconMapperHelper';
import ServicePricingColumn from './ServicePricingColumn';

const propTypes = {
  service: PropTypes.instanceOf(Object).isRequired,
};

const RowComponentSelected = ({ service }) => {
  const { colors, breakpoints } = useTheme();
  return (
    <C.Card breakpoints={breakpoints}>
      <C.Icon colors={colors}>
        <MdiIcon path={iconMapper(service.name)} size={2} />
      </C.Icon>
      <C.Content>
        <Heading as="h3" style={{ marginBottom: 0 }}>{service.name}</Heading>
        <Text isTruncated>{service.description}</Text>
      </C.Content>
      <ServicePricingColumn service={service} />
    </C.Card>
  );
};

RowComponentSelected.propTypes = propTypes;

export default RowComponentSelected;
