import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, Link } from 'react-router-dom';
import moment from 'moment';
import * as Layout from '~ui/Layout';
import * as Badge from '~ui/Badge';
import {
  Flex,
  Wrap,
  Text,
  Box,
  Tooltip,
  Tag,
  TagLabel,
  useTheme,
} from '@chakra-ui/react';
import {
  TableSort,
  TableSearch,
  TableSearchProvider,
  TablePagination,
  TableHeader,
  TableFilterTagGroup,
  TableFilterTags,
  TableDrawer,
  TableRows,
  TableBody,
  TableBodyHeader,
  TableFilterSelect,
  TableResultCount,
} from '~ui/Table';
import { getBackPathUrlParam } from '~lib/hooks/useBackPath';
import * as Keys from '../../../../../lib/utils/historyStateKeys';

import translation from './Tickets.translation';
import * as C from './Tickets.styled';
import { searchTickets } from '../../../../../lib/services/tickets';
import { LIST_NAME } from '../constants';
import { listFilterType } from '../../../../../utils/propTypes';

const tagColors = {
  type: 'blue',
  status: 'gold',
  priority: 'ruby',
};

const Tickets = (
  { onUpdateListFilter, listFilter: globalListFilter },
) => {
  const { colors } = useTheme();

  const { t } = translation;
  const { params } = useRouteMatch();
  const { customerId, entityId, testName } = params;

  const columns = [
    { label: t('priority'), size: '8rem' },
    { label: t('id'), size: '5rem' },
    { label: t('subject'), size: 'minmax(16.75rem, 2fr)' },
    { label: t('tags'), size: '9.375rem' },
    { label: t('modified'), size: '9.375rem' },
    { label: t('type'), size: '6.25rem' },
    { label: t('status'), size: '9rem' },
  ];

  const sortList = [
    {
      value: 'modified', label: t('modified'), desc: true, default: true,
    },
    { value: 'created', label: t('created') },
    { value: 'due', label: t('due') },
    { value: 'closed', label: t('closed') },
  ];

  const callback = useCallback((state, azureSearchParser) => {
    const parsers = {
      filter: {
        priority: (val) => `priority eq ${parseInt(val, 10)}`,
      },
    };

    const payload = {
      payload: {},
      customerId,
    };

    if (state.isFilterTrigger) {
      Object.assign(payload, {
        payload: azureSearchParser.facets(state, state.filterKey, parsers),
      });
    } else {
      onUpdateListFilter({
        [LIST_NAME]: { ...state },
      });
      Object.assign(payload, {
        payload: azureSearchParser.items(state, parsers),
      });
    }

    const { filter, search_string: searchString } = payload.payload;
    Object.assign(payload.payload, {
      filter: `(entity_id eq '${entityId}') ${filter && `and ${filter}`}`.trim(),
      search_string: `a_web_test ${testName}+${searchString}`,
    });

    return payload;
  }, [customerId, entityId, onUpdateListFilter, testName]);

  const filterPills = {
    type: (_, value) => `${t('type')}: ${value}`,
    status: (_, value) => `${t('status')}: ${value}`,
    priority: (_, value) => `${t('priority')}: ${t(`priority${value}`)}`,
  };

  const backPath = getBackPathUrlParam(t('backButtonText'));

  return (
    <TableSearchProvider
      pageSize={15}
      payload={callback}
      fetcher={searchTickets}
      urlStateKey={Keys.APP_MONITOR_TICKETS}
      sort={sortList}
      initialValues={globalListFilter}
    >
      <Box flex="1">
        <TableHeader>
          <TableSearch />
          <TableSort />

          <TableDrawer title={t('filterDrawerTitle')}>
            <TableFilterSelect
              title={t('type')}
              label={t('type')}
              filterKey="type"
              renderTags
              color={tagColors.type}
            />

            <TableFilterSelect
              title={t('status')}
              label={t('status')}
              filterKey="status"
              renderTags
              color={tagColors.status}
            />

            <TableFilterSelect
              title={t('priority')}
              label={t('priority')}
              filterKey="priority"
              configuration={(filter) => ({
                title: t(`priority${filter.value}`),
                subtitle: filter.count,
                value: filter.value,
              })}
            />
            <Wrap>
              <TableFilterTagGroup
                filterKey="priority"
                color={tagColors.priority}
                configure={(_, value) => `${t('priority')}: ${t(`priority${value}`)}`}
              />
            </Wrap>

          </TableDrawer>
        </TableHeader>

        <Wrap>
          <TableFilterSelect
            label={t('type')}
            filterKey="type"
          />
          <TableFilterSelect
            label={t('status')}
            filterKey="status"
          />
          <TableFilterSelect
            label={t('priority')}
            filterKey="priority"
            configuration={(filter) => ({
              title: t(`priority${filter.value}`),
              subtitle: filter.count,
              value: filter.value,
            })}
          />
        </Wrap>
        <TableFilterTags
          configurations={filterPills}
          colors={tagColors}
        />

      </Box>

      <Layout.Scene>
        <TableResultCount fileName="appmonitor-testruns.csv" />
        <TableBody columns={columns}>
          <TableBodyHeader />
          <TableRows>
            {(data, idx, RowComponent) => (
              <Link key={data.id} to={`/tickets/${customerId}/${data.ticket_id}/details/${backPath}`} style={{ textDecoration: 'none' }}>

                <RowComponent>

                  <Flex overflow="hidden" p={2} alignItems="center">
                    <C.Priority colors={colors} priority={data.priority} />
                    {t(`priority${data.priority}`)}
                  </Flex>

                  <Flex overflow="hidden" p={2} alignItems="center">
                    <Text fontWeight="600" isTruncated>{data.ticket_id}</Text>
                  </Flex>

                  <Flex overflow="hidden" p={2} alignItems="center">
                    <Tooltip hasArrow label={data.subject} placement="auto">
                      <Text isTruncated>{data.subject}</Text>
                    </Tooltip>
                  </Flex>

                  <Box overflow="hidden" p={2}>
                    <Wrap spacing={2}>
                      { data.tags.map((tag) => (
                        <Tag key={tag} bg="#f5edd8">
                          <Tooltip hasArrow label={tag} placement="top">
                            <TagLabel isTruncated>
                              {tag}
                            </TagLabel>
                          </Tooltip>
                        </Tag>
                      ))}
                    </Wrap>
                  </Box>

                  <Flex overflow="hidden" p={2} alignItems="center">
                    <Tooltip hasArrow label={data.modified} placement="auto">
                      <Text isTruncated>{moment(data.modified).fromNow() }</Text>
                    </Tooltip>
                  </Flex>

                  <Flex overflow="hidden" p={2} alignItems="center">
                    <Text isTruncated textTransform="uppercase">{data.type}</Text>
                  </Flex>

                  <Flex overflow="hidden" p={2} alignItems="center">
                    <Badge.Status status={data.status} />
                  </Flex>

                </RowComponent>
              </Link>
            )}
          </TableRows>
        </TableBody>
        <TablePagination />
      </Layout.Scene>
    </TableSearchProvider>
  );
};

const propTypes = {
  onUpdateListFilter: PropTypes.func.isRequired,
  listFilter: listFilterType,
};

Tickets.propTypes = propTypes;

Tickets.defaultProps = {
  listFilter: null,
};

export default Tickets;
