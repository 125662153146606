import { connect } from 'react-redux';
import Main from './components/Main';
import * as A from './actions';
import withNavigation from '../../../../hoc/Navigation';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onMount: (payload) => dispatch(A.onMount(payload)),
  onUnmount: (payload) => dispatch(A.onUnmount(payload)),
  redeployAgent: (payload) => dispatch(A.redeployAgent(payload)),
});

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Main));
