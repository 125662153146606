import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AlertLevel',
  sv: {
    urgent: 'Brådskande pågående',
    high: 'Hög pågående',
    medium: 'Medium pågående',
    low: 'Låg pågående',
    allGood: 'Inga pågående incidenter',

  },
  en: {
    urgent: 'Urgent ongoing',
    high: 'High ongoing',
    medium: 'Medium ongoing',
    low: 'Low ongoing',
    allGood: 'No ongoing incidents',

  },
});
