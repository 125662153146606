import React, {
  useRef, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import * as C from './PowerBI.styled';
import translation from './Report.translation';

const { t } = translation;

const propTypes = {
  pbiConfig: PropTypes.instanceOf(Object),
  pbiService: PropTypes.instanceOf(Object),
};

const defaultProps = {
  pbiConfig: null,
  pbiService: null,
};

const PowerBI = ({ pbiConfig, pbiService }) => {
  const reportContainer = useRef(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (reportContainer?.current) {
      const report = pbiService.embed(reportContainer.current, pbiConfig);

      report.on('error', () => {
        setHasError(true);
        report.off('error');
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (hasError) {
    return <C.PowerBI><h3>{t('powerBIFailed')}</h3></C.PowerBI>;
  }

  return <C.PowerBI ref={reportContainer} />;
};

PowerBI.propTypes = propTypes;
PowerBI.defaultProps = defaultProps;

export default PowerBI;
