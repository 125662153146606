import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'CreateEditProcessModelServices',
  sv: {
    removeSuccess: 'Automatiseringsprocess borttagen',
    removeFail: 'Misslyckades att ta bort Automatiseringsprocess',
    createSuccess: 'Automatiseringsprocess sparad',
    createFail: 'Kunde inte spara Automatiseringsprocess',
  },
  en: {
    removeSuccess: 'Success! Auto Action removed',
    removeFail: 'Failed to remove Auto Action',
    createSuccess: 'Success! Auto Action saved',
    createFail: 'Failed to save Auto Action',
  },
});
