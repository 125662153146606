import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { mdiArrowLeft } from '@mdi/js';
import * as Layout from '~ui/Layout';
import { Button } from '~ui/Button';
import * as Block from '~ui/Block';
import translate from './Details.translation';
import * as C from './Details.styled';

const Details = (props) => {
  const { entity, entityId, customerId } = props;
  const { t } = translate;

  return (
    <>
      <Layout.Omnibar.Main>
        <Layout.Omnibar.LeftActions>
          <Button
            variant="ghost"
            leftIcon={mdiArrowLeft}
            internalLink={`/my-environment/${customerId}/${entityId}/information`}
          >
            {t('back')}
          </Button>
        </Layout.Omnibar.LeftActions>
      </Layout.Omnibar.Main>
      <Layout.Scene>
        <Block.Stretch style={{ margin: '2rem' }}>
          <C.StyledProperties>
            <ReactJson
              src={entity || {}}
              theme="summerfruit:inverted"
              iconStyle="circle"
              name={null}
              indentWidth={3}
              collapsed={false}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
              sortKeys
            />
          </C.StyledProperties>
        </Block.Stretch>
      </Layout.Scene>
    </>
  );
};

Details.propTypes = {
  entity: PropTypes.instanceOf(Object).isRequired,
  entityId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
};

export default Details;
