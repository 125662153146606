import {
  take,
  call,
  cancel,
  fork,
  spawn,
  takeEvery,
  delay,
} from 'redux-saga/effects';
import * as t from './actionTypes';
import getAlertRuleMetrics from './services/getAlertRuleMetrics.service';

const action = (type, id) => `${type}-${id}`;

function* getMetricData({
  customerId,
  entityId,
  metricName,
  setGraphData,
}) {
  const payload = { customerId, entityId, metricName };

  const { success } = yield call(getAlertRuleMetrics, payload);

  if (success) {
    setGraphData(success);
  } else {
    setGraphData([]);
  }
}

function* longpoll(meta) {
  while (true) {
    yield call(getMetricData, meta);
    yield delay(60 * 1000);
  }
}

function* change(meta, tasks, { meta: update }) {
  yield cancel(tasks);
  tasks.push(
    yield spawn(longpoll, { ...meta, ...update }),
  );
}

function* keepAlive({ meta }) {
  yield call(getMetricData, meta);
  meta.setLoading(false);

  const tasks = [
    yield fork(longpoll, meta),
  ];

  const update = yield takeEvery(action(t.UPDATE, meta.instance), change, meta, tasks);
  yield take(action(t.UNMOUNT, meta.instance));
  yield cancel([update, ...tasks]);
}

export default function* moduleRootWatcher() {
  yield takeEvery(t.MOUNT, keepAlive);
}
