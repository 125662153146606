import React, {
  useMemo, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';

import * as Block from '~ui/Block';
import { Button } from '~ui/Button';
import * as List from '~ui/List';
import * as Permission from '~ui/Permission';
import { Tag, Wrap, Heading } from '@chakra-ui/react';
import { mdiArrowRight } from '@mdi/js';
import { Link } from 'react-router-dom';
import SelectedCloudOpsServices from '../SelectedCloudOpsServices';
import CloudOpsDescription from '../Description';
import translate from './Information.translation';

const propTypes = {
  customerId: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  entity: PropTypes.instanceOf(Object).isRequired,
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
};

const defaultProps = {};

const InformationPage = ({
  entity,
  customerId,
  entityId,
  onMount,
  onUnmount,
}) => {
  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();
  const { t } = translate;

  const tags = useMemo(() => Object.entries(entity.tags || [])
    .map(([group, type]) => `${group}: ${type}`), [entity.tags]);

  const content = useMemo(() => {
    const {
      customer_id: cId,
      status,
      name,
      resource_group: rG,
      id,
      type,
      source,
      region,
    } = entity;

    return [
      isGlobalAdmin && {
        label: t('customerId'),
        value: cId || '',
      },
      {
        label: t('entityStatus'),
        value: status || '',
      },
      {
        label: t('entityName'),
        value: name || '',
      },
      {
        label: t('resourceGroup'),
        value: rG || '',
      },
      {
        label: t('entityId'),
        value: id || '',
      },
      {
        label: t('entityType'),
        value: type || '',
      },
      {
        label: t('entitySource'),
        value: source || '',
      },
      {
        label: t('entityRegion'),
        value: region || '',
      },
      {
        label: t('tags'),
        value: tags?.length > 0
          ? (
            <Wrap spacing={2}>
              {tags.map((tag) => <Tag key={tag} bg="#f5edd8">{tag}</Tag>)}
            </Wrap>
          )
          : null,
      },
      {
        row: (
          <Button
            variant="ghost"
            style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
            rightIcon={mdiArrowRight}
            p={0}
            m="1rem 0"
            internalLink={`/my-environment/${customerId}/${entityId}/information/details`}
          >
            { t('viewJson') }
          </Button>
        ),
      },
    ];
  }, [customerId, entity, entityId, isGlobalAdmin, t, tags]);

  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    onMount({ setServiceList, customerId, entityId });

    return () => onUnmount();
  }, [customerId, entityId, onMount, onUnmount]);

  if (entity) {
    return (
      <>
        <Block.Stretch style={{ marginBottom: '2.25rem' }}>
          <Heading as="h2">{t('details')}</Heading>
          <List.Primary rows={content} />
        </Block.Stretch>

        <Block.Stretch withBottomMargin>
          <Heading as="h2">{t('cloudOpsServices')}</Heading>
          {
            serviceList.map((activeService) => (
              <Link key={activeService.name} to={`/my-environment/${customerId}/${entityId}/cloudops`}>
                <SelectedCloudOpsServices service={activeService} />
              </Link>
            ))
          }
        </Block.Stretch>
        <CloudOpsDescription customerId={customerId} entityId={entityId} />
      </>
    );
  }

  return null;
};

InformationPage.propTypes = propTypes;
InformationPage.defaultProps = defaultProps;

export default InformationPage;
