import * as t from './actionTypes';

export const onMount = (payload) => ({
  type: t.MOUNT,
  meta: payload,
});

export const onUnmount = () => ({
  type: t.UNMOUNT,
});

export const onSetRedirectOrigin = (path) => ({
  type: t.SET_REDIRECT_ORIGIN,
  meta: { path },
});

export const triggerLogin = () => ({
  type: t.LOGIN,
});

export const triggerLogout = () => ({
  type: t.LOGOUT,
});

export const setAccountState = (
  accessToken,
  jwt,
  expires,
) => ({
  type: t.SET_ACCOUNT_STATE,
  meta: {
    accessToken,
    jwt,
    expires,
  },
});

export const setJwtToken = (
  jwt,
  expires,
) => ({
  type: t.SET_JWT,
  meta: {
    jwt,
    expires,
  },
});

export const isUserAuthed = (authed, error) => {
  const { errorMessage } = error || '';

  const silentFailed = errorMessage?.includes('AADSTS50058');

  return {
    type: t.AUTHED,
    meta: { authed, silentFailed },
  };
};

export const isInitiated = (initiated) => ({
  type: t.INITIATED,
  meta: { initiated },
});

export const applicationIsReady = () => ({
  type: t.APPLICATION_READY,
  meta: { applicationReady: true },
});

export const setLanguage = (language) => ({
  type: t.SET_LANGUAGE,
  meta: { language },
});

export const clearLoginTriggered = () => ({
  type: t.CLEAR_LOGIN_TRIGGERED,
});
