import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'NotAuthorized',
  sv: {
    title: 'Ej behörig',
    content: 'Du saknar behörighet för den här sidan. Kontakta din administratör för mer information.',
    buttonTitle: 'Tillbaka till startsidan',
  },
  en: {
    title: 'Not authorized',
    content: "You don't have access to this page. Contact your administrator for further information.",
    buttonTitle: 'Go back to start page',

  },
});
