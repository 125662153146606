import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Details',
  sv: {
    error: 'Ett fel inträffade när datan skulle hämtas.',
  },
  en: {
    error: 'An error occurred while fetching data.',
  },
});
