import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, Link } from 'react-router-dom';
import moment from 'moment';
import * as Layout from '~ui/Layout';
import * as Permission from '~ui/Permission';
import * as Badge from '~ui/Badge';
import {
  Flex,
  Wrap,
  Text,
  Box,
  Tooltip,
  Tag,
  TagLabel,
  useTheme,
} from '@chakra-ui/react';
import {
  TableSort,
  TableSearch,
  TableRows,
  TableSearchProvider,
  TablePagination,
  TableHeader,
  TableFilterTags,
  TableFilterSelect,
  TableBody,
  TableBodyHeader,
  TableResultCount,
} from '~ui/Table';
import * as Keys from '../../../../../lib/utils/historyStateKeys';
import * as C from './Ticketspage.styled';
import translation from './Ticketspage.translation';
import { searchTickets } from '../../../../../lib/services/tickets';
import { LIST_NAME } from '../constants';
import { listFilterType } from '../../../../../utils/propTypes';

const tagColors = {
  customer_display_name: 'blue',
  type: 'gold',
  status: 'ruby',
  priority: 'green',
};

const TicketsPage = (
  { onUpdateListFilter, listFilter: globalListFilter },
) => {
  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();
  const { params: { customerId } } = useRouteMatch();
  const { colors } = useTheme();

  const { t } = translation;

  const columns = [
    { label: t('priority'), size: '8rem' },
    { label: t('ticketId'), key: 'ticket-id', size: '5rem' },
    { label: t('subject'), size: 'minmax(18.75rem, 1fr)' },
    { label: t('tags'), size: '9.375rem' },
    { label: t('modified'), size: '9.375rem' },
    { label: t('type'), size: '6.25rem' },
    { label: t('status'), size: '9.375rem' },
    {
      key: 'customer_display_name',
      label: t('customer'),
      size: 'minmax(9.375rem, 11.25rem)',
      render: isGlobalAdmin,
    },
  ];

  const filterPills = {
    customer_display_name: (_, value) => `${t('customer')}: ${value}`,
    priority: (_, value) => `${t('priority')}: ${t(`priority${value}`)}`,
    ticket_id: (_, value) => `${t('ticketId')}: ${value}`,
    subject: (_, value) => `${t('subject')}: ${value}`,
    modified: (_, value) => `${t('modified')}: ${value}`,
    type: (_, value) => `${t('type')}: ${value}`,
    status: (_, value) => `${t('status')}: ${value}`,
    metricName: (_, value) => `${t('metricName')}: ${value}`,
    closed: () => `${t('onlyNotClosed')}`,
  };

  const sortList = [
    {
      value: 'modified', label: t('modified'), desc: true, default: true,
    },
  ];

  const callback = useCallback((state, azureSearchParser) => {
    const { is_stale: stale, ...restFiler } = state.filter;
    const newState = { ...state, filter: restFiler };
    const includeStale = stale !== undefined;

    const parsers = {
      filter: {
        priority: (val) => `priority eq ${parseInt(val, 10)}`,
        // custom filters for when passed from Overview-page
        metricName: (val) => `alert_rule_name eq '${val}'`,
        closed: () => `status ne 'closed'`,
      },
    };

    const payload = {
      payload: {},
      global: permissions.isGlobalAdmin(),
      customerId,
      includeStale,
    };

    if (state.isFilterTrigger) {
      Object.assign(payload, {
        payload: azureSearchParser.facets(newState, state.filterKey, parsers),
      });
    } else {
      onUpdateListFilter({
        [LIST_NAME]: { ...newState },
      });
      Object.assign(payload, {
        payload: azureSearchParser.items(newState, parsers),
      });
    }

    return payload;
  }, [onUpdateListFilter, permissions, customerId]);

  return (
    <TableSearchProvider
      pageSize={15}
      payload={callback}
      fetcher={searchTickets}
      urlStateKey={Keys.TICKETS}
      sort={sortList}
      initialValues={globalListFilter}
    >
      <Layout.Omnibar.Main>
        <Box flex="1">
          <TableHeader>
            <TableSearch />
            <TableSort />
          </TableHeader>

          <Wrap>
            {isGlobalAdmin && (
              <TableFilterSelect
                label={t('customer')}
                filterKey="customer_display_name"
              />
            )}

            <TableFilterSelect
              label={t('type')}
              filterKey="type"
            />
            <TableFilterSelect
              label={t('status')}
              filterKey="status"
            />
            <TableFilterSelect
              label={t('priority')}
              filterKey="priority"
              configuration={(filter) => ({
                title: t(`priority${filter.value}`),
                subtitle: filter.count,
                value: filter.value,
              })}
            />

          </Wrap>
          <TableFilterTags
            configurations={filterPills}
            colors={tagColors}
          />
        </Box>
      </Layout.Omnibar.Main>
      <Layout.Scene>
        <TableResultCount fileName="tickets.csv" />
        <TableBody columns={columns}>
          <TableBodyHeader />
          <TableRows>
            {(data, idx, RowComponent) => (
              <Link
                key={idx}
                to={`/tickets/${(data.customer_id)}/${data.ticket_id}/details`}
                style={{ textDecoration: 'none' }}
              >
                <RowComponent>

                  <Flex overflow="hidden" p={2} alignItems="center">
                    <C.Priority colors={colors} priority={data.priority} />
                    <Text margin="0" isTruncated>{t(`priority${data.priority}`)}</Text>
                  </Flex>

                  <Flex overflow="hidden" p={2} alignItems="center">
                    <Text margin="0" isTruncated>{data.ticket_id}</Text>
                  </Flex>

                  <Flex overflow="hidden" p={2} alignItems="center">
                    <Tooltip hasArrow label={data.subject} placement="auto">
                      <Text margin="0" isTruncated>{data.subject}</Text>
                    </Tooltip>
                  </Flex>

                  <Box overflow="hidden" p={2}>
                    <Wrap p={2} spacing=".4rem">
                      {data.tags.length ? (
                        data.tags.map((label, index) => (
                          index === 0 ? (
                            <Tag key={label} bg="#f5edd8" position="relative" style={{ marginRight: '20px' }}>
                              <Tooltip hasArrow placement="top" label={data.tags.map((el) => `${el}`).join(', ')}>
                                <TagLabel isTruncated>
                                  {data.tags[0]}
                                </TagLabel>
                              </Tooltip>
                              { data.tags.length > 1 && (
                                <span style={{ position: 'absolute', right: '-20px', fontSize: '12px' }}>
                                  {(' +')}
                                  {data.tags.length - 1}
                                </span>
                              )}
                            </Tag>
                          )
                            : null
                        ))
                      ) : (
                        <Text padding="2px">-</Text>
                      )}
                    </Wrap>
                  </Box>

                  <Flex overflow="hidden" p={2} alignItems="center">
                    <Tooltip hasArrow label={data.modified} placement="auto">
                      <Text margin="0">{ moment(data.modified).fromNow()}</Text>
                    </Tooltip>
                  </Flex>

                  <Flex overflow="hidden" p={2} alignItems="center">
                    <Text margin="0" textTransform="capitalize" isTruncated>{data.type}</Text>
                  </Flex>

                  <Flex overflow="hidden" p={2} alignItems="center">
                    <Badge.Status status={data.status} />
                  </Flex>

                  {isGlobalAdmin && (
                  <Flex overflow="hidden" p={2} alignItems="center">
                    <Tooltip hasArrow label={data.customer_display_name} placement="auto">
                      <Text margin="0" isTruncated>{data.customer_display_name}</Text>
                    </Tooltip>
                  </Flex>
                  )}

                </RowComponent>
              </Link>
            )}
          </TableRows>
        </TableBody>
        <TablePagination />
      </Layout.Scene>
    </TableSearchProvider>
  );
};

const propTypes = {
  onUpdateListFilter: PropTypes.func.isRequired,
  listFilter: listFilterType,
};

TicketsPage.propTypes = propTypes;

TicketsPage.defaultProps = {
  listFilter: null,
};

export default TicketsPage;
