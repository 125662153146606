import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useTheme, Flex, HStack,
} from '@chakra-ui/react';
import MdiIcon from '@mdi/react';
import { mdiChevronUp } from '@mdi/js';
import { Button } from '~ui/Button';
import { getBackPathUrlParam } from '~lib/hooks/useBackPath';
import * as C from './ExpandedContent.styled';
import EntityGroups from '../../../EntityGroups';
import EntitiesList from '../../../EntitiesList';
import translation from './ExpandedContent.translation';

const propTypes = {
  entities: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  customerId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const defaultProps = {};

const TAB_ENTITY_GROUPS = 'TAB_ENTITY_GROUPS';
const TAB_ENTITIES = 'TAB_ENTITIES';

const ExpandedContent = ({ entities, handleClose, customerId }) => {
  const hasTickets = useMemo(() => entities.some(({ tickets }) => tickets?.length > 0), [entities]);

  const [activeTab, setActiveTab] = useState(hasTickets ? TAB_ENTITY_GROUPS : TAB_ENTITIES);

  const handleSelectEntityGroupsTab = () => {
    setActiveTab(TAB_ENTITY_GROUPS);
  };

  const handleSelectEntityTab = () => {
    setActiveTab(TAB_ENTITIES);
  };

  const { colors } = useTheme();
  const { t } = translation;

  const entitiesWithPriority = useMemo(() => entities.map((entity) => {
    const sortedTickets = entity.tickets?.sort((a, b) => b.priority - a.priority);
    const highestPriority = sortedTickets?.[0]?.priority || null;

    return {
      ...entity,
      tickets: sortedTickets,
      highestPriority,
    };
  }).sort((a, b) => b.highestPriority - a.highestPriority), [entities]);

  const backPathUrlParam = getBackPathUrlParam(t('backPathButtonText'));

  return (
    <C.Wrapper>
      <Flex>
        <C.TabButtonWrappers>
          <HStack spacing={4}>
            {hasTickets && (
            <C.TabButton
              colors={colors}
              isActive={activeTab === TAB_ENTITY_GROUPS}
              onClick={handleSelectEntityGroupsTab}
            >
              {t('entityGroupsTabButton')}
            </C.TabButton>
            )}
            <C.TabButton
              colors={colors}
              isActive={activeTab === TAB_ENTITIES}
              onClick={handleSelectEntityTab}
            >
              {t('entitiesTabButton')}

            </C.TabButton>
          </HStack>
        </C.TabButtonWrappers>
      </Flex>
      <C.ContentWrapper colors={colors}>

        <C.Content>
          {activeTab === TAB_ENTITY_GROUPS && (
          <EntityGroups
            entities={entitiesWithPriority}
            customerId={customerId}
            backPathUrlParam={backPathUrlParam}
          />
          )}
          {activeTab === TAB_ENTITIES && (
          <EntitiesList
            entities={entitiesWithPriority}
            customerId={customerId}
            backPathUrlParam={backPathUrlParam}
          />
          )}

        </C.Content>

      </C.ContentWrapper>

      <C.Bottom colors={colors}>
        <C.BottomButtonWrapper>
          <Button variant="ghost" onClick={handleClose}><MdiIcon path={mdiChevronUp} size={0.75} /></Button>
        </C.BottomButtonWrapper>

      </C.Bottom>

    </C.Wrapper>

  );
};

ExpandedContent.propTypes = propTypes;
ExpandedContent.defaultProps = defaultProps;

export default ExpandedContent;
