import { connect } from 'react-redux';
import NotAuthorized from './NotAuthorized';

import { triggerLogout } from '../../../actions';

const mapStateToProps = ({ application: { authed } }) => ({
  authed,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(triggerLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotAuthorized);
