import {
  take,
  call,
  cancel,
  takeEvery,
} from 'redux-saga/effects';
import * as t from './actionTypes';
import getAutoActionForEntity from './services/getAutoActionForEntity.service';
import getAutoActionsAvailableForEntity from './services/getAutoActionsAvailableForEntity.service';
import getAlertsForEntity from './services/getAlertsForEntity.service';
import updateAutoActionsForEntity from './services/updateAutoActionsForEntity.service';
import createAutoActionForEntity from './services/createAutoActionForEntity.service';
import { buildProcessModel } from './helpers';

function* save(currentModel, { entityId, customerId }, { meta: { sequence, metadata, callback } }) {
  const processModel = {
    ...currentModel,
    name: metadata.name,
    description: metadata.description,
    alert_rule_ids: metadata.selectedAlertRule ? metadata.selectedAlertRule : [],
  };
  const model = yield buildProcessModel(processModel, sequence);
  const payload = {
    entityId,
    customerId,
    model,
  };

  const { success, error } = yield call(updateAutoActionsForEntity, payload);
  yield call(callback, { success, error });
}

function* create({ entityId, customerId }, { meta: { sequence, metadata, callback } }) {
  const processModel = {
    name: metadata.name,
    description: metadata.description,
    alert_rule_ids: metadata.selectedAlertRule ? metadata.selectedAlertRule : [],
  };
  const model = yield buildProcessModel(processModel, sequence);
  const payload = {
    entityId,
    customerId,
    model,
  };

  const { success, error } = yield call(createAutoActionForEntity, payload);
  yield call(callback, { success, error });
}

function* loadData(meta) {
  const {
    customerId, entityId,
    setLoading,
    setAlertRules,
    setAvaliableAutoActions,
    setCanApplyOnAlertRule,
    processModelId,
    setMetaData,
    setSequence,
  } = meta;

  const payload = { customerId, entityId };

  const { success: autoActions } = yield call(getAutoActionForEntity, payload);
  const { success: allAutoActions } = yield call(getAutoActionsAvailableForEntity, payload);
  const { success: alertRules } = yield call(getAlertsForEntity, payload);

  const targetedAutoAction = autoActions.find(({ id }) => id === processModelId);
  const appliedAutoActions = autoActions
    .reduce((acc, { alert_rule_ids: ids }) => [...ids, ...acc], []);

  if (allAutoActions) {
    setAvaliableAutoActions(allAutoActions);
  }

  if (alertRules) {
    const avaliableAlertRules = alertRules
      .reduce((acc, { name, id }) => {
        const isSelected = appliedAutoActions.includes(id);
        const currentProcessHasRuleApplied = (targetedAutoAction?.alert_rule_ids || [])
          .find((alertRuleId) => alertRuleId === id);
        return [...acc,
          {
            value: `${id}`,
            label: name,
            key: id,
            disabled: isSelected ? (!currentProcessHasRuleApplied) : false,
          }];
      }, []);
    setAlertRules(avaliableAlertRules);
  }

  // We are in create-new mode
  if (!processModelId) {
    const generalAutoActionHasBeenApplied = autoActions
      .find(({ alert_rule_ids: ids }) => ids.length === 0);

    if (generalAutoActionHasBeenApplied === undefined) {
      setCanApplyOnAlertRule(true);
    } else {
      setCanApplyOnAlertRule(false);
    }
  }

  // We are in edit-mode
  if (processModelId) {
    const canApplyAsGeneralRule = autoActions
      .find(({ alert_rule_ids: ids }) => ids.length === 0) === undefined;

    const { alert_rule_ids: ids, autoActions: actions } = targetedAutoAction;
    const targetedAutoActionHasGeneralAppliedAutoAction = ids.length === 0;

    const sequence = actions
      .map((step, index) => ({
        ...step,
        localId: `${new Date().getTime()}-${index}`,
      }));

    if (targetedAutoActionHasGeneralAppliedAutoAction || canApplyAsGeneralRule) {
      setCanApplyOnAlertRule(true);
    } else {
      setCanApplyOnAlertRule(false);
    }

    const { name, description } = targetedAutoAction;

    setSequence(sequence);
    setMetaData({
      name,
      description,
      selectedAlertRule: ids,
      applyOnAllAlertRules: targetedAutoActionHasGeneralAppliedAutoAction,
    });
  }

  setLoading(false);

  if (targetedAutoAction) {
    return targetedAutoAction;
  }
  return null;
}

export default function* rootWatcher() {
  while (true) {
    const { meta } = yield take(t.MOUNT);
    const { processModelId: updateExistingModel } = meta;

    const currentModel = yield call(loadData, meta);
    const tasks = [];

    if (updateExistingModel && currentModel) {
      tasks.push(yield takeEvery(t.SAVE_PROCESSMODEL, save, currentModel, meta));
    } else {
      tasks.push(yield takeEvery(t.SAVE_PROCESSMODEL, create, meta));
    }

    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
