import { connect } from 'react-redux';
import EditMeta from './components/EditMeta';
import {
  onMount,
  onUnmount,
  onUpdateTicket,
} from './actions';

const mapStateToProps = ({ model }) => ({
  name: model.userContext.name,
  email: model.userContext.email,
});

const mapDispatchToProps = (dispatch) => ({
  onMount: (payload) => dispatch(onMount(payload)),
  onUnmount: (payload) => dispatch(onUnmount(payload)),
  onUpdateTicket: (payload) => dispatch(onUpdateTicket(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMeta);
