import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Layout from '~ui/Layout';
import * as Permission from '~ui/Permission';

import { useRouteMatch } from 'react-router-dom';

import {
  Wrap,
  Box,
} from '@chakra-ui/react';
import {
  TableSort,
  TableSearch,
  TableSearchProvider,
  TablePagination,
  TableHeader,
  TableFilterTags,
  TableFilterSelect,
  TableResultCount,
  TableRows,
} from '~ui/Table';
import * as Keys from '../../../../lib/utils/historyStateKeys';
import Card from './modules/Card';
import translation from './List.translation';
import { searchWebTests } from '../../../../lib/services/appMonitor';
import { LIST_NAME } from './constants';
import { LIST_NAME as TEST_RUNS_LIST } from '../TestRuns/constants';
import { LIST_NAME as TICKETS_LIST } from '../Tickets/constants';

import { listFilterType } from '../../../../utils/propTypes';

const { t } = translation;

const tagColors = {
  customer_display_name: 'blue',
};

const List = (
  { onUpdateListFilter, listFilter: globalListFilter },
) => {
  const { params } = useRouteMatch();

  const { customerId } = params;

  const permissions = Permission.usePermission();
  const isGlobalAdmin = permissions.isGlobalAdmin();

  const filterPills = {
    ...(isGlobalAdmin && { customer_display_name: (_, value) => `${t('customer')}: ${value}` }),
  };

  const sortList = [
    {
      value: 'name', label: t('name'), desc: true, default: true,
    },
    { value: 'last_run_timestamp', label: t('lastRunTimeStamp') },
  ];

  const callback = useCallback((state, azureSearchParser) => {
    const parsers = { };
    if (state.isFilterTrigger) {
      return {
        payload: azureSearchParser.facets(state, state.filterKey, parsers),
        isGlobalAdmin,
        customerId,
      };
    }

    onUpdateListFilter({
      [LIST_NAME]: { ...state },
      [TICKETS_LIST]: null,
      [TEST_RUNS_LIST]: null,
    });

    return {
      payload: azureSearchParser.items(state, parsers),
      isGlobalAdmin,
      customerId,
    };
  }, [customerId, isGlobalAdmin, onUpdateListFilter]);

  return (
    <TableSearchProvider
      pageSize={15}
      payload={callback}
      fetcher={searchWebTests}
      urlStateKey={Keys.APP_MONITOR}
      sort={sortList}
      initialValues={globalListFilter}

    >
      <Layout.Omnibar.Main>
        <Box flex="1">
          <TableHeader>
            <TableSearch />
            <TableSort />
          </TableHeader>

          {isGlobalAdmin && (
          <>
            <Wrap>
              <TableFilterSelect
                label={t('customer')}
                filterKey="customer_display_name"
              />
            </Wrap>
            <TableFilterTags
              configurations={filterPills}
              colors={tagColors}
            />
          </>
          )}
        </Box>
      </Layout.Omnibar.Main>

      <Layout.Scene>
        <TableResultCount fileName="myenvironment-sw.csv" />
        <Box p={4}>
          <TableRows>
            {(row) => <Card row={row} key={row.id} />}
          </TableRows>
        </Box>
        <TablePagination />
      </Layout.Scene>
    </TableSearchProvider>
  );
};

const propTypes = {
  onUpdateListFilter: PropTypes.func.isRequired,
  listFilter: listFilterType,
};

List.propTypes = propTypes;

List.defaultProps = {
  listFilter: null,
};

export default List;
