// import './wdyr';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

if (process.env.NODE_ENV !== 'development') {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
    },
  });
  const loader = appInsights.loadAppInsights();
  loader.trackPageView();
}

const render = () => {
  // Dont run app when getting redirected from MSAL
  if (window.location.hash.includes('#access_token') === false) {
    ReactDOM.render(<App />, document.getElementById('root'));
  }
};

render();
