import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import * as Form from '~ui/Form';

import translation from './CreateAlertRule.translation';
import AlertRuleForm from '../../../components/AlertRuleForm';
import formTranslations from '../../../components/AlertRuleForm/AlertRuleForm.translation';
import { formSpec } from '../../../helpers';

const defaultProps = {};
const propTypes = {
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  onCreateAlertRule: PropTypes.func.isRequired,
  resourceName: PropTypes.string.isRequired,
};

const CreateAlertRule = ({
  onMount,
  onUnmount,
  onCreateAlertRule,
  resourceName,
}) => {
  const { t } = translation;
  const formData = Form.useFormBuilder(formSpec(formTranslations));
  const [loading, setLoading] = useState(false);
  const [metricDefinitions, setMetricDefinitions] = useState([]);
  const { params: { customerId, entityId } } = useRouteMatch();

  useEffect(() => {
    onMount({
      entityId,
      customerId,
      setMetricDefinitions,
      setLoading,
    });

    return () => onUnmount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formData.updateFields([
      { name: 'metric_name', options: metricDefinitions },
      { name: 'depends_on_metrics', options: metricDefinitions },
      { name: 'valid_from ', value: '0' },
      { name: 'valid_from_dt', value: moment() },
      { name: 'valid_to', value: '0' },
      { name: 'valid_to_dt', value: moment() },
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricDefinitions]);

  return (
    <>
      <AlertRuleForm
        resourceName={resourceName}
        titleText={t('titleText')}
        buttonText={t('buttonText')}
        loading={loading}
        formHook={formData}
        onSubmitForm={onCreateAlertRule}
      />
    </>
  );
};

CreateAlertRule.defaultProps = defaultProps;
CreateAlertRule.propTypes = propTypes;

export default CreateAlertRule;
