import { connect } from 'react-redux';
import CloudOpsServiceForm from './components/ServiceForm/ServiceForm';
import { onMount, onUnmount, onUpdateService } from './actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onMount: (payload) => dispatch(onMount(payload)),
  onUnmount: (payload) => dispatch(onUnmount(payload)),
  onUpdateService: (payload) => dispatch(onUpdateService(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CloudOpsServiceForm);
