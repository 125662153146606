import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'PopOverDetail',
  sv: {
    ticket: 'Ärende',
    ticketId: 'Ärendenummer',
    resourceName: 'Resursnamn',
    resource: 'Resurs',
    subject: 'Ämne',
    modified: 'Ändrad',
    type: 'Typ',
    alertRule: 'Larmregel',
    alertDescription: 'Larmbeskrivning',
    resourceGroup: 'Resursgrupp',
    containerName: 'Container namn',
    goToResource: 'Gå till resurs',
    goToTicket: 'Gå till ärende',
    backButtonText: 'Tillbaka till larmöversikt',
  },
  en: {
    ticket: 'Ticket',
    ticketId: 'Ticket id',
    resourceName: 'Resource name',
    resource: 'Resource',
    subject: 'Subject',
    modified: 'Modified',
    type: 'Type',
    alertRule: 'Alert rule',
    alertDescription: 'Alert description',
    resourceGroup: 'Resource group',
    containerName: 'Container name',
    goToResource: 'Go to resource',
    goToTicket: 'Go to ticket',
    backButtonText: 'Back to Alert overview',
  },
});
