import Api from '../../../../../../../lib/api';

const getServicesForEntity = async ({
  customerId,
  entityId,
}) => {
  const path = `customer/${customerId}/entity/${entityId}/cloudopsservice/mapping`;

  return Api.get(path)
    .then((success) => ({ success }))
    .catch((error) => {
      if (error.response && error.response.data) {
        return { error: error.response.data };
      }

      return { error };
    });
};

export default getServicesForEntity;
