import PropTypes from 'prop-types';

export const listFilterType = PropTypes.shape({
  query: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  filter: PropTypes.object,
  order: PropTypes.string,
  order_desc: PropTypes.bool,
  page: PropTypes.number,
});

export const attachmentType = PropTypes.shape({
  attachment_url: PropTypes.string.isRequired,
  content_type: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  updated: PropTypes.string.isRequired,
});
