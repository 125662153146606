import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AlertStatus',
  sv: {
    serviceWindow: 'Aktivt servicefönster',
    serviceWindowShort: 'Servicefönster',
    allGood: 'Allt bra',
    allGoodShort: 'Allt bra',
    urgent: 'Brådskande',
    urgentShort: 'Brådskande',
    high: 'Hög',
    highShort: 'Hög',
    medium: 'Medium',
    mediumShort: 'Medium',
    low: 'Låg',
    lowShort: 'Låg',
    alertNotApplicable: 'Larmregel inte tillämplig',
    alertNotApplicableShort: 'N/A',
  },
  en: {
    serviceWindow: 'Active service window window',
    serviceWindowShort: 'Service window',
    allGood: 'All good',
    allGoodShort: 'All good',
    urgent: 'Urgent',
    urgentShort: 'Urgent',
    high: 'High',
    highShort: 'High',
    medium: 'Medium',
    mediumShort: 'Medium',
    low: 'Low',
    lowShort: 'Low',
    alertNotApplicable: 'Alert rule not applicable',
    alertNotApplicableShort: 'N/A',
  },
});
