import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'Servicewindow.Edit.CustomerPicker',
  sv: {
    title: 'Välj kund',
    customer: 'Kund',
    customerPlaceholder: 'Ange en kund',

    searchPlaceholder: 'Sök efter en kund',
    placeHolder: '',
    formSubject: 'formSubject - formSubject',

    label: 'label - label',
  },
  en: {
    title: 'Select customer',
    customer: 'Customer',
    customerPlaceholder: 'Choose a customer',

    searchPlaceholder: 'Search for a customer',
    placeHolder: '',
    label: 'label - label',

  },
});
