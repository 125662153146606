import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import * as Block from '~ui/Block';
import { Spinner } from '@chakra-ui/react';
import TicketsList from './TicketsList';
import translation from './AnalyticRule.translation';

import {
  useAnalyticRuleTickets,
} from '../../../../lib/services/analyticRules';

const { t } = translation;

const splitOpenClosed = (arr = []) => arr.reduce((acc, cur) => {
  const status = cur.status === 'closed' ? 'closed' : 'open';
  return { ...acc, [status]: [...(acc[status] || []), cur] };
}, {});

const Tickets = () => {
  const { params } = useRouteMatch();
  const {
    customerId,
    analyticRuleId,
  } = params;

  const {
    isError,
    isLoading,
    data: tickets,
  } = useAnalyticRuleTickets(customerId, analyticRuleId);

  const splitTickets = useMemo(() => {
    if (tickets?.result) {
      return splitOpenClosed(tickets.result);
    }
    return null;
  }, [tickets]);

  if (isError) {
    return <p>{t('errorTicketFetch')}</p>;
  }

  if (isLoading) {
    return (
      <Block.Center withPadding>
        <Spinner
          color="#133A5D"
          emptyColor="#DADADA"
          speed="0.65s"
          thickness="4px"
          size="xl"
        />
      </Block.Center>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      {!splitTickets?.open && !splitTickets.closed && (
        <div>
          <p>{t('noRelatedTickets')}</p>
        </div>
      )}

      {splitTickets?.open && (
        <div>
          <h6>{t('openTickets')}</h6>
          <TicketsList tickets={splitTickets.open} customerId={customerId} />
        </div>
      )}

      {splitTickets?.closed && (
        <div>
          <h6>{t('closedTickets')}</h6>
          <TicketsList tickets={splitTickets.closed} customerId={customerId} />
        </div>
      )}
    </div>
  );
};

export default Tickets;
