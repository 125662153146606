import React from 'react';
import PropTypes from 'prop-types';
import {
  Tag, Tooltip, TagLabel, UnorderedList, ListItem,
} from '@chakra-ui/react';

const propTypes = {
  tags: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

const defaultProps = {};

const TagElements = ({ tags }) => {
  const tooltipLabel = (
    <UnorderedList>
      {tags.map((tag) => (
        <ListItem>
          {tag?.key}
          :
          {tag?.value}
        </ListItem>
      ))}
    </UnorderedList>
  );

  const displayValue = `${tags[0]?.key}:${tags[0]?.value}`;

  return (
    <Tag bg="#f5edd8" position="relative" style={{ marginRight: '20px' }}>
      <Tooltip
        hasArrow
        placement="top"
        label={tooltipLabel}
      >
        <TagLabel isTruncated>
          {displayValue}
        </TagLabel>
      </Tooltip>
      { tags.length > 1 && (
      <span style={{ position: 'absolute', right: '-20px', fontSize: '12px' }}>
        {(' +')}
        {tags.length - 1}
      </span>
      )}
    </Tag>
  );
};

TagElements.propTypes = propTypes;
TagElements.defaultProps = defaultProps;

export default TagElements;
