import { connect } from 'react-redux';
import CreateAlertRule from './components/CreateAlertRule';
import * as A from './actions';

const mapStateToProps = (state) => ({ resourceName: state?.model?.entity?.name });

const mapDispatchToProps = (dispatch) => ({
  onMount: (payload) => dispatch(A.onMount(payload)),
  onUnmount: (payload) => dispatch(A.onUnmount(payload)),
  onCreateAlertRule: (payload) => dispatch(A.onCreateAlertRule(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAlertRule);
