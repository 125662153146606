import { useQuery, useMutation } from 'react-query';
import * as Permission from '~ui/Permission';
import { searchEntities } from './entities';
import Api from '../api';

const createFilterString = (arr) => {
  if (arr?.length > 0) {
    const str = arr.reduce((acc, cur, ind) => {
      const newString = `${acc} id eq '${cur}'`;

      return ind === arr.length - 1 ? newString : `${newString} or`;
    }, '');

    return str.trim();
  }
  return '';
};

export const searchServiceWindows = async ({
  payload,
  isGlobalAdmin,
  customerId,
}) => {
  let path = `customer/${customerId}/servicewindow/search`;

  if (isGlobalAdmin) {
    path = '/servicewindow/search';
  }

  return Api.post(path, payload);
};

export const useSWByTempID = (customerId, tempTicketId, isGlobalAdmin, config) => {
  const getSWByTempID = async () => {
    const path = isGlobalAdmin
      ? `/ticket/?tempId=${tempTicketId}`
      : `customer/${customerId}/ticket/?tempId=${tempTicketId}`;
    return Api.get(path)
      .then(({ result }) => result);
  };

  return useQuery(
    ['sw_tempid', tempTicketId],
    () => getSWByTempID(), {
      ...config,
    },
  );
};

export const useUpdateSW = (config) => {
  const updateServiceWindow = async ({ payload, customerId, swId }) => {
    const path = `customer/${customerId}/servicewindow/${swId}`;
    return Api.put(path, payload);
  };

  return useMutation(updateServiceWindow, { ...config });
};

export const useCreateSW = (config) => {
  const postServiceWindow = async ({ customerId, payload }) => {
    const path = `customer/${customerId}/servicewindow`;

    return Api.post(path, payload);
  };

  return useMutation(postServiceWindow, { ...config });
};

export const useGetServiceWindow = (customerId, swId, config) => {
  const getServiceWindow = async () => {
    const path = `customer/${customerId}/servicewindow/${swId}`;

    const res = await Api.get(path);
    return res;
  };

  return useQuery(
    ['service_window', swId],
    () => getServiceWindow(),
    { ...config },
  );
};

export const useGetServiceWindowEntities = (
  customerId,
  entityIds,
  config,
) => {
  const permissions = Permission.usePermission();

  return useQuery(
    ['useGetServiceWindowEntities', customerId, entityIds],
    () => searchEntities({
      customerId,
      global: permissions.isGlobalAdmin() && !customerId,
      payload: {
        search_string: '',
        filter: createFilterString(entityIds),
        order_by: ['name desc'],
        page_size: 1000,
        page: 1,
      },
    }), { enabled: !!entityIds, ...config },
  );
};
