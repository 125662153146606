import React from 'react';
import PropTypes from 'prop-types';
import {
  GridItem,
  IconButton,
  Flex,
  Select,
} from '@chakra-ui/react';
import MdiIcon from '@mdi/react';
import { mdiSort } from '@mdi/js';

/*
  This component is used togheter with the
  useLocalSort hook to provide a local sorting without the need of backend as in TableProvider
*/
export const LocalSort = ({
  sort, activeSortKey, orderDesc, onSort, size,
}) => {
  const handeSortKeyChange = ({ target }) => {
    const value = target.value ?? '';
    onSort(value);
  };

  const onHandleSortDirection = () => {
    onSort(activeSortKey);
  };

  return (
    <GridItem minWidth={40}>
      <Flex>
        <Select
          isTruncated
          borderRightRadius={0}
          onChange={handeSortKeyChange}
          value={activeSortKey || ''}
          size={size}
        >
          {sort.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
        <IconButton
          variant="outline"
          borderLeft="none"
          borderLeftRadius={0}
          borderRightRadius={5}
          onClick={onHandleSortDirection}
          size={size}
          icon={(
            <MdiIcon
              size={0.8}
              path={mdiSort}
              rotate={orderDesc ? 180 : 0}
            />
          )}
        />
      </Flex>
    </GridItem>
  );
};

LocalSort.propTypes = {
  sort: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  activeSortKey: PropTypes.string.isRequired,
  orderDesc: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
  size: PropTypes.string,
};

LocalSort.defaultProps = {
  size: 'md',
};

export default LocalSort;
