/* eslint-disable max-len */
import {
  put,
  take,
  fork,
  call,
  select,
  cancel,
  takeEvery,
  cancelled,
} from 'redux-saga/effects';
import { EventType as MsalEventType } from '@azure/msal-browser';
import moment from 'moment';
import * as i18n from '~ui/translations';
import { setScopeUserEmailErrorLogging } from '../utils/Sentry';
import { setGAUserInfo } from './helpers';
import * as t from './actionTypes';
import * as model from '../model';
import {
  isInitiated,
  isUserAuthed,
  setAccountState,
  applicationIsReady,
} from './actions';

import * as auth from './services/auth.service';
import * as graph from './services/graph.service';

import getUserContextService from './services/getUserContext.service';
import getCustomerService from './services/getCustomer.service';
import updateUserContextService from './services/updateUserContext.service';

const { i18next } = i18n;

const getUserContext = (state) => state.model.userContext;
const getRedirectedFromOrigin = (state) => state.application.redirectedFromOrigin;

function* loginRedirectTask() {
  const origin = yield select(getRedirectedFromOrigin);
  yield call(auth.triggerLoginRedirect, origin);
}

function* logoutTask() {
  yield call(auth.triggerLogout);
  yield put(isUserAuthed(false));
}

function* updateLanguageTask(onChangeLanguageCallback, { meta: { language } }) {
  const userContext = Object.assign(
    yield select(getUserContext), {
      preferred_language: language,
    },
  );
  yield call(onChangeLanguageCallback, language);

  i18next.changeLanguage(language);
  moment.locale(language);
  yield call(updateUserContextService, userContext);
}

function* checkSessionStateTask() {
  try {
    if (yield call(auth.userIsLoggedIn)) {
      const origin = yield select(getRedirectedFromOrigin);

      const { success: accessObject, error } = yield call(auth.loginSession, origin);

      if (!error) {
        const { expiresOn, idToken, accessToken } = accessObject;

        if (expiresOn > new Date()) {
          yield put(setAccountState(accessToken, idToken, expiresOn));

          yield put(isUserAuthed(true));
        } else {
          yield put(isUserAuthed(false));
        }
      } else {
        yield put(isUserAuthed(false, error));
      }
    } else {
      yield put(isUserAuthed(false));
    }
  } finally {
    if (yield cancelled()) {
      // eslint-disable-next-line no-console
      console.log('Cancel all tasks');
    }
  }
}

function* actionWatcher(onChangeLanguageCallback) {
  try {
    yield takeEvery(t.LOGIN, loginRedirectTask);
    yield takeEvery(t.LOGOUT, logoutTask);
    yield takeEvery(t.SET_LANGUAGE, updateLanguageTask, onChangeLanguageCallback);
  } finally {
    if (yield cancelled()) {
      // eslint-disable-next-line no-console
      console.log('Cancel all tasks');
    }
  }
}

function* loginWatcherTask() {
  const { meta: { accessToken, idToken, expiresOn } } = yield take(MsalEventType.LOGIN_SUCCESS);
  yield put(setAccountState(accessToken, idToken, expiresOn));
}

function* getUserInformationTask(onUserContextCallback) {
  const { meta: { accessToken } } = yield take(t.SET_ACCOUNT_STATE);

  // TODO: provide userCameFromLogin

  const userContextPayload = {
    userGroups: null,
    userPhoto: null,
  };

  /**
   * Disabled for:
   * Remove memberof call in frontend #880
   * https://github.com/asurgent/admin/issues/880

    const userGroups = yield call(graph.getUserGroups, accessToken);
    Object.assign(userContextPayload, {
      userGroups,
    });
  */

  const userCameFromLogin = yield select(getRedirectedFromOrigin);
  // Only fetch userPhoto and userGroups when user logsin
  if (userCameFromLogin) {
    const userPhoto = yield call(graph.getUserPhoto, accessToken);
    Object.assign(userContextPayload, {
      userPhoto,
    });
  }

  const {
    success: userContext,
    error: errorUserContex,
  } = yield call(getUserContextService, userContextPayload, userCameFromLogin);

  if (!errorUserContex) {
    const {
      success: customer,
      error: errorCustomer,
    } = yield call(getCustomerService, userContext.customer_id);

    const { preferred_language: language } = userContext;
    i18next.changeLanguage(language);
    moment.locale(language);

    yield call(onUserContextCallback, userContext, language);

    if (!errorCustomer) {
      // Clean up usercontext from unwanted/unused widgets.
      // This can be removed whenever ALL users have had their userContext updated at least once

      yield put({ type: model.USER_CONTEXT_SET, meta: { userContext } });
      yield put({ type: model.CUSTOMER_SET, meta: { customer } });

      const customerRoles = customer.role_feature_mappings.map((roleFeature) => roleFeature?.role);

      const sentryTags = {
        userId: userContext.oid,
        userRoles: customerRoles,
        customerId: userContext.customer_id,
      };

      yield call(setScopeUserEmailErrorLogging, sentryTags);

      setGAUserInfo({ userContext, customer });

      yield put(applicationIsReady());
      return true;
    }
  }

  yield put(isUserAuthed(false));
  return false;
}

export default function* main() {
  while (true) {
    const { meta } = yield take(t.MOUNT);
    const { onUserContextCallback, onChangeLanguageCallback } = meta;

    const tasks = [
      yield fork(checkSessionStateTask),
      yield fork(loginWatcherTask),
      yield fork(getUserInformationTask, onUserContextCallback),
      yield fork(actionWatcher, onChangeLanguageCallback),
    ];

    yield put(isInitiated(true));
    yield take(t.UNMOUNT);
    yield cancel(tasks);
  }
}
