import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'OverviewTable',
  sv: {
    resource: 'Resurs',
    customer: 'Kund',
  },
  en: {
    resource: 'Resource',
    customer: 'Customer',
  },
});
