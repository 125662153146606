import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  fill: PropTypes.string.isRequired,
};

const defaultProps = {};

const UrgentIcon = ({ fill }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill || 'currentColor'} d="M8.25 11.25H4.5L9.75 0.75V6.75H13.5L8.25 17.25V11.25Z" />
  </svg>
);

UrgentIcon.propTypes = propTypes;
UrgentIcon.defaultProps = defaultProps;

export default UrgentIcon;
