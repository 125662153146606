import styled from 'styled-components';

export const Title = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    color: #000;
    margin-left: 1.125rem;
    margin-top: 1.25rem;
    margin-bottom: 1rem;
`;

// move to UI after migration
export const BlockGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 1rem;

  @media screen and (min-width: 690px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1070px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (min-width: 1580px) {
    grid-gap: 1.5rem;
    grid-template-columns: repeat(4, 1fr);
  }
`;
