import Api from '../../../../../lib/api';

const getAlertRuleById = async ({ customerId, entityId, alertRuleId }) => {
  const path = `customer/${customerId}/entity/${entityId}/alertrule/${alertRuleId}`;

  return Api.get(path)
    .then((success) => ({ success }))
    .catch((error) => ({ error }));
};

export default getAlertRuleById;
