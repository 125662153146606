import addTranslation from '~ui/translations/addTranslation';

const actionDesc = (id) => `${id}-desc`;
const fieldLabel = (id, key) => `property-validation-auto-action-${id}-${key}`;
const fieldDesc = (id, key) => `property-validation-auto-action-${id}-${key}-description`;

// Actions without paramters
const checkIfMetricIsStillAlertingId = '7a116520-ae05-4919-afc7-13cf8a2669d5';
const startWindowsServiceId = '64d9af93-8d03-48e3-8ff6-1136f90f6a42';
const startIISWebSiteId = 'EB2FF733-FCDF-4748-8ED4-BE45165DCD09';
const startIISAppPoolId = '59D17959-0A3F-49F3-AF88-4996AEE9BCAF';

// Actions with paramters
const emailId = '4ACE20BC-2DC4-437E-99F3-D1B3C2F91955';
const voiceCallId = '5981950E-596D-44A7-9CFB-F95A50C096F5';
const updateTicketStatusID = '976c9ae5-0575-4e72-8471-498df585f7b9';
const updateTicketAssigneeGroupId = '2EED542B-D062-4ADA-819F-AC54501A57F6';
const delayId = 'bc20c8bb-2d0f-454a-928c-842d9347e18f';
const updateTicketPriorityAutoActionId = 'a42953f1-e203-4188-a426-0842b549548c';
const tagTicketAutoActionId = '1b5a7671-3d90-437d-a7b0-5d3790669268';
const restartIISAppPoolId = '3ABFE41D-21AA-4109-9D0F-6C836606F9AF';
const smsId = '0322C832-27B3-4CF0-9ABE-180DCCDE897B';

export default addTranslation({
  id: 'AutoActionsTranslations',
  sv: {
    [emailId]: 'Email',
    [actionDesc(emailId)]: 'Skickar Email till en mottagare',
    [voiceCallId]: 'Telefonsamtal',
    [actionDesc(voiceCallId)]: 'Ringer upp ett nummer och läser upp ett automatiskt meddelande',
    [updateTicketStatusID]: 'Uppdatera ärendestatus',
    [actionDesc(updateTicketStatusID)]: 'Uppdaterar ärende enligt konfiguration',
    [updateTicketAssigneeGroupId]: 'Uppdatera tilldelad grupp',
    [actionDesc(updateTicketAssigneeGroupId)]: 'Uppdaterar ärende med den tilldelade gruppen som blivit konfigurerad',
    [delayId]: 'Fördröjning',
    [actionDesc(delayId)]: 'Fördröjer processen efter angivna minuter',
    [checkIfMetricIsStillAlertingId]: 'Kontrollera att mätvärde fortfarande larmar',
    [actionDesc(checkIfMetricIsStillAlertingId)]: 'Om det inte larmar, avbryt sekvensen. Om den larmar så fortsätt med sekvensen',
    [startWindowsServiceId]: 'Starta Windowstjänst',
    [actionDesc(startWindowsServiceId)]: 'Skicka instruktion till VM-agenten för att starta/start om Windowstjänsten',
    [updateTicketPriorityAutoActionId]: 'Uppdatera ärendeprioritet',
    [actionDesc(updateTicketPriorityAutoActionId)]: 'Uppdatera ärendeprioritet till angivet värde',
    [tagTicketAutoActionId]: 'Tagga ärende',
    [actionDesc(tagTicketAutoActionId)]: 'Lägg till en eller flera taggar till ett ärende',
    [startIISWebSiteId]: 'Starta IIS Website',
    [actionDesc(startIISWebSiteId)]: 'Skicka instruktion till VM-agenten för att starta en  IIS Website',
    [startIISAppPoolId]: 'Start IIS App Pool',
    [actionDesc(startIISAppPoolId)]: 'Skicka instruktion till VM-agenten för att starta en  IIS  Application Pool',
    [restartIISAppPoolId]: 'Start om IIS App Pool',
    [actionDesc(restartIISAppPoolId)]: 'Skicka instruktion till VM-agenten för att starta en eller flera IIS  Application Pool. Den kommer även försöka starta app pooler är avstängda',
    [smsId]: 'SMS',
    [actionDesc(smsId)]: 'Skickar SMS till telefonnummer',

    [fieldLabel(emailId, 'to')]: 'Till',
    [fieldDesc(emailId, 'to')]: 'Ange mottagarens emailadress, multipla emailadresser måste separeras med semikolon',
    [fieldLabel(emailId, 'subject')]: 'Ämne',
    [fieldDesc(emailId, 'subject')]: 'Emailets ämnesrad',
    [fieldLabel(emailId, 'body')]: 'Meddelande',
    [fieldDesc(emailId, 'body')]: 'Email meddelande',
    [fieldLabel(emailId, 'includeTicketInformation')]: 'Inkludera Ärendeinformation',
    [fieldDesc(emailId, 'includeTicketInformation')]: 'Inkludera Ärendeinformation i email meddelande. Värden true/false',

    [fieldLabel(voiceCallId, 'phoneNumber')]: 'Telefonnummer',
    [fieldDesc(voiceCallId, 'phoneNumber')]: 'Ange telefonnummer som skall bli uppringt',
    [fieldLabel(voiceCallId, 'message')]: 'Ärendenotering',
    [fieldDesc(voiceCallId, 'message')]: 'Notering som kommer inkluderas i ärendet',
    [fieldLabel(voiceCallId, 'startHour')]: 'Starttimme',
    [fieldDesc(voiceCallId, 'startHour')]: 'Ring från och med denna timme på dygnet',
    [fieldLabel(voiceCallId, 'endHour')]: 'Sluttimme',
    [fieldDesc(voiceCallId, 'endHour')]: 'Ring till och med denna timme på dygnet',
    [fieldLabel(voiceCallId, 'timeZone')]: 'Tidszon',
    [fieldDesc(voiceCallId, 'timeZone')]: "Namn på tidszon, e.g 'W. Europe Standard Time'",
    [fieldLabel(voiceCallId, 'useFullDayOnWeekends')]: 'Använd hela dagar på helgen',
    [fieldDesc(voiceCallId, 'useFullDayOnWeekends')]: 'Detta aktiverar 24/7 samtal under helger',
    [fieldLabel(voiceCallId, 'language')]: 'Språk',
    [fieldDesc(voiceCallId, 'language')]: 'Språkkoder för meddelanden. e.g sv-SE, en-US',

    [fieldLabel(updateTicketStatusID, 'ticketStatus')]: 'Ärendestatus',
    [fieldDesc(updateTicketStatusID, 'ticketStatus')]: 'Statusen som ärendet skall uppdateras till',
    [fieldLabel(updateTicketStatusID, 'ticketNote')]: 'Ärendenotering',
    [fieldDesc(updateTicketStatusID, 'ticketNote')]: 'Notering som skrivs till ärendet',

    [fieldLabel(updateTicketAssigneeGroupId, 'assigneeGroup')]: 'Tilldelad grupp',
    [fieldDesc(updateTicketAssigneeGroupId, 'assigneeGroup')]: 'Grupper som kommer tilldelas ärendet',

    [fieldLabel(delayId, 'delayInMinutes')]: 'Fördröjning I Minuter',
    [fieldDesc(delayId, 'delayInMinutes')]: 'Ange antal minuter för fördröjningen',
    [fieldLabel(delayId, 'completeActionIfIncidentIsResolved')]: 'Slutför åtgärden om incident blir löst',
    [fieldDesc(delayId, 'completeActionIfIncidentIsResolved')]: 'Slutför åtgärden om incident blir löst',

    [fieldLabel(updateTicketPriorityAutoActionId, 'ticketPriority')]: 'Ärendeprioritet',
    [fieldDesc(updateTicketPriorityAutoActionId, 'ticketPriority')]: 'Uppdaterar ärendet till angiven prioritet',
    [fieldLabel(updateTicketPriorityAutoActionId, 'ticketNote')]: 'Ärendenotering',
    [fieldDesc(updateTicketPriorityAutoActionId, 'ticketNote')]: 'Notering som skrivs till ärendet',

    [fieldLabel(tagTicketAutoActionId, 'tag')]: 'Tagga ärende',
    [fieldDesc(tagTicketAutoActionId, 'tag')]: 'Ärendetagg, multipla taggar måste separeras med semikolon',
    [fieldLabel(tagTicketAutoActionId, 'ticketNote')]: 'Ärendenotering',
    [fieldDesc(tagTicketAutoActionId, 'ticketNote')]: 'Notering som skrivs till ärendet',

    [fieldLabel(restartIISAppPoolId, 'appPoolNames')]: '',
    [fieldDesc(restartIISAppPoolId, 'appPoolNames')]: '',

    [fieldLabel(smsId, 'phoneNumber')]: 'Telefonnummer',
    [fieldDesc(smsId, 'phoneNumber')]: 'Telefonnummer som meddelandet kommer skickas till',
    [fieldLabel(smsId, 'message')]: 'Meddelande',
    [fieldDesc(smsId, 'message')]: 'Meddelade som skickas i SMS-et',
  },
  en: {
    [emailId]: 'Email',
    [voiceCallId]: 'Voice Call',
    [updateTicketStatusID]: 'Update Ticket Status',
    [updateTicketAssigneeGroupId]: 'Update Ticket Assignee Group',
    [delayId]: 'Delay',
    [checkIfMetricIsStillAlertingId]: 'Check If Metric Is Still Alerting',
    [startWindowsServiceId]: 'Start Windows Service',
    [updateTicketPriorityAutoActionId]: 'Update Ticket Priority AutoAction',
    [tagTicketAutoActionId]: 'Tag Ticket Auto Action',
    [startIISWebSiteId]: 'Start IIS WebSite',
    [startIISAppPoolId]: 'Start IIS AppPool',
    [restartIISAppPoolId]: 'Restart IIS AppPool',
    [smsId]: 'SMS',

    [fieldLabel(emailId, 'to')]: 'To',
    [fieldLabel(emailId, 'subject')]: 'Subject',
    [fieldLabel(emailId, 'body')]: 'Body',
    [fieldLabel(emailId, 'includeTicketInformation')]: 'Include Ticket Information',
    [fieldLabel(voiceCallId, 'phoneNumber')]: 'Phone Number',
    [fieldLabel(voiceCallId, 'message')]: 'Message',
    [fieldLabel(voiceCallId, 'startHour')]: 'Start Hour',
    [fieldLabel(voiceCallId, 'endHour')]: 'End Hour',
    [fieldLabel(voiceCallId, 'timeZone')]: 'Time Zone',
    [fieldLabel(voiceCallId, 'useFullDayOnWeekends')]: 'Use Full Day On Weekends',
    [fieldLabel(voiceCallId, 'language')]: 'Language',
    [fieldLabel(updateTicketStatusID, 'ticketStatus')]: 'Ticket Status',
    [fieldLabel(updateTicketStatusID, 'ticketNote')]: 'Ticket Note',
    [fieldLabel(updateTicketAssigneeGroupId, 'assigneeGroup')]: 'Assignee Group',
    [fieldLabel(delayId, 'delayInMinutes')]: 'Delay In Minutes',
    [fieldLabel(delayId, 'completeActionIfIncidentIsResolved')]: 'Complete Action If Incident Is Resolved',
    [fieldLabel(updateTicketPriorityAutoActionId, 'ticketPriority')]: 'Ticket Priority',
    [fieldLabel(updateTicketPriorityAutoActionId, 'ticketNote')]: 'Ticket Note',
    [fieldLabel(tagTicketAutoActionId, 'tag')]: 'Tag',
    [fieldLabel(tagTicketAutoActionId, 'ticketNote')]: 'Ticket Note',
    [fieldLabel(restartIISAppPoolId, 'appPoolNames')]: 'AppPool Names',
    [fieldLabel(smsId, 'phoneNumber')]: 'Phone Number',
    [fieldLabel(smsId, 'message')]: 'Message',
  },
});
