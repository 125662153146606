/* eslint-disable prefer-object-spread */
import * as i18n from '~ui/translations';
import { EventType as MsalEventType } from '@azure/msal-browser';
import * as api from '../lib/api';
import * as t from './actionTypes';

const { i18next } = i18n;

const initalState = {
  ready: false,
  jwt: null,
  authed: undefined,
  history: null,
  initiated: false,
  accessToken: null,
  expires: 0,
  unverifiedUser: false,
  redirectedFromOrigin: null,
  selectedLanguage: i18next.language ? i18next.language : 'en',
};

export default (state = initalState, { type, meta }) => {
  switch (type) {
    case t.MOUNT:
      return Object.assign({}, state, {
        history: meta.history,
      });

      // Probably deprecated from msal v1
    case t.SET_REDIRECT_ORIGIN:

      return Object.assign({}, state, {
        // This is when pedining requests fail after we have been
        // redirected to /login and we dont want to overwrite our state

        redirectedFromOrigin: (
          meta.path !== '/login'
            ? meta.path
            : state.redirectedFromOrigin
        ),
      });

    case api.API_TOKEN_EXPIRED:

      return Object.assign({}, state, {
        // This is when pedining requests fail after we have been
        // redirected to /login and we dont want to overwrite our state

        redirectedFromOrigin: (
          meta.fromOrigin !== '/login'
            ? meta.fromOrigin
            : state.redirectedFromOrigin
        ),
        authed: false,
      });

    case t.AUTHED:

      return Object.assign({}, state, {
        authed: meta.authed,
        silentFailed: meta.silentFailed,
      });

    case MsalEventType.ACQUIRE_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        authed: true,
        expires: meta.expiresOn,
        accessToken: meta.accessToken,
      });

    case MsalEventType.LOGIN_SUCCESS:

      return Object.assign({}, state, {
        authed: true,
        jwt: meta.idToken,
        accessToken: meta.accessToken,
        expires: meta.expiresOn,
      });

    case t.SET_ACCOUNT_STATE:
      return Object.assign({}, state, {
        accessToken: meta.accessToken,
        jwt: meta.jwt,
        expires: meta.expires,
      });
    case t.SET_JWT:
      return Object.assign({}, state, {
        jwt: meta.jwt,
        expires: meta.expires,
      });
    case t.SET_LANGUAGE:
      return Object.assign({}, state, {
        selectedLanguage: meta.language,
      });
    case t.INITIATED:
      return Object.assign({}, state, {
        initiated: meta.initiated,
      });
    case t.APPLICATION_READY:
      return Object.assign({}, state, {
        ready: meta.applicationReady,
      });
    case t.CLEAR_LOGIN_TRIGGERED: {
      return Object.assign({}, state, {
        wasLoginTriggeredManually: false,
      });
    }
    case t.LOGOUT:
      return Object.assign({}, state, {
        wasLoginTriggeredManually: false,
      });

    default:
      return state;
  }
};
