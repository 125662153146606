import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AppMonitor.Logs',
  sv: {
    errorTitle: 'Något gick fel',
    errorMessage: 'Kunde inte hämta loggarna för testkörningen',
    testRun: 'Testkörning',
  },
  en: {
    errorTitle: 'Something went wrong',
    errorMessage: 'Could not get logs for test run',
    testRun: 'Test Run',
  },
});
