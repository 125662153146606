import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import * as Form from '~ui/Form';

import AlertRuleForm from '../../../components/AlertRuleForm';
import translation from './EditAlertRule.translation';
import formTranslations from '../../../components/AlertRuleForm/AlertRuleForm.translation';
import { populateFormFromAlertRule, formSpec } from '../../../helpers';

const defaultProps = {};
const propTypes = {
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const EditAlertRule = ({
  onMount,
  onUnmount,
  onSubmit,
}) => {
  const { t } = translation;
  const formData = Form.useFormBuilder(formSpec(formTranslations));
  const [loading, setLoading] = useState(false);
  const [metricDefinitions, setMetricDefinitions] = useState([]);
  const [alertRule, setAlertRule] = useState(null);

  const { params: { customerId, entityId, alertRuleId } } = useRouteMatch();

  useEffect(() => {
    onMount({
      entityId,
      customerId,
      alertRuleId,
      setAlertRule,
      setMetricDefinitions,
      setLoading,
    });

    return () => onUnmount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (alertRule && metricDefinitions.length > 0) {
      const formattedFields = populateFormFromAlertRule({ alertRule, metricDefinitions });
      formData.updateFields(formattedFields);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricDefinitions, alertRule]);

  return (
    <AlertRuleForm
      titleText={t('titleText')}
      buttonText={t('buttonText')}
      loading={loading}
      formHook={formData}
      onSubmitForm={onSubmit}
    />
  );
};

EditAlertRule.defaultProps = defaultProps;
EditAlertRule.propTypes = propTypes;

export default EditAlertRule;
