import React from 'react';
import Navigation from './Navigation';

const withNavigation = (Component, title) => (props) => (
  <Navigation title={title}>
    { (workspace) => (<Component {...props} workspace={workspace} />) }
  </Navigation>
);

export default withNavigation;
