import { useMemo } from 'react';
import queryString from 'query-string';
import { URL_STATE_KEY } from '~scenes/securityHours/constants';

export default function useInitialValues(location) {
  return useMemo(() => {
    const initialState = {
      selectedCustomerId: null,
      selectedDate: new Date(),
    };
    const search = queryString.parse(location.search);

    const securityHoursStateValue = search[URL_STATE_KEY]
      ? JSON.parse(window?.atob(search[URL_STATE_KEY]))
      : null;

    if (!securityHoursStateValue) return initialState;

    const { selectedDate, selectedCustomerId } = securityHoursStateValue;

    // check if date is a valid date before instantiating a new Date object
    const isValidDate = (d) => d instanceof Date && !Number.isNaN(d);

    const dateObject = isValidDate(new Date(selectedDate)) ? new Date(selectedDate) : new Date();

    return {
      selectedCustomerId,
      selectedDate: dateObject,
    };
  }, [location]);
}
