import React from 'react';
import {
  Text, Link,
} from '@chakra-ui/react';

const formatPlainText = (textInput) => {
  if (typeof textInput === 'string') {
    const detectUrls = /(([a-z]+:\/\/)?(([a-z0-9-]+\.)+([a-z]{2}|com|se|org))(:[0-9]{1,5})?(\/[a-z0-9_\-.~]+)*(\/([a-z0-9_\-.]*)(\?[a-z0-9+_\-.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;

    return textInput?.split('\n').map((line, i, splittedByLineBreaksArray) => {
      const foundedUrls = line.match(detectUrls);

      const isLastLine = i === splittedByLineBreaksArray.length - 1;
      const isFirstLine = i === 0;

      const textStyle = {
        mt: isFirstLine ? 2 : 0,
        mb: isLastLine ? 5 : 2,
      };

      // eslint-disable-next-line react/no-array-index-key
      if (!foundedUrls) return <Text {...textStyle} key={i}>{line}</Text>;

      const { formatedLines } = foundedUrls.reduce((acc, currentUrl, reducerIndex) => {
        const { unHandledString } = acc;

        const [partBeforUrl, partAfterUrl] = unHandledString.split(currentUrl);

        acc.formatedLines = [...acc.formatedLines,
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={reducerIndex}>
            <span>{partBeforUrl}</span>
            <Link href={currentUrl}>{currentUrl}</Link>
          </React.Fragment>,
        ];
        acc.unHandledString = partAfterUrl;

        return acc;
      }, {
        formatedLines: [],
        unHandledString: line,
      });

      // eslint-disable-next-line react/no-array-index-key
      return <Text {...textStyle} key={i}>{formatedLines}</Text>;
    });
  }
  return null;
};

export default formatPlainText;
