import React from 'react';
import * as Block from '~ui/Block';
import * as Layout from '~ui/Layout';
import OnCallScene from '../../OnCall';
import Permissions from '../../Permissions';
import translation from './ActionsList.translation';

const { t } = translation;

const PATH_ROOT = '/admin';

const ActionsList = () => {
  const pageSwitch = () => {
    switch (window.location.pathname) {
      case `${PATH_ROOT}/permissions`:
        return <Permissions />;
      default:
        return <OnCallScene />;
    }
  };

  const titleSwitch = () => {
    switch (window.location.pathname) {
      case `${PATH_ROOT}/permissions`:
        return t('permissions');
      default:
        return t('onCall');
    }
  };

  return (
    <Layout.Scene>
      <Block.SubnavigationBlock
        title={titleSwitch()}
        navigationList={[
          {
            label: t('onCall'),
            path: PATH_ROOT,
            isActive: window.location.pathname === PATH_ROOT,
          },
          {
            label: t('permissions'),
            path: `${PATH_ROOT}/permissions`,
            isActive: window.location.pathname === `${PATH_ROOT}/permissions`,
          },
        ]}
      >
        { pageSwitch() }
      </Block.SubnavigationBlock>
    </Layout.Scene>
  );
};

export default ActionsList;
