import Api from '../../../../../lib/api';

const createTicket = async ({
  customerId,
  description,
  subject,
  email,
  priority,
  type,
  area,
}) => {
  const path = `customer/${customerId}/ticket`;

  const payload = {
    subject,
    email,
    description,
    priority: priority || 2,
    status: 'open',
    type: type || 'change',
    area,
  };

  return Api.post(path, payload)
    .then((success) => ({ success }))
    .catch((error) => {
      if (error?.response?.data) {
        return { error: error.response.data };
      }

      return { error };
    });
};

export default createTicket;
