import Api from '../../../../../../../lib/api';

const createAlertRule = async ({ customerId, entityId, payload }) => {
  const path = `customer/${customerId}/alertrule`;

  Object.assign(payload, {
    entity_id: entityId,
    enabled: 1,
  });

  return Api.post(path, payload)
    .then((success) => ({ success }))
    .catch((error) => {
      if (error?.response?.data) {
        return { error: error.response.data };
      }

      return { error };
    });
};

export default createAlertRule;
