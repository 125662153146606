import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import On24SevenUntillSetting from './components/On24SevenUntillSetting';
import SnoozeUntillSetting from './components/SnoozeUntillSetting';

const propTypes = {
  onUnmount: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  onUpdateOnCall: PropTypes.func.isRequired,
};

const OnCall = ({ onUpdateOnCall, ...props }) => {
  const [currentOnCall, setCurrentOnCall] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    props.onMount({
      setCurrentOnCall,
      setErrorMessage,
    });

    return () => props.onUnmount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (!errorMessage && (
    <>
      <SnoozeUntillSetting
        currentOnCall={currentOnCall}
        onUpdateOnCall={onUpdateOnCall}
      />
      <On24SevenUntillSetting
        style={{ marginTop: '2rem' }}
        onUpdateOnCall={onUpdateOnCall}
        currentOnCall={currentOnCall}
      />
    </>
  )
  );
};

OnCall.propTypes = propTypes;

export default OnCall;
