import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Block from '~ui/Block';
import { Button } from '~ui/Button';
import * as Form from '~ui/Form';
import { Heading, Text } from '@chakra-ui/react';

const propTypes = {
  currentOnCall: PropTypes.instanceOf(Object).isRequired,
  onUpdateOnCall: PropTypes.func.isRequired,
  style: PropTypes.instanceOf(Object),
};

const defaultProps = {
  style: {},
};

const OnCall = ({ onUpdateOnCall, currentOnCall, style }) => {
  const on24SevenForm = Form.useFormBuilder({
    on_24_7_until: {
      type: 'datepicker',
      label: 'on 24/7 until',
      minDate: moment().subtract(2, 'days').startOf('day').toISOString(),
    },
  });

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    on24SevenForm.updateFields([
      {
        name: 'on_24_7_until', value: currentOnCall.on_24_7_until,
      },
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOnCall.on_24_7_until]);

  return (
    <Block.Bordered noShadow withPadding style={style}>
      <Heading as="h2">Upcoming holiday?</Heading>
      <Text>
        {`
          This option turns on calling Service Desk 24/7
          regardless if it's currently business hours or not.
          Used for holidays and such. This is overridden by snooze function.
        `}
      </Text>

      <Form.Primary
        form={on24SevenForm}
        onSubmit={({ values, dirty }) => {
          if (dirty) {
            setErrorMessage('');
            setIsSaving(true);
            onUpdateOnCall({
              payload: {
                ...currentOnCall,
                ...values,
              },
              onSuccess: () => {
                setIsSaving(false);
              },
              onFailSave: (error) => {
                setIsSaving(false);
                setErrorMessage(error.message);
              },
            });
          } else {
            onUpdateOnCall();
          }
        }}
      >
        {({ render, onSubmitAction }) => (
          <>
            {render}
            <Block.SpaceBetween>
              <Button isLoading={isSaving} onClick={onSubmitAction}>
                Update
              </Button>
            </Block.SpaceBetween>
            { errorMessage && (
              <Block.Error withMargins>
                {errorMessage}
              </Block.Error>
            )}
          </>
        )}
      </Form.Primary>
    </Block.Bordered>
  );
};

OnCall.propTypes = propTypes;
OnCall.defaultProps = defaultProps;

export default OnCall;
