export const MOUNT = '@view.CreateTicketModule.mount';
export const UNMOUNT = '@view.CreateTicketModule.unmount';

export const CREATE = '@action.CreateTicketModule.form.submit';

export const RESTORE_STATE = '@action.CreateTicketModule.form.restoreState';
export const INIT_CREATE_NEW_TICKET = '@action.CreateTicketModule.form.initCreateNewTicket';
export const TICKET_ERROR = '@action.CreateTicketModule.form.submit.ticketError';
export const TICKET_CREATED = '@action.CreateTicketModule.form.submit.ticketCreated';
export const CREATE_TICKET_TASK_DONE = '@action.CreateTicketModule.form.submit.createTicketTaskDone';
