import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'AlertRuleListUpdateService',
  sv: {
    successEnable: 'Larmregel aktiverad',
    successDisable: 'Larmregel avaktiverad',
    failEnable: 'Misslyckades att aktivera Larmregel',
    failDisable: 'Misslyckades att avaktivera Larmregel',
  },
  en: {
    successEnable: 'Success! Alert Rule activated',
    successDisable: 'Success! Alert Rule deactivated',
    failEnable: 'Failed to activate Alert Rule',
    failDisable: 'Failed to deactivated Alert Rule',
  },
});
