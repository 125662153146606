import { useMutation, useQuery } from 'react-query';
import Api from '../api';

export const useFeatureRolesMutation = (config) => {
  const getFeaturesRoles = async () => {
    const path = `/authentication/permissions`;
    return Api.get(path);
  };

  return useMutation(getFeaturesRoles, { ...config });
};

export const useFeatureRolesQuery = (config) => {
  const getFeaturesRoles = async () => {
    const path = `/authentication/permissions`;
    return Api.get(path)
      .then(({ result }) => result);
  };

  return useQuery(
    ['authentication/permissions'],
    () => getFeaturesRoles(), {
      ...config,
    },
  );
};
