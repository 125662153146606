import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'TicketspageMain',
  sv: {
    ticketId: 'ID',
    emptystate: 'Inga ärenden hittades',
    searchLabel: 'Sök ärenden',
    subject: 'Ämne',
    tags: 'Taggar',
    modified: 'Ändrad',
    type: 'Typ',
    status: 'Status',
    customer: 'Kund',
    priority: 'Prioritet',

    priorityAvaliable: 'Tillgängliga',
    prioritySelected: 'Valda',
    priority1: 'Låg',
    priority2: 'Medium',
    priority3: 'Hög',
    priority4: 'Brådskande',

    filterDrawerTitle: 'Filtrera bland ärenden',
    changeCustomer: 'Ändra kund',
    changeType: 'Ändra typ',
    changePriority: 'Ändra prioritet',
    changeStatus: 'Ändra status',

    onlyNotClosed: 'Bara icke-stängda',
    metricName: 'Metric-namn',
  },
  en: {
    ticketId: 'ID',
    emptystate: 'No tickets found',
    searchLabel: 'Search tickets',
    subject: 'Subject',
    tags: 'Tags',
    modified: 'Modified',
    type: 'Type',
    status: 'Status',
    customer: 'Customer',
    priority: 'Priority',

    priorityAvaliable: 'Available',
    prioritySelected: 'Selected',
    priority1: 'Low',
    priority2: 'Medium',
    priority3: 'High',
    priority4: 'Urgent',

    filterDrawerTitle: 'Filter on tickets',
    changeCustomer: 'Change customer',
    changeType: 'Change type',
    changePriority: 'Change priority',
    changeStatus: 'Change status',

    onlyNotClosed: 'Only not closed',
    metricName: 'Metric name',
  },
});
